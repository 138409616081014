<template>
  <div class="page-wrapper idea-upload-adjustment">
    <nav class="page-header navbar navbar-secondary navbar-default">
      <div class="menu">
        <h3>{{ $t('UPLOADS.DETAIL.HEADING') }}</h3>
        <a href="#" @click.prevent="back" class="link-main icon-link">
          <Icon icon="close" />
          {{ $t('GENERAL.CLOSE') }}
        </a>
        <ProgressButton
          class="btn btn-primary"
          v-if="saveAdjustedAction || saveOriginalAction"
          :label="$t('BULK_ASSORTMENT.PT_SELECTION_CTA')"
          :on-click="saveSelection"
        />
      </div>
    </nav>
    <div class="page-content">
      <div class="overflow-y">
        <div>
          <div
            class="upload-adjustment-content"
            v-if="saveAdjustedAction || saveOriginalAction"
          >
            <div class="upload-header" v-if="saveAdjustedAction">
              <p>{{ $t(adjustedExplanation) }}</p>
              <a
                class="link-red icon-link delete-link"
                v-if="deleteAction"
                @click="action(deleteAction)"
              >
                <Icon icon="bin" />
                {{ $t('GENERAL.DELETE') }}
              </a>
            </div>
            <div class="versions">
              <div
                class="version"
                v-if="adjustedImage"
                :class="{
                  selected: selected === 'adjusted',
                }"
              >
                <a
                  href="#"
                  @click.prevent="setSelection('adjusted')"
                  class="image"
                >
                  <div class="image-header">
                    <div>
                      <h3>{{ $t('UPLOADS.DETAIL.ADJUSTED_FILE') }}</h3>
                      <p>{{ $t(adjustedImageDescription) }}</p>
                    </div>
                    <a
                      href="#"
                      class="img-bg-toggler"
                      @click.stop.prevent="
                        showDarkBgAdjusted = !showDarkBgAdjusted
                      "
                    >
                      <Icon icon="background-color" />
                    </a>
                  </div>
                  <div class="image-container">
                    <div class="image-inner-container">
                      <img
                        v-show="!showErrorImg"
                        :src="`${adjustedImage.href}?width=500`"
                        :class="{
                          dark: showDarkBgAdjusted,
                        }"
                        class="design-checked-background"
                      />
                      <img
                        v-if="errorImage"
                        v-show="showErrorImg"
                        :src="`${errorImage.href}?width=500`"
                        :class="{
                          dark: showDarkBgAdjusted,
                        }"
                        class="design-checked-background"
                      />
                    </div>
                  </div>
                </a>
                <div class="info" v-if="errorImage">
                  <a
                    href="#"
                    class="error-link link-blue"
                    @click.prevent="showErrorImg = !showErrorImg"
                  >
                    <Icon icon="eye" />
                    {{ $t('UPLOADS.DETAIL.CHECK_ADJUSTMENTS') }}
                  </a>
                </div>
              </div>
              <div
                class="version"
                v-if="originalImage"
                :class="{
                  selected: selected === 'original',
                }"
              >
                <a
                  href="#"
                  @click.prevent="setSelection('original')"
                  class="image"
                >
                  <div class="image-header">
                    <div>
                      <h3>
                        {{ $t('UPLOADS.DETAIL.ORIGINAL_FILE') }}
                      </h3>
                      <p>{{ $t(originalImageDescription) }}</p>
                    </div>
                    <a
                      href="#"
                      class="img-bg-toggler"
                      @click.stop.prevent="
                        showDarkBgOriginal = !showDarkBgOriginal
                      "
                    >
                      <Icon icon="background-color" />
                    </a>
                  </div>
                  <div class="image-container">
                    <div class="image-inner-container">
                      <img
                        :src="`${originalImage.href}?width=500`"
                        :class="{
                          dark: showDarkBgOriginal,
                        }"
                        class="design-checked-background"
                      />
                    </div>
                  </div>
                </a>
                <div
                  class="info"
                  v-if="uploadInteractions && uploadInteractions.colors"
                >
                  <strong> {{ $t('UPLOADS.DETAIL.COLORS_DETECTED') }}: </strong>
                  <div class="color-preview">
                    <div
                      v-for="color in uploadInteractions.colors.split(',')"
                      :key="color"
                      :title="`#${color}`"
                      :style="{ 'background-color': '#' + color }"
                      class="color"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="broken">
            <img src="/images/broken.svg" alt="broken-image" />
            <h2>
              {{ $t('UPLOADS.DETAIL.FILE_REJECTED_HINT') }}
            </h2>
            <p v-if="uploadError">{{ uploadError }}</p>
            <ProgressButton
              v-if="deleteAction"
              class="btn btn-danger icon-btn"
              icon="bin"
              label="GENERAL.DELETE"
              :onClick="() => action(deleteAction)"
            />
          </div>
          <GraphicsServiceBanner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import apiClient from '@/api/client/apiClient';
import GraphicsServiceBanner from 'src/app/components/banners/GraphicsServiceBanner.vue';

export default {
  name: 'IdeaUploadAdjustment',
  components: {
    ProgressButton,
    GraphicsServiceBanner,
  },
  data() {
    return {
      actions: {},
      images: {},
      showDarkBgAdjusted: false,
      showDarkBgOriginal: false,
      showErrorImg: false,
      uploadError: false,
      selected: 'adjusted',
    };
  },
  computed: {
    idea() {
      return this.$store.state.ideas.list.find(
        (idea) => idea.id === this.$route.params.ideaId
      );
    },
    UPLOAD_ERRORS() {
      return {
        TOO_MANY_PIXELS: {
          translation: 'DESIGNS.UPLOAD.LIST.VALIDATION.TOO_LARGE',
          params: (v) => ({ pixels: v }),
        },
        TOO_FEW_PIXELS: {
          translation: 'DESIGNS.UPLOAD.LIST.VALIDATION.TOO_SMALL',
          params: (v) => ({ pixels: v }),
        },
        SIDE_TOO_SHORT: {
          translation: 'DESIGNS.UPLOAD.LIST.VALIDATION.SIDE_TOO_SHORT',
          params: (v) => ({ pixels: v }),
        },
        WRONG_ASPECT_RATIO: {
          translation: 'DESIGNS.UPLOAD.LIST.VALIDATION.WRONG_FORMAT',
        },
        DEFAULT: {
          translation: 'DESIGNS.UPLOAD.LIST.VALIDATION.GENERAL_ERROR',
        },
      };
    },
    uploadInteractions() {
      return (
        this.idea.bitmapUploadInteractions || this.idea.vectorUploadInteractions
      );
    },
    adjustedImage() {
      if (!this.uploadInteractions || !this.uploadInteractions.images) {
        return null;
      }

      return this.uploadInteractions.images.find((image) =>
        ['CROPPED', 'CHECKED'].includes(image.type)
      );
    },
    originalImage() {
      if (!this.uploadInteractions || !this.uploadInteractions.images) {
        return null;
      }
      return this.uploadInteractions.images.find((image) =>
        ['ORIGINAL', 'PREVIEW'].includes(image.type)
      );
    },
    errorImage() {
      if (!this.uploadInteractions || !this.uploadInteractions.images) {
        return null;
      }
      return this.uploadInteractions.images.find((image) =>
        ['ERROR'].includes(image.type)
      );
    },
    deleteAction() {
      if (this.idea.assemblyDeletionInteraction) {
        return this.idea.assemblyDeletionInteraction.action;
      }

      if (!this.uploadInteractions || !this.uploadInteractions.userActions) {
        return null;
      }

      return this.uploadInteractions.userActions.find((action) =>
        ['DELETE'].includes(action.type)
      );
    },
    saveOriginalAction() {
      if (!this.uploadInteractions || !this.uploadInteractions.userActions) {
        return null;
      }

      return this.uploadInteractions.userActions.find((action) =>
        ['CONTINUE', 'BITMAP'].includes(action.type)
      );
    },
    saveAdjustedAction() {
      if (!this.uploadInteractions || !this.uploadInteractions.userActions) {
        return null;
      }

      return this.uploadInteractions.userActions.find((action) =>
        ['ACCEPT'].includes(action.type)
      );
    },
    originalImageDescription() {
      let description;

      if (this.idea.bitmapUploadInteractions) {
        description = 'UPLOADS.DETAIL.ORIGINAL_IMAGE.CROPPED_CASE';
      } else if (this.idea.vectorUploadInteractions) {
        description = 'UPLOADS.DETAIL.ORIGINAL_IMAGE.VECTOR_CASE';
      }

      return description;
    },
    adjustedImageDescription() {
      let description;

      if (this.idea.bitmapUploadInteractions) {
        description = 'UPLOADS.DETAIL.ADJUSTED_IMAGE.CROPPED_CASE';
      } else if (this.idea.vectorUploadInteractions) {
        description = 'UPLOADS.DETAIL.ADJUSTED_IMAGE.VECTOR_CASE';
      }

      return description;
    },
    adjustedExplanation() {
      let explanation;

      if (this.idea.bitmapUploadInteractions) {
        explanation = 'UPLOADS.DETAIL.CROPPED_CASE_HINT';
      } else if (this.idea.vectorUploadInteractions) {
        explanation = 'UPLOADS.DETAIL.COMPARE_IMAGE_HINT';
      }

      return explanation;
    },
  },
  created() {
    if (this.idea.assemblyDeletionInteraction) {
      const error = this.getUploadError(
        this.idea.assemblyDeletionInteraction.reason
      );
      this.uploadError = this.$t(
        error.translation,
        error.params
          ? error.params(this.idea.assemblyDeletionInteraction.violatedValue)
          : {}
      );
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: 'partnerarea.ideas',
        query: { restoreState: true },
      });
    },
    async action(action) {
      await apiClient.request({
        method: 'POST',
        url: action.href,
      });
      this.$router.push({ name: 'partnerarea.ideas' });
    },
    getUploadError(type) {
      return this.UPLOAD_ERRORS[type] || this.UPLOAD_ERRORS.DEFAULT;
    },
    setSelection(selected) {
      this.selected = selected;
    },
    saveSelection() {
      if (this.selected === 'original' && this.saveOriginalAction) {
        return this.action(this.saveOriginalAction);
      } else if (this.selected === 'adjusted' && this.saveAdjustedAction) {
        return this.action(this.saveAdjustedAction);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.idea-upload-adjustment {
  .menu {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    h3 {
      margin: 0;
    }

    a {
      margin-left: auto;
    }

    .btn {
      margin-left: 24px;
    }
  }

  .overflow-y {
    width: 100%;

    & > div {
      padding: 0 24px;
    }
  }

  .upload-adjustment-content {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    padding: 24px;
    margin-top: 24px;
  }

  .upload-header {
    display: flex;
    align-items: flex-start;

    p {
      color: $grey60;
      margin: 0 24px 0 0;
      max-width: 600px;
    }

    a {
      margin-left: auto;
    }
  }

  .versions {
    display: flex;
    margin: 24px -12px 0 -12px;

    .version {
      padding: 0 12px;
      width: 50%;
      max-width: 500px;

      .image-header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        & > div {
          margin-right: 24px;
        }
      }

      h3 {
        margin: 0 0 8px 0;
      }

      .image {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 4px;
        border: 2px solid $grey20;
        background-color: $grey5;

        & > p {
          color: $grey60;
          margin: 8px 0 24px 0;
        }

        .image-container {
          position: relative;

          &:before {
            content: ' ';
            padding-top: 100%;
            display: block;
          }

          .image-inner-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px;
          box-shadow: rgba(0, 0, 0, 0.16) 0 2px 4px;
        }
      }

      &.selected {
        .image {
          border-color: $pa-color-main;
        }
      }

      .info {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .color-preview {
          display: flex;
          justify-content: center;
          align-items: center;

          .color {
            border: 1px solid $sprd-color-darkest-grey;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }
        }

        .error-link {
          display: flex;
          align-items: center;

          .icon {
            font-size: 1.5em;
            margin-right: 8px;
          }
        }
      }

      .img-bg-toggler {
        height: 40px;
        width: 40px;
        background-color: $white;
        border: 2px solid $pa-color-main;
        border-radius: 4px;
        color: $pa-color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        flex-shrink: 0;

        .icon {
          width: 24px;
          height: 24px;
        }

        &:hover {
          color: $pa-color-main-dark;
        }
      }
    }
  }
}

.broken {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;

  img {
    margin-top: 104px;
    width: 256px;
  }
  h2 {
    margin: 32px 0 0 0;
  }

  p {
    margin: 16px 0 0 0;
  }

  .btn {
    margin-top: 24px;
    padding: 8px 32px;
  }
}
</style>
