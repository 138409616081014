<template>
  <div class="configuration-metadata">
    <div class="heading">
      <h1 v-if="translationsEditable">
        {{ $t('DESIGNS.CONFIGURATION.META_DATA.HEADING') }}
      </h1>
      <h1 v-else>
        {{ $t('DESIGNS.DETAILS.META_DATA_SUBHEADING') }}
      </h1>
    </div>

    <Banner
      class="banner"
      v-if="!isNonInternationalShopOnlyUser && !ideaPublished"
      heading="ONBOARD.META_DATA.HEADING"
      subheading="ONBOARD.META_DATA.SUBHEADING"
    />

    <div class="configuration-metadata-content">
      <div class="idea-settings">
        <CondensedIdeaPreviewImage :idea="idea" />
        <IdeaTranslationControl :idea="idea" />
        <MpTransformerModeControl
          :mpTransformerMode="idea.mpTransformerMode"
          @setMpTransformerMode="setMpTransformerMode"
          style="margin-left: auto"
        />
      </div>

      <div class="availableTranslations" v-if="translationsEditable">
        <ul>
          <li
            v-for="translation in manualTranslations"
            :key="translation.locale"
            :class="{
              selected: translation.locale === currentLocale,
              invalid: invalidLocales.includes(translation.locale),
            }"
          >
            <a href="#" @click.prevent="setCurrentLocale(translation.locale)">{{
              translation.name
            }}</a>
          </li>
          <li v-if="autoTranslations.length && !displayAutoTranslations">
            <button
              class="btn text-btn link-blue"
              @click="displayAutoTranslations = true"
            >
              {{
                `+ ${autoTranslations.length} ${$t(
                  'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.SHOW_BUTTON'
                )}`
              }}
            </button>
          </li>
        </ul>
        <ul v-if="autoTranslations.length && displayAutoTranslations">
          <li>
            <small class="text-grey">
              {{
                $t(
                  'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTO_TRANSLATIONS'
                )
              }}
            </small>
          </li>
          <li
            v-for="translation in autoTranslations"
            :key="translation.locale"
            :class="{
              selected: translation.locale === currentLocale,
              invalid: invalidLocales.includes(translation.locale),
            }"
          >
            <a href="#" @click.prevent="setCurrentLocale(translation.locale)">{{
              translation.name
            }}</a>
          </li>
        </ul>
      </div>

      <div class="configuration-metadata-block">
        <div class="data">
          <div v-if="isAutoTranslated" class="autotranslated-hint">
            <div class="icon-circle">
              <Icon icon="lightbulb" />
            </div>
            {{
              $t(
                'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATED_HINT'
              )
            }}
          </div>
          <div v-if="isAutoTranslationPending" class="autotranslated-hint">
            <div class="icon-circle">
              <Icon icon="lightbulb" />
            </div>
            {{
              $t(
                'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATIONS_PENDING_HINT'
              )
            }}
          </div>
          <div class="meta-data" v-if="currentLocale">
            <div class="meta-data-label-description">
              <h4>{{ $t('DESIGNS.NAME') }}</h4>
              <p class="text-grey text-sm">
                {{ $t('DESIGNS.DETAILS.NAME_HINT') }}
              </p>
            </div>
            <LabelInput
              class="meta-data-labelinput"
              :class="{ 'has-error': nameError }"
            >
              <input
                name="designName"
                type="text"
                :value="name"
                id="input-design-name"
                @input="setName"
                @change="checkBlacklistForField('name')"
                class="design-name-input"
                :maxlength="validatorOptions.name.max"
              />
              <div class="field-info">
                <small class="error-info error-info-name" v-if="!validName">
                  {{
                    $t('DESIGNS.VALIDATION.NAME.LENGTH', {
                      min: validatorOptions.name.min,
                      max: validatorOptions.name.max,
                    })
                  }}
                </small>
                <small v-else class="info">
                  {{ currentTranslation.name }}
                </small>
                <small
                  class="error-info error-info-name"
                  v-if="currentBlacklist && currentBlacklist.name.length"
                >
                  {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
                  {{ currentBlacklist.name.join(', ') }}
                </small>
                <small class="char-info design-name-chars-left"
                  >{{ name.length }} / {{ validatorOptions.name.max }}</small
                >
              </div>
            </LabelInput>
            <div class="meta-data-label-description">
              <h4>
                {{ $t('DESIGNS.DETAILS.DESCRIPTION_TITLE') }}
              </h4>
              <p
                class="text-grey text-sm"
                v-html="$t('DESIGNS.DETAILS.DESCRIPTION_HINT')"
              ></p>
            </div>
            <LabelInput :class="{ 'has-error': descriptionError }">
              <textarea
                name="description"
                :maxlength="validatorOptions.description.max"
                :value="description"
                rows="3"
                @input="setDescription"
                @change="checkBlacklistForField('description')"
                id="input-design-description"
              >
              </textarea>
              <div class="field-info">
                <small
                  class="error-info error-info-description"
                  v-if="currentBlacklist && currentBlacklist.description.length"
                >
                  {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
                  {{ currentBlacklist.description.join(', ') }}
                </small>
                <small v-else class="info">
                  {{ currentTranslation.name }}
                </small>
                <small class="char-info design-description-chars-left"
                  >{{ (description && description.length) || 0 }} /
                  {{ validatorOptions.description.max }}</small
                >
              </div>
            </LabelInput>
            <div class="meta-data-label-description">
              <h4>
                {{ $t('DESIGNS.TAGS') }}
              </h4>
              <p class="text-grey text-sm">
                {{ $t('DESIGNS.DETAILS.TAG_HINT') }}
              </p>
            </div>
            <IdeaTagInput
              v-if="idea && currentLocale"
              :idea="idea"
              :tags="tags"
              @addTag="addTag"
              @removeTag="removeTag"
              :hideLabel="true"
              :displayTagsLeft="true"
              :displayRelatedTags="true"
              :displayErrorText="true"
              @blacklistUpdate="onTagBlacklistUpdate"
              :locale="currentLocale"
              :validate="strictValidation"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabelInput from '@/labelInput/LabelInput.vue';
import ideaValidator from '@/api/validators/idea/ideaValidator';
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';
import { checkTerm } from '@/api/blacklistService/blacklistService';
import eventBus from '@/eventBus/eventBus';
import INTPUB_DECISION_STATES from '@/states/intpubDecisionStates';
import IdeaTranslationControl from './IdeaTranslationControl.vue';
import Banner from 'src/app/components/banners/Banner.vue';
import CondensedIdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/CondensedIdeaPreviewImage.vue';
import MpTransformerModeControl from 'src/app/components/publishing/MpTransformerModeControl.vue';
import IdeaTagInput from '@/tagInput/IdeaTagInput.vue';

export default {
  name: 'IdeaConfigMetaData',
  components: {
    LabelInput,
    IdeaTagInput,
    IdeaTranslationControl,
    Banner,
    CondensedIdeaPreviewImage,
    MpTransformerModeControl,
  },
  props: {
    strictValidation: {
      type: Boolean,
    },
  },
  data() {
    return {
      validatorOptions: ideaValidator.getDefaultOptions(),
      blacklist: {},
      currentLocale: null,
      INTPUB_DECISION_STATES,
      displayAutoTranslations: false,
    };
  },
  watch: {
    defaultLocale: {
      handler(newState) {
        this.setCurrentLocale(newState);
      },
    },
    manualTranslations: {
      immediate: true,
      deep: true,
      handler(newState) {
        let newBlacklist = {};

        newState.map(({ locale }) => {
          newBlacklist[locale] = this.blacklist[locale] || {
            name: [],
            description: [],
            tags: [],
          };
        });

        this.blacklist = newBlacklist;
      },
    },
  },
  computed: {
    ...mapGetters({
      designImages: 'idea/designImages',
      localizedName: 'idea/localizedName',
      localizedDescription: 'idea/localizedDescription',
      localizedTags: 'idea/localizedTags',
      getTranslation: 'idea/getTranslation',
      languageByIso: 'platform/languageByIso',
      defaultLocale: 'idea/language',
      intPubDecision: 'idea/intPubDecision',
      requestedAutotranslations: 'idea/requestedAutotranslations',
      translationsEditable: 'dms/translationsEditable',
      ideaPublished: 'onboarding/ideaPublished',
      isNonInternationalShopOnlyUser: 'user/isNonInternationalShopOnlyUser',
    }),
    ...mapState({
      idea: (state) => state.idea.current,
      languages: (state) => state.platform.languages,
    }),
    availableTranslations() {
      const translations = this.languages
        .filter((language) => this.languageByIso(language.isoCode))
        .map((language) => {
          return {
            locale: language.isoCode,
            name: this.languageByIso(language.isoCode).name,
          };
        })
        .filter((translation) => {
          if (
            this.requestedAutotranslations &&
            this.intPubDecision !== INTPUB_DECISION_STATES.REJECTED
          ) {
            return true;
          } else {
            return this.idea.translations.some(
              (ideaTranslation) => ideaTranslation.locale === translation.locale
            );
          }
        })
        .sort((x, y) => (x.name < y.name ? -1 : x.name > y.name ? 1 : 0))
        .sort((x, y) =>
          x.locale === this.defaultLocale
            ? -1
            : y.locale === this.defaultLocale
            ? 1
            : 0
        );

      // !this.intPub &&
      if (
        !translations.some(
          (translation) => translation.locale === this.currentLocale
        )
      ) {
        this.setCurrentLocale(this.defaultLocale);
      }

      return this.translationsEditable
        ? translations
        : translations.slice(0, 1);
    },
    currentTranslation() {
      return this.availableTranslations.find(
        (translation) => translation.locale === this.currentLocale
      );
    },
    manualTranslations() {
      return this.availableTranslations.filter((translation) =>
        this.idea.translations.some(
          (ideaTranslation) =>
            ideaTranslation.locale === translation.locale &&
            ideaTranslation.autotranslated !== true
        )
      );
    },
    autoTranslations() {
      return this.availableTranslations.filter(
        (translation) => !this.manualTranslations.includes(translation)
      );
    },
    name() {
      return this.localizedName(this.currentLocale);
    },
    description() {
      return this.localizedDescription(this.currentLocale);
    },
    tags() {
      return this.localizedTags(this.currentLocale);
    },
    currentBlacklist() {
      return this.blacklist && this.blacklist[this.currentLocale];
    },
    designCount() {
      return this.designImages.length;
    },
    validName() {
      if (!this.strictValidation) {
        return true;
      }

      return ideaValidator.validateName(this.idea, {
        locale: this.currentLocale,
      });
    },
    nameError() {
      return !this.validName || this.currentBlacklist?.name?.length;
    },
    validDescription() {
      if (!this.strictValidation) {
        return true;
      }

      return ideaValidator.validateDescription(this.idea, {
        locale: this.currentLocale,
      });
    },
    descriptionError() {
      return (
        !this.validDescription || this.currentBlacklist?.description?.length
      );
    },
    invalidLocales() {
      if (!this.strictValidation) {
        return [];
      }

      return ideaValidator
        .validate(this.idea, {}, this.strictValidation)
        .locales.filter(
          (item) => !item.result || this.hasBlacklistedMetaData(item.locale)
        )
        .map((item) => item.locale);
    },
    isAutoTranslated() {
      if (!this.getTranslation(this.currentLocale)) {
        return false;
      }

      return this.getTranslation(this.currentLocale).autotranslated;
    },
    isAutoTranslationPending() {
      return !this.getTranslation(this.currentLocale);
    },
  },
  async created() {
    this.setCurrentLocale(this.defaultLocale);

    this.manualTranslations.map((translation) => {
      this.checkBlacklistForField('name', translation.locale);
      this.checkBlacklistForField('description', translation.locale);
      this.checkBlacklistForField('tags', translation.locale);
    });

    eventBus.$on('idea:editFailed', this.selectFirstInvalidLocale);
  },
  beforeUnmount() {
    eventBus.$off('idea:editFailed', this.selectFirstInvalidLocale);
  },
  methods: {
    ...mapMutations({
      addBlacklistedTerms: 'idea/addBlacklistedTerms',
      setRequestedAutotranslations: 'idea/setRequestedAutotranslations',
      setMpTransformerMode: 'idea/setMpTransformerMode',
    }),
    ...mapActions({
      updateName: 'idea/updateName',
      updateDescription: 'idea/updateDescription',
      addTagToIdea: 'idea/addTag',
      removeTagToIdea: 'idea/removeTag',
    }),
    hasBlacklistedMetaData(locale) {
      return (
        !!this.blacklist[locale]?.name.length ||
        !!this.blacklist[locale]?.description.length ||
        !!this.blacklist[locale]?.tags.length
      );
    },
    addTag(tag) {
      this.addTagToIdea({
        tag,
        locale: this.currentLocale,
      });
    },
    removeTag(tag) {
      this.removeTagToIdea({
        tag,
        locale: this.currentLocale,
      });
    },
    setName(evt) {
      this.updateName({
        name: evt.target.value,
        locale: this.currentLocale,
      });
    },
    setDescription(evt) {
      this.updateDescription({
        description: evt.target.value,
        locale: this.currentLocale,
      });
    },
    async checkBlacklistForField(field, currentLocale = this.currentLocale) {
      // Ensure computed properties do not change during async await for legalcheck
      const locale = currentLocale;

      try {
        await checkTerm({
          field,
          terms: this.getTranslation(locale)[field],
          locale,
        });
        if (this.blacklist[locale]) {
          this.blacklist[locale][field] = [];
        }
      } catch (error) {
        if (error?.data?.list && this.blacklist[locale]) {
          this.blacklist[locale][field] = error.data.list[0].blacklisted.reduce(
            (result, blacklist) => result.concat(blacklist.terms),
            []
          );
          this.addBlacklistedTerms(this.blacklist[locale][field]);
        }
      }
    },
    onTagBlacklistUpdate({ blacklistResult, locale }) {
      let blacklistTerms = [];

      if (blacklistResult?.length) {
        blacklistTerms = blacklistResult[0].blacklisted.reduce(
          (result, blacklist) => {
            return result.concat(blacklist.terms);
          },
          []
        );
      }

      if (this.blacklist[locale]) {
        this.blacklist[locale].tags = blacklistTerms;
      }

      this.addBlacklistedTerms(blacklistTerms);
    },
    setCurrentLocale(locale) {
      this.currentLocale = locale;
    },
    selectFirstInvalidLocale() {
      if (
        this.invalidLocales.length &&
        !this.invalidLocales.includes(this.currentLocale)
      ) {
        this.setCurrentLocale(this.invalidLocales[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.configuration-metadata {
  padding: 0 0 32px 0;

  .heading {
    display: flex;
    padding: 0 0 24px 0;
    align-items: center;

    h1 {
      margin: 0 24px 0 0;
    }
  }

  .configuration-metadata-content {
    background-color: #fff;

    .idea-settings {
      display: flex;
      padding: 24px;

      div + div {
        margin-left: 16px;
      }
    }
  }

  .configuration-metadata-block {
    display: flex;
    justify-content: center;
    border-top: 2px solid $grey5;
    padding: 16px 24px 24px;
  }
}

.availableTranslations {
  display: flex;
  overflow-x: auto;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 8px;

    a {
      color: $grey80;
    }

    small {
      margin-left: 8px;
    }

    &.selected {
      border-bottom: 4px solid $pa-color-main;
      font-weight: bold;

      &.invalid {
        border-bottom-color: $pa-color-red;
      }

      a {
        color: $grey80;
      }
    }

    &.invalid {
      a {
        color: $pa-color-red;
      }
    }
  }
}

.data {
  width: 100%;
  border-radius: 4px;
  flex-shrink: 0;

  .meta-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    & > div:not(:last-child) {
      margin-bottom: 24px;
    }

    .meta-data-label-description .labelInput {
      overflow: hidden;
    }

    .meta-data-label-description {
      width: 30%;
      padding-right: 40px;

      p {
        margin: 8px 0 0 0;
      }

      h4 {
        margin: 0 0 8px;
      }
    }
    .labelInput {
      width: 70%;
    }
  }
}

.banner {
  margin-bottom: 24px;
}

.autotranslated-hint {
  display: flex;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: $grey5;

  .icon-circle {
    background-color: $sunset;
    border-radius: 100%;
    padding: 4px;
    margin-right: 16px;

    .icon {
      width: 24px;
      height: 24px;
      color: $white;
    }
  }
}
</style>
