<template>
  <div class="statistics-filters" :class="{ 'flush-bottom': flushBottom }">
    <div class="heading-text">
      <div>
        <h2>{{ $t(heading) }}</h2>
        <small v-if="subHeading">{{ $t(subHeading) }}</small>
      </div>
      <BetaIndicator v-if="beta" />
    </div>
    <div class="filters">
      <Dropdown
        v-if="displayCommissionTypeFilter"
        :items="COMMISSION_TYPE_FILTER_ARRAY"
        :value="filters.commissionType"
        :itemKey="(ct) => ct.key"
        :itemToString="getCommissionTypeName"
        @change="onCommissionTypeChange"
      >
      </Dropdown>

      <Dropdown
        v-if="displayTypeFilter"
        :items="typeFilter"
        :value="filters.type"
        :itemKey="(item) => item.key"
        :itemToString="getStatsName"
        @change="onTypeFilterUpdate"
      >
      </Dropdown>

      <Dropdown
        v-if="displayPosFilter && posFilter.length > 2"
        :items="posFilter"
        :value="filters.pointOfSale"
        :itemKey="(item) => item.key"
        :itemToString="(posFilter) => posFilter.name"
        @change="onPosChange"
      >
      </Dropdown>

      <LabelInput
        v-if="displayDateFromFilter"
        :model="filters.dateFrom"
        class="date"
      >
        <div class="input-group">
          <label for="dateFrom">{{ $t('GENERAL.FROM') }}</label>
          <VueDatePicker
            v-model="filters.dateFrom"
            :disabled-dates="dateFromOptions.disabledDates"
            utc
            :format="formatDate"
            :enable-time-picker="false"
            @closed="onFilterUpdate"
            auto-apply
            id="dateFrom"
          >
            <template #input-icon />
            <template #clear-icon />
          </VueDatePicker>
          <Icon class="addon" icon="calendar"></Icon>
        </div>
      </LabelInput>

      <LabelInput
        v-if="displayDateToFilter"
        :model="filters.dateTo"
        class="date"
      >
        <div class="input-group">
          <label for="dateTo">{{ $t('GENERAL.TO') }}</label>
          <VueDatePicker
            v-model="filters.dateTo"
            :disabled-dates="dateToOptions.disabledDates"
            utc
            auto-apply
            :format="formatDate"
            :enable-time-picker="false"
            @closed="onFilterUpdate"
            id="dateTo"
          >
            <template #input-icon />
            <template #clear-icon />
          </VueDatePicker>
          <Icon class="addon" icon="calendar"></Icon>
        </div>
      </LabelInput>

      <div class="clear">
        <a
          href
          @click.prevent="clearFilters"
          :class="{ disabled: !isFilterActive }"
          >{{ $t('GENERAL.CLEAR') }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import parse from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import statisticsService from '@/api/statisticsService/statisticsService';

import date from '@/date/date';
import Dropdown from '@/Dropdown/Dropdown.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import BetaIndicator from 'src/app/components/indicator/BetaIndicator.vue';
import { removeNullValues } from 'src/app/commons/utils';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';

export default {
  name: 'StatisticsFilter',
  components: {
    Dropdown,
    LabelInput,
    VueDatePicker,
    BetaIndicator,
  },
  props: {
    heading: {
      required: true,
      type: String,
    },
    subHeading: {
      type: String,
    },
    displayTypeFilter: {
      type: Boolean,
    },
    displayCommissionTypeFilter: {
      type: Boolean,
    },
    displayPosFilter: {
      type: Boolean,
    },
    displayDateFromFilter: {
      type: Boolean,
    },
    displayDateToFilter: {
      type: Boolean,
    },
    flushBottom: {
      type: Boolean,
    },
    beta: {
      type: Boolean,
    },
  },
  data() {
    return {
      typeFilter: [
        {
          key: 'TOP_PRODUCT_TYPES',
          fetchFn: statisticsService.getBestsellingProductTypes,
          downloadFn: statisticsService.getBestsellingProductTypesDownloadUrl,
          value: 'productTypes',
        },
        {
          key: 'TOP_COUNTRIES',
          fetchFn: statisticsService.getBestsellingShippingCountries,
          downloadFn:
            statisticsService.getBestsellingShippingCountriesDownloadUrl,
          value: 'shippingCountries',
        },
        {
          key: 'TOP_DESIGNS',
          fetchFn: statisticsService.getBestsellingDesigns,
          downloadFn: statisticsService.getBestsellingDesignsDownloadUrl,
          value: 'designs',
        },
      ],
      dateFromOptions: {
        disabledDates: (d) => {
          return isAfter(d, new Date());
        },
        maxMode: 'month',
      },
      dateToOptions: {
        disabledDates: (d) => {
          return (
            isAfter(d, new Date()) ||
            isAfter(parseISO(this.filters.dateFrom), addDays(d, 1))
          );
        },
        // disabledDates: {
        //   from: new Date(),
        //   to: null,
        // },
        maxMode: 'month',
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        pointOfSale: null,
        type: null,
        commissionType: null,
      },
      getStatsName: (stat) => this.$t(`STATISTICS.BESTSELLERS.${stat.key}`),
      formatDate: (d) => date(d, 'shortDate'),
      posFilter: [],
      COMMISSION_TYPE_FILTERS: {
        ALL: null,
        ...statisticsService.getCommissionTypes(),
      },
      COMMISSION_TYPE_FILTER_ARRAY: null,
      getCommissionTypeName: (ct) =>
        this.$t(`STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.${ct.key}`),
    };
  },
  async created() {
    this.posFilter = statisticsService.getPosFilters();

    this.COMMISSION_TYPE_FILTER_ARRAY = Object.entries(
      this.COMMISSION_TYPE_FILTERS
    ).map(([key, value]) => ({ key, value }));

    if (this.$route.query.type) {
      this.filters.type = this.typeFilter.find(
        (typeFilter) => typeFilter.key === this.$route.query.type
      );
    } else {
      this.filters.type = this.typeFilter[0];
    }

    if (this.$route.query.commissionType) {
      this.filters.commissionType = this.COMMISSION_TYPE_FILTER_ARRAY.find(
        (commissionTypeFilter) =>
          commissionTypeFilter.key === this.$route.query.commissionType
      );
    } else {
      this.filters.commissionType = this.COMMISSION_TYPE_FILTER_ARRAY[0];
    }

    if (this.$route.query.pos) {
      this.filters.pointOfSale = this.posFilter.find(
        (posFilter) => posFilter.key === this.$route.query.pos
      );
    } else {
      this.filters.pointOfSale = this.posFilter[0];
    }

    if (this.$route.query.from && isValid(parse(this.$route.query.from))) {
      this.filters.dateFrom = this.$route.query.from;
    }

    if (this.$route.query.to && isValid(parse(this.$route.query.to))) {
      this.filters.dateTo = this.$route.query.to;
    }

    this.onFilterUpdate({ initial: true });
  },
  computed: {
    isFilterActive() {
      return (
        this.filters.dateFrom ||
        this.filters.dateTo ||
        this.filters.pointOfSale !== this.posFilter[0]
      );
    },
  },
  methods: {
    clearFilters() {
      if (!this.isFilterActive) {
        return;
      }

      this.filters.dateFrom = null;
      this.filters.dateTo = null;
      this.filters.pointOfSale = this.posFilter[0];
      this.filters.commissionType = this.COMMISSION_TYPE_FILTER_ARRAY[0];

      this.onFilterUpdate();
    },
    onPosChange(newPos) {
      this.filters.pointOfSale = newPos;
      this.onFilterUpdate();
    },
    onCommissionTypeChange(newCommissionType) {
      this.filters.commissionType = newCommissionType;
      this.onFilterUpdate();
    },
    onFilterUpdate({ initial } = {}) {
      // nextTick needed, otherwise this.filter.dateFrom will still be emtpy even just set https://github.com/charliekassel/vuejs-datepicker/issues/119
      this.$nextTick(() => {
        this.$emit(
          'filterUpdate',
          {
            ...this.filters,
            dateFrom: this.filters.dateFrom ? this.filters.dateFrom : null,
            dateTo: this.filters.dateTo ? this.filters.dateTo : null,
          },
          { initial }
        );

        this.updateParams();
      });
    },
    onTypeFilterUpdate(newFilter) {
      this.filters.type = newFilter;
      this.onFilterUpdate();
    },
    async updateParams() {
      await this.$nextTick();
      this.$router.replace({
        query: removeNullValues({
          ...this.$route.query,
          subpage: this.$route.query.subpage,
          from: this.filters.dateFrom
            ? parseISO(this.filters.dateFrom).toISOString()
            : null,
          to: this.filters.dateTo
            ? parseISO(this.filters.dateTo).toISOString()
            : null,
          pos:
            this.filters.pointOfSale && this.filters.pointOfSale.key !== 'ALL'
              ? this.filters.pointOfSale.key
              : null,
          type:
            this.filters.type &&
            this.filters.type.key !== this.typeFilter[0].key
              ? this.filters.type.key
              : null,
          commissionType:
            this.filters.commissionType &&
            this.filters.commissionType.key !== 'ALL'
              ? this.filters.commissionType.key
              : null,
        }),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links.scss';

.statistics-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 24px 8px 24px;
  margin-bottom: 18px;
  background-color: white;
  border-radius: 4px;

  &.flush-bottom {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > div {
    margin-bottom: 16px;
  }

  & > .icon {
    font-size: 2em;
    margin-right: 10px;
  }

  .heading-text {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      justify-content: center;
      margin-right: 24px;
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      margin: 0;
    }

    small {
      margin-top: 6px;
      color: $grey60;
    }
  }

  .filters {
    display: flex;
    align-items: center;

    .dropdown {
      min-width: 200px;
      margin-right: 10px;
    }
  }

  .pos-filter,
  .commission-type-filter {
    width: 220px;
    margin: 0 10px 0 0;
    color: $sprd-color-grey;

    @include small-desktop {
      width: 150px;
    }
  }

  .commission-type-filter {
    width: 210px;
    @include small-desktop {
      width: 190px;
    }
  }

  .date {
    display: flex;
    margin: 0 10px 0 0;

    & > input {
      width: 120px;
    }

    & > .btn {
      display: flex;
      border-radius: 0 2px 2px 0;
      border-left: none;
      border-color: $sprd-color-medium-grey;
      border-width: 1px;
      margin-left: -2px;

      &:hover {
        background-color: #fff;
        color: $pa-color-main;
      }
    }

    & > input,
    & > .btn {
      border: none;
    }

    &:hover {
      & > .btn,
      & > input {
        border: none;
      }
    }
  }

  .clear {
    a {
      @extend .link;
      color: $sprd-color-grey;
    }
  }
}
</style>
