<template>
  <div class="consent-required">
    <slot v-if="consent" />
    <div v-else class="no-consent">
      <Icon icon="broken-file" />
      <h4>{{ $t('MISSING_CONSENT.HEADING') }}</h4>
      <p>
        {{ $t('MISSING_CONSENT.TEXT') }}
      </p>
      <button @click="showConsentSettings" class="btn btn-primary btn-lg">
        {{ $t('MISSING_CONSENT.CTA') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConsentRequired',
  props: {
    type: {
      validator(value) {
        return ['functional', 'performance', 'remarketing', 'social'].includes(
          value
        );
      },
    },
  },
  data() {
    return {
      consent: false,
    };
  },
  created() {
    if (window.sprdConsent?.getStatus) {
      const consent = window.sprdConsent.getStatus();

      if (consent && consent[this.type]) {
        this.consent = true;
      }
    }
  },
  methods: {
    showConsentSettings() {
      if (window.sprdConsent?.showSettings) {
        window.sprdConsent.showSettings();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.consent-required {
  height: 100%;
}

.no-consent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
  background-color: $grey10;

  .icon {
    width: 32px;
    height: 32px;
  }

  h4 {
    margin: 16px 0 0 0;
  }

  p {
    margin: 8px 0 16px 0;
  }
}
</style>
