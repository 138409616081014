<template>
  <div class="outerWrapper">
    <LoadingHeart v-if="loading" />
    <div class="wrapper" v-else>
      <Banner
        v-if="isNonProfit && !disableSetPricing"
        class="non-profit-banner"
        icon="settings"
        heading="POS.SHOP_SETTINGS.PRICING.NON_PROFIT_DISCLAIMER.LABEL"
        subheading="POS.SHOP_SETTINGS.PRICING.NON_PROFIT_DISCLAIMER.DESCRIPTION"
        link="POS.SHOP_SETTINGS.PRICING.NON_PROFIT_DISCLAIMER.LINK"
        @onLinkClick="goToNonProfit"
      />
      <div class="button-wrapper" v-if="!disableSetPricing">
        <button
          type="button"
          @click="resetAllCommissions"
          :disabled="!hasNonDefaultCommissions || isNonProfit"
          class="btn icon-btn text-btn link-main"
        >
          <Icon icon="rotate" />
          {{ $t('POS.SHOP_SETTINGS.PRICING.RESET_ALL_BTN') }}
        </button>
        <div class="csv-actions">
          <button
            type="button"
            @click="downloadCSV"
            class="btn icon-btn text-btn link-main"
          >
            <Icon icon="download-file"></Icon>
            {{ $t('STATISTICS.DOWNLOAD_CSV') }}
          </button>
          <input
            type="file"
            ref="csvfileInput"
            id="csvfile"
            accept="text/csv"
            @change="uploadCSV"
            class="fileinput"
            :disabled="isNonProfit"
          />
          <label
            for="csvfile"
            @click="uploadCSV"
            class="fileinputlabel btn icon-btn text-btn link-main"
            :class="{ disabled: isNonProfit }"
          >
            <Icon icon="upload"></Icon>
            {{ $t('POS.SHOP_SETTINGS.PRICING.UPLOAD_PRICES') }}
          </label>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th colspan="2" class="tal">
              {{ $t('POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE') }}
            </th>
            <th>
              {{ $t('POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE_PRICE') }}
              <VDropdown>
                <button type="button" class="popover-btn">
                  <Icon icon="question" />
                </button>
                <template #popper>
                  <div class="popover-content">
                    {{
                      $t('POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE_PRICE_INFO')
                    }}
                  </div>
                </template>
              </VDropdown>
            </th>
            <th>
              {{ $t('POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE_COMMISSION') }}
              <VDropdown>
                <button type="button" class="popover-btn">
                  <Icon icon="question" />
                </button>
                <template #popper>
                  <div class="popover-content">
                    {{
                      $t('POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE_MARGIN_INFO')
                    }}
                  </div>
                </template>
              </VDropdown>
            </th>
            <th>
              {{ $t('POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE_FINAL_PRICE') }}
              <VDropdown>
                <button type="button" class="popover-btn">
                  <Icon icon="question" />
                </button>
                <template #popper>
                  <div class="popover-content">
                    {{
                      $t(
                        'POS.SHOP_SETTINGS.PRICING.PRODUCT_TYPE_FINAL_PRICE_INFO'
                      )
                    }}
                  </div>
                </template>
              </VDropdown>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="productType in expandedProductTypes" :key="productType.id">
            <td class="img">
              <img :src="productType.resources[0].href" loading="lazy" alt="" />
            </td>
            <td class="tal">
              {{ productType.name }}
              <PerformanceIndicator
                v-if="isVolumeShareEligible(productType.id) && !isNonProfit"
              />
            </td>
            <td>
              {{ sprdCurrency(priceMap[productType.id].base, currency) }}
            </td>
            <td>
              {{ sprdCurrency(priceMap[productType.id].commission, currency) }}
            </td>
            <td v-if="disableSetPricing">
              {{ sprdCurrency(priceMap[productType.id].final, currency) }}
            </td>
            <td v-else-if="isNonProfit">
              <VDropdown placement="top" :triggers="['hover']">
                <div class="disabled-input">
                  {{ sprdCurrency(priceMap[productType.id].base, currency) }}
                </div>
                <template #popper>
                  <div class="popover-content">
                    {{
                      $t(
                        'POS.SHOP_SETTINGS.PRICING.NON_PROFIT_DISCLAIMER.DESCRIPTION'
                      )
                    }}
                  </div>
                </template>
              </VDropdown>
            </td>
            <td v-else>
              <input
                type="text"
                :value="sprdCurrency(priceMap[productType.id].final, currency)"
                @change="updateProductTypeCommission(productType.id, $event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import currencyFormatService from '@/currency/service/currencyFormatService';
import { mapGetters } from 'vuex';
import { PricingData } from 'src/app/partnerarea/pos/shop/shopSavable/PricingData';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import { downloadCSVFile, parseUploadedCsv } from './pricingCsv';
import PerformanceIndicator from 'src/app/components/indicator/PerformanceIndicator.vue';
import Banner from 'src/app/components/banners/Banner.vue';

export default {
  name: 'PricingPage',
  components: {
    LoadingHeart,
    PerformanceIndicator,
    Banner,
  },
  mixins: [PricingData],
  created() {
    this.$store.commit('shop/setLivePreviewVisible', false);
  },
  computed: {
    ...mapGetters({
      currencyById: 'platform/currencyById',
      productTypeShares: 'platform/productsWithProductTypeShare',
    }),
    config() {
      return this.$store.state.shop.shopSavable.clientData.config;
    },
    isNonProfit() {
      return this.config.shopCommission === 'OFF';
    },
    disableSetPricing() {
      return this.$route.meta.disableSetPricing || false;
    },
    currency() {
      return this.productTypeCommissions.DEFAULT.currency.id;
    },
    priceMap() {
      return this.buildPriceMap(this.expandedProductTypes);
    },
    hasNonDefaultCommissions() {
      return Object.values(this.productTypeCommissions).some(
        (item) => item.isDefault !== true
      );
    },
  },
  methods: {
    buildPriceMap(productTypes) {
      const res = {};
      productTypes.forEach((pt) => {
        const printArea =
          this.printAreaPrices[pt.id] || this.printAreaPrices.DEFAULT;
        const base = pt.price.vatIncluded + printArea.amount;
        const commission =
          this.productTypeCommissions[pt.id] ||
          this.productTypeCommissions.DEFAULT;
        res[pt.id] = {
          base,
          commission: this.isNonProfit ? 0 : commission.amount,
          final: this.isNonProfit ? base : base + commission.amount,
        };
      });
      return res;
    },
    downloadCSV() {
      const currency = this.currencyById(
        this.productTypeCommissions.DEFAULT.currency.id
      );
      downloadCSVFile(
        this.productTypes,
        this.buildPriceMap(this.productTypes),
        currency
      );
    },
    async uploadCSV(event) {
      const files = event.target.files;
      if (files && files[0]) {
        const currency = this.currencyById(
          this.productTypeCommissions.DEFAULT.currency.id
        );
        const upload = await parseUploadedCsv(files[0]);
        Object.keys(upload).forEach((productTypeId) => {
          this.productTypeCommissions[productTypeId] = {
            amount:
              upload[productTypeId] > this.commissionLimit
                ? 0
                : upload[productTypeId],
            currency: currency,
            isDefault: false,
          };
        });
        this.$refs.csvfileInput.value = ''; // allows to upload another file with the same name
      }
    },
    updateProductTypeCommission(productTypeId, event) {
      const rawValue = event.target.value.replace(/[^\d.,-]/g, ''); // strip the currency symbol(s) again
      let newFinalValue = currencyFormatService.parseValue(
        rawValue,
        this.currency
      );

      const base = this.priceMap[productTypeId].base;
      if (
        Number.isNaN(newFinalValue) ||
        newFinalValue < base ||
        newFinalValue - base > this.commissionLimit
      ) {
        // block UI from creating invalid prices
        newFinalValue = base;
      }

      const amount = currencyFormatService.parseValue(
        `${newFinalValue - base}`,
        this.currency
      );
      const newEntry = {
        amount,
        currency: this.productTypeCommissions.DEFAULT.currency,
        isDefault: false,
      };

      this.productTypeCommissions[productTypeId] = newEntry;
    },
    isVolumeShareEligible(id) {
      return this.productTypeShares && this.productTypeShares.includes(id);
    },
    goToNonProfit() {
      this.$router.push({ name: 'shop-area.pricing-and-promos.non-profit' });
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';

#application.state-shop-area-pricing-and-promos-non-profit,
#application.state-shop-area-pricing-and-promos-pricing {
  .context {
    .settings {
      background-color: $grey5;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.outerWrapper {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 4em;
}

.non-profit-banner {
  margin-bottom: 16px;
}

.csv-actions,
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;

  button,
  label {
    padding: 10px;
    border-radius: 4px;
    background-color: $grey5;
  }

  button + button {
    margin-left: 1em;
  }
  button:disabled,
  button.disabled {
    color: $grey20;
  }
}

.button-wrapper {
  margin-bottom: 16px;
}

.csv-actions {
  padding: 4px;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 1em;

  button,
  label {
    background-color: #fff;
  }
}

.fileinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.fileinput + label {
  cursor: pointer; /* "hand" cursor */
}
.fileinputlabel {
  margin-left: 1em;
  font-weight: 700;

  &:hover {
    color: $pa-color-main-dark;
  }
}

.disclaimer {
  background-color: $pa-color-main;
  color: white;
  margin-top: -24px;
  margin-bottom: 24px;
  padding-left: 25px;
  border-radius: 4px;

  h2 {
    margin-top: 1.25em;
  }
}

.disclaimerLink {
  display: block;
  margin-top: 13px;
  margin-bottom: 16px;
  font-weight: bold;
  color: white;
}

table {
  width: 100%;
  margin: 0;
}

tr:nth-child(2n) {
  :deep(.indicator-button) {
    background-color: #fff;
  }
}

tr:not(:nth-child(2n)) {
  background-color: #fff;
}

th {
  position: sticky;
  top: -1rem;
  vertical-align: bottom;
  background: $grey5;

  &::after {
    content: '';
    display: block;
    margin: 1em -1em -1em;
    border-bottom: 4px solid $sprd-color-lightest-grey;
  }
}

th,
td {
  padding: 1em;
  text-align: right;
}

.tal {
  text-align: left;

  .indicator {
    margin-top: 8px;
  }
}

.VDropdown {
  display: inline-block;
}

.popover-btn {
  padding: 0;
  vertical-align: 1px;
  color: $pa-color-main;
  background: none;
  border: 0;
  outline: none;
}

.popover-content {
  text-align: left;
}

.img {
  min-width: 4em;
  max-width: 4em;
  padding-right: 0;

  &::before {
    content: '';
    height: 4em;
    display: inline-block;
  }

  img {
    max-width: 100%;
  }
}

input[type='text'] {
  width: 5.25em;
  text-align: right;
}

.disabled-input {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  border: 1px solid $grey20;
  border-radius: 2px;
  color: $grey20;
  background-color: #fff;
  padding: 0 12px;
  height: 2.5em;
  width: 5.25em;
  white-space: nowrap;
  overflow: hidden;
}
</style>
