import { getSpreadshopFontUrl } from 'src/app/commons/api/shop/urlBuilder';

const arbitraryPrefix = 'xxx-';

const fontsWithoutRegular = ['LOBSTER_TWO', 'OSWALD', 'POPPINS'];

const lineHeightStretch = {
  INCONSOLATA: 0.9,
  PACIFICO: 1.15,
  LATO: 0.9,
  BALOO_BHAI: 0.9,
};

const paddingStretch = {
  PACIFICO: 1.1,
  ANTON: 0.9,
  INDIE_FLOWER: 1.1,
  BALOO_BHAI: 0.9,
};

// prefix the font name to avoid collisions in the global name space (which can lead to an accidental overwrite of the partner area's font lel)
export const getSafeFontName = (fontName) => `${arbitraryPrefix}${fontName}`;

// returns the url where the .woff2 can be downloaded from spreadshop
export const getFontLocation = (fontName) => {
  const suffix = fontsWithoutRegular.includes(fontName) ? '700' : 'regular';
  const canonicalFontName = fontName.replace(/_/g, '-').toLowerCase();
  return getSpreadshopFontUrl(
    canonicalFontName,
    `${canonicalFontName}-${suffix}.woff2`
  );
};

// returns the factor so that: font_size_in_px * this_factor = space_between_two_subsequent_lines_in_px
// these values are hard coded because there is literally no way to calculate font height reliably across browsers.
// things like measuring <svg> elements or things like https://videlais.com/2014/03/16/the-many-and-varied-problems-with-measuring-font-height-for-html5-canvas/ just do not work.
export const getFontLineHeightFactor = (safeFontName) => {
  const stretch =
    lineHeightStretch[safeFontName.substr(arbitraryPrefix.length)] || 1;
  return 1.2 * stretch;
};

// returns the factor so that: font_size_in_px * this_factor = padding_bottom_the_very_last_line_must_get_in_px
export const getFontPaddingBottomFactor = (safeFontName) => {
  const stretch =
    paddingStretch[safeFontName.substr(arbitraryPrefix.length)] || 1;
  return 1.44 * stretch;
};
