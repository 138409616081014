import localeService from 'src/app/commons/localeService/localeService';

// get domain without subdomain and port
const domain = localeService.getSprdUrl();

const linksNA = {
  SHOP_TERMS: `${domain}/-C2376`,
  START_SELLING: `${domain}/-C3598`,
  PRIVACY_POLICY: `${domain}/-C3259`,
};

const linksEU = {
  SHOP_TERMS: `${domain}/-C10183`,
  START_SELLING: `${domain}/-C5780`,
  PRIVACY_POLICY: `${domain}/-C3928`,
};

const commonLinks = {
  IMPRINT: `${domain}/-C1338`,
};

export function getExternalLinks() {
  return {
    ...(SPRD.PLATFORM === 'NA' ? linksNA : linksEU),
    ...commonLinks,
  };
}
