<template>
  <div class="publishing-upload-item">
    <h4>{{ file.name }}</h4>
    <LoadingBar :executeFn="uploadFile" />
    <div v-if="!hasError" class="upload-steps">
      <p :class="uploadStepState">
        <Icon :icon="uploadIcon" />
        {{ $t('PUBLISHING.UPLOAD.STAGE.UPLOADING') }}
      </p>
      <p :class="processingStepState">
        <Icon :icon="processingIcon" />
        {{ $t('PUBLISHING.UPLOAD.STAGE.PROCESSING') }}
      </p>
    </div>

    <div v-else class="upload-steps">
      <p class="FAILED"><Icon icon="exclamation" />{{ $t(errorMessage) }}</p>
    </div>
  </div>
</template>

<script>
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import designUploadServiceV2 from '@/api/designUploadService/designUploadServiceV2';
import LoadingBar from 'src/app/components/loadingBar/LoadingBar.vue';
import { delay } from '@/utils';

const UPLOAD_STEPS_STATES = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export default {
  name: 'PublishingUploadItem',
  props: {
    file: { type: File, required: true },
  },
  components: {
    LoadingBar,
  },
  data() {
    return {
      UPLOAD_STEPS_STATES,
      uploadStepState: UPLOAD_STEPS_STATES.WAITING,
      processingStepState: UPLOAD_STEPS_STATES.WAITING,
      errorMessage: null,
    };
  },
  computed: {
    uploadIcon() {
      if (this.uploadStepState === UPLOAD_STEPS_STATES.SUCCEEDED) {
        return 'accept';
      } else if (this.uploadStepState === UPLOAD_STEPS_STATES.FAILED) {
        return 'close';
      } else if (this.uploadStepState === UPLOAD_STEPS_STATES.IN_PROGRESS) {
        return 'gear';
      } else {
        return 'hourglass';
      }
    },
    processingIcon() {
      if (this.processingStepState === UPLOAD_STEPS_STATES.SUCCEEDED) {
        return 'accept';
      } else if (this.processingStepState === UPLOAD_STEPS_STATES.FAILED) {
        return 'close';
      } else if (this.processingStepState === UPLOAD_STEPS_STATES.IN_PROGRESS) {
        return 'gear';
      } else {
        return 'hourglass';
      }
    },
    hasError() {
      return (
        this.uploadStepState === UPLOAD_STEPS_STATES.FAILED ||
        this.processingStepState === UPLOAD_STEPS_STATES.FAILED
      );
    },
  },
  methods: {
    async uploadFile() {
      let uploadUrl;
      this.uploadStepState = UPLOAD_STEPS_STATES.IN_PROGRESS;
      try {
        uploadUrl = await designUploadServiceV2.uploadFile(this.file);
        this.uploadStepState = UPLOAD_STEPS_STATES.SUCCEEDED;
      } catch (error) {
        this.uploadStepState = UPLOAD_STEPS_STATES.FAILED;
        this.mapUploadError(error);
        return Promise.reject(error);
      }

      this.processingStepState = UPLOAD_STEPS_STATES.IN_PROGRESS;
      try {
        await publishingCoreService.createCompositionFromDesignUploadUrl(
          uploadUrl
        );
        this.processingStepState = UPLOAD_STEPS_STATES.SUCCEEDED;
      } catch (error) {
        this.processingStepState = UPLOAD_STEPS_STATES.FAILED;
        this.mapUploadError(error);
        return Promise.reject(error);
      }

      this.finish();
    },
    async finish() {
      await delay(2000);
      this.$emit('onDone');
    },
    mapUploadError(error) {
      if (error?.status === 415) {
        this.errorMessage = 'PUBLISHING.UPLOAD.ERROR.UNSUPPORTED_MEDIA_TYPE';
      } else {
        this.errorMessage = 'PUBLISHING.UPLOAD.ERROR.DEFAULT';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.publishing-upload-item {
  background-color: $grey5;
  border-radius: 8px;
  padding: 24px;

  h4 {
    margin: 0 0 16px 0;
  }

  .loading-bar {
    margin: 16px 0;
  }

  .upload-steps {
    margin: 16px 0 0 0;
    p {
      display: flex;
      align-items: center;
      margin: 0 0 16px 0;
      .icon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
      &.SUCCEEDED {
        color: $pa-color-green;
      }

      &.FAILED {
        color: $pa-color-red;
      }

      &.WAITING {
        color: $grey45;
      }

      &.IN_PROGRESS {
        .icon {
          animation: rotate 4s linear infinite;
        }
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
