function initLiveChat(customVariables) {
  window.__lc = window.__lc || {};
  window.__lc.license = 12811461;
  window.__lc.params = customVariables;
  return new Promise(function (resolve, reject) {
    var lc = document.createElement('script');
    lc.type = 'text/javascript';
    lc.async = true;
    lc.src = 'https://cdn.livechatinc.com/tracking.js';
    lc.onload = resolve;
    lc.onerror = reject;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(lc, s);
  });
}

function hideChatWindowIfPossible() {
  if (
    window.LC_API.visitor_engaged() === false &&
    liveChatService.chatStarted === false
  ) {
    window.LC_API.hide_chat_window();
  }
}

const liveChatService = {
  isEnabled() {
    return false;
  },
  chatStarted: false,
  async init(customVariables = []) {
    if (this.isEnabled && !window.LC_API) {
      await initLiveChat(customVariables);
      return new Promise((resolve) => {
        window.LC_API.on_before_load = function () {
          hideChatWindowIfPossible();
        };
        window.LC_API.on_after_load = function () {
          hideChatWindowIfPossible();

          resolve(window.LC_API);
        };
        window.LC_API.on_chat_started = function () {
          liveChatService.chatStarted = true;
        };
        window.LC_API.on_chat_ended = function () {
          liveChatService.chatStarted = false;
          window.LC_API.hide_chat_window();
        };
        window.LC_API.on_chat_window_hidden = function () {
          hideChatWindowIfPossible();
        };
        window.LC_API.on_chat_window_minimized = function () {
          hideChatWindowIfPossible();
        };
      });
    } else {
      return window.LC_API;
    }
  },
};

export default liveChatService;
