<template>
  <div class="address-validation-confirmation overflow-y">
    <PdvAddressValidation mode="CONFIRM" @onDone="done" />
  </div>
</template>

<script>
import PdvAddressValidation from '@/partnerDataValidation/addressValidation/PdvAddressValidation.vue';

export default {
  name: 'AddressValidation',
  components: {
    PdvAddressValidation,
  },
  methods: {
    done() {
      this.$router.push({ name: 'partnerarea.dashboard' });
    },
  },
};
</script>

<style scoped>
.address-validation-confirmation {
  padding: 48px;
  display: flex;
  justify-content: center;
}
</style>
