<template>
  <div class="fullHeightWrapper">
    <ShopSetupWizardHeader @onSave="onSave" @onDismiss="onDismiss" />
    <div class="setupWizardBody">
      <div class="shopMenuContainer">
        <div class="introText">
          {{ $t('POS.SHOP_SETTINGS.NAVIGATION.SETUP_WIZARD.SUBHEADING') }}
        </div>
        <h4 class="logoTitle">
          {{ $t('POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.HEADING') }}
        </h4>
        <Logo class="logoWrapper" />
        <Colors />
      </div>
      <ShopLivePreview />
    </div>
  </div>
</template>

<script>
import ShopSetupWizardHeader from './ShopSetupWizardHeader.vue';
import Logo from 'src/app/partnerarea/pos/shop/design/logo/Logo.vue';
import Colors from 'src/app/partnerarea/pos/shop/design/shopStyles/Colors.vue';
import ShopLivePreview from 'src/app/partnerarea/pos/shop/livePreview/ShopLivePreview.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';

export default {
  name: 'ShopSetupWizard',
  components: {
    ShopSetupWizardHeader,
    Logo,
    Colors,
    ShopLivePreview,
  },
  mixins: [SettingsData],
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
  methods: {
    async onSave() {
      this.settings.setupWizardDone = true;
      await this.$store.dispatch('shop/saveShopSavable');
      this.$store.dispatch('user/fetchShops');
    },
    async onDismiss() {
      // revert changes but set the flag to true anyway
      this.$store.commit('shop/resetShopSavable');
      this.settings.setupWizardDone = true;
      await this.$store.dispatch('shop/saveShopSavable');
      this.$store.dispatch('user/fetchShops');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.fullHeightWrapper {
  height: 100%;
  // the flex properties are just for the box shadow of the header
  display: flex;
  flex-direction: column;
}
.setupWizardBody {
  height: 100%;
  overflow-y: auto;
}
.shopMenuContainer {
  position: relative;
  float: left;
  width: 400px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid $grey20;
}
.introText {
  margin: 8px 25px;
  color: $sprd-color-middle-grey;
}
.logoTitle {
  padding-left: 25px;
}
.logoWrapper {
  margin: 16px 25px 25px;
}
</style>
