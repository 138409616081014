export const DIGITAL_PRODUCTS_CATEGORIES = {
  BOOK: {
    key: 'BOOK',
    name: 'DIGITAL_PRODUCTS.CATEGORIES.BOOK.NAME',
    description: 'DIGITAL_PRODUCTS.CATEGORIES.BOOK.DESCRIPTION',
    icon: 'book',
  },
  ARTWORK: {
    key: 'ARTWORK',
    name: 'DIGITAL_PRODUCTS.CATEGORIES.ARTWORK.NAME',
    description: 'DIGITAL_PRODUCTS.CATEGORIES.ARTWORK.DESCRIPTION',
    icon: 'brush',
  },
  WALLPAPER: {
    key: 'WALLPAPER',
    name: 'DIGITAL_PRODUCTS.CATEGORIES.WALLPAPER.NAME',
    description: 'DIGITAL_PRODUCTS.CATEGORIES.WALLPAPER.DESCRIPTION',
    icon: 'photos',
  },
  PHOTO_FILTER: {
    key: 'PHOTO_FILTER',
    name: 'DIGITAL_PRODUCTS.CATEGORIES.FILTER.NAME',
    description: 'DIGITAL_PRODUCTS.CATEGORIES.FILTER.DESCRIPTION',
    icon: 'vector-to-bitmap',
  },
};

export const DIGITAL_PRODUCTS_SORTED_CATEGORIES = [
  DIGITAL_PRODUCTS_CATEGORIES.BOOK,
  DIGITAL_PRODUCTS_CATEGORIES.ARTWORK,
  DIGITAL_PRODUCTS_CATEGORIES.WALLPAPER,
  DIGITAL_PRODUCTS_CATEGORIES.PHOTO_FILTER,
];

export function getDigitalProductCategory(productClass) {
  return DIGITAL_PRODUCTS_CATEGORIES[productClass];
}
