import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import enGb from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import da from 'date-fns/locale/da';
import es from 'date-fns/locale/es';
import it from 'date-fns/locale/it';
import no from 'date-fns/locale/nb';
import pl from 'date-fns/locale/pl';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';
import nl from 'date-fns/locale/nl';
import frCA from 'date-fns/locale/fr-CA';
import enUs from 'date-fns/locale/en-US';
import enCa from 'date-fns/locale/en-CA';
import enAu from 'date-fns/locale/en-AU';
import localeService from '@/localeService/localeService';

const packageByLocale = {
  en_GB: enGb,
  de_DE: de,
  fr_FR: fr,
  fr_BE: fr,
  da_DK: da,
  es_ES: es,
  en_IE: enGb,
  it_IT: it,
  no_NO: no,
  de_AT: de,
  pl_PL: pl,
  de_CH: de,
  fi_FI: fi,
  sv_SE: sv,
  nl_NL: nl,
  nl_BE: nl,
  it_CH: it,
  fr_CH: fr,
  fr_CA: frCA,
  en_US: enUs,
  en_CA: enCa,
  en_AU: enAu,
};

export default (value, preferredFormat = 'P') => {
  if (preferredFormat === 'shortDateWithoutYear') {
    return new Date(value).toLocaleDateString(
      packageByLocale[localeService.getLocale()] || null,
      {
        month: 'numeric',
        day: 'numeric',
      }
    );
  }

  const presets = {
    mediumDate: 'PP',
    medium: 'PPpp',
    longDate: 'PPP',
    shortDate: 'P',
    short: 'Pp',
  };
  let dateValue = value;
  if (!(dateValue instanceof Date)) {
    dateValue = parseISO(dateValue);
  }

  return format(dateValue, presets[preferredFormat] || preferredFormat, {
    locale: packageByLocale[localeService.getLocale()] || null,
  });
};
