<template>
  <GlossaryList
    heading="DIGITAL_PRODUCTS.STATUS.GLOSSARY_HEADING"
    :glossary="states"
  />
</template>

<script>
import GlossaryList from 'src/app/partnerarea/idea/list/statusGlossary/GlossaryList.vue';
import { DIGITAL_PRODUCTS_STATES } from '@/api/models/digitalProducts/digitalProductsStates';

export default {
  name: 'DigitalProductsStatusDialog',
  components: { GlossaryList },
  props: ['modalInstance', 'data'],
  computed: {
    states() {
      return Object.values(DIGITAL_PRODUCTS_STATES).map((status) => ({
        name: status.name,
        description: status.description,
        color: status.color,
      }));
    },
  },
};
</script>
