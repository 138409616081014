<template>
  <div>
    <div>
      <h4 class="pluginsTitle">
        {{ $t('SA.integration.webhosting.title') }}
      </h4>
      <p class="description">
        {{ $t('SA.integration.webhosting.description') }}
      </p>
      <ul class="pluginList">
        <li class="plugin">
          <a href="https://wordpress.org/plugins/spreadshop/" target="_blank">
            <img src="/images/wordpress.svg" alt="" />
          </a>
        </li>
        <li class="plugin">
          <a
            href="https://extensions.joomla.org/extensions/extension/e-commerce/e-commerce-integrations/spreadshop/"
            target="_blank"
          >
            <img src="/images/joomla.svg" alt="" />
          </a>
        </li>
      </ul>
    </div>

    <div class="sprd-shop-input">
      <h4>{{ $t('SA.integration.embedJs.name') }}</h4>
      <p class="description">
        {{ $t('SA.integration.embedJs.description') }}
      </p>
      <div class="helpLink">
        <SmartFaqLink :link="jsIntegrationHelpLink" />
      </div>
      <JsIntegration />
    </div>

    <div>
      <h4>{{ $t('SA.integration.redirectUrl.title') }}</h4>
      <p class="description">{{ redirectDescription }}</p>
      <ValidLabelInput
        v-model="settings.jsShopUrl"
        label=""
        class="sprd-shop-input"
        maxlength="2000"
        :validators="[isValidPublicUrl]"
      />
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import JsIntegration from './JsIntegration.vue';
import ValidLabelInput from '../../shop/common/ValidLabelInput.vue';

import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'EmbedShop',
  mixins: [SettingsData],
  components: {
    JsIntegration,
    ValidLabelInput,
    SmartFaqLink,
  },
  data: function () {
    return {
      jsIntegrationHelpLink: smartFaqLinks.JS_INTEGRATION,
    };
  },
  created: function () {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
  computed: {
    redirectDescription() {
      const shopUrl = this.$store.getters['shop/getSpreadshopUrl']('', false);
      return this.$t('SA.integration.redirectUrl.description', {
        shopURL: shopUrl,
      });
    },
  },
  methods: {
    isValidPublicUrl(url) {
      if (url) {
        // no entry at all is considered valid
        if (
          (!url.startsWith('http://') && !url.startsWith('https://')) ||
          !url.includes('.')
        ) {
          throw new Error(this.$t('SA.shared.form.error.invalidUrl'));
        }
        try {
          // eslint-disable-next-line no-new
          new URL(url); // this does some validation as a side effect
        } catch (e) {
          throw new Error(this.$t('SA.shared.form.error.invalidUrl'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.pluginsTitle {
  margin-top: 0;
}

.pluginList {
  display: flex;
  margin: 0;
  flex-wrap: wrap;
}

.plugin {
  width: 50%;
  white-space: normal;
  word-break: normal;
  min-height: 50px;
  padding: 5px;
  height: 85px;
  display: flex;
  flex-basis: 50%;

  a {
    background-color: white;
    display: block;
    width: 100%;

    img {
      padding: 0.5em;
      width: 100%;
      height: 100%;
    }
  }
}

.description {
  color: $sprd-color-middle-grey;
}

.helpLink {
  margin-bottom: 10px;
}
</style>
