import apiClient from '@/api/client/apiClient';
import store from '@/store/Store.js';

export default {
  fetchIdeasAssortment() {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/ideas/assortment`,
      })
      .then((res) => res.data);
  },
  triggerUpdate(productTypeIds, intendedPointsOfSale) {
    return apiClient
      .request({
        method: 'POST',
        url: `api/v1/users/${store.state.user.data.id}/ideas/assortment/updates`,
        data: {
          productTypeIds,
          intendedPointsOfSale,
        },
      })
      .then((res) => res.data);
  },
  getPreviousUpdates() {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/ideas/assortment/updates`,
      })
      .then((res) => res.data);
  },
  fetchIdeasWithEmptyAssortment(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/ideas/issues`,
        params,
      })
      .then((res) => res.data);
  },
};
