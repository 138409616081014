import IBAN from 'iban';
import { isEmpty } from '@/utils';
import { mergeResults, validateStringAttribute } from '../genericValidator';

const paymentInfoValidator = {
  validate(data, obfuscatedData) {
    return Object.keys(paymentInfoValidator.singleValidate)
      .map((field) =>
        paymentInfoValidator.singleValidate[field](data, obfuscatedData)
      )
      .reduce((previous, current) => mergeResults(previous, current), {});
  },
  singleValidate: {
    bankAccountHolder: function (data) {
      if (data.type === 'BANK') {
        return validateStringAttribute(
          'bankAccountHolder',
          data.accountHolder,
          { required: true }
        );
      } else {
        return {
          result: true,
        };
      }
    },
    bankLocation: function (data) {
      if (data.type === 'BANK') {
        return validateStringAttribute('bankLocation', data.bankLocation, {
          required: true,
        });
      } else {
        return {
          result: true,
        };
      }
    },
    bankName: function (data) {
      if (data.type === 'BANK') {
        return validateStringAttribute('bankName', data.bankName, {
          required: true,
        });
      } else {
        return {
          result: true,
        };
      }
    },
    accountId: function (data, obfuscatedData) {
      if (
        obfuscatedData &&
        isEmpty(data.accountId) &&
        !isEmpty(obfuscatedData.accountId)
      ) {
        return { result: true };
      }

      if (data.type === 'BANK') {
        if (SPRD.PLATFORM === 'EU') {
          return IBAN.isValid(data.accountId)
            ? { result: true }
            : {
                result: false,
                errors: { accountId: { reason: 'INVALID_IBAN' } },
              };
        } else {
          return validateStringAttribute('accountId', data.accountId, {
            required: true,
          });
        }
      } else {
        return {
          result: true,
        };
      }
    },
    bankId: function (data, obfuscatedData) {
      if (
        obfuscatedData &&
        isEmpty(data.bankId) &&
        !isEmpty(obfuscatedData.bankId)
      ) {
        return { result: true };
      }

      if (data.type === 'BANK') {
        if (SPRD.PLATFORM === 'EU') {
          const bankIdValid =
            /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/.test(
              data.bankId
            );
          return bankIdValid
            ? { result: true }
            : { result: false, errors: { bankId: { reason: 'INVALID_BIC' } } };
        } else {
          return /^\d{9}$/.test(`${data.bankId}`)
            ? { result: true }
            : { result: false, errors: { bankId: { reason: 'INVALID_BIC' } } };
        }
      } else {
        return {
          result: true,
        };
      }
    },
    paypalAccountHolder: function (data) {
      if (data.type === 'PAYPAL') {
        return validateStringAttribute(
          'paypalAccountHolder',
          data.accountHolder,
          { required: true }
        );
      } else {
        return {
          result: true,
        };
      }
    },
    paypalEmail: function (data) {
      if (data.type === 'PAYPAL') {
        return validateStringAttribute('paypalEmail', data.email, {
          required: true,
          email: true,
        });
      } else {
        return {
          result: true,
        };
      }
    },
  },
  validateByFieldName(fieldName, data, obfuscatedData) {
    if (paymentInfoValidator.singleValidate[fieldName]) {
      return paymentInfoValidator.singleValidate[fieldName](
        data,
        obfuscatedData
      );
    } else {
      return {};
    }
  },
  mergeResults,
};

export default paymentInfoValidator;
