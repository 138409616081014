<template>
  <button @click="onClick" v-click-outside="reset" :class="{ confirm }">
    <slot :confirm="confirm"></slot>
  </button>
</template>

<script>
export default {
  name: 'DoubleConfirmButton',
  data() {
    return {
      confirm: false,
    };
  },
  methods: {
    onClick() {
      if (this.confirm) {
        this.$emit('onConfirm');
        this.confirm = false;
      } else {
        this.confirm = true;
      }
    },
    reset() {
      this.confirm = false;
    },
  },
};
</script>
