<template>
  <div class="pdv-address-validation">
    <div
      v-if="
        initialized &&
        currentPdvState !== PDV_STATES.FAILED &&
        !displayFailedMsg
      "
    >
      <Icon :icon="modeSettings.icon" />
      <h1>{{ $t(modeSettings.heading) }}</h1>

      <div class="verified-msg" v-if="softCheckSucceeded">
        <p v-if="manualAddressEdit">{{ $t('PDV.VERIFIED.EDIT') }}</p>
        <p v-else>{{ $t('PDV.VERIFIED.INFO') }}</p>
      </div>
      <div
        class="validate-msg"
        v-if="
          (![PDV_STATES.CODE_INPUT].includes(currentPdvState) &&
            !softCheckSucceeded) ||
          manualAddressEdit
        "
      >
        <p class="subheading" v-if="!softCheckSucceeded">
          {{ $t('ONBOARDING.ADDRESS_DIALOG.SUBHEADING') }}
        </p>
        <div class="attempt-info">
          <p>{{ $t('PDV.VALIDATE_INFO') }}</p>
          <p
            class="attempts"
            :class="{ highlight: softCheckFailed }"
            v-if="pdvState.softcheck.attemptsLeft < 5"
          >
            {{
              $t('PDV.ATTEMPTS_LEFT', {
                count: pdvState.softcheck.attemptsLeft,
              })
            }}
          </p>
        </div>
      </div>

      <PersonalDataForm
        class="address-form"
        :updatePdvState="updatePdvState"
        hidePhoneInput
        hidePdvValidateMsg
        @onEditAddress="onEditAddress"
        skipPdvFailedDialog
      />

      <div class="tnc" v-if="mode === 'PUBLISH' && !termsAlreadyAccepted">
        <Checkbox id="terms" v-model="termsAccepted" />
        <label>
          <span>{{ $t('SHOPS.ADDRESS_DIALOG.TERMS.INTRO') }}</span>
          <a class="link link-main" :href="TcLinkTarget" target="_blank">{{
            $t('SHOPS.ADDRESS_DIALOG.TERMS.SHOP_TERMS_LINK')
          }}</a
          >.
        </label>
      </div>

      <ProgressButton
        class="btn btn-primary btn-block btn-lg pdv-save-btn"
        :label="$t('GENERAL.DONE')"
        :disabled="saveBtnDisabled"
        :onClick="save"
      />
      <a
        href="#"
        class="link link-main contact-link"
        v-if="softCheckFailed"
        @click.prevent="setFailedMsgDisplay(true)"
        >{{ $t('PDV.CONTACT') }}</a
      >
    </div>
    <div
      class="failed-address"
      v-else-if="
        initialized &&
        (currentPdvState === PDV_STATES.FAILED || displayFailedMsg)
      "
    >
      <PdvFailedMsg :address="serverSavedAddress" />
      <a
        v-if="displayFailedMsg"
        href="#"
        class="link link-main icon-link back-link"
        @click.prevent="setFailedMsgDisplay(false)"
      >
        <Icon icon="left" /> {{ $t('PDV.BACK') }}
      </a>
      <button
        class="bbtn btn-primary btn-block btn-lg pdv-save-btn"
        v-if="!displayFailedMsg && mode === 'CONFIRM'"
        @click="$emit('onDone')"
      >
        {{ $t('PDV.CONFIRMATION.CONTINUE') }}
      </button>
    </div>
    <div v-else class="loading-wrapper">
      <LoadingHeart />
    </div>
    <div v-if="waitingForTcRequest" class="loading-overlay">
      <LoadingHeart />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import PersonalDataForm from 'src/app/partnerarea/account/personal/PersonalDataForm.vue';
import accountService from '@/api/accountService/accountService';
import pdvService from '@/partnerDataValidation/service/pdvService';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import PdvFailedMsg from '@/partnerDataValidation/failedMsg/PdvFailedMsg.vue';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import { getExternalLinks } from '@/externalLinks/externalLinks';
import Checkbox from '@/checkbox/Checkbox.vue';
import analyticsService from '@/tracking/analytics';

const MODE_SETTINGS = {
  PUBLISH: {
    icon: 'rocket',
    heading: 'ONBOARDING.ADDRESS_DIALOG.HEADING',
  },
  CONFIRM: {
    icon: 'lock',
    heading: 'PDV.CONFIRMATION.HEADING',
  },
};

export default {
  name: 'PdvAddressValidation',
  props: {
    mode: { type: String, default: 'PUBLISH' },
  },
  components: {
    PersonalDataForm,
    ProgressButton,
    PdvFailedMsg,
    LoadingHeart,
    Checkbox,
  },
  data() {
    return {
      updatePdvState: accountService.updatePdvState,
      manualAddressEdit: false,
      PDV_STATES: pdvService.STATES,
      initialized: false,
      displayFailedMsg: false,
      TcLinkTarget: getExternalLinks().SHOP_TERMS,
      termsAccepted: null,
      waitingForTcRequest: false,
    };
  },
  async created() {
    this.termsAccepted = this.termsAlreadyAccepted;

    await accountService.getData().then((data) => {
      this.setAccountData({ data });
    });

    if (
      ![
        this.PDV_STATES.VALID,
        this.PDV_STATES.CODE_INPUT,
        this.PDV_STATES.CONFIRM_NEEDED,
      ].includes(this.currentPdvState)
    ) {
      this.manualAddressEdit = true;
    }

    this.initialized = true;
    analyticsService.logEvent('pdv_start');
  },
  computed: {
    ...mapState({
      termsAlreadyAccepted: (state) => state.user.data.acceptTermsAndConditions,
      pdvState: (state) => state.account.pdvState,
      serverSavedAddress: (state) => state.account.originalData.user,
    }),
    ...mapGetters({}),
    modeSettings() {
      return MODE_SETTINGS[this.mode] || MODE_SETTINGS.PUBLISH;
    },
    currentPdvState() {
      return pdvService.getCurrentState(this.pdvState);
    },
    softCheckSucceeded() {
      return pdvService.softCheckSucceeded(this.pdvState);
    },
    softCheckFailed() {
      return pdvService.softCheckFailed(this.pdvState);
    },
    saveBtnDisabled() {
      return this.mode === 'PUBLISH' && !this.termsAccepted;
    },
  },
  methods: {
    ...mapMutations({
      setAccountData: 'account/setAccountData',
      updateUserData: 'user/updateUserData',
    }),
    onEditAddress() {
      this.manualAddressEdit = true;
    },
    setFailedMsgDisplay(state) {
      this.displayFailedMsg = state;
    },
    save() {
      try {
        return this.$store
          .dispatch('account/save', {
            forceValidationForFields: [
              'firstName',
              'lastName',
              'company',
              'countryId',
              'city',
              'postalCode',
              'street',
              'street2',
              'stateId',
            ],
          })
          .then(
            async (result) => {
              if (pdvService.softCheckFailed(this.pdvState)) {
                this.termsAccepted = this.termsAlreadyAccepted;
                return Promise.reject(result.origin);
              } else {
                if (this.mode === 'PUBLISH') {
                  this.waitingForTcRequest = true;
                  await pdvService.acceptTermsAndConditions().catch((e) => {
                    this.waitingForTcRequest = false;
                    throw e;
                  });

                  this.waitingForTcRequest = false;

                  this.updateUserData({
                    acceptTermsAndConditions: true,
                  });

                  this.$emit('onDone');
                } else {
                  this.$emit('onDone');
                }

                analyticsService.logEvent('pdv_success');
                return Promise.resolve(result.origin);
              }
            },
            (error) => {
              analyticsService.logEvent('pdv_fail');
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            analyticsService.logEvent('pdv_fail');
            return Promise.reject(error);
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.pdv-address-validation {
  max-width: 600px;
  position: relative;
}

.icon {
  color: $pa-color-main;
  width: 48px;
  height: 48px;
}

h1 {
  margin: 18px 0 12px 0;
}

.validate-msg {
  p {
    @extend .text-sm;
  }
}

.attempt-info {
  p {
    display: inline;
  }

  .attempts.highlight {
    color: $pa-color-red;
  }
}

.address-form {
  margin-top: 18px;
}

.tnc {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.pdv-save-btn {
  margin-top: 24px;
  height: 48px;
}

.contact-link {
  @extend .text-sm;
  display: inline-block;
  margin-top: 12px;
}

.back-link {
  margin-top: 12px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}
</style>
