<template>
  <div class="composition-infos">
    <h4>{{ $t('PUBLISHING.DETAIL.INFO.HEADING') }}</h4>
    <div class="info-row">
      <div>
        <strong>ID:</strong>
      </div>
      <div id="design-idea-id">{{ composition.id }}</div>
    </div>
    <div class="info-row">
      <div>
        <strong>{{ $t('DESIGNS.ID') }}:</strong>
      </div>
      <div id="design-detail-id">{{ composition.mainDesign.id }}</div>
    </div>
    <div class="info-row" v-if="design">
      <div>
        <strong>{{ $t('DESIGNS.FILE_NAME') }}:</strong>
      </div>
      <div id="design-detail-filename">
        {{ design.fileName }}
      </div>
    </div>
    <div class="info-row" v-if="design">
      <div>
        <strong>{{ $t('DESIGNS.DIMENSIONS') }}:</strong>
      </div>
      <div id="design-detail-dimensions">
        {{ design.size.width }}{{ design.size.unit }} x {{ design.size.height
        }}{{ design.size.unit }}
      </div>
    </div>
    <div class="info-row">
      <div>
        <strong>{{ $t('DESIGNS.CREATED') }}: </strong>
      </div>
      <div id="design-detail-created">
        {{ date(composition.dateCreated, 'short') }}
      </div>
    </div>
    <div class="info-row">
      <div>
        <strong>{{ $t('DESIGNS.MODIFIED') }}: </strong>
      </div>
      <div id="design-detail-modified">
        {{ date(composition.dateModified, 'short') }}
      </div>
    </div>
  </div>
</template>

<script>
import designService from '@/api/designService/designService';

export default {
  name: 'CompositionInfos',
  props: {
    composition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      design: null,
    };
  },
  async created() {
    this.design = await designService.getDesignById(
      this.composition.mainDesign.id
    );
  },
};
</script>

<style lang="scss" scoped>
.composition-infos {
  text-align: left;
}

.info-row {
  margin-bottom: 16px;

  & > div:first-child {
    margin-bottom: 8px;
  }
}

h4 {
  margin: 0 0 16px 0;
}
</style>
