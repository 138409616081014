import apiClient from '@/api/client/apiClient';
import 'src/app/commons/dialogs/forceUpdate/ForceUpdateDialog.vue';
import store from '@/store/Store';
import ForceUpdateDialog from '@/dialogs/forceUpdate/ForceUpdateDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

let interval;

function getRelevantVersionPart(version) {
  const match = version.match(/\d*\.(\d*)\.\d*/);
  if (match.length > 1) {
    return match[1];
  } else {
    return null;
  }
}

export default {
  startVersionCheck() {
    interval = setInterval(() => {
      apiClient
        .request({
          method: 'GET',
          url: `/version.json`,
          withCredentials: false,
          params: {
            locale: null,
          },
        })
        .then((res) => {
          if (
            getRelevantVersionPart(SPRD.VERSION) !==
            getRelevantVersionPart(res.data.version)
          ) {
            this.stopVersionCheck();
            dialogService.openDialog(ForceUpdateDialog).then(
              () => {},
              () => {
                this.startVersionCheck();
              }
            );
          }
        });
    }, 5 * 60 * 1000);
  },
  stopVersionCheck() {
    clearInterval(interval);
  },
  persistLastVisitedVersion() {
    if (store.getters['account/isAdmin']) {
      return;
    }

    store.dispatch('settings/setSetting', {
      key: 'LAST_VISITED_VERSION',
      value: SPRD.VERSION,
    });
  },
  getLastVisitedVersion() {
    return store.getters['settings/getSetting']('LAST_VISITED_VERSION');
  },
  getLastVisitedSpreadAppVersion() {
    return store.getters['settings/getSetting'](
      'LAST_VISITED_SPREADAPP_VERSION'
    );
  },
};
