export default {
  MP: {
    EU: {
      de_DE: 'https://www.spreadshirt.de/grafik-service',
      de_AT: 'https://www.spreadshirt.at/grafik-service',
      de_CH: 'https://www.spreadshirt.ch/grafik-service',
      it_CH: 'https://www.spreadshirt.ch/it/graphic-service',
      fr_CH: 'https://www.spreadshirt.ch/fr/service-graphique',
      fr_FR: 'https://www.spreadshirt.fr/service-graphique',
      fr_BE: 'https://www.spreadshirt.be/service-graphique',
      nl_NL: 'https://www.spreadshirt.nl/grafische-service',
      nl_BE: 'https://www.spreadshirt.be/nl/grafische-service',
      en_IE: 'https://www.spreadshirt.ie/graphics-service',
      da_DK: 'https://www.spreadshirt.dk/grafikservice',
      es_ES: 'https://www.spreadshirt.es/servicio-gr%C3%A1fico',
      it_IT: 'https://www.spreadshirt.it/graphic-service',
      no_NO: 'https://www.spreadshirt.no/grafisk-service',
      pl_PL: 'https://www.spreadshirt.pl/serwis-graficzny',
      fi_FI: 'https://www.spreadshirt.fi/grafiikka-palvelu',
      sv_SE: 'https://www.spreadshirt.se/grafikservice',
      default: 'https://www.spreadshirt.co.uk/graphics-service',
    },
    NA: {
      en_CA: 'https://www.spreadshirt.ca/graphics-service',
      fr_CA: 'https://www.spreadshirt.ca/fr/service-graphique',
      default: 'https://www.spreadshirt.com/graphics-service ',
    },
  },
  SHOP: {
    EU: {
      de_DE: 'https://www.spreadshirt.de/grafik-service',
      de_AT: 'https://www.spreadshirt.at/grafik-service',
      de_CH: 'https://www.spreadshirt.ch/grafik-service',
      it_CH: 'https://www.spreadshirt.ch/it/graphic-service',
      fr_CH: 'https://www.spreadshirt.ch/fr/service-graphique',
      fr_FR: 'https://www.spreadshirt.fr/service-graphique',
      fr_BE: 'https://www.spreadshirt.be/service-graphique',
      nl_NL: 'https://www.spreadshirt.nl/grafische-service',
      nl_BE: 'https://www.spreadshirt.be/nl/grafische-service',
      da_DK: 'https://www.spreadshirt.dk/grafikservice',
      es_ES: 'https://www.spreadshirt.es/servicio-gr%C3%A1fico',
      it_IT: 'https://www.spreadshirt.it/graphic-service',
      no_NO: 'https://www.spreadshirt.no/grafisk-service',
      pl_PL: 'https://www.spreadshirt.pl/serwis-graficzny',
      fi_FI: 'https://www.spreadshirt.fi/grafiikka-palvelu',
      sv_SE: 'https://www.spreadshirt.se/grafikservice',
      default: 'https://www.spreadshop.com/premiumdesign',
    },
    NA: {
      fr_CA: 'https://www.spreadshirt.ca/fr/service-graphique',
      default: 'https://www.spreadshop.com/premiumdesign',
    },
  },
};
