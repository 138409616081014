<template>
  <div class="design-config-control">
    <div class="tabs" v-if="relevantModes.length > 1">
      <ul>
        <li
          v-for="mode in relevantModes"
          :key="mode.key"
          :class="{ active: currentMode.key === mode.key }"
        >
          <a href="#" @click.prevent="changeMode(mode)">
            <Icon :icon="mode.icon" />
            <p>{{ $t(mode.translation) }}</p>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div v-if="currentMode.key === MODES.DESIGN.key">
        <div v-if="configuration" class="configuration">
          <div class="design-size" v-if="designSizeSupported">
            <h4>
              {{ $t('DESIGNS.CONFIGURATION.EDITOR.DESIGN_SIZE') }}
            </h4>

            <div class="slider">
              <input
                id="design-size-slider"
                type="range"
                :min="minScale"
                :max="maxScale"
                step="1"
                v-model="svgImageWidth"
              />
            </div>
            <div class="design-size-hint">
              <p>{{ scalePercentage }}%</p>
              <p>-</p>
              <small>{{ imageWidthText }} x {{ imageHeightText }}</small>
            </div>
          </div>
          <div class="positioning">
            <h4>
              {{ $t('DESIGNS.CONFIGURATION.EDITOR.DESIGN_CENTER') }}
            </h4>
            <div class="options">
              <a
                href="#"
                @click.prevent="centerVertical"
                class="link-main icon-link"
              >
                <Icon icon="align-horizontally" />
                {{ $t('DESIGNS.CONFIGURATION.EDITOR.DESIGN_CENTER_VERTICAL') }}
              </a>
              <a
                href="#"
                @click.prevent="centerHorizontal"
                class="link-main icon-link"
              >
                <Icon icon="align-vertically" />
                {{
                  $t('DESIGNS.CONFIGURATION.EDITOR.DESIGN_CENTER_HORIZONTALLY')
                }}
              </a>
            </div>
          </div>
          <div class="design-colors" v-if="designColorsSupported">
            <h4>
              {{ $t('DESIGNS.CONFIGURATION.EDITOR.DESIGN_COLORS') }}
            </h4>
            <p>
              {{ $t('DESIGNS.CONFIGURATION.EDITOR.DESIGN_COLORS_EXPLANATION') }}
            </p>
            <ul>
              <li
                v-for="(color, index) in configuration.printColorRGBs"
                :key="index"
              >
                <VDropdown>
                  <ColorCircle :color="color" :name="getColorName(color)" />
                  <template #popper>
                    <div class="color-list">
                      <ColorCircle
                        v-for="color in configuration.localizedAvailableRGBs"
                        :key="color.rgb"
                        :color="color.rgb"
                        size="20"
                        @click="() => setDesignColor(color.rgb, index)"
                        :name="color.localizedName"
                        :active="
                          configuration.printColorRGBs[index] === color.rgb
                        "
                      />
                    </div>
                  </template>
                </VDropdown>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!configuration">
          <p>
            {{ $t('DESIGNS.CONFIGURATION.EDITOR.NO_DESIGN_ON_PRINTAREA') }}
          </p>
        </div>
      </div>
      <div v-if="currentMode.key === MODES.PRODUCT.key" class="configuration">
        <div class="colors">
          <h4>
            {{ $t('DESIGNS.CONFIGURATION.EDITOR.DEFAULT_PRODUCT_COLOR') }}
          </h4>
          <p>
            {{
              $t('DESIGNS.CONFIGURATION.EDITOR.DEFAULT_PRODUCT_COLOR_TOOLTIP')
            }}
          </p>
          <ul>
            <li
              v-for="appearance in appearances"
              :key="appearance.appearanceId"
            >
              <ColorCircle
                :color="appearance.rgbs"
                @onClick="$emit('selectAppearance', appearance)"
                :active="
                  appearance.appearanceId === config.template.appearanceId
                "
                :name="appearance.localizedName"
                :glossy="
                  appearance.hints && appearance.hints.includes('GLOSSY')
                "
                :transparent="
                  appearance.hints && appearance.hints.includes('TRANSPARENT')
                "
              />
            </li>
          </ul>
        </div>
        <div class="color-restrictions">
          <h4>
            {{ $t('DESIGNS.CONFIGURATION.EDITOR.COLOR_RESTRICTIONS.LABEL') }}
          </h4>
          <div class="color-restrictions-content">
            <p>
              {{
                $t(
                  'DESIGNS.CONFIGURATION.EDITOR.COLOR_RESTRICTIONS.DESCRIPTION'
                )
              }}
            </p>
            <ul>
              <li
                v-for="appearance in config.template.availableAppearances"
                :key="appearance.appearanceId"
                v-tooltip="{
                  content:
                    appearance.appearanceId === config.template.appearanceId
                      ? $t(
                          'DESIGNS.CONFIGURATION.EDITOR.COLOR_RESTRICTIONS.DEFAULT_HINT'
                        )
                      : null,
                  hideOnTargetClick: false,
                }"
              >
                <ColorCircle
                  :color="appearance.rgbs"
                  @onClick="excludeAppearance(appearance)"
                  :deactivated="appearance.excluded"
                  :allowClickWhenDeactivated="true"
                  :name="appearance.localizedName"
                  :disableTooltip="
                    appearance.appearanceId === config.template.appearanceId
                  "
                  :glossy="
                    appearance.hints && appearance.hints.includes('GLOSSY')
                  "
                  :transparent="
                    appearance.hints && appearance.hints.includes('TRANSPARENT')
                  "
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="currentMode.key === MODES.PRINTAREA.key" class="configuration">
        <div class="print-areas">
          <h4>{{ $t('DESIGNS.CONFIGURATION.EDITOR.PRINT_AREA') }}</h4>
          <p>{{ $t('DESIGNS.CONFIGURATION.EDITOR.PRINT_AREA_SUB') }}</p>
          <PrintAreaSelector
            :printAreas="printAreaData"
            @change="(options) => $emit('changePrintArea', options)"
            @onClick="(printArea) => $emit('setPrintArea', printArea.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';
import localeService from 'src/app/commons/localeService/localeService';
import PrintAreaSelector from 'src/app/partnerarea/idea/multipleDesigns/PrintAreaSelector.vue';
import { mapMutations, mapState } from 'vuex';

function toInch(valueInMM) {
  return valueInMM / 25.4;
}

export default {
  name: 'DesignConfigControl',
  data() {
    return {
      rangeSlider: null,
      rangeSliderWrapper: null,
      MODES: {
        DESIGN: {
          key: 'DESIGN',
          translation: 'DESIGNS.CONFIGURATION.EDITOR.TAB_DESIGN',
          icon: 'brush',
          hidden: (config) =>
            config?.restrictedFeatures?.includes('DESIGN_EDIT'),
        },
        PRODUCT: {
          key: 'PRODUCT',
          translation: 'DESIGNS.CONFIGURATION.EDITOR.TAB_PRODUCT',
          icon: 'drop',
        },
        PRINTAREA: {
          key: 'PRINTAREA',
          translation: 'DESIGNS.CONFIGURATION.EDITOR.TAB_PRINTAREAS',
          icon: 'multipleprintarea',
          hidden: (config) =>
            config?.restrictedFeatures?.includes('PRINT_AREA_CHANGE'),
        },
      },
      printAreaData: [],
    };
  },
  components: {
    ColorCircle,
    PrintAreaSelector,
  },
  props: ['config'],
  created() {
    this.updateRangeSliderWrapper();

    this.calculatePrintAreaData();
  },
  watch: {
    configuration: function () {
      this.updateRangeSliderWrapper();
    },
    printAreasForConfigurations: {
      handler() {
        this.calculatePrintAreaData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      lastDesignerTab: (s) => s.idea.lastDesignerTab,
    }),
    printAreasForConfigurations() {
      return this.config.template.configurations.map(
        (configuration) => configuration.printAreaId
      );
    },
    configuration() {
      return this.config.configuration;
    },
    relevantModes() {
      return Object.values(this.MODES).filter(
        (mode) => !mode.hidden || !mode.hidden(this.configuration)
      );
    },
    printAreas() {
      return Object.keys(this.config.template.productType.printAreas).map(
        (paId) => this.config.template.productType.printAreas[paId]
      );
    },
    designColorsSupported() {
      return (
        this.configuration &&
        !this.configuration.restrictedFeatures.includes(
          'DESIGN_COLOR_CHANGE'
        ) &&
        this.configuration.printColorRGBs
      );
    },
    designSizeSupported() {
      return !this.configuration?.restrictedFeatures.includes('SCALING');
    },
    minScale() {
      return Math.ceil(this.ratio.minWidth);
    },
    maxScale() {
      return Math.floor(this.ratio.maxWidth);
    },
    svgImageWidth: {
      get() {
        return this.configuration.width;
      },
      set(value) {
        const delta = value - this.svgImageWidth;
        this.$emit('scale', delta);
      },
    },
    svgImageHeight() {
      return this.configuration.height;
    },
    imageWidthText() {
      const isInchCountry = ['en_GB', 'en_US'].includes(
        localeService.getLocale()
      );
      const width = isInchCountry
        ? toInch(this.svgImageWidth)
        : this.svgImageWidth;
      const rounded = Math.round(width * 100) / 100;
      return `${rounded} ${isInchCountry ? 'in' : 'mm'}`;
    },
    imageHeightText() {
      const isInchCountry = ['en_GB', 'en_US'].includes(
        localeService.getLocale()
      );
      const height = isInchCountry
        ? toInch(this.svgImageHeight)
        : this.svgImageHeight;
      const rounded = Math.round(height * 100) / 100;
      return `${rounded} ${isInchCountry ? 'in' : 'mm'}`;
    },
    scalePercentage() {
      const value = Math.min(
        100,
        Math.max(
          0,
          Math.round(
            100 -
              ((this.maxScale - this.svgImageWidth) /
                (this.maxScale - this.minScale)) *
                100
          )
        )
      );
      if (this.rangeSliderWrapper) {
        colorRangeSlider(value, this.rangeSliderWrapper);
      }
      return value;
    },
    printArea() {
      return this.config.printArea;
    },
    ratio() {
      return this.config.printArea.ratios[this.config.configuration.designId];
    },
    appearances() {
      return this.config.template.availableAppearances.filter(
        (a) => !a.excluded
      );
    },
    excludedAppearanceCount() {
      return (
        this.config.template.availableAppearances.length -
        this.appearances.length
      );
    },
    currentMode() {
      return this.lastDesignerTab &&
        this.relevantModes.some((mode) => mode.key === this.lastDesignerTab.key)
        ? this.lastDesignerTab
        : this.relevantModes[0];
    },
  },
  methods: {
    ...mapMutations({
      setCurrentMode: 'idea/setLastDesignerTab',
    }),
    changeMode(mode) {
      this.setCurrentMode(mode);
      this.updateRangeSliderWrapper();
    },
    updateRangeSliderWrapper() {
      this.$nextTick(() => {
        this.rangeSliderWrapper = document.querySelector('.design-size');
      });
    },
    getColorName(color) {
      const localizedColors = this.configuration.localizedAvailableRGBs;
      if (localizedColors) {
        const localizedColor = localizedColors.find((c) => c.rgb === color);
        if (localizedColor) {
          return localizedColor.localizedName;
        }
      }
      return '';
    },
    setDesignColor(color, index) {
      this.configuration.printColorRGBs[index] = color;
    },
    centerHorizontal() {
      this.$emit('move', {
        x: (this.printArea.boundary.size.width - this.svgImageWidth) / 2,
        y: this.configuration.y,
      });
    },
    centerVertical() {
      this.$emit('move', {
        x: this.configuration.x,
        y:
          (this.printArea.boundary.size.height - this.configuration.height) / 2,
      });
    },
    excludeAppearance(appearance) {
      if (appearance.appearanceId === this.config.template.appearanceId) {
        return;
      }
      this.$emit('setAppearanceExclusion', appearance, !appearance.excluded);
    },
    calculatePrintAreaData() {
      const motives = this.config.template.configurations.map(
        (configuration) => {
          const possiblePrintAreas = Object.values(
            this.config.template.productType.printAreas
          )
            .filter((printArea) => !!printArea.ratios[configuration.designId])
            .map((printArea) => ({
              location: printArea.id,
            }));

          const motive = {
            designId: configuration.designId,
            href: this.config.designImages[configuration.designId].href,
            backgroundColor:
              this.config.designImages[configuration.designId].backgroundColor,
            name: this.config.designImages[configuration.designId].name,
            locations: possiblePrintAreas,
          };

          return motive;
        }
      );

      const printAreaData = [];
      Object.values(this.config.template.productType.printAreas).forEach(
        (printArea) => {
          const viewData = this.config.template.productType.views.find(
            (view) => view.id === printArea.viewId
          );

          const fittingConfiguration = this.config.template.configurations.find(
            (configuration) => configuration.printAreaId === printArea.id
          );
          let motive = null;
          if (fittingConfiguration) {
            motive = motives.find(
              (m) => m.designId === fittingConfiguration.designId
            );
          }
          printAreaData.push({
            id: printArea.id,
            name: viewData.name,
            motive,
          });
        }
      );

      this.printAreaData = printAreaData;
    },
  },
};

function colorRangeSlider(value, wrapper) {
  wrapper.style.setProperty('--design-size-value', `${value}%`);
}
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/mixins';

.design-config-control {
  flex-grow: 1;
  display: flex;
}

.tabs {
  border-right: 2px solid $grey5;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
      width: 100px;
      padding: 8px;
      display: flex;
      flex-grow: 1;
      position: relative;
      border-bottom: 2px solid $grey5;

      &.active {
        box-shadow: inset 4px 0px 0 0px $pa-color-main;

        .icon {
          color: $pa-color-main;
        }

        p {
          font-weight: bold;
        }
      }

      a {
        flex-grow: 1;
        text-align: center;
      }

      .icon {
        width: 32px;
        height: 32px;
        margin-bottom: 12px;
      }

      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}

h4 {
  margin: 32px 0 8px 0;
  display: flex;

  .link-blue {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}

.tab-content {
  flex-grow: 1;
  padding: 24px;
  width: 300px;

  p {
    color: $grey60;
  }
}

.configuration > div:first-child h4 {
  margin-top: 0;
}

.colors,
.design-colors,
.color-restrictions {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    padding: 0;
    list-style: none;

    li {
      cursor: pointer;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.design-size {
  --design-size-value: 10%;

  @include range-slider(
    linear-gradient(
      to right,
      $pa-color-main-light var(--design-size-value),
      $sprd-color-light-grey var(--design-size-value)
    ),
    $pa-color-main,
    10px,
    20px,
    $pa-color-main-light,
    $sprd-color-light-grey
  );

  .slider {
    display: flex;
    align-items: center;

    input {
      margin: 0;
    }
  }

  .design-size-hint {
    display: flex;
    align-items: center;
    margin-top: 8px;

    & > p {
      padding: 0;
      margin: 0 5px 0 0;
      font-weight: bold;
    }

    & > small {
      font-size: 12px;
    }
  }
}

.positioning {
  .options {
    display: flex;
  }

  a {
    &:first-child {
      margin-right: 20px;
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
</style>

<style lang="scss">
.v-popper__inner {
  .color-list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
