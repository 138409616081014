<template>
  <div></div>
</template>

<script>
export default {
  name: 'ShopEntryPage',
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
};
</script>
