<template>
  <label class="checkbox-container" :class="{ disabled }">
    <input
      class="checkbox"
      type="checkbox"
      :disabled="disabled"
      :checked="modelValue"
      :indeterminate="indeterminate"
      @click="$emit('update:modelValue', $event.target.checked)"
    />
    <div class="checkmark">
      <Icon icon="minus" v-if="indeterminate" />
      <Icon icon="accept" v-else />
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    disabled: {
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
    },
    indeterminate: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox {
    display: none;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid $grey45;
    border-radius: 4px;
    background-color: white;
    margin-right: 8px;
    flex-shrink: 0;

    .icon {
      width: 12px;
      height: 12px;
      display: none;
      color: $pa-color-main;
    }
  }

  .checkbox:checked ~ .checkmark,
  .checkbox:indeterminate ~ .checkmark {
    border-color: $pa-color-main;
    .icon {
      display: inline-block;
    }
  }

  &:hover {
    .checkmark {
      border-color: $pa-color-main-dark;
    }
  }

  &.disabled {
    color: $grey20;
  }

  .checkbox:disabled ~ .checkmark {
    border-color: $grey20;

    .icon {
      color: $grey20;
    }
  }
}
</style>
