import store from '@/store/Store.js';
import dialogService from '@/dialogs/wrapper/dialogService';
import ShopChoiceDialog from '@/dialogs/shopChoice/ShopChoiceDialog.vue';

import Page404 from 'src/app/partnerarea/404/404.vue';
import GoodBye from 'src/app/partnerarea/goodBye/GoodBye.vue';
import Collections from 'src/app/partnerarea/pos/shop/collections/Collections.vue';
import NoCollectionsPlaceholder from 'src/app/partnerarea/pos/shop/collections/NoCollectionsPlaceholder.vue';
import CollectionData from 'src/app/partnerarea/pos/shop/collections/details/CollectionData.vue';
import AboutPage from 'src/app/partnerarea/pos/shop/about/AboutPage.vue';
import AboutUs from 'src/app/partnerarea/pos/shop/startPage/aboutUs/AboutUs.vue';
import CollectionContent from 'src/app/partnerarea/pos/shop/collections/details/CollectionContent.vue';
import CollectionPreview from 'src/app/partnerarea/pos/shop/startPage/collectionPreview/CollectionPreview.vue';
import Dashboard from 'src/app/partnerarea/dashboard/Dashboard.vue';
import Account from 'src/app/partnerarea/account/Account.vue';
import Logos from 'src/app/partnerarea/pos/shop/design/logo/Logos.vue';
import TipsAndTools from 'src/app/partnerarea/tipsAndTools/TipsAndTools.vue';
import Statistics from 'src/app/partnerarea/statistics/Statistics.vue';
import ProductPreview from 'src/app/partnerarea/pos/shop/startPage/productPreview/ProductPreview.vue';
import NoProductPreviewPlaceholder from 'src/app/partnerarea/pos/shop/startPage/productPreview/NoProductPreviewPlaceholder.vue';
import DesignPreview from 'src/app/partnerarea/pos/shop/startPage/designPreview/DesignPreview.vue';
import NoDesignPreviewPlaceholder from 'src/app/partnerarea/pos/shop/startPage/designPreview/NoDesignPreviewPlaceholder.vue';
import DesignsWithProducts from 'src/app/partnerarea/pos/shop/startPage/designWithProducts/DesignsWithProducts.vue';
import NoDesignsWithProductsPlaceholder from 'src/app/partnerarea/pos/shop/startPage/designWithProducts/NoDesignsWithProductsPlaceholder.vue';
import ProductTypeCategory from 'src/app/partnerarea/pos/shop/startPage/productTypeCategory/ProductTypeCategory.vue';
import StartPage from 'src/app/partnerarea/pos/shop/startPage/StartPage.vue';
import StartPageImpossiblePlaceholder from 'src/app/partnerarea/pos/shop/startPage/StartPageImpossiblePlaceholder.vue';
import StartPageNoCollectionsPlaceholder from 'src/app/partnerarea/pos/shop/startPage/collectionPreview/StartPageNoCollectionsPlaceholder.vue';
import Teaser from 'src/app/partnerarea/pos/shop/startPage/teaser/Teaser.vue';
import TrustElements from 'src/app/partnerarea/pos/shop/startPage/trustElements/TrustElements.vue';
import PromotionSection from 'src/app/partnerarea/pos/shop/startPage/promotionSection/PromotionSection.vue';
import IdeaDetail from 'src/app/partnerarea/idea/detail/IdeaDetail.vue';
import CreateMultiDesignIdea from 'src/app/partnerarea/idea/multipleDesigns/CreateMultiDesignIdea.vue';
import IdeaBulkTags from 'src/app/partnerarea/idea/bulk/tags/IdeaBulkTags.vue';
import IdeaBulkPos from 'src/app/partnerarea/idea/bulk/pos/IdeaBulkPos.vue';
import IdeaAssortmentUpdate from 'src/app/partnerarea/idea/assortmentUpdate/IdeaAssortmentUpdate.vue';
import IdeaAssortmentEmpty from 'src/app/partnerarea/idea/assortmentEmpty/IdeaAssortmentEmpty.vue';
import IdeaMpRemoval from 'src/app/partnerarea/idea/mpRemoval/IdeaMpRemoval.vue';
import ModelImages from 'src/app/partnerarea/pos/shop/general/modelImages/ModelImages.vue';
import ShopNameAndUrl from 'src/app/partnerarea/pos/shop/nameAndUrl/ShopNameAndUrl.vue';
import ShopLanguageAndCurrency from 'src/app/partnerarea/pos/shop/languageAndCurrency/ShopLanguageAndCurrency.vue';
import Imprint from 'src/app/partnerarea/pos/shop/imprint/Imprint.vue';
import ShopStatus from 'src/app/partnerarea/pos/shop/status/ShopStatus.vue';
import IdeaUploadAdjustment from 'src/app/partnerarea/idea/uploadAdjustment/IdeaUploadAdjustment.vue';
import IdeaList from 'src/app/partnerarea/idea/list/IdeaList.vue';
import PublishingList from 'src/app/partnerarea/publishing/list/PublishingList.vue';
import PublishingDetail from 'src/app/partnerarea/publishing/detail/PublishingDetail.vue';
import FollowUs from 'src/app/partnerarea/pos/shop/startPage/followUs/FollowUs.vue';
import NoSocialMediaProfilePlaceholder from 'src/app/partnerarea/pos/shop/startPage/followUs/NoSocialMediaProfilePlaceholder.vue';
import CYOIntro from 'src/app/partnerarea/pos/shop/startPage/cyoIntro/CYOIntro.vue';
import CYONoDesignerPlaceholder from 'src/app/partnerarea/pos/shop/startPage/cyoIntro/CYONoDesignerPlaceholder.vue';
import Templates from 'src/app/partnerarea/pos/shop/design/templates/Templates.vue';
import Design from 'src/app/partnerarea/pos/shop/design/Design.vue';
import ShopStyles from 'src/app/partnerarea/pos/shop/design/shopStyles/ShopStyles.vue';
import Login from 'src/app/partnerarea/login/Login.vue';
import LoginMobile from 'src/app/partnerarea/login/LoginMobile.vue';
import Register from 'src/app/partnerarea/register/Register.vue';
import RegisterMobile from 'src/app/partnerarea/register/RegisterMobile.vue';
import ShopRegister from 'src/app/partnerarea/register/ShopRegister.vue';
import ShopRegisterMobile from 'src/app/partnerarea/register/ShopRegisterMobile.vue';
import ThemeSelection from 'src/app/partnerarea/shop/themes/ThemeSelection.vue';
import ConnectDeviceMobile from 'src/app/partnerarea/app/ConnectDeviceMobile.vue';
import SocialMediaProfile from 'src/app/partnerarea/pos/shop/socialMediaProfile/SocialMediaProfile.vue';
import Promotions from 'src/app/partnerarea/pos/shop/promotions/Promotions.vue';
import PromotionsLeftMenu from 'src/app/partnerarea/pos/shop/promotions/PromotionsLeftMenu.vue';
import PricingNav from 'src/app/partnerarea/pos/shop/pricing/PricingNav.vue';
import NonProfitNav from 'src/app/partnerarea/pos/shop/pricing/NonProfitNav.vue';
import PricingPage from 'src/app/partnerarea/pos/shop/pricing/PricingPage.vue';
import ShopGeneral from 'src/app/partnerarea/pos/shop/general/ShopGeneral.vue';
import ShopDetailPage from 'src/app/partnerarea/pos/shop/productDetailPage/ShopDetailPage.vue';
import NoProductsPlaceholder from 'src/app/partnerarea/pos/shop/common/NoProductsPlaceholder.vue';
import HtmlAndCss from 'src/app/partnerarea/pos/shop/htmlAndCss/HtmlAndCss.vue';
import EmbedShop from 'src/app/partnerarea/pos/shop/embed/EmbedShop.vue';
import ShopCyoSettings from 'src/app/partnerarea/pos/shop/cyo/ShopCyoSettings.vue';
import ShopEntryPage from 'src/app/partnerarea/pos/shop/entryPage/ShopEntryPage.vue';
import ListPage from 'src/app/partnerarea/pos/shop/listPage/ListPage.vue';
import PromotionAppearance from 'src/app/partnerarea/pos/shop/promotionAppearance/PromotionAppearance.vue';
import SocialMediaToolNav from 'src/app/partnerarea/pos/shop/socialMediaTool/SocialMediaToolNav.vue';
import Notifications from 'src/app/partnerarea/notifications/Notifications.vue';
import YoutubeNav from 'src/app/partnerarea/pos/shop/youtube/YoutubeNav.vue';
import YoutubePage from 'src/app/partnerarea/pos/shop/youtube/YoutubePage.vue';
import AddressValidation from 'src/app/partnerarea/addressValidation/AddressValidation.vue';
import GoogleMarketing from 'src/app/partnerarea/pos/shop/marketing/GoogleMarketing.vue';
import FacebookMarketing from 'src/app/partnerarea/pos/shop/marketing/meta/FacebookMarketing.vue';
import PinterestMarketing from 'src/app/partnerarea/pos/shop/marketing/PinterestMarketing.vue';
import TikTokMarketing from 'src/app/partnerarea/pos/shop/marketing/TikTokMarketing.vue';
import ApiKeyManagement from 'src/app/partnerarea/apiKeyManagement/ApiKeyManagement.vue';
import Showroom from 'src/app/partnerarea/pos/showroom/Showroom.vue';
import ShopArea from 'src/app/partnerarea/pos/shop/ShopArea.vue';
import DigitalProductsList from 'src/app/partnerarea/digitalProducts/list/DigitalProductsList.vue';
import DigitalProductDetail from 'src/app/partnerarea/digitalProducts/detail/DigitalProductDetail.vue';
import AiDesignGenerator from 'src/app/commons/designGenerator/AiDesignGenerator.vue';

export default [
  {
    name: 'partnerarea.goodbye',
    path: '/goodbye',
    component: GoodBye,
  },
  {
    name: 'partnerarea.addressValidation',
    path: '/addressValidation',
    component: AddressValidation,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.login',
    path: '/login',
    component: Login,
    meta: {
      redirectIfLoggedIn: 'partnerarea.dashboard',
    },
  },
  {
    name: 'partnerarea.loginmobile',
    path: '/app/login',
    component: LoginMobile,
    meta: {
      redirectIfLoggedIn: 'partnerarea.appmobile',
      accessibleInApp: true,
    },
  },
  {
    name: 'partnerarea.registermobile',
    path: '/app/register',
    component: RegisterMobile,
    meta: {
      redirectIfLoggedIn: 'partnerarea.appmobile',
      accessibleInApp: true,
    },
  },
  {
    name: 'partnerarea.shopregistermobile',
    path: '/app/shop-register',
    component: ShopRegisterMobile,
    meta: {
      redirectIfLoggedIn: 'partnerarea.appmobile',
      accessibleInApp: true,
    },
  },
  {
    name: 'partnerarea.logout',
    path: '/logout',
    meta: {
      accessibleInApp: true,
    },
    beforeEnter: () => {
      store.dispatch('user/logout');
    },
  },
  {
    name: 'partnerarea.register',
    path: '/register',
    component: Register,
    meta: {
      redirectIfLoggedIn: 'partnerarea.dashboard',
    },
  },
  {
    name: 'partnerarea.shopRegister',
    path: '/shop-register/:ctx?',
    component: ShopRegister,
    meta: {
      redirectIfLoggedIn: 'partnerarea.dashboard',
    },
    params: {
      ctx: {
        value: null,
        squash: true,
      },
    },
  },
  {
    name: 'partnerarea.themes',
    path: '/themes',
    component: ThemeSelection,
    meta: {
      redirectIfLoggedIn: 'partnerarea.dashboard',
    },
  },
  {
    name: 'partnerarea.dashboard',
    path: '/dashboard',
    alias: ['', '/'],
    component: Dashboard,
    meta: { loginRequired: true },
    tracking: {
      pageName: 'Partner Area - Overview',
      prop01: 'User Area - Overview',
    },
  },
  {
    name: 'partnerarea.createMultiDesignIdea',
    path: '/designs/create',
    component: CreateMultiDesignIdea,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.bulkTags',
    path: '/designs/tags',
    component: IdeaBulkTags,
    meta: {
      loginRequired: true,
      redirectOnDirectLink: 'partnerarea.ideas',
    },
  },
  {
    name: 'partnerarea.bulkPos',
    path: '/designs/pos',
    component: IdeaBulkPos,
    meta: {
      loginRequired: true,
      redirectOnDirectLink: 'partnerarea.ideas',
    },
  },
  {
    name: 'partnerarea.assortmentUpdate',
    path: '/designs/assortment',
    component: IdeaAssortmentUpdate,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.assortmentEmpty',
    path: '/designs/emptyAssortment',
    component: IdeaAssortmentEmpty,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.mpRemoval',
    path: '/designs/mp-removal',
    component: IdeaMpRemoval,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.ideaAdjustment',
    path: '/designs/adjust/:ideaId',
    component: IdeaUploadAdjustment,
    meta: {
      loginRequired: true,
      redirectOnDirectLink: 'partnerarea.ideas',
    },
  },
  {
    name: 'partnerarea.ideas',
    path: '/designs',
    component: IdeaList,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.idea',
    path: '/designs/:ideaId',
    component: IdeaDetail,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.publishing',
    path: '/publishing',
    component: PublishingList,
    meta: { loginRequired: true },
    params: {
      restoreState: null,
      upload: null,
    },
  },
  {
    name: 'partnerarea.publishingDetail',
    path: '/publishing/:compositionId',
    component: PublishingDetail,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.digitalProducts',
    path: '/digitalProducts',
    component: DigitalProductsList,
    meta: { loginRequired: true },
    params: {
      restoreState: null,
    },
  },
  {
    name: 'partnerarea.digitalProduct',
    path: '/digitalProducts/:digitalProductId',
    component: DigitalProductDetail,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.account',
    path: '/account/:subpage?',
    component: Account,
    meta: { loginRequired: true },
    tracking: {
      pageName: 'Partner Area - Settings',
      prop01: 'User Area - Settings',
    },
  },
  {
    name: 'partnerarea.tipsAndTools',
    path: '/tipsAndTools',
    component: TipsAndTools,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.notifications',
    path: '/notifications',
    component: Notifications,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.showroom',
    path: '/showroom',
    component: Showroom,
    meta: { loginRequired: true },
  },
  {
    name: 'generic-shop-area',
    path: '/shop-area/generic/:shopAreaPath',
    meta: { loginRequired: true },
    beforeEnter: async (to, from, next) => {
      let shopId;
      if (store.getters['user/shops'].length === 1) {
        shopId = store.getters['user/shops'][0].pointOfSale.target.id;
      } else {
        shopId = await dialogService.openDialog(
          ShopChoiceDialog,
          {},
          {
            displayClose: false,
            staticBackdrop: true,
            preventEsc: true,
          }
        );
      }
      next({
        path: `/shop-area/${shopId}/${to.params.shopAreaPath}`,
        params: to.params,
        query: to.query,
      });
    },
  },
  {
    name: 'shop-area',
    path: '/shop-area/:shopId',
    component: ShopArea,
    meta: { loginRequired: true },
    children: [
      {
        name: 'shop-area.general',
        path: 'general',
        components: {
          'context-nav@shop-area': ShopGeneral,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.general.seo',
        path: 'name-and-url',
        components: {
          'context-nav@shop-area': ShopNameAndUrl,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.general.imprint',
        path: 'imprint',
        components: {
          'context-nav@shop-area': Imprint,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.general.shop-status',
        path: 'shop-status',
        components: {
          'context-nav@shop-area': ShopStatus,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages',
        path: 'pages',
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page',
        path: 'start-page',
        components: {
          'context-nav@shop-area': StartPage,
          'context@shop-area': StartPageImpossiblePlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.about-us',
        path: 'about-us',
        components: {
          'context-nav@shop-area': AboutUs,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.follow-us',
        path: 'follow-us',
        components: {
          'context-nav@shop-area': FollowUs,
          'context@shop-area': NoSocialMediaProfilePlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.cyo-intro',
        path: 'cyo-intro',
        components: {
          'context-nav@shop-area': CYOIntro,
          'context@shop-area': CYONoDesignerPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.collection-preview',
        path: 'collection-preview',
        components: {
          'context-nav@shop-area': CollectionPreview,
          'context@shop-area': StartPageNoCollectionsPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.product-preview',
        path: 'product-preview',
        components: {
          'context-nav@shop-area': ProductPreview,
          'context@shop-area': NoProductPreviewPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.product-type-category',
        path: 'product-type-category',
        components: {
          'context-nav@shop-area': ProductTypeCategory,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.teaser',
        path: 'teaser',
        components: {
          'context-nav@shop-area': Teaser,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.trust-elements',
        path: 'trust-elements',
        components: {
          'context-nav@shop-area': TrustElements,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.promotion',
        path: 'promotion',
        components: {
          'context-nav@shop-area': PromotionSection,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.design-preview',
        path: 'design-preview',
        components: {
          'context-nav@shop-area': DesignPreview,
          'context@shop-area': NoDesignPreviewPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.start-page.designs-with-products',
        path: 'designs-with-products',
        components: {
          'context-nav@shop-area': DesignsWithProducts,
          'context@shop-area': NoDesignsWithProductsPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.product-list',
        path: 'product-list',
        components: {
          'context-nav@shop-area': ListPage,
          'context@shop-area': NoProductsPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.detail-page',
        path: 'detail-page',
        components: {
          'context-nav@shop-area': ShopDetailPage,
          'context@shop-area': NoProductsPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.designer',
        path: 'designer',
        components: {
          'context-nav@shop-area': ShopCyoSettings,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.collections',
        path: 'topics',
        components: {
          'context-nav@shop-area': Collections,
          'context@shop-area': NoCollectionsPlaceholder,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.collections-detail',
        path: 'topics/edit',
        components: {
          'context-nav@shop-area': CollectionData,
          'context@shop-area': CollectionContent,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pages.about-us-page',
        path: 'about-us',
        components: {
          'context-nav@shop-area': AboutPage,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.design',
        path: 'design',
        components: {
          'context-nav@shop-area': Design,
        },
        meta: {
          loginRequired: true,
        },
      },
      {
        name: 'shop-area.design.header-logo',
        path: 'logo',
        components: {
          'context-nav@shop-area': Logos,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.design.shop-styles',
        path: 'styles',
        components: {
          'context-nav@shop-area': ShopStyles,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.design.templates',
        path: 'templates',
        components: {
          'context-nav@shop-area': Templates,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.design.promotion-appearance',
        path: 'promotion-appearance',
        components: {
          'context-nav@shop-area': PromotionAppearance,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.design.model-images',
        path: 'model-images',
        components: {
          'context-nav@shop-area': ModelImages,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pricing-and-promos',
        path: 'pricing-and-promos',
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pricing-and-promos.pricing',
        path: 'pricing',
        components: {
          'context-nav@shop-area': PricingNav,
          'context@shop-area': PricingPage,
        },
        params: {
          productTypes: [],
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.pricing-and-promos.non-profit',
        path: 'non-profit',
        components: {
          'context-nav@shop-area': NonProfitNav,
          'context@shop-area': PricingPage,
        },
        params: {
          productTypes: [],
        },
        meta: { loginRequired: true, disableSetPricing: true },
      },
      {
        name: 'shop-area.pricing-and-promos.promos',
        path: 'promos',
        meta: { loginRequired: true },
        components: {
          'context@shop-area': Promotions,
          'context-nav@shop-area': PromotionsLeftMenu,
        },
      },
      {
        name: 'shop-area.marketing',
        path: 'marketing',
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.marketing.social-media-tool',
        path: 'social-media-tool',
        components: {
          'context-nav@shop-area': SocialMediaToolNav,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.marketing.google',
        path: 'google',
        components: {
          'context-nav@shop-area': GoogleMarketing,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.marketing.facebook',
        path: 'facebook',
        components: {
          'context-nav@shop-area': FacebookMarketing,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.marketing.pinterest',
        path: 'pinterest',
        components: {
          'context-nav@shop-area': PinterestMarketing,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.marketing.tiktok',
        path: 'tiktok',
        components: {
          'context-nav@shop-area': TikTokMarketing,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.advanced',
        path: 'advanced',
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.advanced.advanced-html-css',
        path: 'html-css',
        components: {
          'context-nav@shop-area': HtmlAndCss,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.advanced.integration',
        path: 'integration',
        components: {
          'context-nav@shop-area': EmbedShop,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.advanced.header-social-media-profile',
        path: 'social-media-profile',
        components: {
          'context-nav@shop-area': SocialMediaProfile,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.advanced.language',
        path: 'language',
        components: {
          'context-nav@shop-area': ShopLanguageAndCurrency,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.youtubeMerchShelf',
        path: 'youtubeMerchShelf',
        components: {
          'context@shop-area': YoutubePage,
          'context-nav@shop-area': YoutubeNav,
        },
        meta: { loginRequired: true },
      },
      {
        name: 'shop-area.staticShopPreview',
        path: 'shop',
        components: {
          'context-nav@shop-area': ShopEntryPage,
        },
        meta: { loginRequired: true },
      },
    ],
  },
  {
    name: 'partnerarea.statistics',
    path: '/statistics/:subpage?',
    component: Statistics,
    meta: { loginRequired: true },
  },
  {
    name: 'partnerarea.appmobile',
    path: '/app/connectDeviceMobile',
    component: ConnectDeviceMobile,
    meta: { loginRequired: true, accessibleInApp: true },
  },
  {
    name: 'partnerarea.apiKeyManagement',
    path: '/apiKey',
    meta: { loginRequired: true },
    component: ApiKeyManagement,
  },
  {
    name: 'partnerarea.aiShowcase',
    path: '/aiShowcase',
    meta: { loginRequired: true },
    component: AiDesignGenerator,
  },
  {
    name: 'partnerarea.404',
    path: '/:pathMatch(.*)*',
    component: Page404,
  },
];
