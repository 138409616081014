<template>
  <div>
    <ToggleSwitch
      label="SA.shopsettings.international.language.all.label"
      v-model="settings.international"
    />

    <div class="sprd-shop-input">
      <Dropdown
        :items="localization.languages"
        :itemKey="(lang) => lang.id"
        :itemToString="(lang) => lang.name"
        :label="$t('SA.shopsettings.international.language.label')"
        :value="currentLanguage"
        :disabled="settings.international"
        @change="setLanguage"
      />
    </div>

    <div class="shop-currency sprd-shop-input">
      <Dropdown
        :items="localization.currencies"
        :itemKey="(cur) => cur.id"
        :itemToString="(cur) => cur.isoCode"
        :label="$t('SA.shopsettings.international.currency.label')"
        :value="currentCurrency"
        :disabled="!config.shopCurrencyEditable"
        @change="setCurrency"
      />
    </div>

    <div>
      <small>{{ $t('SA.shopsettings.international.currency.info') }}</small>
    </div>

    <ToggleSwitch
      :label="label"
      v-model="shopLinked"
      :disabled="!settings.international"
    />

    <div class="linked-shop">
      <ValidLabelInput
        v-model="settings.linkedShopName"
        :label="$t('SA.shopDashboard.shopId.label')"
        :disabled="!helperActive"
        :validators="[validateLinkedShopName]"
        class="sprd-shop-input"
        maxlength="200"
      />
      <div v-tooltip="helperActive ? { content: $t(info) } : false">
        <Icon
          :class="{
            helpIcon: true,
            helpIconActive: helperActive,
            disabledThing: !helperActive,
          }"
          icon="info-circle"
        />
      </div>
    </div>

    <p :class="{ disabledThing: !settings.international }">
      {{ $t(description) }}
    </p>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

import ValidLabelInput from '../../shop/common/ValidLabelInput.vue';
import Dropdown from '../../../../commons/Dropdown/Dropdown.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { validateShopName } from '../../../shop/create/validateShopName';

export default {
  name: 'ShopLanguageAndCurrency',
  mixins: [SettingsData],
  components: {
    Dropdown,
    ValidLabelInput,
    ToggleSwitch,
  },
  data: function () {
    const isEU = SPRD.PLATFORM === 'EU';
    const linkedShopName =
      this.$store.state.shop.shopSavable.clientData.settings.linkedShopName;
    return {
      localization: {},
      label: isEU
        ? 'SA.shopsettings.international.naShop'
        : 'SA.shopsettings.international.euShop',
      info: isEU
        ? 'SA.shopsettings.international.linkedNaShopInfo'
        : 'SA.shopsettings.international.linkedEuShopInfo',
      description: isEU
        ? 'SA.shopsettings.international.naShopConnectionDescription'
        : 'SA.shopsettings.international.euShopConnectionDescription',
      shopLinked: Boolean(linkedShopName),
    };
  },
  created: function () {
    this.$store.commit('shop/setLivePreview', { path: '' });
    shopAPI
      .getLocalization(this.$store)
      .then((loc) => (this.localization = loc))
      .catch((e) => console.error('Failed to load localization', e));
  },
  computed: {
    currentLanguage() {
      if (!this.localization.languages) {
        return null;
      }
      return this.localization.languages.find(
        (lang) => lang.id === this.config.shopLanguage
      );
    },
    currentCurrency() {
      if (!this.localization.currencies) {
        return null;
      }
      return this.localization.currencies.find(
        (curr) => curr.id === this.config.shopCurrency
      );
    },
    helperActive() {
      return this.settings.international && this.shopLinked;
    },
  },
  methods: {
    setLanguage(lang) {
      this.config.shopLanguage = lang.id;
    },
    setCurrency(curr) {
      this.config.shopCurrency = curr.id;
    },
    validateLinkedShopName(shopName) {
      if (
        !shopName || // no linked shop
        /^\d+$/.test(shopName) || // Shop-ID
        validateShopName(shopName) // Shop name
      ) {
        return;
      }

      throw new Error(this.$t('ACCOUNT.ERRORS.INVALID_FORMAT'));
    },
  },
  watch: {
    shopLinked: function (newValue) {
      if (!newValue) {
        this.settings.linkedShopName = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.shop-currency {
  margin-bottom: 5px;
}

.linked-shop {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;

  .sprd-shop-input {
    flex-grow: 1;
    margin: 0;
  }
}

.helpIcon {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

.helpIconActive {
  color: $pa-color-main;
}

.disabledThing {
  color: #aaa;
}
</style>
