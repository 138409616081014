<template>
  <GlossaryList
    :heading="data.heading || 'DESIGNS.LIST.STATUS_GLOSSARY_HEADING'"
    :glossary="states"
  />
</template>

<script>
import GlossaryList from 'src/app/partnerarea/idea/list/statusGlossary/GlossaryList.vue';
import { COMPOSITION_POS_STATES } from '@/api/models/publishingCore/compositionPosStates';

export default {
  name: 'PublishingStatusDialog',
  components: { GlossaryList },
  props: ['modalInstance', 'data'],
  computed: {
    states() {
      return Object.values(COMPOSITION_POS_STATES).map((status) => ({
        name: status.name,
        description: status.description,
        color: status.color,
      }));
    },
  },
};
</script>
