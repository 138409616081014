<template>
  <div class="publishing-list page-wrapper">
    <div class="page-content">
      <div class="overview overflow-y">
        <div v-if="!initialized">
          <LoadingHeart v-if="!initialized" />
        </div>
        <div v-else-if="compositions && compositions.length">
          <div class="header">
            <h1>{{ $t('PUBLISHING.LIST.HEADING') }}</h1>
            <button
              class="text-btn link-main icon-link glossary-link"
              @click="openStatusGlossary"
            >
              <Icon icon="info-circle" />
              {{ $t('DESIGNS.LIST.STATUS_GLOSSARY_CTA') }}
            </button>
            <button class="btn btn-primary icon-btn" @click="create">
              <Icon icon="product-type" />
              {{ $t('PUBLISHING.LIST.CREATE_CTA') }}
            </button>
          </div>

          <div class="list">
            <ul>
              <li v-for="composition in compositions" :key="composition.id">
                <PublishingTile
                  :composition="composition"
                  @edit="editComposition(composition.id)"
                  displayPosStatus
                  :relevantPosTypes="relevantPosTypes"
                />
                <!-- :relevantPosTypes="[COMPOSITION_POS_TYPES.MP]"
                :relevantPosStates="[COMPOSITION_POS_STATES.UNPUBLISHED]" -->
              </li>
            </ul>
          </div>

          <div class="pagination-wrapper" v-if="compositions">
            <Pagination
              id="design-pagination"
              :total="totalCompositionCount"
              :pageSize="pageSize"
              :currentPage="page"
              @pageChange="onPageChange"
            />
          </div>
        </div>
        <div v-else class="empty-state">
          <div>
            <h1>{{ $t('PUBLISHING.LIST.EMPTY.HEADING') }}</h1>
            <p>
              {{ $t('PUBLISHING.LIST.EMPTY.TEXT') }}
            </p>
            <button class="btn btn-primary" @click="create">
              {{ $t('PUBLISHING.LIST.EMPTY.CTA') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import dialogService from '@/dialogs/wrapper/dialogService';
import PublishingStatusDialog from './PublishingStatusDialog.vue';
import PublishCreateDialog from '../create/PublishingCreateDialog.vue';
import PublishingTile from './PublishingTile.vue';
import Pagination from '@/pagination/Pagination.vue';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import {
  COMPOSITION_POS_STATES,
  COMPOSITION_POS_TYPES,
} from '@/api/models/publishingCore/compositionPosStates';

export default {
  name: 'PublishingList',
  components: { PublishingTile, Pagination, LoadingHeart },
  data() {
    return {
      initialized: false,
      COMPOSITION_POS_STATES,
      COMPOSITION_POS_TYPES,
    };
  },
  computed: {
    ...mapState({
      compositions: (s) => s.compositions.list,
      page: (s) => s.compositions.filter.page,
      pageSize: (s) => s.compositions.filter.pageSize,
    }),
    ...mapGetters({
      totalCompositionCount: 'compositions/totalCompositionCount',
      publishingCoreEnabled: 'settings/publishingCoreEnabled',
      partnerType: 'user/partnerType',
    }),
    relevantPosTypes() {
      if (this.partnerType === 'BOTH') {
        return [COMPOSITION_POS_TYPES.MP, COMPOSITION_POS_TYPES.SHOP];
      } else if (this.partnerType === 'SHOP') {
        return [COMPOSITION_POS_TYPES.SHOP];
      } else {
        return [COMPOSITION_POS_TYPES.MP];
      }
    },
  },
  async created() {
    await this.$store.state.publishingCore.onDataLoaded;

    if (!this.publishingCoreEnabled) {
      this.$router.push({ name: 'partnerarea.ideas' });
    }

    const restoreState =
      this.$route.params.restoreState && this.compositions.length;

    if (!restoreState) {
      this.resetFilter();
      await this.getCompositions();
    }
    this.initialized = true;
  },

  methods: {
    ...mapActions({
      fetchCompositions: 'compositions/fetchCompositions',
    }),
    ...mapMutations({
      resetFilter: 'compositions/resetFilter',
      setFilter: 'compositions/setFilter',
    }),
    async getCompositions() {
      this.initialized = false;
      await this.fetchCompositions();
      this.initialized = true;
    },
    create() {
      dialogService
        .openDialog(PublishCreateDialog)
        .then(this.fetchCompositions);
    },
    editComposition(compositionId) {
      this.$router.push({
        name: 'partnerarea.publishingDetail',
        params: { compositionId },
      });
    },
    openStatusGlossary() {
      dialogService.openDialog(PublishingStatusDialog, {
        heading: 'PUBLISHING.STATUS_GLOSSARY.HEADING',
      });
    },
    onPageChange(page) {
      this.setFilter({ page });
      this.getCompositions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.overview > div {
  padding: 24px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin: 0 0 24px 0;

  .btn {
    height: 44px;
  }
}

.glossary-link {
  margin: 0 24px 0 auto;
}

ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 24px;
  padding: 0 0 24px 0;
  margin: 0;
}

.pagination-wrapper {
  padding: 0 0 24px 0;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0 0 16px 0;
  }

  p {
    margin: 0 0 24px 0;
    color: $grey65;
  }

  & > div {
    max-width: 700px;
  }
}
</style>
