<template>
  <div class="config-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ConfigList',
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

p {
  margin: 0;
}

:deep(ul) {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey10;
    }

    & > :nth-child(2) {
      display: flex;
      align-items: center;
    }
  }
}
</style>
