<template>
  <GlossaryList
    heading="DESIGNS.LIST.STATUS_GLOSSARY_HEADING"
    :glossary="states"
  />
</template>

<script>
import IDEA_STATES from '@/api/models/ideaStates/ideaStates';
import GlossaryList from './GlossaryList.vue';

export default {
  name: 'IdeaStatusGlossaryDialog',
  components: { GlossaryList },
  props: ['modalInstance'],
  computed: {
    states() {
      return Object.values(IDEA_STATES)
        .filter((status) => !status.filterOnly && status.key !== 'OFFLINE')
        .map((status) => ({
          ...status,
        }));
    },
  },
};
</script>
