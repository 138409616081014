<template>
  <div class="mp-publishing-limit-reached-hint">
    <p class="text">
      {{ $t(currentGroup.meta.limitReached) }}
    </p>
    <SmartFaqLink :link="helpLinkTarget" class="help-link" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'MpPublishingLimtReachedHint',
  components: {
    SmartFaqLink,
  },
  data() {
    return {
      helpLinkTarget: smartFaqLinks.DMS_PUBLISHING_LIMIT_REACHED,
    };
  },
  computed: {
    ...mapGetters({
      currentGroup: 'dms/currentGroup',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

p {
  margin: 0;
  color: $grey60;
}

.help-link {
  margin-top: 12px;
}
</style>
