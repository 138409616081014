import userCoreService from '@/api/userAccount/userCoreService';
import { isEqual, deepCopy, isEmpty } from '@/utils';
import paymentInfoValidator from '@/api/validators/userAccount/paymentInfoValidator';

export default {
  namespaced: true,
  state: {
    data: null,
    originalData: null,
    obfuscatedData: null,
    payoutRestricted: null,
    validate: false,
    validation: {
      errors: {},
      result: true,
    },
  },
  getters: {
    hasChanged: (state) => !isEqual(state.data, state.originalData),
    dataWithObfuscatedFields: (state) =>
      getDataWithObfuscatedFields(state.data, state.obfuscatedData),
    paymentDataMissing: (state) => {
      return !state.obfuscatedData?.bankId && !state.obfuscatedData?.email;
    },
  },
  mutations: {
    setData(state, { paymentInfo, payoutRestricted }) {
      state.payoutRestricted = payoutRestricted;
      state.obfuscatedData = deepCopy(paymentInfo);

      state.data = removeObfuscatedData(paymentInfo);
      state.originalData = deepCopy(state.data);
    },
    setValidate(state, validate) {
      state.validate = validate;
    },
    reset(state) {
      state.data = deepCopy(state.originalData);
      state.validation = { errors: {}, result: true };
    },
    setBankAccountHolder(state, accountHolder) {
      state.data.accountHolder = accountHolder;
      validateField(state, 'bankAccountHolder');
    },
    setBankName(state, bankName) {
      state.data.bankName = bankName;
      validateField(state, 'bankName');
    },
    setBankCountry(state, country) {
      state.data.bankLocation = country;
      validateField(state, 'bankLocation');
    },
    setAccountId(state, accountId) {
      state.data.accountId = accountId;
      resetValidationErrorForField(state.validation, 'accountId');
      validateField(state, 'accountId');
    },
    setBankId(state, bankId) {
      state.data.bankId = bankId;
      validateField(state, 'bankId');
    },
    setPaypalAccountHolder(state, accountHolder) {
      state.data.accountHolder = accountHolder;
      validateField(state, 'paypalAccountHolder');
    },
    setPaypalEmail(state, email) {
      state.data.email = email;
      validateField(state, 'paypalEmail');
    },
    validate(state) {
      state.validation = paymentInfoValidator.validate(
        state.data,
        state.obfuscatedData
      );
    },
    setSavingError(state) {
      state.validation.error = true;
    },
  },
  actions: {
    async fetchPaymentInfo({ commit }) {
      const data = await userCoreService.getPaymentInfo();
      commit('setData', data);
    },
    savePaymentInfo({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        commit('setValidate', true);
        commit('validate');
        if (state.validation.result) {
          const dataToSave = getters.dataWithObfuscatedFields;
          userCoreService.updatePaymentInfo(dataToSave).then(
            (data) => {
              commit('setData', data);
              resolve(data);
            },
            (error) => {
              commit('setSavingError', error);
              reject(error);
            }
          );
        } else {
          reject();
        }
      });
    },
  },
};

function validateField(state, field) {
  if (!state.validate) {
    return;
  }

  resetValidationErrorForField(state.validation, field);
  const validationResult = paymentInfoValidator.validateByFieldName(
    field,
    state.data,
    state.obfuscatedData
  );
  state.validation = paymentInfoValidator.mergeResults(
    state.validation,
    validationResult
  );
}

function resetValidationErrorForField(validation, field) {
  if (validation.errors[field]) {
    delete validation.errors[field];
  }
  return validation;
}

function removeObfuscatedData(data) {
  const newData = deepCopy(data);

  if (!isEmpty(newData.accountId)) {
    newData.accountId = '';
  }

  if (!isEmpty(newData.bankId)) {
    newData.bankId = '';
  }

  return newData;
}

function getDataWithObfuscatedFields(data, obfuscatedData) {
  if (!data || !obfuscatedData) {
    return null;
  }

  const result = JSON.parse(JSON.stringify(data));
  if (isEmpty(result.accountId) && obfuscatedData.accountId) {
    result.accountId = obfuscatedData.accountId;
  }
  if (isEmpty(result.bankId) && obfuscatedData.bankId) {
    result.bankId = obfuscatedData.bankId;
  }

  return result;
}
