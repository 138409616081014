<template>
  <div class="requirement">
    <div
      :class="{
        valid: fullfilled,
        invalid: failed,
      }"
    >
      <Icon v-if="fullfilled" icon="accept" />
      <Icon v-if="failed" icon="close" />
      <div v-if="!fullfilled && !failed" class="placeholder"></div>
      <p>{{ $t(text) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Requirement',
  props: {
    fullfilled: {
      type: Boolean,
    },
    failed: {
      type: Boolean,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.requirement {
  margin: 0 0 8px 0;

  & > div {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 10px 0;
    }

    p {
      margin: 0 0 0 8px;
    }

    &.valid {
      color: $pa-color-green;
    }

    &.invalid {
      color: $pa-color-red;
    }
  }

  .icon {
    width: 12px;
    height: 12px;
  }
  .placeholder {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: $grey20;
  }
}
</style>
