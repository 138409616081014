<template>
  <div>
    <ul class="templates">
      <li v-for="template in recommendedTemplates" :key="template.template">
        <h3>{{ template.name }}</h3>
        <img
          class="image"
          :src="template.thumbnailUrl"
          alt=""
          @click="preview(template)"
        />
        <div class="actions">
          <button
            type="button"
            class="btn btn-primary"
            @click="select(template.template)"
          >
            {{ $t('POS.TEMPLATES.OVERVIEW.BUTTON_TRYIT') }}
          </button>
          <a
            target="_blank"
            :href="template.url"
            class="link link-main"
            @click.prevent="preview(template)"
            >{{ $t('POS.TEMPLATES.OVERVIEW.BUTTON_PREVIEW') }}</a
          >
        </div>
      </li>
    </ul>
    <div class="classic" v-if="classicTemplate">
      <div class="inner">
        <div>
          <h3>{{ classicTemplate.name }}</h3>
          <p>
            {{
              $t('POS.TEMPLATES.' + classicTemplate.template + '.DESCRIPTION')
            }}
          </p>
        </div>
        <div class="actions">
          <button
            type="button"
            class="btn btn-primary"
            @click="select(classicTemplate.template)"
          >
            {{ $t('POS.TEMPLATES.OVERVIEW.BUTTON_TRYIT') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translateTemplateName } from './translateTemplateName';
import dialogService from '@/dialogs/wrapper/dialogService';
import PreviewTemplateDialog from 'src/app/partnerarea/pos/shop/design/templates/PreviewTemplateDialog.vue';

export default {
  name: 'SelectTemplateContent',
  props: ['availableTemplates'],
  data() {
    let classicTemplate = null;
    const recommendedTemplates = this.availableTemplates
      .filter((template) => {
        if (template.template === 'CLASSIC') {
          classicTemplate = Object.assign({}, template, {
            name: translateTemplateName(template.template),
          });
          return false;
        }

        return true;
      })
      .map((tpl) =>
        Object.assign({}, tpl, {
          name: translateTemplateName(tpl.template),
          url:
            'https://shop-template-' +
            tpl.template.toLowerCase() +
            '.myspreadshop.com',
        })
      );

    return {
      recommendedTemplates,
      classicTemplate,
    };
  },
  methods: {
    select(template) {
      this.$emit('input', template);
    },
    preview(template) {
      dialogService
        .openDialog(
          PreviewTemplateDialog,
          {
            template: template.template,
            url: template.url,
          },
          {
            displayClose: false,
            fullSize: true,
          }
        )
        .then(this.select);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.templates {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -2em;
  padding: 0;
  list-style: none;

  & > li {
    display: flex;
    flex-direction: column;
    flex: 0 1 calc(50% - 2em);
    min-width: 320px;
    max-width: 600px;
    margin: 0 0 4em 2em;

    @media screen and (min-width: 1920px) {
      flex-basis: calc(33.3333% - 2em);
    }
  }
}

img {
  display: block;
  width: 100%;
  cursor: pointer;
}

h3 {
  margin: 0 0 0.5em;
}

.actions {
  text-align: center;
}

button {
  min-width: 200px;
  height: 2.5rem;
  margin-right: 1em;
}

.classic {
  display: flex;
  margin: auto -12.5% -24px;
  background: $white;

  @media (max-width: 1399px) {
    margin-right: -3.191%;
    margin-left: -3.191%;
  }

  .inner {
    display: flex;
    padding: 24px;
  }

  h3 {
    margin-top: 0;
  }

  .actions {
    flex: none;
    margin: auto 0 auto 2em;
    min-height: 2.5em;
    padding-top: 0;
  }

  button {
    margin-right: 0;
  }
}
</style>
