export const getProductTypeImageUrl = (id) => {
  return `https://image.spreadshirtmedia.${
    SPRD.PLATFORM === 'EU' ? 'net' : 'com'
  }/image-server/v1/productTypes/${id}`;
};

export const getCountryImageUrl = (countryCode) => {
  return `images/flags/${countryCode.toLowerCase()}.svg`;
};

export const getDesignImageUrl = (designId) => {
  return `https://image.spreadshirtmedia.${
    SPRD.PLATFORM === 'EU' ? 'net' : 'com'
  }/image-server/v1/designs/${designId}`;
};
