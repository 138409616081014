<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PROMOTION.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.promotionEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.PROMOTION.ENABLE_PROMO_SECTION"
    />

    <div class="promo-app-desc">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PROMOTION.APPEARANCE') }}
      <div class="promo-app-link" @click="goToPromoAppearance">
        {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PROMOTION.APPEARANCE_LINK_TEXT') }}
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'PromotionSection',
  mixins: [StartPageData],
  components: { ToggleSwitch },
  created() {
    this.$store.commit('shop/setLivePreview', {
      path: '',
      section: 'sprd-startpage-promotion',
    });
  },
  methods: {
    goToPromoAppearance() {
      this.$router.push({ name: 'shop-area.design.promotion-appearance' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';

.promo-app-desc {
  color: $grey50;
}

.promo-app-link {
  color: $pa-color-main;
  display: inline;
  font-weight: bold;
  cursor: pointer;
}
</style>
