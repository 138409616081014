<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-primary add-btn"
      @click="handleSelectChannels"
    >
      {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.ADD_CHANNEL') }}
    </button>
    <div class="channelListContainer">
      <div class="channelList">
        <div v-for="channel in activeChannels" :key="channel.id">
          <div class="channelContainer" @click="editChannel(channel)">
            <Icon class="channelIcon" :icon="channel.icon" />
            <div class="channelFieldContainer">
              <div class="channelFieldText">
                {{
                  channelFields[channel.id]
                    ? channelFields[channel.id]
                    : channel.name
                }}
              </div>
              <div
                class="channelFieldStatus channelFieldStatusSuccess"
                v-if="channelFields[channel.id]"
              >
                {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.VISIBLE') }}
              </div>
              <div class="channelFieldStatus text-danger" v-else>
                {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.NO_URL') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SocialMediaChannelsData } from 'src/app/partnerarea/pos/shop/shopSavable/SocialMediaChannelsData';

import dialogService from '@/dialogs/wrapper/dialogService';
import SocialMediaProfileInputDialog from 'src/app/partnerarea/pos/shop/socialMediaProfile/SocialMediaProfileInputDialog.vue';
import SocialMediaProfileAddChannelDialog from 'src/app/partnerarea/pos/shop/socialMediaProfile/SocialMediaProfileAddChannelDialog.vue';

// regex: exclude all strings containing '/'
// this will exclude any urls that have not been captured by previous rules
const defaultUserNameRule = /^([^/]*)$/;
const defaultUserTagRule = /^@(.+)/;
const youtubeChannelIdRule = /^([A-Za-z0-9_-]{24})$/;
const youtubeChannelRule =
  /(?:https:\/\/)?(?:www\.)?youtube\.com\/channel\/([^/?]+)(?:\/.*)?(?:\?.*)?/;
const spotifyArtistRule =
  /(?:https:\/\/)?open\.spotify\.com\/artist\/([^/?]+)(?:\/.*)?(?:\?.*)?/;
const spotifyUserRule =
  /(?:https:\/\/)?open\.spotify\.com\/user\/([^/?]+)(?:\/.*)?(?:\?.*)?/;
const spotifyShowRule =
  /(?:https:\/\/)?open\.spotify\.com\/show\/([^/?]+)(?:\/.*)?(?:\?.*)?/;

const availableChannels = [
  {
    id: 'facebook',
    name: 'Facebook',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?facebook\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'twitter',
    name: 'Twitter',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?twitter\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserTagRule,
      defaultUserNameRule,
    ],
  },
  {
    id: 'instagram',
    name: 'Instagram',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?instagram\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserTagRule,
      defaultUserNameRule,
    ],
  },
  {
    id: 'youtube',
    name: 'Youtube',
    icon: 'youtube-2',
    patterns: [
      youtubeChannelIdRule,
      youtubeChannelRule,
      /(?:https:\/\/)?(?:www\.)?youtube\.com\/user\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      /(?:https:\/\/)?(?:www\.)?youtube\.com\/c\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      /(?:https:\/\/)?(?:www\.)?youtube\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'snapchat',
    name: 'Snapchat',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?snapchat\.com\/add\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'blogspot',
    name: 'Blogger',
    icon: 'blogger',
    patterns: [
      /(?:https:\/\/)?([^/?]+)\.blogspot\.com(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'pinterest',
    name: 'Pinterest',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?pinterest\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'deviantart',
    name: 'Deviantart',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?deviantart\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'behance',
    name: 'Béhance',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?behance\.net\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'bandcamp',
    name: 'Bandcamp',
    patterns: [
      /(?:https:\/\/)?([^/?]+)\.bandcamp\.com(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'soundcloud',
    name: 'Soundcloud',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?soundcloud\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'applemusic',
    name: 'Apple Music',
    patterns: [
      /(?:https:\/\/)?(?:music|itunes)\.apple\.com\/(?:[a-z]{2}\/)?artist\/(?:[^0-9][^/]*\/)?([0-9]+).*/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'spotify',
    name: 'Spotify',
    icon: 'spotify',
    patterns: [
      spotifyArtistRule,
      spotifyUserRule,
      spotifyShowRule,
      defaultUserNameRule,
    ],
  },
  {
    id: 'steam',
    name: 'Steam',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?steamcommunity\.com\/id\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'twitch',
    name: 'Twitch',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?twitch\.tv\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'mixcloud',
    name: 'Mixcloud',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?mixcloud\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'vimeo',
    name: 'Vimeo',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?vimeo\.com\/([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserNameRule,
    ],
  },
  {
    id: 'tiktok',
    name: 'TikTok',
    patterns: [
      /(?:https:\/\/)?(?:www\.)?tiktok\.com\/@([^/?]+)(?:\/.*)?(?:\?.*)?/,
      defaultUserTagRule,
      defaultUserNameRule,
    ],
  },
  {
    id: 'discordServer',
    name: 'Discord Server',
    icon: 'discord',
    patterns: [
      /(?:https:\/\/)?discord\.gg\/([^/?]+)/,
      /(?:https:\/\/)?discord\.com\/invite\/([^/?]+)/,
    ],
  },
  {
    id: 'custom',
    name: 'ACCOUNT.SELLER.WEBSITE',
    charLength: 800,
    icon: 'chain',
  },
];

export default {
  name: 'SocialMediaProfile',
  mixins: [SocialMediaChannelsData],
  data() {
    return {
      availableChannels: availableChannels.map(
        ({ id, name, icon = id, ...data }) => ({
          id,
          name: this.$t(name),
          icon,
          selected: false,
          ...data,
        })
      ),
    };
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
  computed: {
    activeChannels() {
      return this.availableChannels.filter(
        ({ id, selected }) => selected || this.channelFields[id]
      );
    },
    channelFields() {
      return {
        facebook:
          this.channels.facebook && `facebook.com/${this.channels.facebook}`,
        twitter:
          this.channels.twitter && `twitter.com/${this.channels.twitter}`,
        instagram:
          this.channels.instagram && `instagram.com/${this.channels.instagram}`,
        youtube:
          (this.channels.youtubeUser &&
            `youtube.com/${this.channels.youtubeUser}`) ||
          (this.channels.youtubeChannel &&
            `youtube.com/channel/${this.channels.youtubeChannel}`),
        snapchat:
          this.channels.snapchat &&
          `snapchat.com/add/${this.channels.snapchat}`,
        blogspot:
          this.channels.blogspot && `${this.channels.blogspot}.blogspot.com`,
        pinterest:
          this.channels.pinterest && `pinterest.com/${this.channels.pinterest}`,
        deviantart:
          this.channels.deviantart &&
          `deviantart.com/${this.channels.deviantart}`,
        behance:
          this.channels.behance && `behance.net/${this.channels.behance}`,
        bandcamp:
          this.channels.bandcamp && `${this.channels.bandcamp}.bandcamp.com`,
        soundcloud:
          this.channels.soundcloud &&
          `soundcloud.com/${this.channels.soundcloud}`,
        applemusic:
          this.channels.applemusic &&
          `itunes.apple.com/artist/${this.channels.applemusic}`,
        spotify:
          (this.channels.spotifyArtist &&
            `open.spotify.com/artist/${this.channels.spotifyArtist}`) ||
          (this.channels.spotifyUser &&
            `open.spotify.com/user/${this.channels.spotifyUser}`) ||
          (this.channels.spotifyShow &&
            `open.spotify.com/show/${this.channels.spotifyShow}`),
        steam:
          this.channels.steam && `steamcommunity.com/id/${this.channels.steam}`,
        twitch: this.channels.twitch && `twitch.tv/${this.channels.twitch}`,
        mixcloud:
          this.channels.mixcloud && `mixcloud.com/${this.channels.mixcloud}`,
        vimeo: this.channels.vimeo && `vimeo.com/${this.channels.vimeo}`,
        tiktok: this.channels.tiktok && `tiktok.com/@${this.channels.tiktok}`,
        discordServer:
          this.channels.discordServer &&
          `discord.gg/${this.channels.discordServer}`,
        custom: this.channels.custom,
      };
    },
  },
  methods: {
    editChannel(channel) {
      dialogService
        .openDialog(SocialMediaProfileInputDialog, {
          name: channel.name,
          patterns: channel.patterns,
          charLength: channel.charLength,
          initial: this.channelFields[channel.id],
          onDelete: () => this.setChannelSelected(channel.id, false),
        })
        .then((result) => this.handleEditChannelResult(channel.id, result));
    },
    handleEditChannelResult(channelId, result) {
      switch (channelId) {
        case 'youtube':
          this.editYoutubeProfileResultHandler(result);
          break;
        case 'spotify':
          this.editSpotifyProfileResultHandler(result);
          break;
        default:
          this.updateChannel(channelId, result.value);
          break;
      }
    },
    editYoutubeProfileResultHandler(result) {
      if (
        result.rule === youtubeChannelRule ||
        result.rule === youtubeChannelIdRule
      ) {
        this.updateChannel('youtubeUser', null);
        this.updateChannel('youtubeChannel', result.value);
      } else {
        this.updateChannel('youtubeUser', result.value);
        this.updateChannel('youtubeChannel', null);
      }
    },
    editSpotifyProfileResultHandler(result) {
      this.updateChannel(
        'spotifyArtist',
        result.rule === spotifyArtistRule ? result.value : null
      );
      this.updateChannel(
        'spotifyUser',
        result.rule === spotifyUserRule ? result.value : null
      );
      this.updateChannel(
        'spotifyShow',
        result.rule === spotifyShowRule ? result.value : null
      );
    },
    handleSelectChannels() {
      dialogService
        .openDialog(SocialMediaProfileAddChannelDialog, {
          channels: this.availableChannels.filter(
            ({ id, selected }) => !selected && !this.channelFields[id]
          ),
        })
        .then((selectedChannels) => {
          selectedChannels.forEach((channelId) =>
            this.setChannelSelected(channelId, true)
          );
        });
    },
    setChannelSelected(channelId, selected) {
      const channel = this.availableChannels.find(
        (channel) => channel.id === channelId
      );
      channel.selected = selected;
      if (!selected) {
        this.handleEditChannelResult(channelId, { value: null });
      }
    },
    updateChannel(channel, value) {
      this.channels[channel] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.channelListContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 -25px;
}

.channelList {
  width: 100%;
}

.add-btn {
  width: 100%;
  margin-bottom: 2.25em;
}

.channelContainer {
  width: 100%;
  display: flex;
  align-items: start;
  padding: 0.5em 25px 0.5em 25px;
  cursor: pointer;
  &:hover {
    background-color: $sprd-color-lightest-grey;
  }
}

.channelIcon {
  min-width: 1em;
  font-size: 2.4em;
}

.channelFieldContainer {
  flex-grow: 1;
  min-width: 0;
  margin-left: 0.5em;
}

.channelFieldStatusSuccess {
  color: $pa-color-green;
}

.channelFieldText {
  flex-grow: 1;
  min-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5em;
}
</style>
