<template>
  <div>
    <button
      type="button"
      class="btn btn-primary icon-btn btn-block"
      @click="onSelectTemplate"
    >
      <Icon icon="templating" />
      {{ $t('POS.SHOP_SETTINGS.DESIGN.TEMPLATES.SELECT') }}
    </button>
    <img class="image" :src="currentTemplate.thumbnailUrl" alt="" />
    <strong>{{ translateTemplateName0(currentTemplate.template) }}</strong>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import Icon from '@/icon/component/Icon.vue';
import { translateTemplateName } from './translateTemplateName';
import dialogService from '@/dialogs/wrapper/dialogService';
import SelectTemplateDialog from 'src/app/partnerarea/pos/shop/design/templates/SelectTemplateDialog.vue';

export default {
  name: 'Templates',
  mixins: [SettingsData],
  components: {
    Icon,
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
  computed: {
    availableTemplates() {
      return this.settings.availableTemplates;
    },
    currentTemplate() {
      return this.availableTemplates.find(
        (template) => template.template === this.settings.template
      );
    },
  },
  methods: {
    onSelectTemplate() {
      dialogService
        .openDialog(
          SelectTemplateDialog,
          {
            availableTemplates: this.availableTemplates,
          },
          {
            displayClose: false,
            fullSize: true,
          }
        )
        .then((template) => {
          this.settings.template = template;

          // apply various defaults so that the user actually feels a change in the PA
          const templateDefaults = this.availableTemplates.find(
            (availabelTemplate) =>
              availabelTemplate.template === this.settings.template
          );
          if (templateDefaults) {
            this.settings.fontText = templateDefaults.fontText;
            this.settings.fontHeadline = templateDefaults.fontHeadline;
            this.settings.schemeColorMain1 = templateDefaults.schemeColorMain1;
            this.settings.schemeColorMain2 = templateDefaults.schemeColorMain2;
            this.settings.schemeColorMain3 = templateDefaults.schemeColorMain3;
            this.settings.schemeColorSub2 = templateDefaults.schemeColorSub2;
            this.settings.schemeColorSub3 = templateDefaults.schemeColorSub3;
          }
        });
    },
    translateTemplateName0(name) {
      return translateTemplateName(name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

button {
  height: 40px;
  margin-bottom: 40px;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  display: block;
  margin-top: 0.75em;
}
</style>
