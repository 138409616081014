import { i18n } from '@/translate/i18n';

const { t } = i18n.global;

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomItemFromArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

function createCircle({ ctx, x, y, radius, colorArray }) {
  const color = getRandomItemFromArray(colorArray);

  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2, false);
  ctx.fillStyle = color;
  ctx.fill();
}

function getPointInCircle({ width, radius }) {
  const x0 = width / 2;
  const y0 = width / 2;

  let x1;
  let y1;
  let distance;
  let accept = true;

  do {
    x1 = randomInteger(radius, width - radius);
    y1 = randomInteger(radius, width - radius);

    distance = Math.sqrt((x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0));
    // accept = Math.random() > (distance * 2 + 50) / width;
  } while (distance >= width / 2 - radius || !accept);

  return { x: x1, y: y1 };
}

export const generateDesign = async function () {
  const width = 4000;
  const canvas = document.createElement('canvas');
  // document.getElementById('canvas-target').innerHTML = '';
  // document.getElementById('canvas-target').appendChild(canvas);
  canvas.width = width;
  canvas.height = width;
  const ctx = canvas.getContext('2d');
  const circlesCount = randomInteger(80, 150);

  const colorPalettes = [
    ['#420516', '#7D1935', '#B42B51', '#E63E6D'],
    ['#986D8E', '#87A8A4', '#D9CAB3', '#EFE3D0'],
    ['#F9ED69', '#F08A5D', '#B83B5E', '#6A2C70'],
    ['#F38181', '#FCE38A', '#EAFFD0', '#95E1D3'],
    ['#A8D8EA', '#AA96DA', '#FCBAD3', '#FFFFD2'],
    ['#2B2E4A', '#E84545', '#903749', '#53354A'],
    ['#1B262C', '#0F4C75', '#3282B8', '#BBE1FA'],
    ['#363062', '#4D4C7D', '#827397', '#D8B9C3'],
    ['#48466D', '#3D84A8', '#46CDCF', '#ABEDD8'],
    ['#35477D', '#6C5B7B', '#C06C84', '#F67280'],
  ];

  const colorArray = getRandomItemFromArray(colorPalettes);

  for (let i = 0; i < circlesCount; i++) {
    const radius = randomInteger(width / 100, width / 20);
    const { x, y } = getPointInCircle({ width, radius });

    createCircle({
      ctx,
      x,
      y,
      radius,
      colorArray,
    });
  }

  return fetch(canvas.toDataURL())
    .then((res) => res.blob())
    .then((blob) => {
      return new File(
        [blob],
        `${t('NO_DESIGNS.DIALOG.QUICK_START.FILENAME')}.png`,
        {
          type: 'image/png',
        }
      );
    });
};
