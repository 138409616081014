<template>
  <div class="overflow-y">
    <div class="notification-container">
      <div class="heading">
        <h1>{{ $t('NAV.NOTIFICATIONS') }}</h1>
        <a
          v-if="browserNotificationSupport && !browserNotificationsEnabled"
          href="#"
          @click.prevent="manuallyEnableBrowserNotifications"
          class="link-main icon-link"
        >
          <Icon icon="bell" />
          {{ $t('NOTIFICATIONS.BROWSER.ENABLE') }}
        </a>
      </div>
      <div class="notification-wrapper">
        <div class="notifications">
          <button
            v-if="hasUnreadNotifications"
            class="btn text-btn link-main"
            @click.prevent="setAllAsRead()"
          >
            {{ $t('NOTIFICATIONS.MARK_ALL_AS_READ') }}
          </button>
          <NotificationGroups
            v-if="filteredNotifications && filteredNotifications.length"
            :groups="notificationGroups"
          />
          <div v-else class="empty">
            <p>{{ $t('NOTIFICATIONS.NO_UNREAD') }}</p>
          </div>
          <div class="deletion-disclaimer">
            <p class="text-grey">
              {{
                $t('NOTIFICATIONS.DELETION_DISCLAIMER', {
                  duration: deletionDuration,
                })
              }}
            </p>
          </div>
        </div>

        <div class="notification-filter">
          <h3>{{ $t('NOTIFICATIONS.TOPICS_HEADING') }}</h3>
          <p class="text-grey">
            {{ $t('NOTIFICATIONS.TOPICS_SUBHEADING') }}
          </p>
          <ul>
            <li
              v-for="topic in relevantOrderedNotificationTopics"
              :key="topic.key"
            >
              <VDropdown
                :disabled="!topic.nonExcludable"
                placement="top"
                class="filter-popover"
              >
                <ToggleSwitch
                  :disabled="topic.nonExcludable"
                  fullLabelOpacityWhenDisabled
                  :model-value="!isTopicExcluded(topic.key)"
                  @update:model-value="toggleTopicExclusion(topic)"
                  class="filter-switch"
                >
                  <div
                    class="topic-filter"
                    :class="{
                      'filter-disabled': topic.nonExcludable,
                      'filter-empty': !countNotifications(topic.key),
                      'filter-excluded': isTopicExcluded(topic.key),
                      highlight: topic.key === 'ANNOUNCEMENTS',
                    }"
                  >
                    <div class="topic-icon">
                      <Icon :icon="topic.icon" />
                    </div>
                    <p>
                      {{ $t(topic.name) }}
                      {{ `(${countNotifications(topic.key)})` }}
                    </p>
                  </div>
                </ToggleSwitch>
                <template #popper>
                  {{ $t('NOTIFICATIONS.TOPICS_NON_EXCLUDABLE') }}
                </template>
              </VDropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { notificationTopics } from '@/api/notifications/notificationTopics';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import NotificationGroups from './NotificationGroups.vue';

export default {
  name: 'Notifications',
  components: {
    ToggleSwitch,
    NotificationGroups,
  },
  data() {
    return {
      orderedNotificationTopics: [
        notificationTopics.ANNOUNCEMENTS,
        notificationTopics.SHOP_NEWS,
        notificationTopics.MP_NEWS,
        notificationTopics.WEEKLY_SALES,
        notificationTopics.DESIGNS,
        notificationTopics.ASSORTMENT,
      ],
      deletionDuration: 30,
      browserNotificationSupport: 'Notification' in window,
      browserNotificationsEnabled: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredNotifications: 'notifications/filteredList',
      notifications: 'notifications/list',
      getSetting: 'settings/getSetting',
      partnerType: 'user/partnerType',
      isTopicExcluded: 'notifications/isTopicExcluded',
      fromToday: 'notifications/fromToday',
      fromOneWeek: 'notifications/fromOneWeek',
      fromOneMonth: 'notifications/fromOneMonth',
      otherTimeframe: 'notifications/otherTimeframe',
    }),
    relevantOrderedNotificationTopics() {
      return this.orderedNotificationTopics.filter(
        (topic) =>
          !topic.targetPartnerType ||
          topic.targetPartnerType.includes(this.partnerType)
      );
    },
    hasUnreadNotifications() {
      return (
        this.filteredNotifications.filter(
          (notification) => !notification.additionalProperties.includes('READ')
        ).length > 0
      );
    },
    notificationGroups() {
      return [
        {
          heading: 'NOTIFICATIONS.FROM_TODAY',
          list: this.fromToday,
        },
        {
          heading: 'NOTIFICATIONS.FROM_ONE_WEEK',
          list: this.fromOneWeek,
        },
        {
          heading: 'NOTIFICATIONS.FROM_ONE_MONTH',
          list: this.fromOneMonth,
        },
        {
          heading: 'NOTIFICATIONS.OTHER_TIMEFRAME',
          list: this.otherTimeframe,
        },
      ];
    },
  },
  created() {
    this.enableBrowserNotifications();

    this.noticationTopics = Object.values(notificationTopics).filter(
      (topic) => topic.key != 'DEFAULT'
    );
  },
  methods: {
    ...mapMutations({
      addToast: 'toasts/addToast',
    }),
    ...mapActions({
      setAllAsRead: 'notifications/setAllAsRead',
      setSetting: 'settings/setSetting',
      toggleTopicExclusion: 'notifications/toggleTopicExclusion',
    }),
    countNotifications(topic) {
      // This is not the same as notifications/unread getter as it uses "list" not "filteredList"
      const unreadNotifications = this.notifications.filter(
        (notification) =>
          notification.topic === topic &&
          !notification.additionalProperties.includes('READ')
      );
      return unreadNotifications.length > 99
        ? '99+'
        : unreadNotifications.length;
    },
    updateBrowserNotificationsEnabled() {
      this.browserNotificationsEnabled = Notification?.permission === 'granted';
    },
    async manuallyEnableBrowserNotifications() {
      try {
        await this.enableBrowserNotifications();
      } catch (error) {
        this.addToast({
          variant: 'black',
          text: 'NOTIFICATIONS.BROWSER.DENIED',
          hideIcon: true,
        });
      }
    },
    async enableBrowserNotifications() {
      if (!this.browserNotificationSupport) {
        return;
      }

      const permission = await Notification.requestPermission();
      this.updateBrowserNotificationsEnabled();

      if (permission === 'denied') {
        return Promise.reject();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.notification-container {
  max-width: 1400px;
  margin: auto;
  padding: 24px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    h1 {
      margin: 0;
    }
  }

  .notification-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 4px;
  }

  .notifications {
    position: relative;
    display: flex;
    flex: 1;
    flex-basis: 400px;
    flex-direction: column;
    background-color: $white;
    border-radius: 4px;

    & > button {
      position: absolute;
      top: 26px;
      right: 24px;
    }

    .empty p {
      text-align: center;
      margin: 48px 0;
    }

    .deletion-disclaimer {
      background-color: $grey5;
      padding-top: 24px;
      text-align: center;
    }
  }

  .notification-filter {
    flex: 1;
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;

    & > p,
    & > h3 {
      margin: 0 0 16px;
    }

    ul li {
      & + li {
        margin-top: 16px;
      }

      .filter-popover {
        :deep(div.trigger) {
          display: flex !important;
        }
      }

      .filter-switch {
        width: 100%;
      }

      .topic-filter {
        display: flex;
        align-items: center;
        flex-grow: 1;
        font-weight: bold;
        color: $grey80;
      }

      .topic-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey5;

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .highlight {
        .topic-icon {
          background-color: $pa-color-main;
          color: $white;
        }
      }

      .filter-disabled {
        cursor: not-allowed;
      }

      .filter-excluded,
      .filter-empty {
        font-weight: normal;
      }

      p {
        margin: 0 auto 0 8px;
        padding-right: 4px;
        white-space: nowrap;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>
