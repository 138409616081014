<template>
  <div :class="`register-page login shop`">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
    />
    <div class="top">
      <SpreadshopLogo />
    </div>
    <div class="register-container">
      <CentralAuth
        mode="shop"
        template="CLASSIC"
        :additionalContext="ctxParam"
      />
    </div>
  </div>
</template>

<script>
import CentralAuth from 'src/app/components/centralAuth/CentralAuth.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';

export default {
  name: 'ShopRegister',
  data: function () {
    return {
      ctxParam: this.$route.params.ctx || 'partner-area',
    };
  },
  components: {
    CentralAuth,
    SpreadshopLogo,
  },
};
</script>

<style lang="scss">
#application.state-partnerarea-shopRegister {
  min-width: 0;
  overflow-y: initial;

  #main-content {
    overflow: initial;
  }

  .help-panel {
    display: none;
  }
}
</style>
