import userCoreService from '@/api/userAccount/userCoreService';
import { isEqual, deepCopy } from '@/utils';

export default {
  namespaced: true,
  state: {
    data: null,
    originalData: null,
  },
  getters: {
    hasChanged: (state) => !isEqual(state.data, state.originalData),
  },
  mutations: {
    setData(state, data) {
      state.data = data;
      state.originalData = deepCopy(data);
    },
    reset(state) {
      state.data = deepCopy(state.originalData);
    },
    setCustomerNewsletter(state, subscribed) {
      state.data.customer = subscribed;
    },
    setPartnerNewsletter(state, subscribed) {
      state.data.partner = subscribed;
    },
  },
  actions: {
    async fetchNewsletterData({ commit }) {
      const data = await userCoreService.getNewsletterData();
      commit('setData', data);
    },
    async saveNewsletterData({ commit, state }) {
      const data = await userCoreService.updateNewsletterData(state.data);
      commit('setData', data);
    },
  },
};
