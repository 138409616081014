let _options;

export default {
  getSpritePath: () => _options?.spritePath,
  getVersion: () => _options?.version || '1.0.0',
  getIconRef() {
    return `${this.getSpritePath()}?v=${this.getVersion()}`;
  },
  setOptions(options) {
    _options = options;
  },
};
