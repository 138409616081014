<template>
  <div></div>
</template>

<script>
export default {
  name: 'ShopGeneral',
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
};
</script>
