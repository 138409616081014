<template>
  <div class="badge" :class="type">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  components: {},
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.badge {
  display: flex;
  padding: 4px;
  border-radius: 4px;
  align-items: center;

  :deep(.icon) {
    margin-right: 4px;
  }

  &.success {
    background-color: #cce5cf;
    color: #122c0b;
  }

  &.warning {
    background-color: #f2e2c4;
    color: #423207;
  }

  &.neutral {
    background-color: #e5e5e5;
    color: #333333;
  }

  &.error {
    background-color: #f0d4ca;
    color: #501b18;
  }

  &.dark {
    background-color: #1b1b1b;
    color: #fff;
  }
}
</style>
