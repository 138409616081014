import apiClient from '@/api/client/apiClient';
import localeService from 'src/app/commons/localeService/localeService';

export default {
  getMpDeeplinkForIdea(id, options = {}) {
    return apiClient
      .request({
        method: 'GET',
        url: '/shopData/url/detail',
        params: {
          locale: options.locale || localeService.getLocale(),
          id,
        },
      })
      .then((res) => res.data);
  },
};
