<template>
  <div>
    <p>{{ $t('DESIGNS.LIST.STUCK_IN_PROCESSING') }}</p>
    <a href="#" class="link icon-link delete" @click.prevent="deleteIdea">
      <Icon icon="bin" />
      {{ $t('GENERAL.DELETE') }}
    </a>
  </div>
</template>

<script>
import dialogService from '@/dialogs/wrapper/dialogService';
import IdeaDeletionDialog from 'src/app/partnerarea/idea/delete/IdeaDeleteDialog.vue';

export default {
  name: 'IdeaStuckInProgressingMsg',
  props: ['idea'],
  methods: {
    deleteIdea() {
      dialogService.openDialog(IdeaDeletionDialog, [this.idea]).then(() => {
        this.$router.push({ name: 'partnerarea.ideas' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.link {
  font-weight: normal;
  color: $grey80;

  &:hover {
    color: $pa-color-red;
  }

  .icon {
    color: $pa-color-red;
  }
}
</style>
