<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.DESIGN_PREVIEW.EMPTY.HEADING"
    subHeading="POS.SHOP_SETTINGS.STARTPAGE.DESIGN_PREVIEW.EMPTY.SUBHEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
    :faqLink="faqLink"
    ctaIcon="shirt"
    ctaText="POS.SHOP_SETTINGS.STARTPAGE.DESIGN_PREVIEW.EMPTY.LINK_UPLOAD"
    ctaTarget="partnerarea.ideas"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'NoDesignPreviewPlaceholder',
  mixins: [StartPageData],
  components: {
    NoStuffPlaceholder,
  },
  data() {
    return {
      faqLink: smartFaqLinks.IDEA_CREATION_HELP,
    };
  },
  computed: {
    display() {
      const { designPreviewPossible } = this.startPage;
      return (
        typeof designPreviewPossible === 'boolean' && !designPreviewPossible
      );
    },
  },
};
</script>
