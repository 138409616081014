<template>
  <div class="mp-transformer-mode-control" v-if="enabled">
    <Checkbox :model-value="this.textOnly" @update:model-value="setTextOnly">
      MP Transformer (Text Only)
    </Checkbox>
  </div>
</template>

<script>
import Checkbox from '@/checkbox/Checkbox.vue';

export default {
  name: 'MpTransformerModeControl',
  components: {
    Checkbox,
  },
  props: {
    mpTransformerMode: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      TRANSFORMER_MODES: {
        DISABLED: 'DISABLED',
        ONLY_TEXT_CHANGE: 'ONLY_TEXT_CHANGE',
      },
    };
  },
  computed: {
    textOnly() {
      return this.mpTransformerMode === this.TRANSFORMER_MODES.ONLY_TEXT_CHANGE;
    },
    enabled() {
      return Object.keys(this.TRANSFORMER_MODES).includes(
        this.mpTransformerMode
      );
    },
  },
  methods: {
    setTextOnly(textOnly) {
      this.$emit(
        'setMpTransformerMode',
        textOnly
          ? this.TRANSFORMER_MODES.ONLY_TEXT_CHANGE
          : this.TRANSFORMER_MODES.DISABLED
      );
    },
  },
};
</script>
