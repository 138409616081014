<template>
  <div class="modal-header">
    <div class="headerContainer">
      <button
        class="step"
        :class="{ stepActive: currentStep === n }"
        v-for="n in steps"
        :key="n"
        :disabled="currentStep <= n"
        @click="$emit('gotoStep', n)"
      >
        <span
          class="number"
          :class="{ numberFill: currentStep === n }"
          v-if="currentStep <= n"
        >
          {{ n }}
        </span>
        <span class="number numberFill" v-else>
          <Icon icon="checkmark" class="numberIcon" />
        </span>
        {{
          $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.PROGRESS_' + (n + stepOffset))
        }}
      </button>
      <div class="right" />
      <a
        @click="$emit('back')"
        class="headerBtnBack link-main icon-link"
        :disabled="currentStep == 1 && subStep == 1"
      >
        <Icon icon="chevron-small-left" />
        {{ $t('SA.action.back') }}
      </a>
      <a @click="$emit('cancel')" class="link-main icon-link">
        <Icon icon="close" />
        {{ $t('SA.action.cancel') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    subStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    stepOffset() {
      return this.steps === 3 ? 0 : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.headerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.step {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 1.5rem;
  background-color: transparent;
  border: none;
  &:hover:enabled {
    color: $sprd-color-dark-blue;
  }
}

.number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid $grey15;
  margin-right: 8px;
  background-color: white;
  font-weight: bold;
}

.numberFill {
  background-color: $pa-color-main;
  border-color: $pa-color-main;
  color: white;
}

.numberIcon {
  width: 100%;
  height: 100%;
}

.stepActive {
  font-weight: bold;
}

.channelIcon {
  width: 20px;
  height: 20px;
}

.right {
  margin-left: auto;
}

.headerBtnBack {
  margin-right: 1.5rem;
}
</style>
