import authService from '@/api/authService/authService';

export default {
  namespaced: true,
  state: {
    email: null,
    originalEmail: null,
    username: null,
    originalUsername: null,
  },
  getters: {
    emailChanged: (state) => state.email !== state.originalEmail,
    usernameChanged: (state) => state.username !== state.originalUsername,
    usernameSetup: (state) =>
      !!state.originalUsername &&
      state.originalUsername !== state.originalEmail,
    customUsername: (state, getters) =>
      getters.usernameSetup ? state.originalUsername : null,
  },
  mutations: {
    setData(state, { username, email }) {
      state.username = username;
      state.originalUsername = state.username;
      state.email = email;
      state.originalEmail = state.email;
    },
    setUsername(state, username) {
      state.username = username;
    },
    resetUsername(state) {
      state.username = state.originalUsername;
    },
    setEmail(state, email) {
      state.email = email;
    },
    resetEmail(state) {
      state.email = state.originalEmail;
    },
  },
  actions: {
    async fetchIdentity({ commit }) {
      const { email, username } = await authService.getCurrentIdentity();
      commit('setData', { email, username });
    },
    async saveEmail({ state, commit }, password) {
      const { email } = await authService.changeEmail({
        newEmail: state.email,
        password,
      });
      commit('setData', { email, username: state.username });
    },
    async saveUsername({ state, commit }) {
      const { loginName } = await authService.changeUsername(state.username);
      commit('setData', { email: state.email, username: loginName });
    },
  },
};
