<template>
  <div class="image-crop">
    <img ref="cropImg" class="preview" alt="" :src="imageURL" />
    <div ref="hint" class="cropper-hint">
      <Icon icon="move" class="cropper-hint__icon" />
      {{ $t('SA.imageCrop.hint') }}
    </div>
    <div class="controls">
      <div ref="ratio" class="ratio control">
        <button type="button" @click="zoomOut()" class="btn btn-dec"></button>
        <input
          type="range"
          :min="minRatio"
          :max="maxRatio"
          step="any"
          v-model="ratio"
        />
        <button type="button" @click="zoomIn()" class="btn btn-inc"></button>
      </div>
      <div class="rotate control">
        <button type="button" @click="rotateCounterClockwise()" class="btn">
          <Icon icon="rotate" />
          -90°
        </button>
        <button type="button" @click="rotateClockwise()" class="btn">
          <Icon icon="rotate" class="icon-cw" />
          90°
        </button>
      </div>
      <div class="confirm control">
        <button class="btn btn-warning btn-block btn-lg" @click="onCancel">
          <Icon icon="x" class="cancel-icon" />
          {{ $t('GENERAL.CANCEL') }}
        </button>
        <button class="btn btn-primary btn-block btn-lg" @click="onSave">
          <Icon icon="savedisk" class="save-icon" />
          {{ $t('GENERAL.SAVE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default {
  name: 'ImageCrop',
  props: {
    file: { type: File, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    cancel: { type: Function, required: true },
    save: { type: Function, required: true },
  },
  data: function () {
    return {
      ratio: 1,
      minRatio: 1,
      maxRatio: 1,
    };
  },
  computed: {
    imageURL() {
      return URL.createObjectURL(this.file);
    },
    aspectRatio() {
      return this.width / this.height;
    },
  },
  methods: {
    calculateRatios() {
      const cropper = this.cropper;
      const { naturalWidth, naturalHeight } = cropper.getCanvasData();
      const { width: boxWidth, height: boxHeight } = cropper.getCropBoxData();
      this.minRatio = Math.min(
        Math.max(boxWidth / naturalWidth, boxHeight / naturalHeight),
        1
      );

      if (boxWidth < this.width || boxHeight < this.height) {
        this.maxRatio = Math.min(
          boxWidth / this.width,
          boxHeight / this.height
        );

        if (this.minRatio > this.maxRatio) {
          this.maxRatio = this.minRatio;
        }
      }

      this.ratio = this.minRatio;
    },
    zoomOut() {
      this.ratio -= 0.1;
    },
    zoomIn() {
      this.ratio += 0.1;
    },
    rotateClockwise() {
      this.rotate(90);
    },
    rotateCounterClockwise() {
      this.rotate(-90);
    },
    rotate(degrees) {
      const cropper = this.cropper;
      cropper.rotate(degrees);

      // zoom out
      this.calculateRatios();
      cropper.zoomTo(this.minRatio);

      // center
      const { width, height } = cropper.getCanvasData();
      const { width: containerWidth, height: containerHeight } =
        cropper.getContainerData();
      cropper.moveTo(
        (containerWidth - width) / 2,
        (containerHeight - height) / 2
      );
    },
    onCancel() {
      this.destroyCropper();
      this.cancel();
    },
    onSave() {
      const canvas = this.cropper.getCroppedCanvas({
        width: this.width,
        height: this.height,
        imageSmoothingQuality: 'high',
      });

      if (canvas && canvas.toBlob) {
        canvas.toBlob((blob) => {
          this.destroyCropper();
          this.save(blob);
        }, this.file.type);
      } else {
        this.destroyCropper();
        this.save(); // no data will show generic error
      }
    },
    destroyCropper() {
      this.cropper.destroy();
      this.cropper = null;
    },
  },
  mounted() {
    const component = this;
    const cropImage = component.$refs.cropImg;
    const cropper = new Cropper(cropImage, {
      initialAspectRatio: component.aspectRatio,
      viewMode: 1, // restrict the crop box to not exceed the size of the canvas
      dragMode: 'move',
      guides: false,
      autoCrop: true,
      autoCropArea: 1,
      center: false,
      cropBoxMovable: false,
      cropBoxResizable: true,
      movable: true,
      rotatable: true,
      toggleDragModeOnDblclick: false,
      zoomable: true,
      minCropBoxWidth: 50,
      minCropBoxHeight: 50,
      ready() {
        component.calculateRatios();
        cropper.cropBox.append(component.$refs.hint);
      },
      zoom(event) {
        const { ratio } = event.detail;
        let updateRatio = 0;

        if (ratio > component.maxRatio) {
          updateRatio = component.maxRatio;
        }

        if (ratio < component.minRatio) {
          updateRatio = component.minRatio;
        }

        if (updateRatio !== 0) {
          // make min/max reachable for scroll/touch zooming
          event.preventDefault();
          cropper.zoomTo(updateRatio);
          return;
        }

        if (component.ratio !== ratio) {
          component.ratio = ratio;
        }
      },
      cropstart() {
        component.$refs.hint.classList.add('invisible');
      },
      cropend() {
        // when the crop box is really small, do not display the hint because it overshadows the crop box controls
        if (
          cropper.getCropBoxData().width > 190 &&
          cropper.getCropBoxData().height > 60
        ) {
          component.$refs.hint.classList.remove('invisible');
        }
      },
    });

    this.cropper = cropper;
  },
  watch: {
    ratio(value) {
      this.cropper.zoomTo(value);

      const progress =
        this.minRatio === this.maxRatio
          ? 0
          : ((value - this.minRatio) / (this.maxRatio - this.minRatio)) * 100;
      this.$refs.ratio.style.setProperty('--ratio', `${progress}%`);
    },
  },
};
</script>

<style lang="scss">
.cropper-view-box {
  outline: none;
}

.cropper-modal {
  background-color: #fff;
  opacity: 0.75;
}

.rotate__icon {
  path {
    stroke-width: 1px;
    stroke: currentColor;
  }
}

.cropper-hint {
  pointer-events: none;
  padding: 0.5em 1em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #333;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 0.25em;
}

.cropper-hint__icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.5em;
  vertical-align: -4px;
}
</style>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/mixins';

.preview {
  width: 70vw;
  max-width: 70vw;
  max-height: 70vh;
}

.controls {
  margin-top: 1em;
}

.controls,
.control {
  display: flex;
  justify-content: space-between;
}

.ratio {
  --ratio: 0;
  flex-grow: 1;
  max-width: 480px;
  @include range-slider(
    linear-gradient(
      to right,
      $pa-color-main-light var(--ratio),
      $sprd-color-light-grey var(--ratio)
    ),
    $pa-color-main,
    10px,
    20px,
    $pa-color-main-light,
    $sprd-color-light-grey
  );

  input[type='range'] {
    margin: auto 0.25em;

    &:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0.5em 0 $pa-color-main-dark;
    }

    &:focus::-moz-range-thumb {
      box-shadow: 0 0 0.5em 0 $pa-color-main-dark;
    }

    &:focus::-ms-thumb {
      box-shadow: 0 0 0.5em 0 $pa-color-main-dark;
    }
  }

  .btn {
    padding: 0 0.5em;
    background: none;
    border: 0;

    &::after {
      content: '';
      display: block;
      border: $sprd-color-medium-grey 2px solid;
      border-radius: 0.25em;
    }

    &:focus {
      outline: none;

      &::after {
        box-shadow: 0 0 0.5em 0 $pa-color-main-dark;
      }
    }
  }

  .btn-dec {
    margin-left: -0.5em;

    &::after {
      width: 1em;
      height: 0.75em;
    }
  }

  .btn-inc {
    &::after {
      width: 2em;
      height: 1.5em;
    }
  }
}

.rotate {
  width: 9em;
  margin: 0 1.5em;

  .btn {
    flex: 1;
    padding: 0;
    color: $pa-color-main-dark;
    font-weight: bold;
    background: none;
    border: 0;
    border-radius: 0.5em;

    &:focus {
      outline: none;
      box-shadow: 0 0 0.5em 0 $pa-color-main-dark;
    }
  }

  .icon {
    width: 1.5em;
    height: 1.5em;
  }

  .icon-cw {
    transform: scaleX(-1);
  }
}

.confirm {
  min-width: 18em;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    white-space: nowrap;

    & + .btn {
      margin-left: 1em;
    }

    &:focus {
      box-shadow: 0 0 0.5em 0 $pa-color-main-dark;
    }
  }

  .cancel-icon {
    width: 2.25em;
    height: 2.25em;
  }

  .save-icon {
    width: 1.75em;
    height: 1.75em;
    margin-right: 0.75em;
  }
}
</style>
