import apiClient from '@/api/client/apiClient';

let resolveOnDataLoaded;

export default {
  namespaced: true,
  state: {
    data: {},
    onDataLoaded: new Promise((resolve) => {
      resolveOnDataLoaded = resolve;
    }),
    loaded: false,
  },
  getters: {
    getSetting: (state) => (key) =>
      state.data[key] ? state.data[key].value : undefined,
    mpEnabled: (state, getters) => getters.getSetting('MP_ENABLED'),
    digitalProductsEnabled: (state, getters) =>
      getters.getSetting('DIGITAL_PRODUCTS'),
    publishingCoreEnabled: (state, getters) =>
      getters.getSetting('PUBLISHING_CORE'),
    lastNpsSubmit: (state, getters) => getters.getSetting('LAST_PA_NPS_SUBMIT'),
    aiEnabled: (state, getters) =>
      ['ENABLED', 'INTERNAL'].includes(getters.getSetting('AI_ENABLED')),
  },
  mutations: {
    fetchSettings(state, data) {
      data.forEach((item) => {
        state.data[item.key] = item;
      });
      resolveOnDataLoaded();
      state.loaded = true;
    },
    setSetting(state, { key, value }) {
      state.data[key].value = value;
    },
  },
  actions: {
    async fetchSettings({ commit, rootState }) {
      const { data } = await apiClient.request({
        method: 'GET',
        url: `/api/v1/partners/${rootState.user.data.id}/settings`,
      });
      commit('fetchSettings', data);
    },
    async setSetting({ commit, rootState, state }, { key, value }) {
      if (!Object.keys(state.data).includes(key)) {
        return Promise.reject();
      }

      await commit('setSetting', { key, value });
      await apiClient.request({
        method: 'PUT',
        url: `/api/v1/partners/${rootState.user.data.id}/settings/${key}`,
        data: state.data[key],
      });
    },
  },
};
