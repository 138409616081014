<template>
  <div class="publishing-mp-config">
    <ConfigList>
      <ul>
        <li>
          <MarketplaceLogo class="logo" />
          <ToggleSwitch
            :model-value="composition.publishingDetails.d2c.mp"
            @update:model-value="setMpPublishing"
          />
        </li>
        <li>
          <p>{{ $t('PUBLISHING.DETAIL.POS.STATUS.LABEL') }}</p>
          <CompositionPosStatusBadge
            :posTypes="[posStatus.posType]"
            :status="posStatus.status"
            hideIcon
          />
        </li>
        <li v-if="rejected">
          <p>{{ $t('PUBLISHING.DETAIL.POS.REJECTION_REASON.LABEL') }}</p>
          <button
            class="link rejection-reason-link"
            @click="openRejectionReasonDialog"
          >
            {{ $t(rejectionReasonName) }} <Icon icon="right" />
          </button>
        </li>
        <li v-if="online">
          <p>{{ $t('PUBLISHING.DETAIL.POS.VISIT.LABEL') }}</p>
          <div>
            <button
              @click="openMpPreviewDialog"
              class="link link-main icon-link icon-right"
            >
              {{ $t('PUBLISHING.DETAIL.POS.VISIT.CTA') }}
              <Icon icon="outside" />
            </button>
          </div>
        </li>
      </ul>
    </ConfigList>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import ConfigList from 'src/app/components/publishing/ConfigList.vue';
import RejectionReasonDialog from 'src/app/components/publishing/RejectionReasonDialog.vue';
import PublishingMpPreviewDialog from './pos/mp/PublishingMpPreviewDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import { rejectionReasonName } from '@/api/models/publishingCore/publishingCore';
import {
  getCompositionPosStateForPosType,
  COMPOSITION_POS_TYPES,
} from '@/api/models/publishingCore/compositionPosStates';
import CompositionPosStatusBadge from 'src/app/components/publishing/statusBadges/CompositionPosStatusBadge.vue';

export default {
  name: 'PublishingMpConfig',
  components: {
    ToggleSwitch,
    MarketplaceLogo,
    ConfigList,
    CompositionPosStatusBadge,
  },
  props: {},
  data() {
    return {
      mpSelected: false,
    };
  },
  created() {},
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({}),
    rejected() {
      return (
        this.composition.publishingDetails.d2c.publishingStatus === 'REJECTED'
      );
    },
    online() {
      return (
        this.composition.publishingDetails.d2c.publishingStatus === 'ONLINE'
      );
    },
    rejectionReasonName() {
      return rejectionReasonName('COPYRIGHT');
    },
    posStatus() {
      return getCompositionPosStateForPosType({
        composition: this.composition,
        posType: COMPOSITION_POS_TYPES.MP,
      });
    },
  },
  methods: {
    ...mapMutations({
      setMpPublishing: 'composition/setMpPublishing',
    }),
    ...mapActions({}),
    openRejectionReasonDialog() {
      dialogService.openDialog(RejectionReasonDialog, { reason: 'COPYRIGHT' });
    },
    openMpPreviewDialog() {
      dialogService.openDialog(PublishingMpPreviewDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.logo {
  height: 24px;
}

.rejection-reason-link {
  font-weight: normal;
  color: $grey80;
  .icon {
    margin-left: 8px;
  }
}
</style>
