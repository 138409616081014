// this one talks to the shop-backend part of flexi-api

import localeService from 'src/app/commons/localeService/localeService';
import apiClient from '@/api/client/apiClient';

const getPosID = (store) =>
  store.state.user.pointsOfSale.find(
    (p) => p.target.id === store.state.shop.currentShop.id
  ).id;
const getProductTypeRankingUrl = (store) =>
  `/shop-api/users/${store.state.user.data.id}/pointsOfSale/${getPosID(
    store
  )}/productTypeRanking`;
const locale = localeService.convertToSprdLocale(localeService.getLocale());

export const shopBackendAPI = {
  getIdeaSorting(userId, shopId, offset, limit) {
    return apiClient
      .request({
        method: 'GET',
        url: `/shop-api/users/${userId}/shops/${shopId}/sorting`,
        params: {
          locale,
          offset,
          limit,
        },
      })
      .then((res) => res.data);
  },

  saveIdeaSorting(userId, shopId, data) {
    return apiClient.request({
      method: 'PATCH',
      url: `/shop-api/users/${userId}/shops/${shopId}/sorting`,
      params: {
        locale: null,
      },
      data,
    });
  },

  getProductTypeSorting(store) {
    return apiClient
      .request({
        method: 'GET',
        url: getProductTypeRankingUrl(store),
        params: {
          locale,
        },
      })
      .then((res) => res.data);
  },

  saveProductTypeSorting(store, data) {
    return apiClient.request({
      method: 'PUT',
      url: getProductTypeRankingUrl(store),
      params: {
        locale,
      },
      data,
    });
  },

  resetProductTypeSorting(store) {
    return apiClient.request({
      method: 'DELETE',
      url: getProductTypeRankingUrl(store),
      params: {
        locale,
      },
    });
  },
};
