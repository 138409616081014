<template>
  <div class="box">
    <div class="box-header">
      <h2>{{ $t('ACCOUNT.NEWSLETTER.HEADING') }}</h2>
    </div>
    <div class="box-content">
      <div class="newsletters form-group sprd-newsletter">
        <div class="newsletter">
          <ToggleSwitch
            class="newsletter-switch"
            :model-value="data.partner"
            @update:model-value="setPartnerNewsletter"
            condensed
          >
            <strong>{{ $t('ACCOUNT.CONTACT.NEWSLETTER.PARTNER') }}</strong>
          </ToggleSwitch>
          <p>{{ $t('ACCOUNT.CONTACT.NEWSLETTER.PARTNER_TOOLTIP') }}</p>
        </div>
        <div class="newsletter">
          <ToggleSwitch
            class="newsletter-switch"
            v-model="data.customer"
            @update:model-value="setCustomerNewsletter"
            condensed
          >
            <strong>{{ $t('ACCOUNT.CONTACT.NEWSLETTER.CUSTOMER') }}</strong>
          </ToggleSwitch>
          <p>{{ $t('ACCOUNT.CONTACT.NEWSLETTER.CUSTOMER_TOOLTIP') }}</p>
        </div>
      </div>
      <AccountPageActions
        :save="saveNewsletterData"
        @onRevert="reset"
        :disabled="!hasChanged"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import AccountPageActions from 'src/app/partnerarea/account/AccountPageActions.vue';

export default {
  name: 'Newsletter',
  components: {
    AccountPageActions,
    ToggleSwitch,
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.userNewsletter.data,
    }),
    ...mapGetters({
      hasChanged: 'userNewsletter/hasChanged',
    }),
  },
  methods: {
    ...mapMutations({
      setCustomerNewsletter: 'userNewsletter/setCustomerNewsletter',
      setPartnerNewsletter: 'userNewsletter/setPartnerNewsletter',
      reset: 'userNewsletter/reset',
    }),
    ...mapActions({
      saveNewsletterData: 'userNewsletter/saveNewsletterData',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.newsletter {
  margin-bottom: 24px;

  p {
    margin: 12px 0 0 0;
    color: $grey60;
    max-width: 500px;
  }
}

.newsletter-switch {
  display: inline-block;

  strong {
    display: block;
    min-width: 120px;
    margin-right: 16px;
  }
}
</style>
