import { deepCopy } from '@/utils';

export function mergeResults(previous, single) {
  if (!previous.errors || !Object.keys(previous.errors).length) {
    previous = {
      result: true,
      errors: {},
    };
  }
  let overall = deepCopy(previous);

  overall.result = previous.result && single.result;
  overall.errors = Object.assign({}, previous.errors, single.errors);

  return overall;
}

export function validateStringAttribute(fieldName, text, options) {
  const stringValidation = validateString(text, options);
  let result;
  if (stringValidation.result === 'ERROR') {
    result = {
      result: false,
      errors: {},
    };
    result.errors[fieldName] = {
      reason: stringValidation.reason,
    };
  } else {
    result = {
      result: true,
      errors: {},
    };
  }

  return result;
}

function validateString(text, options) {
  if (options.required && (!text || !text.length)) {
    return {
      result: 'ERROR',
      reason: 'REQUIRED',
    };
  } else if (options.email && !/^[^@,;\s]+@[^@,;\s]+\.[^@,;\s]+$/.test(text)) {
    return {
      result: 'ERROR',
      reason: 'INVALID_FORMAT',
    };
  } else if (options.minLength && text.length < options.minLength) {
    return {
      result: 'ERROR',
      reason: 'INVALID_FORMAT',
    };
  } else if (options.maxLength && text && text.length > options.maxLength) {
    return {
      result: 'ERROR',
      reason: 'INVALID_FORMAT',
    };
  }

  return {
    result: 'SUCCESS',
  };
}
