<template>
  <div class="ai-design-generator-survey">
    <img src="/images/coming_soon.png" alt="coming soon" />
    <h2>{{ $t('AI_DESIGN_GENERATOR.SURVEY.HEADING') }}</h2>
    <p>
      {{ $t('AI_DESIGN_GENERATOR.SURVEY.SUBHEADING') }}
    </p>
    <button class="btn btn-primary btn-xl icon-btn" @click="openSurvey">
      <Icon icon="outside" />{{ $t('GENERAL.GIVE_FEEDBACK') }}
    </button>
    <button class="link-main back" @click="$emit('back')">
      {{ $t('GENERAL.BACK') }}
    </button>
  </div>
</template>

<script>
import localeService from '@/localeService/localeService';
import { mapState } from 'vuex';

const aiSurveyLinks = {
  EU: {
    de: 'https://www.surveymonkey.com/r/GSKJ8YW?spreadid=[spreadid_value]&lang=de',
    default: 'https://www.surveymonkey.com/r/GSKJ8YW?spreadid=[spreadid_value]',
  },
  NA: {
    default: 'https://www.surveymonkey.com/r/VR86PD7?spreadid=[spreadid_value]',
  },
};

export default {
  name: 'AiDesignGeneratorSurvey',
  props: {},
  data() {},
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    surveyLink() {
      const relevantSurveyLink =
        aiSurveyLinks[SPRD.PLATFORM][localeService.getLanguage()] ||
        aiSurveyLinks[SPRD.PLATFORM].default;
      return relevantSurveyLink.replace('[spreadid_value]', this.userId);
    },
  },
  methods: {
    openSurvey() {
      window.open(this.surveyLink, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.ai-design-generator-survey {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
  }

  & > h2 {
    margin: 24px 0 0 0;
  }

  & > p {
    margin: 8px 0 0 0;
    color: $grey65;
  }

  button {
    margin: 24px 0 0 0;
    padding: 12px 24px;

    &.back {
      margin-top: 12px;
    }
  }
}
</style>
