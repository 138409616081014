<template>
  <Dropdown
    :items="filteredItems"
    :item-key="itemKey"
    :item-to-string="itemToString"
    :value="value"
    @change="onChange"
  >
    <template
      #dropdown-button="{
        toggleMenu,
        isOpen,
        showMenu,
        reset,
        onKeyDown,
        item,
        buttonId,
      }"
    >
      <label
        v-if="label"
        class="dropdown-button-label"
        :class="{ filled: Boolean(filterValue) || focused }"
        >{{ label }}</label
      >
      <div
        class="dropdown-button"
        @keydown="onKeyDown"
        :data-value="item ? itemKey(item) : ''"
        :data-text="item ? itemToString(item) : ''"
        :id="buttonId"
      >
        <input
          class="dropdown-input"
          type="text"
          :value="filterValue"
          :maxlength="maxInputLength"
          @input="
            (evt) => {
              onFilterChange(evt, showMenu, reset);
            }
          "
          @focus="onInputFocusChanged"
          @blur="onInputFocusChanged"
        />
        <div
          @click="toggleMenu()"
          class="dropdown-icon-container"
          role="button"
          aria-haspopup="true"
          :aria-expanded="isOpen"
        >
          <Icon
            icon="chevron"
            class="dropdown-icon"
            :class="{ open: isOpen }"
          ></Icon>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from './../Dropdown/Dropdown.vue';

export default {
  name: 'Autocomplete',
  components: {
    Dropdown,
  },
  emits: ['change', 'focusChanged'],
  props: {
    items: {
      type: [Array, Function],
      required: false,
      default: () => [],
    },
    value: {
      required: false,
    },
    itemToString: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    itemKey: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    label: {
      type: String,
      required: false,
    },
    clearOnChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxInputLength: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      filterValue: '',
      focused: false,
      asyncItems: [],
    };
  },
  created() {
    if (this.value) {
      this.filterValue = this.itemToString(this.value);
    }
  },
  watch: {
    value: function (newValue) {
      if (newValue && this.filterValue !== newValue) {
        this.filterValue = this.itemToString(this.value);
      }
    },
  },
  computed: {
    filteredItems() {
      if (typeof this.items === 'function') {
        return [this.filterValue, ...this.asyncItems];
      } else {
        if (!this.filterValue) {
          return this.items;
        } else {
          const filteredItems = this.items.filter((item) =>
            this.itemToString(item)
              .toLowerCase()
              .startsWith(this.filterValue.toLowerCase())
          );
          return [this.filterValue, ...filteredItems];
        }
      }
    },
  },
  methods: {
    onChange(item) {
      if (!item) {
        return;
      }

      if (!this.clearOnChange) {
        this.filterValue = this.itemToString(item);
      } else {
        this.filterValue = '';
      }

      this.$emit('change', item);
    },
    onFilterChange(evt, showMenu, reset) {
      this.filterValue = evt.target.value;
      if (typeof this.items === 'function') {
        this.items(this.filterValue).then((data) => {
          this.asyncItems = data;
          this.$nextTick(() => {
            this.filteredItems.length ? showMenu() : reset();
          });
        });
      } else {
        this.filteredItems.length ? showMenu() : reset();
      }
    },
    onInputFocusChanged() {
      this.focused = !this.focused;
      this.$emit('focusChanged', this.focused);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  padding-top: 0;
  padding-bottom: 0;

  .dropdown-input {
    padding: 0 0 0 16px;
    margin: 0;
    border: none;
    height: 99%;

    &:hover,
    &:focus,
    &:active {
      padding: 0 0 0 16px;
      margin: 0;
      border: none;
    }
  }
}
</style>
