import { retryInIntervalUntilTrue } from '@/utils';

export function trackEvent({ name, details = {} }) {
  if (!window._satellite) {
    return;
  }

  const value = details.value;
  delete details.value;

  const detailsString = Object.entries(details)
    .map((entry) => `${entry[0]}:${entry[1]}`)
    .join(',');

  window._satellite.track('pa-feature-used', {
    feature: name,
    details: detailsString,
    value,
  });
}

export async function trackPageView(pageName) {
  if (!window._satellite?.appMeasurementReady) {
    try {
      await retryInIntervalUntilTrue(
        () => window._satellite?.appMeasurementReady,
        { maxTime: 2000, interval: 200 }
      );
    } catch (error) {
      return;
    }
  }

  if (!window._satellite.track) {
    return;
  }

  window._satellite.track('spa-PV', {
    pageName,
  });
}
