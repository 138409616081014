import localeService from 'src/app/commons/localeService/localeService';

export default {
  ios: {
    link: 'https://geo.itunes.apple.com/app/spreadapp/id1472348719',
    heading: 'CONNECT_DEVICES.DOWNLOAD.IOS.HEADING',
    explanation: 'CONNECT_DEVICES.DOWNLOAD.IOS.SUBHEADING',
    image: '/images/appstore.png',
  },
  android: {
    link: `https://play.google.com/store/apps/details?id=net.spreadshirt.partner&hl=${localeService.getLanguage()}`,
    heading: 'CONNECT_DEVICES.DOWNLOAD.ANDROID.HEADING',
    explanation: 'CONNECT_DEVICES.DOWNLOAD.ANDROID.SUBHEADING',
    image: '/images/googleplay.png',
  },
  iosBeta: {
    link: 'https://testflight.apple.com/join/MDii8Bt3',
    heading: 'CONNECT_DEVICES.BETA.DOWNLOAD.IOS.HEADING',
    explanation: 'CONNECT_DEVICES.BETA.DOWNLOAD.IOS.SUBHEADING',
    image: '/images/appstore.png',
  },
  androidBeta: {
    link: `https://play.google.com/apps/testing/net.spreadshirt.partner`,
    heading: 'CONNECT_DEVICES.BETA.DOWNLOAD.ANDROID.HEADING',
    explanation: 'CONNECT_DEVICES.BETA.DOWNLOAD.ANDROID.SUBHEADING',
    image: '/images/googleplay.png',
  },
};
