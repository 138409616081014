<template>
  <div class="composition-pos-status-badges">
    <CompositionPosStatusBadge
      v-for="posState in posStates"
      :key="posState.status.key"
      :posTypes="posState.posTypes"
      :status="posState.status"
    />
  </div>
</template>

<script>
import CompositionPosStatusBadge from './CompositionPosStatusBadge.vue';
import {
  getAllCompositionPosStates,
  getAllCompositionPosStatesCombined,
} from '@/api/models/publishingCore/compositionPosStates';

export default {
  name: 'CompositionPosStatusBadges',
  components: {
    CompositionPosStatusBadge,
  },
  props: {
    composition: {
      required: true,
      type: Object,
    },
    relevantPosStates: {
      type: Array,
    },
    relevantPosTypes: {
      type: Array,
    },
  },
  data() {
    return {
      combine: true,
    };
  },
  computed: {
    posStates() {
      const posStates = getAllCompositionPosStates({
        composition: this.composition,
        relevantPosTypes: this.relevantPosTypes,
        relevantPosStates: this.relevantPosStates,
      });

      if (this.combine) {
        return getAllCompositionPosStatesCombined({
          composition: this.composition,
          relevantPosTypes: this.relevantPosTypes,
          relevantPosStates: this.relevantPosStates,
        });
      } else {
        return posStates.map((posState) => ({
          ...posState,
          posTypes: [posState.posType],
        }));
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.composition-pos-status-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
</style>
