<template>
  <div class="taxation-na-private">
    <p v-html="$t('ACCOUNT.TAXATION.NA_PRIVATE.HEADING')"></p>
    <p v-html="$t('ACCOUNT.TAXATION.NA_PRIVATE.EXPLANATION')"></p>
    <div class="tax-id" :class="{ 'has-error': validation.errors.ssn }">
      <LabelInput>
        <label for="tax-id">{{
          $t('ACCOUNT.TAXATION.NA_PRIVATE.SSN_TIN')
        }}</label>
        <div class="position-relative">
          <DebounceInput
            id="tax-id"
            type="text"
            :model-value="taxationData.ssn"
            :placeholder="obfuscatedData.ssn"
            @update:model-value="setSsn"
            v-mask="'###-##-####'"
          />
          <a
            v-if="!taxationData.ssn && obfuscatedData.ssn"
            href
            class="input-clear-button"
            @click.prevent="clearObfuscatedField({ field: 'ssn' })"
          >
            <Icon icon="close"></Icon>
          </a>
        </div>
        <AccountError
          v-if="validation.errors.ssn"
          :reason="validation.errors.ssn.reason"
        ></AccountError>
      </LabelInput>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AccountError from './../../errorMessage/AccountError.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'PrivateNA',
  components: {
    AccountError,
    LabelInput,
    DebounceInput,
  },
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      taxationData: (state) => state.userTaxation.data,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
    }),
  },
  methods: {
    ...mapMutations({
      updateSsn: 'userTaxation/setSsn',
      clearObfuscatedField: 'userTaxation/clearObfuscatedField',
    }),
    setSsn(ssn) {
      this.updateSsn({ ssn });
    },
  },
};
</script>

<style lang="scss" scoped>
.taxation-na-private {
  width: 70%;
  padding-right: 40px;
}

.tax-id {
  margin-top: 16px;
  width: 350px;
}
</style>
