const protocolPattern = /^([a-z]+:)?[/]{2,}/;
const validSLDs = ['spreadshirt', 'spreadshirtmedia']; // SLD = second level domain

const isAbsoluteUrl = (url) => {
  // check, if a domain is at the beginning of the url
  // if not sou, the url is relative and therefore valid
  return protocolPattern.test(url);
};

const trimUrl = (url) => {
  let trimmedUrl = url.trim();

  // remove quote signs at the end and the beginning
  trimmedUrl = trimmedUrl.replace(/^(?:"|')/, '');
  trimmedUrl = trimmedUrl.replace(/(?:"|')$/, '');

  return trimmedUrl;
};

const getDomain = (url) => {
  const trimmedUrl = url.replace(protocolPattern, '');
  return trimmedUrl.match(/^[^/]+/).pop();
};

const findUrls = (txt) => {
  const urlValues = txt.match(/url[^(]*?[(][^)]*?\)/gi) || [];

  return urlValues.map((urlValue) => {
    return urlValue.replace(/url[^(]*?[(]/, '').replace(')', '');
  });
};

// this weird and probably buggy code is supposed to remove any url() entries from the css unless they refer to spreadshirt domains.
// because there is no such sanitization on the server side, i assumed that this code is still somehow important and left it untouched (despite the unideal approach).
export const removeExternalUrls = (txt = '') => {
  let sanitized = txt,
    urls = findUrls(txt),
    invalidUrls,
    externalUrlComment = '/* external URLs are not allowed */';

  // find the invalid Urls
  invalidUrls = urls.reduce((invUrls, url) => {
    let trimmedUrl = trimUrl(url),
      domain,
      domainParts;

    if (!isAbsoluteUrl(trimmedUrl)) {
      return invUrls;
    }

    domain = getDomain(trimmedUrl);
    domainParts = domain.split('.');

    // remove tld
    domainParts.pop();
    if (validSLDs.indexOf(domainParts.pop()) < 0) {
      invUrls.push(url);
    }

    return invUrls;
  }, []);

  invalidUrls.forEach((url) => {
    sanitized = sanitized.replace(url, externalUrlComment);
  });

  return sanitized;
};
