<template>
  <div class="dialog-size-md success-dialog">
    <div class="modal-body text-center">
      <p class="icon-container">
        <Icon icon="rocket"></Icon>
      </p>
      <h1>{{ $t('SA.successDialog.headline') }}</h1>

      <a class="btn btn-primary shop-link" target="_blank" :href="shopUrl">
        <div>
          <Icon icon="eye"></Icon>
          {{ $t('SA.menubar.preview') }}
        </div>
      </a>

      <div class="shop-coupon-container">
        <h1 class="shop-coupon">
          {{ $t('SA.successDialog.coupon.label') }}
        </h1>
        <h2 class="shop-coupon-heading">
          {{ $t('SA.successDialog.coupon.heading') }}
        </h2>
        <p class="shop-coupon-redeem-hint">
          {{ $t('SA.successDialog.coupon.redeemHint') }}
        </p>
      </div>
      <p class="shop-coupon-hint">
        {{ $t('SA.successDialog.coupon.subheading') }}
      </p>

      <h3>{{ $t('SA.successDialog.sharing') }}</h3>
      <ActivationSocialSharing />
    </div>
  </div>
</template>

<script>
import ActivationSocialSharing from './ActivationSocialSharing.vue';

export default {
  name: 'ActivationSuccessDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  components: {
    ActivationSocialSharing,
  },
  computed: {
    shopUrl() {
      return this.$store.getters['shop/getSpreadshopUrl']('', false);
    },
  },
  methods: {
    close() {
      this.modalInstance.dismiss();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.success-dialog {
  min-width: 600px;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-container .icon {
      margin-bottom: 10px;
      width: 3.5em;
      height: 3.5em;
      display: inline-block;
      color: #5e5e5e;
    }
  }

  .icon {
    background: none;
  }

  .shop-link {
    margin-top: 15px;
    margin-bottom: 32px;
    & > div {
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
    }
  }

  .shop-coupon-heading {
    padding: 10px;
    margin: 0;
  }

  .shop-coupon {
    padding-top: 10px;
    margin: 0;
    text-align: center;
    color: $pa-color-main;
  }

  .shop-coupon-container {
    border: 2px dashed $pa-color-main;
  }

  .shop-coupon-hint {
    margin-top: 5px;
    margin-bottom: 14px;
    font-size: 12px;
  }
}
</style>
