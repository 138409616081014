<template>
  <div class="taxation-na-corporation">
    <p v-html="$t('ACCOUNT.TAXATION.NA_COOPERATION.HEADING')"></p>
    <div class="tax-id" :class="{ 'has-error': validation.errors.taxId }">
      <LabelInput>
        <label for="tax-id">{{
          $t('ACCOUNT.TAXATION.NA_COOPERATION.FEDERAL_TAX_ID')
        }}</label>
        <div class="position-relative">
          <DebounceInput
            id="tax-id"
            type="text"
            :model-value="taxationData.taxId"
            :placeholder="obfuscatedData.taxId"
            @update:model-value="setTaxId"
            v-mask="'##-#######'"
          />
          <a
            v-if="!taxationData.taxId && obfuscatedData.taxId"
            href
            class="input-clear-button"
            @click.prevent="clearObfuscatedField({ field: 'taxId' })"
          >
            <Icon icon="close"></Icon>
          </a>
        </div>
        <AccountError
          v-if="validation.errors.taxId"
          :reason="validation.errors.taxId.reason"
        ></AccountError>
      </LabelInput>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AccountError from './../../errorMessage/AccountError.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'CompanyNA',
  components: {
    AccountError,
    LabelInput,
    DebounceInput,
  },
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      taxationData: (state) => state.userTaxation.data,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
    }),
  },
  methods: {
    ...mapMutations({
      updateTaxId: 'userTaxation/setTaxId',
      clearObfuscatedField: 'userTaxation/clearObfuscatedField',
    }),
    setTaxId(taxId) {
      this.updateTaxId({ taxId });
    },
  },
};
</script>

<style lang="scss" scoped>
.taxation-na-corporation {
  width: 70%;
  padding-right: 40px;
}

.tax-id {
  margin-top: 16px;
  width: 350px;
}
</style>
