import apiClient from '@/api/client/apiClient';

export default {
  getFormData(obj) {
    return Object.keys(obj).reduce((formData, key) => {
      formData.append(key, obj[key]);
      return formData;
    }, new FormData());
  },
  upload(options = {}) {
    return apiClient.request({
      ...options,
      method: 'POST',
      url: options.url,
      uploadEventHandlers: {
        progress: options.onUploadProgress || (() => {}),
      },
      data: this.getFormData(options.data),
      headers: { 'Content-Type': undefined },
    });
  },
};
