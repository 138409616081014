<template>
  <div class="dialog-size-md confirm-dialog">
    <div class="modal-body">
      <div class="image-container" v-if="data.image">
        <img :src="data.image" />
      </div>
      <h2 v-if="data.heading" :class="{ txtDanger: data.danger }">
        {{ $t(data.heading) }}
      </h2>
      <component v-if="data.content" :is="data.content" />
    </div>
    <div class="modal-footer">
      <button
        id="confirm-dialog-confirm"
        class="btn btn-primary btn-lg"
        :class="{ 'btn-danger': data.danger }"
        @click="confirm"
      >
        {{ $t(confirmLabel) }}
      </button>
      <a
        href="#"
        id="confirm-dialog-cancel"
        class="link-main"
        @click.prevent="dismiss"
      >
        {{ $t(cancelLabel) }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
    /*
            {
                heading: { type: String },
                description: { type: String },
                content: { type: VueComponent },
                cancel: { type: String },
                confirm: { type: String },
                danger: { type: Boolean },
            }
         */
  },
  computed: {
    confirmLabel() {
      return this.data.confirm || 'GENERAL.CONFIRM';
    },
    cancelLabel() {
      return this.data.cancel || 'GENERAL.CANCEL';
    },
  },
  methods: {
    confirm() {
      this.modalInstance.close();
    },
    dismiss() {
      this.modalInstance.dismiss({ reset: true });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.image-container {
  margin-bottom: 16px;

  img {
    width: 128px;
    height: 128px;
  }
}

.modal-body {
  h2,
  p {
    margin: 0 0 16px 0;
  }
}

.txtDanger {
  color: $pa-color-red;
}

.modal-footer {
  display: flex;
  padding: 0;
  align-items: center;
}

.btn {
  margin-right: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
</style>
