<template>
  <div class="recap-module" v-if="displayRecap">
    <div>
      <h3 class="heading1">
        {{ $t(`DASHBOARD.RECAP.HEADING`, { year: currentRecapYear }) }}
      </h3>
      <p class="heading3">
        {{ $t('DASHBOARD.RECAP.SUBHEADING') }}
      </p>
      <a class="link-main" @click.prevent="openRecap">{{
        $t('DASHBOARD.RECAP.CTA')
      }}</a>
    </div>
    <img src="/images/recap.svg" alt="recap" />
  </div>
</template>

<script>
import RecapService from '@/dialogs/recap/RecapService';
import analyticsService from '@/tracking/analytics';

export default {
  name: 'RecapModule',
  computed: {
    currentRecapYear() {
      return RecapService.getCurrentRecapYear();
    },
    displayRecap() {
      return !!this.currentRecapYear;
    },
  },
  methods: {
    openRecap() {
      RecapService.openCurrentRecapDialog();

      analyticsService.logEvent('dashboard-open-recap');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/utils';

.recap-module {
  padding: 24px;
  border-radius: 8px;
  background-color: $white;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;

    a {
      margin-top: auto;
    }
  }

  h3 {
    margin: 0 0 8px 0;
  }

  img {
    height: 100px;
  }
}
</style>
