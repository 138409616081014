<template>
  <div class="styles">
    <Colors />
    <Fonts />
  </div>
</template>

<script>
import Colors from './Colors.vue';
import Fonts from './Fonts.vue';

export default {
  name: 'ShopStyles',
  components: {
    Colors,
    Fonts,
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
};
</script>

<style lang="scss" scoped>
.styles {
  margin: 0 -25px;
}
</style>
