<template>
  <div class="credit-module">
    <div class="credit-box">
      <div class="credit-label">
        <h3>{{ $t('DASHBOARD.CREDIT') }}</h3>
        <div class="hint text-grey text-sm">
          <span>{{ $t('DASHBOARD.NEXT_PAYOUT') }}:</span>
          <span v-if="!nextPayoutDate">{{
            $t('DASHBOARD.NEXT_PAYOUT_DATE', { month: nextPayoutMonth })
          }}</span>
          <span v-if="nextPayoutDate">{{ date(nextPayoutDate) }}</span>
        </div>
      </div>
      <div class="credit-amount">
        <h1>
          {{ creditBalance }}
        </h1>

        <SmartFaqLink :link="creditHelpLink.key" iconOnly class="help-link" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import addMonths from 'date-fns/addMonths';
import statisticsService from '@/api/statisticsService/statisticsService';
import date from '@/date/date';
import parse from 'date-fns/parseISO';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'CreditModule',
  components: {
    SmartFaqLink,
  },
  data() {
    return {
      credit: null,
      iso: null,
      nextPayoutDate: null,
      nextPayoutMonth: null,
      creditHelpLink: smartFaqLinks.COMMISSION_INFO,
    };
  },
  created() {
    if (new Date().getDate() > 15) {
      this.nextPayoutMonth = date(addMonths(Date.now(), 1), 'MMMM');
    } else {
      this.nextPayoutMonth = date(new Date(), 'MMMM');
    }

    statisticsService.getCreditOverview().then((credit) => {
      this.credit = credit || {
        balance: 0,
        currencyId: this.user.currency.id,
        payoutLimitReached: false,
      };
      this.iso = this.currencyById(this.credit.currencyId).isoCode;
      if (this.credit.dateUpcomingPayout) {
        this.nextPayoutDate = parse(this.credit.dateUpcomingPayout);
      }
    });
  },
  computed: {
    ...mapState({
      user: (s) => s.user.data,
    }),
    ...mapGetters({
      shops: 'user/shops',
      currencyById: 'platform/currencyById',
    }),
    creditBalance() {
      if (this.credit && this.iso) {
        return this.sprdCurrency(this.credit.balance, this.iso);
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';

.credit-module {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding: 0 !important;
}

.credit-box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  background-color: $white;
  border-radius: 8px;

  .credit-label {
    display: flex;
    flex-direction: column;
    margin: 0 24px 24px 0;
    position: relative;
    flex-grow: 1;

    & > h3 {
      margin: 0;
    }

    & > a {
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .credit-amount {
    margin: 0 0 24px 0;
    display: flex;
    align-items: flex-end;

    & > h1 {
      margin: 0;
    }

    .help-link {
      margin-left: auto;
    }
  }

  .hint {
    margin-top: 8px;

    span {
      margin-right: 4px;
    }
  }
}
</style>
