<template>
  <div class="idea-status">
    <Icon :icon="displayState.icon" :style="{ color: displayState.color }" />
    <span class="text-truncate">{{ $t(displayState.name) }}</span>
  </div>
</template>

<script>
import ideaHelper from '@/ideaHelper/ideaHelper';

export default {
  name: 'IdeaStatus',
  props: {
    idea: {
      type: Object,
    },
    state: {
      type: Object,
    },
  },
  computed: {
    displayState() {
      if (this.idea) {
        return ideaHelper.getState(this.idea);
      } else {
        return this.state;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
