<template>
  <Copyable id="jsintegration" :content="jsContent" v-if="jsContent">
    <textarea readonly :value="jsContent" class="codeBox jsIntegrationCode" />
  </Copyable>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import Copyable from '../../../../commons/copyable/Copyable.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import { buildJsSnippet } from './buildJsSnippet';

export default {
  name: 'JsIntegration',
  mixins: [SettingsData],
  components: {
    Copyable,
  },
  data: function () {
    return {
      jsContent: '',
    };
  },
  created: function () {
    const config = this.config;
    shopAPI
      .getLocalization(this.$store)
      .then((localization) => {
        const tld = localization.domains.find(
          (domain) => domain.country === config.shopCountry
        ).domain;
        const country = localization.countries.find(
          (c) => c.id === config.shopCountry
        );
        const language = localization.languages.find(
          (l) => l.id === config.shopLanguage
        );

        const locale = language.isoCode + '_' + country.isoCode;
        const shopName = config.shopUrlName || this.shopId;
        this.jsContent = buildJsSnippet(tld, shopName, locale);
      })
      .catch((e) => console.error('Failed to load localization', e));
  },
};
</script>

<style lang="scss" scoped>
.codeBox {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 250px;
  text-wrap: nowrap;
}
</style>
