<template>
  <div class="dialog-size-md mfa-decativate-dialog">
    <div class="modal-body">
      <h3>{{ $t('MFA.DISCONNECT.HEADING') }}</h3>
      <p>{{ $t('MFA.DISCONNECT.SUBHEADING') }}</p>
      <div class="actions">
        <ProgressButton
          class="btn btn-danger"
          :onClick="disconnect"
          label="MFA.DISCONNECT.CTA"
        />
        <button class="link link-main" @click="modalInstance.dismiss">
          {{ $t('GENERAL.CANCEL') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import authService from '@/api/authService/authService';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'MfaDeactivateDialog',
  components: { ProgressButton },
  props: ['modalInstance'],
  data() {
    return {};
  },
  created() {
    analytics.logEvent('mfa-deactivate-start');
  },
  methods: {
    async disconnect() {
      await authService.deleteMfa();
      analytics.logEvent('mfa-deactivate-disconnected');
      this.modalInstance.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.mfa-decativate-dialog {
  h3 {
    margin: 0;
  }

  p {
    color: $grey60;
    margin: 8px 0 16px 0;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
</style>
