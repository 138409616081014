<template>
  <div class="digital-products-config-meta-data">
    <LabelInput
      class="input-section meta-data-labelinput"
      :class="{ 'has-error': nameError }"
    >
      <label for="digitalProductName">{{
        $t('DIGITAL_PRODUCTS.DETAILS.META_DATA.NAME.LABEL')
      }}</label>
      <input
        name="digitalProductName"
        type="text"
        :value="name"
        id="input-digital-product-name"
        @input="(evt) => setName(evt.target.value)"
        @change="checkBlacklistForField('name')"
        class="digital-prodcut-name-input"
        :maxlength="validatorOptions.name.max"
      />
      <div class="field-info">
        <small class="error-info error-info-name" v-if="validation.errors.name">
          {{ validation.errors.name.reason }}
        </small>
        <small
          class="error-info error-info-name"
          v-else-if="currentBlacklist && currentBlacklist.name.length"
        >
          {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
          {{ currentBlacklist.name.join(', ') }}
        </small>
        <small v-else class="info">
          {{ $t('DIGITAL_PRODUCTS.DETAILS.META_DATA.NAME.DESCRIPTION') }}
        </small>
        <small class="char-info"
          >{{ name.length }} / {{ validatorOptions.name.max }}</small
        >
      </div>
    </LabelInput>

    <LabelInput
      class="input-section"
      :class="{ 'has-error': descriptionError }"
    >
      <label for="digitalProductDescription">{{
        $t('DIGITAL_PRODUCTS.DETAILS.META_DATA.DESCRIPTION.LABEL')
      }}</label>
      <textarea
        name="digitalProductDescription"
        :maxlength="validatorOptions.description.max"
        :value="description"
        @input="descriptionInput"
        @change="checkBlacklistForField('description')"
        id="input-digital-product-description"
        class="description-textarea"
        ref="descriptionTextArea"
      >
      </textarea>
      <div class="field-info">
        <small
          class="error-info error-info-description"
          v-if="validation.errors.description"
        >
          {{ validation.errors.description.reason }}
        </small>
        <small
          class="error-info error-info-description"
          v-else-if="currentBlacklist && currentBlacklist.description.length"
        >
          {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
          {{ currentBlacklist.description.join(', ') }}
        </small>
        <small v-else class="info">
          {{ $t('DIGITAL_PRODUCTS.DETAILS.META_DATA.DESCRIPTION.DESCRIPTION') }}
        </small>
        <small class="char-info"
          >{{ (description && description.length) || 0 }} /
          {{ validatorOptions.description.max }}</small
        >
      </div>
    </LabelInput>

    <DigitalProductTagInput
      v-if="digitalProduct"
      class="input-section"
      :tags="tags"
      :digitalProduct="digitalProduct"
      @addTag="addTag"
      @removeTag="removeTag"
      displayTagsLeft
      displayErrorText
      :locale="currentLocale"
      :validation="validation"
      displayHint
      hintText="DIGITAL_PRODUCTS.DETAILS.META_DATA.TAGS.DESCRIPTION"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import LabelInput from '@/labelInput/LabelInput.vue';
import DigitalProductTagInput from '@/tagInput/DigitalProductTagInput.vue';
import { checkTerm } from '@/api/blacklistService/blacklistService';
import digitalProductValidator from '@/api/validators/digitalProduct/digitalProductValidator';

export default {
  name: 'DigitalProductConfigMetaData',
  components: {
    LabelInput,
    DigitalProductTagInput,
  },
  data() {
    return {
      blacklist: {},
      validatorOptions: digitalProductValidator.defaultOptions,
    };
  },
  created() {
    this.blacklist = {
      [this.currentLocale]: {
        name: [],
        description: [],
        tags: [],
      },
    };

    this.checkBlacklistForField('name');
    this.checkBlacklistForField('description');
    this.checkBlacklistForField('tags');
  },
  mounted() {
    this.resizeDescriptionTextArea();
  },
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      name: (s) => s.digitalProduct.current.workingState.name,
      description: (s) => s.digitalProduct.current.workingState.description,
      tags: (s) => s.digitalProduct.current.workingState.tags,
      validation: (state) => state.digitalProduct.validation,
    }),
    ...mapGetters({
      defaultLocale: 'user/language',
    }),
    currentLocale() {
      return this.defaultLocale;
    },
    currentBlacklist() {
      return this.blacklist && this.blacklist[this.currentLocale];
    },
    nameError() {
      return this.validation.errors.name || this.currentBlacklist?.name?.length;
    },
    descriptionError() {
      return (
        this.validation.errors.description ||
        this.currentBlacklist?.description?.length
      );
    },
  },
  methods: {
    resizeDescriptionTextArea() {
      const { descriptionTextArea } = this.$refs;
      if (descriptionTextArea) {
        descriptionTextArea.style.height = '18px';
        descriptionTextArea.style.height = `${
          descriptionTextArea.scrollHeight + 8
        }px`;
      }
    },
    descriptionInput(evt) {
      this.setDescription(evt.target.value);
      this.resizeDescriptionTextArea();
    },
    ...mapMutations({
      setName: 'digitalProduct/setName',
      setDescription: 'digitalProduct/setDescription',
      addTag: 'digitalProduct/addTag',
      removeTag: 'digitalProduct/removeTag',
    }),
    ...mapActions({}),
    async checkBlacklistForField(field, currentLocale = this.currentLocale) {
      // Ensure computed properties do not change during async await for legalcheck
      const locale = currentLocale;

      try {
        await checkTerm({
          field,
          terms: this.getTranslation(locale)[field],
          locale,
        });
        if (this.blacklist[locale]) {
          this.blacklist[locale][field] = [];
        }
      } catch (error) {
        if (error?.data?.list && this.blacklist[locale]) {
          this.blacklist[locale][field] = error.data.list[0].blacklisted.reduce(
            (result, blacklist) => result.concat(blacklist.terms),
            []
          );
          this.addBlacklistedTerms(this.blacklist[locale][field]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.digital-products-config-meta-data {
}

.input-section:not(:first-child) {
  margin-top: 24px;
}

.description-textarea {
  min-height: 64px;
  max-height: 350px;
}
</style>
