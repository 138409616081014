<template>
  <div
    class="designer-container"
    :class="{ error: validation.error, bright: isBrightAppearance }"
  >
    <svg
      :viewBox="viewBox"
      id="designer"
      @mousedown.prevent="deselectDesign"
      @touchstart.prevent="deselectDesign"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g v-if="designEditRestricted">
        <image
          :xlink:href="productImageUrl"
          @mousedown.prevent
          @touchstart.prevent
          :width="view.width"
          :height="view.height"
        ></image>
      </g>
      <g id="productType" v-else>
        <image
          :xlink:href="productTypeImage"
          @mousedown.prevent
          @touchstart.prevent
          :width="view.width"
          :height="view.height"
        ></image>
        <g id="printAreaGroup" :transform="printAreaTransform">
          <rect
            id="printArea"
            ref="printArea"
            v-if="!this.passive"
            v-show="this.config.designSelected"
            class="print-area"
            :width="printArea.boundary.size.width"
            :height="printArea.boundary.size.height"
          ></rect>
          <path
            v-show="this.config.designSelected"
            v-if="!this.passive && hasSoftboundary"
            :d="softboundaryPath"
            class="soft-boundary-border"
          ></path>
          <g>
            <line
              class="snapLine"
              v-if="snapX"
              :x1="printAreaCenter.x"
              y1="1"
              :x2="printAreaCenter.x"
              :y2="printArea.boundary.size.height - 1"
            ></line>
            <line
              class="snapLine"
              v-if="snapY"
              x1="1"
              :y1="printAreaCenter.y"
              :x2="printArea.boundary.size.width - 1"
              :y2="printAreaCenter.y"
            ></line>
          </g>
          <g
            id="designGroup"
            v-if="configuration"
            :key="configuration.printAreaId"
            :transform="designGroupTransform"
          >
            <g :clip-path="softboundaryClipUrl">
              <clipPath
                id="designClipPath"
                clip-rule="evenodd"
                v-if="hasSoftboundary"
                :transform="softboundaryTransform"
              >
                <path :d="softboundaryPath"></path>
              </clipPath>
              <image
                transform="translate(0,0)"
                id="design"
                ref="design"
                :xlink:href="designImage"
                @mousedown.prevent="onMoveStart"
                @touchstart.prevent="onMoveStart"
                :width="svgImageWidth"
                :height="svgImageHeight"
                @click="$emit('setDesignSelection', true)"
              ></image>
            </g>
            <g v-if="!passive && this.config.designSelected">
              <rect
                class="designBox"
                :width="svgImageWidth"
                :height="svgImageHeight"
              ></rect>
              <g
                :transform="leftConfigHandleTransfrom"
                @mousedown.prevent="onRotationStart"
                @touchstart.prevent="onRotationStart"
                class="handle rotation-handle"
                v-if="!designRotationRestricted"
              >
                <circle cx="6" cy="6" r="8" class="icon-wrapper rotate" />
                <g transform="scale(0.39)">
                  <use :xlink:href="`${iconPath}#rotate`" />
                </g>
              </g>
              <g
                :transform="rightConfigHandleTransform"
                @mousedown.prevent="onScaleStart"
                @touchstart.prevent="onScaleStart"
                class="handle size-handle"
                v-if="!designSizeRestricted"
              >
                <circle
                  cx="6"
                  cy="6"
                  r="8"
                  stroke="black"
                  class="icon-wrapper scale"
                />
                <g transform="scale(0.39)">
                  <use :xlink:href="`${iconPath}#scale`" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import iconService from '@/icon/service/iconService';

function getClientCoords(evt) {
  let clientCoords = {
    x: evt.clientX,
    y: evt.clientY,
  };

  if (evt.touches && evt.touches.length > 0) {
    clientCoords.x = evt.touches[0].clientX;
    clientCoords.y = evt.touches[0].clientY;
  }
  return clientCoords;
}

export default {
  name: 'Designer',
  props: ['config', 'passive'],
  data() {
    return {
      lastMousePos: {},
      distanceToDesignCenter: 0,
      rotationStartPoint: {},
      isDesignOutOfBounds: false,
      isDesignTooLarge: false,
      snapDesignCenter: {},
      snapX: false,
      snapY: false,
      initalSnapAngle: 0,
      initialSnapAngleOffset: 0,
      windowWidth: window.innerWidth,
    };
  },
  created() {
    if (!this.passive) {
      document.addEventListener('keydown', this.handleArrowKeys);
      window.addEventListener('resize', this.handleWindowResize, false);
    }
  },
  watch: {
    async printAreaId() {
      await this.$nextTick();
      this.emitRefs();
    },
  },
  mounted() {
    this.emitRefs();
  },
  methods: {
    emitRefs() {
      this.$emit('setRefs', {
        design: this.$refs.design,
        printArea: this.$refs.printArea,
      });
    },
    deselectDesign(e) {
      if (
        e.target.id !== 'design' &&
        e.target.localName !== 'circle' &&
        e.target.localName !== 'path'
      ) {
        this.$emit('setDesignSelection', false);
      }
    },
    transformPoint(point) {
      const svg = document.getElementById('designer');
      const matrix = svg.getScreenCTM().inverse();
      const svgPoint = svg.createSVGPoint();
      svgPoint.x = point.x;
      svgPoint.y = point.y;

      return svgPoint.matrixTransform(matrix);
    },
    onRotationStart(evt) {
      this.rotationStartPoint = this.transformPoint(getClientCoords(evt));

      const rotationSnapStart = this.transformPoint({
        x: this.configuration.x,
        y: this.configuration.y + this.svgImageHeight / 2,
      });

      const currentAngle = Math.atan2(
        this.rotationStartPoint.y - this.designCenter.y,
        this.rotationStartPoint.x - this.designCenter.x
      );

      this.initalSnapAngle = Math.atan2(
        rotationSnapStart.y - this.designCenter.y,
        rotationSnapStart.x - this.designCenter.x
      );

      this.initialSnapAngleOffset =
        ((currentAngle - this.initalSnapAngle) * 180) / Math.PI -
        this.configuration.rotate;

      document.addEventListener('mousemove', this.onRotationMove);
      document.addEventListener('touchmove', this.onRotationMove);
      document.addEventListener('mouseup', this.onRotationStop);
      document.addEventListener('touchend', this.onRotationStop);
    },
    onRotationMove(evt) {
      const currentPos = this.transformPoint(getClientCoords(evt));

      const startAngle = Math.atan2(
        this.rotationStartPoint.y - this.designCenter.y,
        this.rotationStartPoint.x - this.designCenter.x
      );
      const currentAngle = Math.atan2(
        currentPos.y - this.designCenter.y,
        currentPos.x - this.designCenter.x
      );

      let angle = ((currentAngle - startAngle) * 180) / Math.PI;
      let snapAngle =
        ((currentAngle - this.initalSnapAngle) * 180) / Math.PI -
        this.initialSnapAngleOffset;
      if (snapAngle > 360) {
        snapAngle -= 360;
      } else if (snapAngle < 0) {
        snapAngle += 360;
      }
      let isSnapped = false;

      [0, 45, 90, 135, 180, 225, 270, 315, 360].forEach((angleToSnap) => {
        if (Math.abs(angleToSnap - snapAngle) <= 5) {
          angle = angleToSnap;
          isSnapped = true;
        }
      });

      this.rotationStartPoint = currentPos;

      this.$emit('rotate', angle, { absolute: isSnapped });
    },
    onRotationStop() {
      document.removeEventListener('mousemove', this.onRotationMove);
      document.removeEventListener('touchmove', this.onRotationMove);
      document.removeEventListener('mouseup', this.onRotationStop);
      document.removeEventListener('touchend', this.onRotationStop);
    },
    onScaleStart(evt) {
      this.lastMousePos = this.transformPoint(getClientCoords(evt));

      this.distanceToDesignCenter = Math.sqrt(
        Math.pow(this.designCenter.x - this.lastMousePos.x, 2) +
          Math.pow(this.designCenter.y - this.lastMousePos.y, 2)
      );

      document.addEventListener('mousemove', this.onScaleMove);
      document.addEventListener('touchmove', this.onScaleMove);
      document.addEventListener('mouseup', this.onScaleStop);
      document.addEventListener('touchend', this.onScaleStop);
    },
    onScaleMove(evt) {
      this.lastMousePos = this.transformPoint(getClientCoords(evt));
      const oldDistance = this.distanceToDesignCenter;

      this.distanceToDesignCenter = Math.sqrt(
        Math.pow(this.designCenter.x - this.lastMousePos.x, 2) +
          Math.pow(this.designCenter.y - this.lastMousePos.y, 2)
      );
      const delta = this.distanceToDesignCenter - oldDistance;

      this.$emit('scale', delta);
    },
    onScaleStop() {
      document.removeEventListener('mousemove', this.onScaleMove);
      document.removeEventListener('touchmove', this.onScaleMove);
      document.removeEventListener('mouseup', this.onScaleStop);
      document.removeEventListener('touchend', this.onScaleStop);
    },
    snapToGrid(offset, point) {
      const newOffset = Object.assign({}, offset);

      this.snapDesignCenter.x += point.x - this.lastMousePos.x;
      this.snapDesignCenter.y += point.y - this.lastMousePos.y;

      const currentDistance = {
        x: Math.abs(this.snapDesignCenter.x - this.printAreaCenter.x),
        y: Math.abs(this.snapDesignCenter.y - this.printAreaCenter.y),
      };

      const snapTreshold = this.view.width * 0.025;

      if (currentDistance.x < snapTreshold) {
        newOffset.x =
          (this.printArea.boundary.size.width - this.svgImageWidth) / 2;
        this.snapX = true;
      } else {
        this.snapX = false;
      }
      if (currentDistance.y < snapTreshold) {
        newOffset.y =
          (this.printArea.boundary.size.height - this.svgImageHeight) / 2;
        this.snapY = true;
      } else {
        this.snapY = false;
      }

      return newOffset;
    },
    onMove(evt) {
      const point = this.transformPoint(getClientCoords(evt));

      const newOffset = {
        x: this.configuration.x + (point.x - this.lastMousePos.x),
        y: this.configuration.y + (point.y - this.lastMousePos.y),
      };

      const snappedOffset = this.snapToGrid(newOffset, point);

      this.$emit('move', snappedOffset);

      this.lastMousePos = point;
    },
    onMoveStart(evt) {
      this.lastMousePos = this.transformPoint(getClientCoords(evt));

      this.snapDesignCenter = {
        x: this.configuration.x + this.svgImageWidth / 2,
        y: this.configuration.y + this.svgImageHeight / 2,
      };

      document.addEventListener('mousemove', this.onMove);
      document.addEventListener('touchmove', this.onMove);
      document.addEventListener('mouseup', this.onMoveStop);
      document.addEventListener('touchend', this.onMoveStop);
    },
    onMoveStop() {
      this.snapX = this.snapY = false;

      document.removeEventListener('mousemove', this.onMove);
      document.removeEventListener('touchmove', this.onMove);
      document.removeEventListener('mouseup', this.onMoveStop);
      document.removeEventListener('touchend', this.onMoveStop);
    },
    handleArrowKeys(evt) {
      if (evt.keyCode === 38) {
        this.$emit('move', {
          x: this.configuration.x,
          y: this.configuration.y - 1,
        });
      } else if (evt.keyCode === 40) {
        this.$emit('move', {
          x: this.configuration.x,
          y: this.configuration.y + 1,
        });
      } else if (evt.keyCode === 37) {
        this.$emit('move', {
          x: this.configuration.x - 1,
          y: this.configuration.y,
        });
      } else if (evt.keyCode === 39) {
        this.$emit('move', {
          x: this.configuration.x + 1,
          y: this.configuration.y,
        });
      }
    },
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    configuration() {
      return this.config.configuration;
    },
    productImageUrl() {
      return `${this.config.defaultImageUrl},appearanceId=${this.config.template.appearanceId},width=500`;
    },
    validation() {
      return this.config.validation[this.config.printArea.id];
    },
    designEditRestricted() {
      return (
        this.config.configuration &&
        this.config.configuration.restrictedFeatures.includes('DESIGN_EDIT')
      );
    },
    designSizeRestricted() {
      return this.configuration?.restrictedFeatures.includes('SCALING');
    },
    designRotationRestricted() {
      return this.configuration?.restrictedFeatures.includes('ROTATION');
    },
    designImageUrl() {
      return this.config.designImages[this.configuration.designId].href;
    },
    isBrightAppearance() {
      return assortmentHelper.isBrightColor(
        this.config.template.availableAppearances.find(
          (appearance) =>
            appearance.appearanceId === this.config.template.appearanceId
        ).rgbs[0]
      );
    },
    printArea() {
      return this.config.printArea;
    },
    printAreaId() {
      return this.config.printArea.id;
    },
    printAreaTransform() {
      const viewMap = this.view.viewMaps[0];
      return `translate(${viewMap.offsetX},${viewMap.offsetY})`;
    },
    viewBox() {
      const view = this.config.template.productType.views.find(
        (v) => v.id === this.printArea.viewId
      );
      return `0 0 ${view.width} ${view.height}`;
    },
    view() {
      return this.config.template.productType.views.find(
        (v) => v.id === this.printArea.viewId
      );
    },
    productTypeImage() {
      return `https://image.spreadshirtmedia.${
        SPRD.PLATFORM === 'EU' ? 'net' : 'com'
      }/image-server/v1/productTypes/${
        this.config.template.productType.id
      }/views/${this.printArea.viewId}/appearances/${
        this.config.template.appearanceId
      },width=800,height=800.png`;
    },
    designImage() {
      const designImageSizeAttribute =
        this.configuration.width > this.configuration.height
          ? 'width=600'
          : 'height=600';
      const colors = this.configuration.printColorRGBs
        ? this.configuration.printColorRGBs.map(
            (color, index) => `colors[${index}]=${color.replace('#', '')}`
          )
        : '';
      return `${this.designImageUrl},${designImageSizeAttribute},${colors}.png`;
    },
    designGroupTransform() {
      if (!this.configuration.rotate) {
        return `translate(${this.configuration.x},${this.configuration.y})`;
      } else {
        return `translate(${this.configuration.x},${
          this.configuration.y
        }) rotate(${this.configuration.rotate}, ${this.svgImageWidth / 2} ${
          this.svgImageHeight / 2
        })`;
      }
    },
    softboundaryTransform() {
      if (!this.configuration.rotate) {
        return `translate(${-this.configuration.x},${-this.configuration.y})`;
      } else {
        return `rotate(${-this.configuration.rotate}, ${
          this.svgImageWidth / 2
        } ${this.svgImageHeight / 2}) translate(${-this.configuration.x},${-this
          .configuration.y})`;
      }
    },
    designCenter() {
      return {
        x:
          this.configuration.x +
          this.view.viewMaps[0].offsetX +
          this.svgImageWidth / 2,
        y:
          this.configuration.y +
          this.view.viewMaps[0].offsetY +
          this.svgImageHeight / 2,
      };
    },
    printAreaCenter() {
      return {
        x: this.printArea.boundary.size.width / 2,
        y: this.printArea.boundary.size.height / 2,
      };
    },
    svgImageWidth: {
      get() {
        return this.configuration.width;
      },
      set(value) {
        this.configuration.width = Math.abs(value);
      },
    },
    svgImageHeight: {
      get() {
        return this.configuration.height;
      },
      set(value) {
        this.configuration.height = Math.abs(value);
      },
    },
    hasSoftboundary() {
      return (
        this.printArea &&
        this.printArea.boundary.soft &&
        this.printArea.boundary.soft.content
      );
    },
    softboundaryPath() {
      if (this.hasSoftboundary) {
        return this.printArea.boundary.soft.content.svg.path.d;
      } else {
        return '';
      }
    },
    softboundaryClipUrl() {
      return this.hasSoftboundary
        ? `url(${window.location.href}#designClipPath)`
        : null;
    },
    leftConfigHandleTransfrom() {
      const width = this.view.width * (2 / this.windowWidth);

      return `matrix(${width}, 0, 0, ${width}, ${-width * 6}, ${-width * 6})`;
    },
    rightConfigHandleTransform() {
      const width = this.view.width * (2 / this.windowWidth);

      return `matrix(${width}, 0, 0, ${width}, ${
        this.svgImageWidth - width * 6
      },${-width * 6})`;
    },
    iconPath() {
      return iconService.getIconRef();
    },
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleArrowKeys);
    window.removeEventListener('resize', this.handleWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.designer-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #designer {
    width: 100%;
    height: 100%;
  }
}

.print-area {
  fill: none;
  stroke: #fff;
  vector-effect: non-scaling-stroke;
}

.bright {
  .print-area,
  .designBox,
  .soft-boundary-border {
    stroke: #000;
  }
}

.icon-wrapper {
  fill: #fff;
  stroke: #000;
  vector-effect: non-scaling-stroke;
}

.designBox {
  fill: none;
  stroke: $pa-color-main;
  vector-effect: non-scaling-stroke;
}

.snapLine {
  stroke-width: 2;
  stroke: $pa-color-main;
  vector-effect: non-scaling-stroke;
}

@supports (-ms-ime-align: auto) {
  .print-area,
  .designBox,
  .soft-boundary-border,
  .snapLine {
    stroke-width: 0.25%;
  }
}

@media all and (-ms-high-contrast: active), all and (-ms-high-contrast: none) {
  .print-area,
  .designBox,
  .soft-boundary-border,
  .snapLine {
    stroke-width: 0.25%;
  }
}

#design {
  cursor: move;
}

.handle {
  cursor: pointer;
}

.error {
  .designBox {
    stroke: $pa-color-red;
  }
}

.soft-boundary-border {
  stroke-dasharray: 5;
  stroke: #fff;
  fill: none;
  vector-effect: non-scaling-stroke;
}
</style>
