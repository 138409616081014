<!-- This yes/no popup is supposed to show up when your shop is hidden and you leave the shop area. -->
<template>
  <div class="dialog-size-md activation-dialog">
    <Icon icon="sign-closed"></Icon>
    <h1>{{ $t('SA.dialogs.offlineShop.heading') }}</h1>
    <p>{{ $t('SA.dialogs.offlineShop.subheading') }}</p>
    <button
      type="button"
      name="button"
      class="btn btn-primary"
      @click="activate"
    >
      <Icon icon="rocket"></Icon>
      {{ $t('SA.dialogs.offlineShop.activate') }}
    </button>
    <a class="link" @click="dismiss">{{
      $t('SA.dialogs.offlineShop.remindLater')
    }}</a>
  </div>
</template>

<script>
import { ActivationMixin } from './ActivationMixin';

export default {
  name: 'ShopActivationDialog',
  mixins: [ActivationMixin],
  props: {
    modalInstance: { type: Object, required: true },
  },
  methods: {
    dismiss() {
      this.modalInstance.dismiss();
    },
    activate() {
      this.modalInstance.close();
      this.toggleShopStatus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.activation-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .icon {
    font-size: 5em;
    color: $pa-color-main;
    margin-bottom: 10px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    margin: 20px;

    .icon {
      margin-right: 5px;
      font-size: 1.7em;
    }
  }
}
</style>
