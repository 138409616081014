<template>
  <div class="idea-infos">
    <div class="design-info-row">
      <div>
        <strong>ID:</strong>
      </div>
      <div id="design-idea-id">{{ idea.id }}</div>
    </div>
    <div class="design-info-row" v-if="!multipleDesigns">
      <div>
        <strong>{{ $t('DESIGNS.ID') }}:</strong>
      </div>
      <div id="design-detail-id">{{ idea.mainDesignId }}</div>
    </div>
    <div class="design-info-row" v-if="!multipleDesigns">
      <div>
        <strong>{{ $t('DESIGNS.FILE_NAME') }}:</strong>
      </div>
      <div id="design-detail-file-name" class="text-truncate">
        {{ design.fileName }}.{{ design.fileExtension }}
      </div>
    </div>
    <div class="design-info-row" v-if="!multipleDesigns">
      <div>
        <strong>{{ $t('DESIGNS.DIMENSIONS') }}:</strong>
      </div>
      <div id="design-detail-dimensions">
        {{ design.size.width }}{{ design.size.unit }} x {{ design.size.height
        }}{{ design.size.unit }}
      </div>
    </div>
    <div class="design-info-row">
      <div>
        <strong>{{ $t('DESIGNS.CREATED') }}: </strong>
      </div>
      <div id="design-detail-created">
        {{ date(idea.dateCreated, 'short') }}
      </div>
    </div>
    <div class="design-info-row">
      <div>
        <strong>{{ $t('DESIGNS.MODIFIED') }}: </strong>
      </div>
      <div id="design-detail-modified">
        {{ date(idea.dateModified, 'short') }}
      </div>
    </div>
  </div>
</template>

<script>
import designService from '@/api/designService/designService';

export default {
  name: 'IdeaInfos',
  props: ['idea'],
  data() {
    return {
      design: {
        size: {},
      },
    };
  },
  computed: {
    multipleDesigns() {
      return this.idea.resources.filter((r) => r.type !== 'default').length > 1;
    },
  },
  async created() {
    if (!this.multipleDesigns) {
      this.design = await designService.getDesignById(this.idea.mainDesignId);
    }
  },
};
</script>

<style lang="scss" scoped>
.idea-infos {
  text-align: left;
}

.design-info-row {
  margin-bottom: 8px;
}
</style>
