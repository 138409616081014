const MFA_STATES = {
  LOADING: {
    key: 'LOADING',
  },
  SETUP: {
    key: 'SETUP',
  },
  SETUP_FINISHED: {
    key: 'SETUP',
  },
  ACTIVATED: {
    key: 'ACTIVATED',
  },
};

export default MFA_STATES;
