import shopService from '@/api/shopService/shopService';
import assortmentSystemService from '@/api/assortmentSystemService/assortmentSystemService';

const showLoader = (store) => {
  const id = Math.floor(Math.random() * 9000000);
  store.commit('shop/addLoadingJob', id);
  return id;
};

const hideLoader = (store, id) => {
  store.commit('shop/removeLoadingJob', id);
};

const extractProductTypeIdsFromCategories = (result, categories) => {
  categories.forEach((c) => {
    result = result.concat(c.productTypeIds);
  });
  categories.forEach((c) => {
    result = extractProductTypeIdsFromCategories(result, c.children);
  });
  return result;
};

export const PricingData = {
  beforeCreate() {
    const userId = this.$store.state.user.data.id;
    if (!this.$store.state.shop.shopSavable.clientData.pricing) {
      const initialData = {
        userId, // constant master data
        categories: [],
        productTypes: [],
      };
      this.$store.commit('shop/addDynamicSection', {
        key: 'pricing',
        initialData,
      });
      const loaderId = showLoader(this.$store);

      const shopId = this.$store.state.shop.currentShop.id;
      Promise.all([
        shopService.getMdsCommissions(userId, shopId),
        shopService.getProductTypes(shopId),
        assortmentSystemService.getCategories(shopId),
      ]).then(([commissions, productTypeList, categories]) => {
        const { productTypeCommissions, commissionLimit, printAreaPrices } =
          commissions;
        const productTypeIdsInCategories = extractProductTypeIdsFromCategories(
          [],
          categories
        );
        let { productTypes } = productTypeList;
        productTypes = productTypes.filter((pt) => pt.price); // anti corruption layer against missing mds prices
        productTypes = productTypes.filter(
          (
            pt // do not show digital product types (they are not part of the category tree)
          ) => productTypeIdsInCategories.includes(parseInt(pt.id))
        );
        this.$store.commit('shop/updateSectionOriginalData', {
          sectionKey: 'pricing',
          origData: {
            printAreaPrices, // constant master data
            commissionLimit, // constant master data
            productTypeCommissions, // can be changed by user like regular settings
            categories, // constant master data
            productTypes, // constant master data
            expandedProductTypes: productTypes, // user selection from "productTypes"
          },
        });
        hideLoader(this.$store, loaderId);
      });
    }
  },
  computed: {
    pricing() {
      return (
        this.$store.state.shop.shopSavable.clientData.pricing || {
          categories: [],
          productTypes: [],
        }
      );
    },
    printAreaPrices() {
      return this.pricing.printAreaPrices;
    },
    productTypeCommissions() {
      return this.pricing.productTypeCommissions;
    },
    categories() {
      return this.pricing.categories;
    },
    productTypes() {
      return this.pricing.productTypes;
    },
    expandedProductTypes() {
      return this.pricing.expandedProductTypes;
    },
    commissionLimit() {
      return (
        this.pricing.commissionLimit && this.pricing.commissionLimit.amount
      );
    },
    loading() {
      return this.$store.state.shop.loadingJobs.length > 0;
    },
    shopId() {
      return this.$store.state.shop.currentShop.id;
    },
    userId() {
      return this.pricing.userId;
    },
  },
  methods: {
    async resetAllCommissions() {
      const loaderId = showLoader(this.$store);

      // collect all commissions that must be reset on the server
      const patchData = {};
      Object.keys(this.productTypeCommissions).forEach((productTypeId) => {
        const commission = this.productTypeCommissions[productTypeId];
        if (!commission.isDefault) {
          patchData[productTypeId] = Object.assign({}, commission, {
            remove: true,
          });
        }
      });
      await shopService.patchMdsCommissions(this.userId, this.shopId, {
        productTypeCommissions: patchData,
      });
      const newCommissions = await shopService.getMdsCommissions(
        this.userId,
        this.shopId
      );
      this.$store.commit('shop/updateSectionOriginalData', {
        sectionKey: 'pricing',
        origData: {
          productTypeCommissions: newCommissions.productTypeCommissions,
        },
      });
      hideLoader(this.$store, loaderId);
    },
  },
};
