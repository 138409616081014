<template>
  <div class="sidepanelpage bulkpage bulktags">
    <div class="sidebar">
      <h2 class="sidebar-heading">
        {{ $t('DESIGNS.BULK.TAGS.HEADING') }}
      </h2>
      <p class="sidebar-subheading">
        {{ $t('DESIGNS.BULK.TAGS.SUBHEADING') }}
      </p>
      <IdeaTagInput
        :tags="bulkTags"
        :locale="userLocale"
        @addTag="addBulkTag"
        @removeTag="removeBulkTag"
        @blacklistUpdate="blacklistUpdate"
        :disabled="multipleDefaultLanguages"
      />
      <div class="actions">
        <a
          href="#"
          class="link icon-link remove-link"
          :disabled="actionsDisabled"
          @click.prevent="removeTags"
        >
          <Icon icon="bin" />
          {{ $t('GENERAL.REMOVE') }}
        </a>
        <a
          href="#"
          class="link icon-link add-link"
          :disabled="actionsDisabled"
          @click.prevent="addTags"
        >
          <Icon icon="plus" />
          {{ $t('GENERAL.ADD') }}
        </a>
      </div>
    </div>
    <div class="sidepanelpage-content bulkpagecontent">
      <div class="ideas overflow-y">
        <div v-if="multipleDefaultLanguages" class="language-warning">
          <div class="color-box red">
            {{ $t('DESIGNS.BULK.TAGS.MULTIPLE_DEFAULT_LANGUAGES_WARNING') }}
          </div>
        </div>

        <ul>
          <li v-for="idea in selectedIdeas" :key="idea.id">
            <IdeaPreviewImage
              class="preview-image"
              :idea="idea"
              :checkedBackground="true"
              :width="150"
              :height="150"
            />
            <div class="data">
              <div class="data-heading">
                <strong>{{ getCurrentTranslation(idea).name }}</strong>
                <!-- <a
                  href
                  class="idea-remove-link"
                  ><Icon icon="close"
                /></a> -->
              </div>
              <IdeaTagInput
                class="single-tag-input"
                :idea="idea"
                :tags="getCurrentTranslation(idea).tags"
                :handleClick="addTagToBulk"
                :locale="userLocale"
                @removeTag="
                  (tag) => removeTagFromIdea({ ideaId: idea.id, tag })
                "
                @addTag="(tag) => addTagToIdea({ ideaId: idea.id, tag })"
                :highlightTags="bulkTags.join(',')"
                :displayTagsLeft="true"
                :hideLanguage="true"
              />
              <div class="default-language">
                <Icon icon="globe" /> {{ languageByIso(idea.language).name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ul class="toolbar-bottom nav navbar-nav nav-bottom">
        <li>
          <button type="button" class="btn btn-light" @click="back">
            {{ $t('GENERAL.CANCEL') }}
          </button>
        </li>
        <li>
          <ProgressButton
            class="btn btn-primary icon-btn"
            label="GENERAL.APPLY"
            :onClick="save"
            :disabled="multipleDefaultLanguages"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IdeaTagInput from '@/tagInput/IdeaTagInput.vue';
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import analytics from '@/tracking/analytics';

export default {
  name: 'IdeaBulkTags',
  components: {
    IdeaTagInput,
    IdeaPreviewImage,
    ProgressButton,
  },
  data() {
    return {
      bulkTags: [],
      blacklist: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedIdeas: 'ideas/selected',
      selectedIdeasDirty: 'ideas/selectedIdeasDirty',
      languageByIso: 'platform/languageByIso',
    }),
    ...mapState({
      userLocale: (s) => s.user.locale,
    }),
    actionsDisabled() {
      return (
        !this.bulkTags.length ||
        this.bulkTags.some((tag) => this.blacklist.includes(tag))
      );
    },
    multipleDefaultLanguages() {
      return (
        [...new Set(this.selectedIdeas.map((idea) => idea.language))].length > 1
      );
    },
  },
  created() {
    this.updateOriginal();

    unsavedChangesService.registerComponent({
      check: () => this.selectedIdeasDirty,
      save: () => {
        return this.save();
      },
      discard: () => {
        this.resetToOriginal();
      },
    });
  },
  methods: {
    ...mapMutations({
      addTagsToSelected: 'ideas/addTagsToSelected',
      removeTagsFromSelected: 'ideas/removeTagsFromSelected',
      removeTagFromIdea: 'ideas/removeTagFromIdea',
      addTagToIdea: 'ideas/addTagToIdea',
      updateOriginal: 'ideas/updateOriginal',
      resetToOriginal: 'ideas/resetToOriginal',
    }),
    ...mapActions({
      saveSelected: 'ideas/saveSelected',
    }),
    addBulkTag(tag) {
      this.bulkTags.push(tag);
    },
    removeBulkTag(tag) {
      this.bulkTags = this.bulkTags.filter((t) => t !== tag);
    },
    blacklistUpdate({ blacklistResult }) {
      if (blacklistResult.length) {
        this.blacklist = blacklistResult[0].blacklisted.map(
          (item) => item.terms[0]
        );
      } else {
        this.blacklist = [];
      }
    },
    removeTags() {
      if (this.actionsDisabled) {
        return;
      }

      this.removeTagsFromSelected(this.bulkTags);
      this.bulkTags = [];
    },
    addTags() {
      if (this.actionsDisabled) {
        return;
      }

      this.addTagsToSelected(this.bulkTags);
      this.bulkTags = [];
    },
    addTagToBulk(tag) {
      if (!this.bulkTags.includes(tag)) {
        this.bulkTags.push(tag);
      }
    },
    goBack() {
      if (this.$route.previousState?.name) {
        this.$router.push({
          ...this.$route.previousState,
          query: {
            ...(this.$route.previousState.query || {}),
            restoreState: true,
          },
        });
      } else {
        this.$router.push({
          name: 'partnerarea.ideas',
          query: { restoreState: true },
        });
      }
    },
    back() {
      this.resetToOriginal();
      this.goBack();
    },
    async save() {
      analytics.logEvent('idea-bulk-tags', {
        selectedCount: this.selectedIdeas.length,
      });

      await this.saveSelected();
      this.goBack();
    },
    getCurrentTranslation: ideaHelper.getCurrentTranslation,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/type';

.single-tag-input {
  position: initial;
  margin-bottom: 12px;

  :deep(label) {
    display: none;
  }

  :deep(.tag-input-container) {
    border: none !important;
    padding: 0;
    margin-left: -2px;

    input {
      display: none;
    }

    .dropdown-items {
      display: none;
    }

    & + .field-info {
      position: absolute;
      bottom: 16px;
      right: 16px;
      padding: 0;

      small {
        @extend .text-default;
      }
    }
  }
}

.data {
  position: relative;
}

.default-language {
  margin-top: auto;
  display: flex;
  align-items: center;

  .icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}

.language-warning {
  max-width: 1400px;
  margin: 24px auto 0;
  padding: 0 16px;

  .color-box {
    text-align: center;
  }
}
</style>
