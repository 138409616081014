<template>
  <div>
    <div class="page overflow-y" v-if="currentMode === MODE.DETAIL">
      <div>
        <div class="header">
          <a href="#" @click.prevent="back" class="link-main icon-link">
            <Icon icon="left" />
            {{ $t(this.backName) }}
          </a>
        </div>

        <Banner
          class="banner"
          v-if="wasInWizard && currentMode === MODE.DETAIL"
          image="/images/rocket2.svg"
          heading="DESIGNS.DETAILS.PUBLISH_SUCCESS.BANNER.HEADING"
          subheading="DESIGNS.DETAILS.PUBLISH_SUCCESS.BANNER.SUBHEADING"
          link="DESIGNS.CONFIGURATION.COMPLETE.UPLOAD_ANOTHER_DESIGN"
          @onLinkClick="uploadMore"
        />
        <IdeaSummary
          @editMetaData="editMetaData"
          @editPos="editPos"
          @editAssortment="editAssortment"
        />
      </div>
    </div>

    <IdeaWizard
      v-if="currentMode === MODE.WIZARD"
      :edit="triggerEdit"
      @done="onWizardDone"
      @cancel="onWizardCancel"
      :strictValidation="strictValidation"
    />

    <div class="single-config" v-if="currentMode.includes('CONFIG')">
      <div class="config overflow-y" id="detail-page-scroller">
        <div>
          <IdeaConfigMetaData
            v-if="currentMode === MODE.CONFIG_META_DATA"
            :strictValidation="strictValidation"
          />
          <IdeaConfigPointOfSale
            v-if="currentMode === MODE.CONFIG_POINTS_OF_SALE"
          />
          <AssortmentConfiguration
            v-if="currentMode === MODE.CONFIG_ASSORTMENT"
          />
        </div>
      </div>

      <ul class="toolbar-bottom nav navbar-nav nav-bottom">
        <li>
          <button type="button" class="btn btn-light" @click="backToSummary">
            {{ $t('GENERAL.CANCEL') }}
          </button>
        </li>
        <li>
          <ProgressButton
            class="btn btn-primary icon-btn"
            :onClick="singleEdit"
            label="GENERAL.APPLY"
          />
        </li>
      </ul>
    </div>

    <div class="no-pos overflow-y" v-if="currentMode === MODE.NO_POS">
      <OpenSalesChannel />
    </div>

    <div class="loader" v-if="currentMode === MODE.INIT">
      <img src="/images/loader.svg" class="sprd-loader loader" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import IdeaWizard from './IdeaWizard.vue';
import IdeaSummary from './IdeaSummary.vue';
import IdeaConfigMetaData from 'src/app/partnerarea/idea/configuration/metaData/IdeaConfigMetaData.vue';
import IdeaConfigPointOfSale from 'src/app/partnerarea/idea/configuration/pointsOfSale/IdeaConfigPointOfSale.vue';
import AssortmentConfiguration from 'src/app/partnerarea/idea/configuration/assortment/AssortmentConfiguration.vue';
import ideaValidator from '@/api/validators/idea/ideaValidator';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import eventBus from '@/eventBus/eventBus';
import Banner from 'src/app/components/banners/Banner.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';
import OpenSalesChannel from 'src/app/partnerarea/pos/salesChannel/OpenSalesChannel.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import PdvDialog from '@/dialogs/address/PdvDialog.vue';
import { isEqual } from '@/utils';
import analyticsService from '@/tracking/analytics';

const MODE = {
  INIT: 'INIT',
  DETAIL: 'DETAIL',
  WIZARD: 'WIZARD',
  CONFIG_META_DATA: 'CONFIG_META_DATA',
  CONFIG_POINTS_OF_SALE: 'CONFIG_POINTS_OF_SALE',
  CONFIG_ASSORTMENT: 'CONFIG_ASSORTMENT',
  NO_POS: 'NO_POS',
};

export default {
  name: 'IdeaDetail',
  components: {
    IdeaWizard,
    IdeaSummary,
    IdeaConfigMetaData,
    IdeaConfigPointOfSale,
    AssortmentConfiguration,
    OpenSalesChannel,
    ProgressButton,
    Banner,
  },
  data() {
    return {
      currentMode: MODE.INIT,
      MODE,
      deleted: false,
      backName: this.$route.query?.backName || 'GENERAL.BACK_TO_ALL_DESIGNS',
      wasInWizard: null,
      strictValidation: false,
    };
  },
  computed: {
    ...mapState({
      idea: (s) => s.idea.current,
      ideas: (s) => s.ideas.list,
    }),
    ...mapGetters({
      userShops: 'user/shops',
      hasMarketplace: 'idea/hasMarketplace',
      hasShops: 'idea/hasShops',
      getSetting: 'settings/getSetting',
      hasPublishRequirements: 'user/hasPublishRequirements',
    }),
  },
  async created() {
    try {
      await this.fetchIdea(this.$route.params.ideaId);
    } catch (error) {
      this.$router.push({
        name: 'partnerarea.ideas',
        query: { restoreState: true },
      });
      return;
    }

    unsavedChangesService.registerComponent({
      check: this.getDirtyState,
      save: () => {
        if (this.currentMode === MODE.WIZARD) {
          this.clearPublishingDetails();
        }

        return this.triggerEdit();
      },
      discard: () => {
        this.resetToOriginal();
      },
    });

    if (this.userShops.length || this.getSetting('MP_ENABLED')) {
      this.changeMode(
        ideaHelper.wasConfigured(this.idea) ? MODE.DETAIL : MODE.WIZARD
      );
    } else {
      this.changeMode(MODE.NO_POS);
    }

    this.syncIdeaList();
  },
  methods: {
    ...mapMutations({
      updateOriginal: 'idea/updateOriginal',
      updateOriginalIdeaAssortment: 'idea/updateOriginalIdeaAssortment',
      clearPublishingDetails: 'idea/clearPublishingDetails',
      setConfigurationProperty: 'idea/setConfigurationProperty',
      resetToOriginal: 'idea/resetToOriginal',
    }),
    ...mapActions({
      fetchIdea: 'idea/fetchIdea',
      replaceIdea: 'ideas/replaceIdea',
      checkIdeaIntents: 'ideas/checkIdeaIntents',
      saveCurrentIdea: 'idea/saveCurrentIdea',
      updateIdeaAssortment: 'idea/updateIdeaAssortment',
    }),
    back() {
      if (this.$route.query?.backName && this.$route.meta.previousState?.name) {
        this.$router.push({
          ...this.$route.meta.previousState,
          query: {
            ...(this.$route.meta.previousState.query || {}),
            restoreState: true,
          },
        });
      } else {
        this.$router.push({
          name: 'partnerarea.ideas',
          query: { restoreState: true },
        });
      }
    },
    changeMode(newMode) {
      this.currentMode = newMode;

      if (newMode === MODE.WIZARD) {
        this.wasInWizard = true;
      }
    },
    onWizardDone() {
      this.changeMode(MODE.DETAIL);
    },
    onWizardCancel() {
      this.clearPublishingDetails();
      this.updateOriginal();
      this.replaceIdea(this.idea);
      this.back();
    },
    editMetaData() {
      this.changeMode(MODE.CONFIG_META_DATA);
    },
    editPos() {
      this.changeMode(MODE.CONFIG_POINTS_OF_SALE);
    },
    editAssortment() {
      this.changeMode(MODE.CONFIG_ASSORTMENT);
    },
    async triggerEdit(currentWizardStep) {
      analyticsService.logEvent('idea_edit_save');
      try {
        await this.edit(currentWizardStep);
        analyticsService.logEvent('idea_edit_save_success');
      } catch (error) {
        analyticsService.logEvent('idea_edit_save_error');
        return Promise.reject(error);
      }
    },
    async edit(currentWizardStep) {
      if (
        (this.currentMode === MODE.WIZARD &&
          currentWizardStep === 'ASSORTMENT') ||
        this.currentMode === MODE.CONFIG_ASSORTMENT
      ) {
        return this.updateIdeaAssortment();
      }

      const skipPublishingDetails =
        this.currentMode === MODE.WIZARD && currentWizardStep !== 'META_DATA';

      if (
        !skipPublishingDetails &&
        this.hasMarketplace &&
        !this.hasPublishRequirements
      ) {
        try {
          await dialogService.openDialog(PdvDialog);
        } catch (error) {
          return Promise.reject(error);
        }
      }

      if (
        currentWizardStep === 'META_DATA' ||
        this.currentMode === MODE.CONFIG_META_DATA
      ) {
        this.strictValidation = true;
      }

      const validationResult = ideaValidator.validate(
        this.idea,
        {},
        this.strictValidation
      );

      if (validationResult.result) {
        if (
          this.currentMode === MODE.WIZARD &&
          currentWizardStep === 'META_DATA'
        ) {
          this.setConfigurationProperty();
        }

        try {
          await this.saveCurrentIdea({
            skipPublishingDetails,
            forcePublishingDetailsUpdate:
              this.currentMode === MODE.WIZARD &&
              currentWizardStep === 'META_DATA',
          });
        } catch (error) {
          eventBus.$emit('idea:editFailed');
          return Promise.reject(error);
        }

        this.replaceIdea(this.idea);

        this.checkIdeaIntents();
      } else {
        eventBus.$emit('idea:editFailed');

        return Promise.reject();
      }
    },
    backToSummary() {
      this.resetToOriginal();
      this.changeMode(MODE.DETAIL);
    },
    async singleEdit() {
      await this.triggerEdit();
      this.changeMode(MODE.DETAIL);
    },
    getDirtyState() {
      return (
        this.currentMode !== MODE.DETAIL &&
        !this.deleted &&
        !ideaHelper.equals(this.idea, this.$store.state.idea.original, {
          ignorePublishingDetails: this.currentMode === MODE.WIZARD,
        })
      );
    },
    uploadMore() {
      this.$router.push({
        name: 'partnerarea.ideas',
        params: { reload: true, upload: true },
      });
    },
    async syncIdeaList() {
      const ideaFromList = this.ideas.find((e) => e.id === this.idea?.id);

      if (
        this.idea &&
        ideaFromList &&
        !isEqual(this.idea?.publishingDetails, ideaFromList?.publishingDetails)
      ) {
        await this.replaceIdea(this.idea);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.page {
  height: 100%;
  padding-bottom: 32px;

  & > div {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
  }
}

.header {
  padding: 12px 0;
}

.banner {
  margin: 12px 0 24px 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: 200px;
    height: 200px;
  }
}

.single-config {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .config {
    flex-grow: 1;
    padding-top: 16px;

    & > div {
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
}

.no-pos {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    background-color: $white;
    margin: auto;
    padding: 50px;
  }
}
</style>
