<template>
  <div :class="`register-page login mp overflow-y`">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
    />
    <CentralAuth mode="mp" additional-context="spread-app" />
  </div>
</template>

<script>
import CentralAuth from 'src/app/components/centralAuth/CentralAuth.vue';

export default {
  name: 'Register',
  components: {
    CentralAuth,
  },
};
</script>

<style lang="scss">
#application.state-partnerarea-registermobile {
  min-width: 0;
  background-color: #fff;
  overflow-y: initial;

  #main-content {
    overflow: initial;
  }

  .help-panel {
    display: none;
  }
}
</style>
