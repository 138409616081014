<template>
  <div class="page-wrapper device-connection overflow-y">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
    />
    <div>
      <div class="intro">
        <h1>{{ $t('CONNECT_DEVICES.MANUAL.HEADING') }}</h1>
        <img :src="showSuccess ? '/images/party.svg' : '/images/connect.svg'" />
      </div>
      <div class="connection-content" v-if="!showSuccess">
        <h3 v-if="username">{{ $t('ACCOUNT.GREETING') }}, {{ username }}</h3>
        <p>{{ $t('CONNECT_DEVICES.MANUAL.DEVICE') }}</p>
        <strong class="device-info">{{ deviceInfo }}</strong>

        <div class="action-wrapper">
          <a
            v-if="data"
            :href="data"
            @click="showSuccess = true"
            class="btn btn-block btn-primary btn-lg icon-btn action"
          >
            <Icon icon="connect" />
            {{ $t('CONNECT_DEVICES.MANUAL.CTA') }}
          </a>
        </div>

        <p>{{ $t('CONNECT_DEVICES.MANUAL.WRONG_ACCOUNT') }}</p>
        <a href="#" @click.prevent="logout" class="link-main">
          {{ $t('LOGIN.LOGOUT') }}
        </a>
      </div>
      <div class="connection-content success-msg" v-else>
        <h3>{{ $t('CONNECT_DEVICES.MANUAL.DONE') }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import deviceService from '@/api/deviceService/deviceService';

export default {
  name: 'ConnectDeviceMobile',
  data() {
    return {
      redirectUrl: this.$route.query.redirectUrl,
      deviceInfo: this.$route.query.deviceInfo,
      data: null,
      showSuccess: false,
    };
  },
  computed: {
    ...mapState({
      username: (s) => s.identity.username,
    }),
  },
  async created() {
    const { token } = await deviceService.getToken();
    this.data = `${this.redirectUrl || 'partnerapp://auth/signIn'}?platform=${
      SPRD.PLATFORM
    }&token=${token}`;

    if (this.$route.query?.instantConnect) {
      window.location.href = this.data;
    }
  },
  async mounted() {
    // in app browser window of safari somehow is not scrolled to the top at start..
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
    }),
    async connectDevice() {
      this.showSuccess = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links.scss';

.device-connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.intro {
  background-color: $grey10;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px;
  min-height: 180px;

  h1 {
    margin: 0 0 32px 0;
  }

  img {
    height: 100%;
    min-height: 0;
    max-width: 100%;
  }
}

.connection-content {
  background-color: $white;
  padding: 32px 12px 48px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  h3 {
    margin: 0 0 16px 0;
  }

  p {
    color: $grey60;
  }

  .device-info {
    margin-top: 16px;
  }

  .action-wrapper {
    width: 100%;
    height: 42px;
    margin: 32px 0;
  }

  .action {
    height: 100%;
    color: $white;
    border-radius: 12px;
    display: flex;
    justify-content: center;

    .icon {
      margin-right: 8px;
    }
  }
}

.success-msg {
  min-height: 30%;
  justify-content: center;
}
</style>

<style lang="scss">
#application.state-partnerarea-appmobile {
  min-width: 0;

  .help-panel {
    display: none;
  }
}
</style>
