import { deepCopy } from '@/utils';
import productIdeaService from '@/api/productIdeaService/productIdeaService';
import ideaHelper from '@/ideaHelper/ideaHelper';

function ensureMpAndCyoConsistency({ idea, userPos }) {
  const cyoPos = userPos.find(
    (publishingDetail) => publishingDetail.pointOfSale.type === 'CYO'
  );

  if (
    ideaHelper.isPublishedOnCYO(idea) &&
    !ideaHelper.isPublishedOnMarketplace(idea)
  ) {
    idea.publishingDetails = idea.publishingDetails.filter(
      (pos) => cyoPos.pointOfSale.id !== pos.pointOfSale.id
    );
  } else if (
    !ideaHelper.isPublishedOnCYO(idea) &&
    ideaHelper.isPublishedOnMarketplace(idea)
  ) {
    idea.publishingDetails.push(cyoPos);
  }
}

const defaultState = {
  list: [],
  originalList: [],
  selectedIdeaIds: [],
  meta: {
    count: null,
    limit: null,
    offset: null,
  },
  filter: {
    q: null,
    lifeState: null,
    posFilter: null,
    page: 0,
  },
  hasAtLeastOneIdeaWithIntent: null,
};

export default {
  namespaced: true,
  state: deepCopy(defaultState),
  getters: {
    filterActive: (state) =>
      state.filter.q || state.filter.lifeState || state.filter.posFilter,
    allSelected: (state) =>
      !state.list.some(
        (idea) =>
          ideaHelper.isSelectable(idea) &&
          !state.selectedIdeaIds.includes(idea.id)
      ),
    selectable: (state) =>
      state.list.filter((idea) => ideaHelper.isSelectable(idea)),
    selected: (state, getters) => state.list.filter(getters.isSelected),
    processing: (state) =>
      state.list.filter((idea) => ideaHelper.getState(idea).isProcessing()),
    isSelected: (state) => (idea) => state.selectedIdeaIds.includes(idea.id),
    totalIdeaCount: (state) => state.meta.count,
    selectedIdeasDirty: (state, getters) => {
      return getters.selected.some((idea) => {
        const originalIdea = state.originalList.find((i) => i.id === idea.id);

        return !ideaHelper.equals(idea, originalIdea);
      });
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, defaultState);
    },
    setList(state, { ideas, meta }) {
      state.list = ideas;
      state.meta = meta;
      state.originalList = deepCopy(state.list);
      state.selectedIdeaIds = [];
    },
    setIdeaSelection(state, { ideaId, selection }) {
      if (selection) {
        state.selectedIdeaIds.push(ideaId);
      } else {
        state.selectedIdeaIds = state.selectedIdeaIds.filter(
          (selectedIdeaId) => selectedIdeaId !== ideaId
        );
      }
    },
    setFilter(state, filter) {
      Object.assign(state.filter, filter);
    },
    resetFilter(state) {
      state.filter = {
        q: null,
        lifeState: null,
        posFilter: null,
        page: 0,
      };
    },
    clearSearchTerm(state) {
      state.filter.q = null;
    },
    replaceIdea(state, idea) {
      state.list[state.list.findIndex((i) => i.id === idea.id)] =
        deepCopy(idea);
    },
    updateOriginal(state) {
      state.originalList = deepCopy(state.list);
    },
    resetToOriginal(state) {
      state.list = deepCopy(state.originalList);
    },
    addTagsToSelected(state, tags) {
      state.list
        .filter((idea) => state.selectedIdeaIds.includes(idea.id))
        .forEach((idea) => {
          tags.forEach((tag) => {
            const translation = ideaHelper.getCurrentTranslation(idea);
            if (
              !translation.tags.includes(tag) &&
              translation.tags.length < 25
            ) {
              translation.tags.push(tag);
            }
          });
        });
    },
    removeTagsFromSelected(state, tags) {
      state.list
        .filter((idea) => state.selectedIdeaIds.includes(idea.id))
        .forEach((idea) => {
          tags.forEach((tag) => {
            const translation = ideaHelper.getCurrentTranslation(idea);
            if (!translation.tags) {
              return;
            }
            translation.tags = translation.tags.filter((t) => t !== tag);
          });
        });
    },
    addTagToIdea(state, { ideaId, tag }) {
      const idea = state.list.find((i) => i.id === ideaId);
      if (idea) {
        const translation = ideaHelper.getCurrentTranslation(idea);
        if (!translation.tags) {
          translation.tags = [];
        }
        if (!translation.tags.includes(tag) && translation.tags.length < 25) {
          translation.tags.push(tag);
        }
      }
    },
    removeTagFromIdea(state, { ideaId, tag }) {
      const idea = state.list.find((i) => i.id === ideaId);
      if (idea) {
        const translation = ideaHelper.getCurrentTranslation(idea);
        if (!translation.tags) {
          return;
        }
        translation.tags = translation.tags.filter((t) => t !== tag);
      }
    },
    addPosToSelected(state, { publishingDetail, userPos }) {
      state.list
        .filter((idea) => state.selectedIdeaIds.includes(idea.id))
        .filter(
          (idea) =>
            !idea.publishingDetails.find(
              (detail) => publishingDetail.id === detail.id
            )
        )
        .filter((idea) => ideaHelper.wasConfigured(idea))
        .forEach((idea) => {
          idea.publishingDetails.push(publishingDetail);
          ensureMpAndCyoConsistency({ idea, userPos });
        });
    },
    removePosFromSelected(state, { publishingDetail, userPos }) {
      state.list
        .filter((idea) => state.selectedIdeaIds.includes(idea.id))
        .filter((idea) =>
          idea.publishingDetails.find(
            (detail) => publishingDetail.id === detail.id
          )
        )
        .forEach((idea) => {
          idea.publishingDetails = idea.publishingDetails.filter(
            (pos) => publishingDetail.pointOfSale.id !== pos.pointOfSale.id
          );
          ensureMpAndCyoConsistency({ idea, userPos });
        });
    },
    setHasAtLeastOneIdeaWithIntent(state, value) {
      state.hasAtLeastOneIdeaWithIntent = value;
    },
  },
  actions: {
    fetchIdeas: async ({ commit }, options) => {
      const {
        list: ideas,
        count,
        limit,
        offset,
      } = await productIdeaService.getIdeasOfUser(options);

      commit('setList', { ideas, meta: { count, limit, offset } });
    },
    fetchListOfIdeas: async ({ commit }, ideaIds) => {
      const ideas = await Promise.all(
        ideaIds.map((ideaId) => productIdeaService.getIdeaOfUserById(ideaId))
      );

      commit('setList', {
        ideas,
        meta: { count: ideas.length, limit: ideas.length, offset: 0 },
      });
    },
    replaceIdea: ({ commit }, idea) => {
      commit('replaceIdea', idea);
      commit('updateOriginal');
    },
    selectAll({ commit, getters }) {
      getters.selectable.forEach((idea) =>
        commit('setIdeaSelection', { ideaId: idea.id, selection: true })
      );
    },
    deselectAll({ commit, state }) {
      state.list.forEach((idea) =>
        commit('setIdeaSelection', { ideaId: idea.id, selection: false })
      );
    },
    toggleIdeaSelection({ commit, state }, idea) {
      commit('setIdeaSelection', {
        ideaId: idea.id,
        selection: !state.selectedIdeaIds.includes(idea.id),
      });
    },
    saveSelected: async ({ commit, getters, dispatch }, options) => {
      const promises = [];
      getters.selected.forEach((idea) => {
        promises.push(
          productIdeaService.updateIdea(idea, options).then((updatedIdea) => {
            idea.processingState = updatedIdea.processingState;
            idea.publishingDetails = updatedIdea.publishingDetails;
          })
        );
      });
      await Promise.all(promises);
      await commit('updateOriginal');
      await dispatch('checkIdeaIntents');
    },
    addPosToSelected({ commit, rootState }, publishingDetail) {
      commit('addPosToSelected', {
        publishingDetail,
        userPos: rootState.user.publishingDetails,
      });
    },
    removePosFromSelected({ commit, rootState }, publishingDetail) {
      commit('removePosFromSelected', {
        publishingDetail,
        userPos: rootState.user.publishingDetails,
      });
    },
    checkIdeaIntents: async ({ commit, dispatch }) => {
      try {
        dispatch('dms/fetchPartnerData', null, { root: true });
        await productIdeaService.getHasIntent();
        await commit('setHasAtLeastOneIdeaWithIntent', true);
      } catch (error) {
        if (error && error.status === 404) {
          await commit('setHasAtLeastOneIdeaWithIntent', false);
        }
      }
    },
  },
};
