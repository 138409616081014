import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import localeService from '@/localeService/localeService';

export default {
  getDesignById(designId) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/users/${store.state.user.data.id}/designs/${designId}`,
        params: {
          mediaType: 'json',
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
  getDesigns(options = {}) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/users/${store.state.user.data.id}/designs`,
        params: {
          mediaType: 'json',
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
          ...options,
        },
      })
      .then((res) => res.data);
  },
};
