<template>
  <div class="dialog-size-md delete-dialog">
    <div class="modal-body">
      <h2
        v-if="data.length > 1"
        v-html="
          $t('DESIGNS.DELETE.WARNING', {
            designCount: data.length,
          })
        "
      ></h2>
      <h2 v-if="data.length === 1">
        {{ $t('DESIGNS.DELETE.SINGLE_WARNING') }}
      </h2>
      <p>{{ $t('DESIGNS.DELETE.POS_REMOVE_HINT') }}</p>

      <div class="color-box red" v-if="compositeError">
        <span v-if="data.length > 1">{{ $t('DESIGNS.DELETE.MPA_ERROR') }}</span>
        <span v-if="data.length === 1">{{
          $t('DESIGNS.DELETE.SINGLE_MPA_ERROR')
        }}</span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        id="design-delete-dialog-cancel"
        class="btn btn-lg btn-light"
        @click="modalInstance.dismiss"
      >
        {{ $t('GENERAL.CANCEL') }}
      </button>
      <ProgressButton
        class="btn btn-lg btn-danger"
        :onClick="deleteIdeas"
        :label="$t('GENERAL.DELETE')"
        icon="bin"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ProgressButton from 'src/app/commons/btnProgress/ProgressButton.vue';
import productIdeaService from 'src/app/commons/api/productIdeaService/productIdeaService';
import analytics from '@/tracking/analytics';

export default {
  name: 'IdeaDeletionDialog',
  props: {
    modalInstance: Object,
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ProgressButton,
  },
  data() {
    return {
      compositeError: false,
    };
  },
  methods: {
    ...mapActions({
      checkIdeaIntents: 'ideas/checkIdeaIntents',
    }),
    deleteIdeas() {
      analytics.logEvent('idea-bulk-delete', {
        selectedCount: this.data.length,
      });

      return productIdeaService.deleteIdeas(this.data).then(
        () => {
          this.checkIdeaIntents();
          this.modalInstance.close(this.data);
        },
        (res) => {
          if (res.data && res.data.errorCode === '1560') {
            this.compositeError = true;
          }
          return Promise.reject();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.delete-dialog {
  h2 {
    margin: 0;
  }

  p {
    margin: 12px 0 25px 0;
  }

  .modal-footer {
    display: flex;

    button {
      height: 40px;
      min-width: 200px;
      padding: 0;

      @include small-desktop {
        height: 35px;
      }

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }

      & > div {
        .icon {
          font-size: 1.5em;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
