<template>
  <div
    class="digital-products-config-preview-images"
    :class="{ 'has-error': validation.errors.previewImages }"
  >
    <draggable
      v-model="previewImageList"
      :disabled="previewImageList.length <= 1"
      :class="{
        empty: !previewImageList.length,
      }"
      itemKey="id"
      tag="ul"
      draggable=".digital-product-preview-image"
    >
      <template #item="{ element, index }">
        <li class="digital-product-preview-image design-checked-background">
          <img :src="element.url" alt="" />

          <VDropdown
            trigger="click"
            popoverClass="vue-popover-theme digital-product-preview-image-popover"
            popoverArrowClass="hidden"
            placement="bottom-end"
          >
            <a class="menu">
              <Icon icon="dots-horizontal" />
            </a>
            <template #popper>
              <div class="menu-content">
                <div>
                  <a
                    v-if="index > 0"
                    href="#"
                    class="link-main icon-link"
                    @click.prevent="movePreviewImageToFront(element.id)"
                  >
                    <Icon icon="star"></Icon>
                    {{ $t('DIGITAL_PRODUCTS.DETAILS.IMAGES.SET_MAIN') }}
                  </a>
                  <a
                    href="#"
                    class="link-red icon-link"
                    @click.prevent="deletePreviewImage(element.id)"
                  >
                    <Icon icon="bin"></Icon>
                    {{ $t('GENERAL.DELETE') }}
                  </a>
                </div>
              </div>
            </template>
          </VDropdown>
        </li>
      </template>
      <template #footer>
        <li class="upload" v-if="previewImageList.length < 5">
          <UploadArea
            :uploadFn="uploadPreviewImage"
            fileTypes=".png, .jpg, .jpeg"
            :error="validation.errors.previewImages"
          />
        </li>
      </template>
    </draggable>
    <DigitalProductLegalDisclaimer />
    <p class="error-info" v-if="validation.errors.previewImages">
      <Icon icon="error" />
      {{ validation.errors.previewImages.reason }}
    </p>
    <div
      class="main-preview-image"
      v-if="digitalProduct.workingState.images.length"
    >
      <div class="digital-product-preview-image design-checked-background">
        <img :src="digitalProduct.workingState.images[0].url" alt="" />
      </div>
      <p>{{ $t('DIGITAL_PRODUCTS.DETAILS.IMAGES.MAIN_DESCRIPTION') }}</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import UploadArea from 'src/app/components/upload/UploadArea.vue';
import DigitalProductLegalDisclaimer from './DigitalProductLegalDisclaimer.vue';
import draggable from 'vuedraggable';
import digitalProductValidator from '@/api/validators/digitalProduct/digitalProductValidator';

export default {
  name: 'DigitalProductConfigPreviewImages',
  components: {
    UploadArea,
    DigitalProductLegalDisclaimer,
    draggable,
  },
  props: {},
  data() {
    return {
      dragging: false,
    };
  },
  created() {},
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      validation: (state) => state.digitalProduct.validation,
    }),
    ...mapGetters({}),
    previewImageList: {
      get() {
        return this.digitalProduct.workingState.images;
      },
      set(value) {
        this.setPreviewImages(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      setPreviewImages: 'digitalProduct/setPreviewImages',
      movePreviewImageToFront: 'digitalProduct/movePreviewImageToFront',
      addToast: 'toasts/addToast',
    }),
    ...mapActions({
      uploadDigitalProductPreviewImages:
        'digitalProduct/uploadDigitalProductPreviewImages',
      deleteDigitalProductPreviewImage:
        'digitalProduct/deleteDigitalProductPreviewImage',
    }),
    async uploadPreviewImage(files) {
      if (files?.length > 0) {
        try {
          await this.uploadDigitalProductPreviewImages(files);
          this.addToast({
            variant: 'black',
            text: 'TOASTS.UPLOAD_SUCCESS',
            hideIcon: true,
          });
        } catch (error) {
          let errorMessage = this.$options.filters.translate('Upload failed');

          switch (error.data.code) {
            case 'UNSUPPORTED_FILE_EXTENSION':
              errorMessage = this.$options.filters.translate(
                'Unsupported File Extension'
              );
              break;
            case 'FILE_SIZE_LIMIT_EXCEEDED':
              errorMessage = this.$options.filters.translate(
                'File Size Limit Error',
                {
                  limit:
                    digitalProductValidator.defaultOptions.previewImages
                      .maxSize,
                }
              );
              break;
          }

          this.addToast({
            variant: 'error',
            text: errorMessage,
            hideIcon: true,
          });
        }
      }
    },
    async deletePreviewImage(imageId) {
      await this.deleteDigitalProductPreviewImage(imageId);
      this.addToast({
        variant: 'black',
        text: 'TOASTS.UPLOAD_SUCCESS',
        hideIcon: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.digital-products-config-preview-images {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul:not(.empty) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;

    li {
      display: flex;
      aspect-ratio: 1;

      &:hover {
        .menu {
          display: flex;
        }
      }

      &.digital-product-preview-image {
        cursor: move;
      }
    }
  }

  li.upload {
    & > div {
      flex: 1;
    }
  }
}

.digital-product-preview-image {
  display: flex;
  aspect-ratio: 1;
  justify-content: center;
  position: relative;
  border: 1px solid $grey10;
  border-radius: 4px;
  padding: 8px;

  img {
    object-fit: contain;
    width: 100%;
  }
}

.v-popover.open .menu {
  display: flex;
}

.menu {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: $white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  &:hover {
    .icon {
      color: $pa-color-main-dark;
    }
  }

  .icon {
    height: 20px;
    width: 20px;
    color: $pa-color-main;
  }
}

.main-preview-image {
  display: flex;
  align-items: center;
  margin-top: 24px;

  .digital-product-preview-image {
    width: 80px;
  }

  p {
    margin: 0 0 0 8px;
  }
}

.error-info {
  padding: 0;
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
}
</style>

<style lang="scss">
.digital-product-preview-image-popover {
  margin: 32px 8px 0 0;

  a:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>
