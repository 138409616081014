<template>
  <TrackingMetricsHint
    class="few-metrics"
    heading="STATISTICS.TRACKING_METRICS.CHART.FEW_TRAFFIC_HEADING"
    description="STATISTICS.TRACKING_METRICS.EMPTY_METRICS_CHART.SUB_HEADING"
  >
    <SmartFaqLink :link="link" :title="linkTitle" />
  </TrackingMetricsHint>
</template>

<script>
import TrackingMetricsHint from './TrackingMetricsHint.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'FewMetricsHint',
  components: { TrackingMetricsHint, SmartFaqLink },
  data() {
    return {
      linkTitle: 'STATISTICS.TRACKING_METRICS.EMPTY_METRICS_CHART.HELP_LINK',
      link: smartFaqLinks.TRACKING_FEW_METRICS,
    };
  },
};
</script>
