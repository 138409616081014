<template>
  <div>
    <h1 class="subheading">
      {{ $t(subheading) }}
    </h1>
    <div class="social-media-content">
      <div v-if="content.id" class="introBox">
        <div class="tile introTile">
          <img class="tileImg" :src="content.imageUrl" :alt="content.name" />
          <div class="tileText">{{ content.name }}</div>
        </div>
        <Icon class="introIcon" icon="arrowright" />
      </div>
      <div class="designs" v-if="content.items && content.items.length">
        <div class="design" v-for="item in content.items" :key="item.id">
          <button
            :key="item.id"
            class="tile no-overflow"
            @click="advanceToNextStep(item)"
            type="button"
            v-if="item.isAvailable"
          >
            <img :src="item.imageUrl" :alt="item.name" />
            <div class="tileText">{{ item.name }}</div>
          </button>
          <div v-else class="tile no-overflow">
            <div class="imgContainer">
              <img class="stockout-img" :src="item.imageUrl" :alt="item.name" />
              <div class="stockout-text color-box yellow">
                <Icon icon="attention" class="stockout-icon" />
                {{ $t('POS.OUT_OF_STOCK') }}
              </div>
            </div>
            <div class="tileText">{{ item.name }}</div>
          </div>
        </div>
        <div
          class="design design-placeholder"
          v-for="(range, index) in 4"
          :key="`placeholder-${index}`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { shopAPI } from 'src/app/commons/api/shop/shopAPI';

export default {
  name: 'SocialMediaToolSelectionWizard',
  props: {
    selectedItem: { type: Object, required: true },
  },
  computed: {
    subheading() {
      return `POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.SUB_PROGRESS_${this.selectedItem.substep}`;
    },
  },
  data() {
    return {
      content: {},
      loading: true,
    };
  },
  async created() {
    this.loadStep(this.selectedItem);
  },
  methods: {
    async advanceToNextStep(item) {
      if (this.loading) {
        return; // we do not have a loading state, so it least prevent actions while stuff is loading
      }
      this.$emit('nextStep', this.selectedItem.substep + 1, item);
    },
    async loadStep(newSelectedItem) {
      this.loading = true;
      switch (newSelectedItem.substep) {
        case 1:
          this.content = await shopAPI.getSharableIdeas(this.$store);
          break;
        case 2:
          this.content = await shopAPI.getSharableSellables(
            this.$store,
            newSelectedItem.selectedIdea.id
          );
          break;
        case 3:
          this.content = await shopAPI.getSharableSellableDetail(
            this.$store,
            newSelectedItem.selectedIdea.id,
            newSelectedItem.selectedSellable.id
          );
          break;
      }
      this.loading = false;
    },
  },
  watch: {
    selectedItem(newSelectedItem) {
      this.loadStep(newSelectedItem);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.social-media-content {
  display: flex;
}
.introBox {
  padding-right: 16px;
  height: 270px;
  display: flex;
  align-items: center;
}
.introTile {
  margin-right: 16px;
  border: 2px solid $pa-color-main;
}
.introIcon {
  width: 31px;
  height: 31px;
  color: $pa-color-main;
}
.designs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  .design {
    padding: 16px;
    flex: 1 1 250px;
    min-width: 250px;
  }

  button {
    border: 2px solid $white;
    border-radius: 4px;
  }
}

img {
  height: 100%;
  width: 100%;
}

.imgContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.stockout-img {
  opacity: 0.4;
}

.stockout-text {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  top: 8px;
  right: 12px;
  left: 12px;
  margin: 0;
  padding: 0.5em;
  color: $sprd-color-darkest-grey;
}

.stockout-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.tile {
  padding: 8px;
  border-radius: 4px;
  background-color: $white;
}

.tileText {
  height: 30px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-left: 2px;
  font-size: 12px;
  font-weight: bold;
}
</style>
