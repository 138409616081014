<template>
  <div class="assortment-catalog-content">
    <div class="filters overflow-y">
      <AssortmentFilter
        :assortment="assortment"
        :activeFilter="activeFilter"
        :flags="flags"
        @setFilter="(...args) => this.$emit('setFilter', ...args)"
      />
      <ColorFilter
        v-if="activeColorFilter"
        :assortment="assortment"
        :activeFilter="activeFilter"
        :activeColorFilter="activeColorFilter"
        @onColorSelect="(...args) => this.$emit('onColorSelect', ...args)"
        @onBulkApply="(...args) => this.$emit('onColorBulkApply', ...args)"
      />
    </div>
    <div class="catalog-content">
      <div class="filtered-content overflow-y">
        <div>
          <CatalogList
            :enableBulkSelection="enableBulkSelection"
            :entries="activeFilter"
            @onToggle="(...args) => this.$emit('onToggle', ...args)"
            @onSetSelection="(...args) => this.$emit('onSetSelection', ...args)"
            @onColorChange="(...args) => this.$emit('onColorChange', ...args)"
            :disableSelection="selectionLimitReached"
          />
          <UnavailableCatalogList :entries="activeFilter" :flags="flags" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssortmentFilter from '../filter/AssortmentFilter.vue';
import ColorFilter from '../filter/ColorFilter.vue';
import CatalogList from './CatalogList.vue';
import UnavailableCatalogList from './UnavailableCatalogList.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'AssortmentCatalogContent',
  props: {
    assortment: {},
    activeFilter: {},
    flags: {},
    activeColorFilter: {},
    enableBulkSelection: {
      type: Boolean,
    },
    selectionLimit: {
      type: Number,
    },
  },
  components: {
    AssortmentFilter,
    ColorFilter,
    CatalogList,
    UnavailableCatalogList,
  },
  data() {
    return {};
  },
  computed: {
    selectionLimitReached() {
      if (!this.selectionLimit) {
        return false;
      }

      return (
        assortmentHelper.getSelectedSellables(this.assortment).length >=
        this.selectionLimit
      );
    },
  },
  watch: {
    activeFilter: function () {
      this.scrollFilterContentToTop();
    },
  },
  methods: {
    scrollFilterContentToTop() {
      const rootCatalogList = document.querySelector('.catalog-list.depth-0');
      if (rootCatalogList) {
        rootCatalogList.scrollIntoView(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/animations';

.assortment-catalog-content {
  display: flex;
  width: 100%;
}

.catalog-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  opacity: 1;
  animation: fadein 0.5s linear both;
  animation-delay: 1s;
}

.filters {
  background-color: #fff;
  width: 270px;
  flex-shrink: 0;
  border-right: 2px solid $grey5;
  animation: slidein 0.25s linear both;
  animation-delay: 0.5s;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

.filtered-content {
  padding: 0 16px 0 16px;
  flex-grow: 1;

  & > div {
    max-width: 1400px;
    margin: 0 auto;
  }
}

:deep(.catalog-list-content) {
  list-style: none;
  padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  & > div {
    padding: 8px;
    flex: 1 1 215px;
  }
}
</style>
