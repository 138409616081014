import productIdeaService from '@/api/productIdeaService/productIdeaService';
import eventBus from '@/eventBus/eventBus';

let _interval;
const _intervalTime = 1000 * 10;

export default {
  fetchModifiedIdeas(options, lastModifiedDate) {
    return productIdeaService
      .getIdeasOfUser(options, {
        'If-Modified-Since': lastModifiedDate,
      })
      .then((data) => {
        if (data && data.list && data.list.length) {
          eventBus.$emit('ideas:changes', data.list);
        }
      });
  },
  startPolling(options) {
    if (_interval) {
      this.stopPolling();
    }

    const lastModifiedDate = new Date().toGMTString();

    this.fetchModifiedIdeas(options, lastModifiedDate);
    _interval = setInterval(() => {
      this.fetchModifiedIdeas(options, lastModifiedDate);
    }, _intervalTime);
  },
  stopPolling() {
    clearInterval(_interval);
  },
};
