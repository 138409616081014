import apiClient from '@/api/client/apiClient';

export default {
  fetchLanguages() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/languages`,
        params: {
          mediaType: 'json',
          fullData: true,
        },
      })
      .then((res) => res.data.languages);
  },
};
