<template>
  <div class="inventory-file-item">
    <span class="name">{{ inventoryFile.originalName }}</span>
    <span class="size">{{ bytesToSize(inventoryFile.fileSizeInBytes) }}</span>
    <div class="actions">
      <a
        :href="`/dgp-pa/users/${userId}/digitalProducts/${digitalProduct.id}/files/${inventoryFile.id}`"
        class="link-main"
        >{{ $t('GENERAL.DOWNLOAD') }}</a
      >
      <a
        href="#"
        class="link-red"
        @click.prevent="deleteFile(inventoryFile.id)"
        >{{ $t('GENERAL.DELETE') }}</a
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { bytesToSize } from 'src/app/commons/utils';

export default {
  name: 'DigitalProductConfigInventoryFilesItem',
  props: {
    inventoryFile: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      userId: (s) => s.user.data.id,
    }),
  },
  methods: {
    ...mapMutations({
      addToast: 'toasts/addToast',
    }),
    bytesToSize,
    ...mapActions({
      deleteDigitalProductInventoryFile:
        'digitalProduct/deleteDigitalProductInventoryFile',
    }),
    async deleteFile(fileId) {
      await this.deleteDigitalProductInventoryFile(fileId);
      this.addToast({
        variant: 'black',
        text: 'TOASTS.UPLOAD_SUCCESS',
        hideIcon: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.inventory-file-item {
  display: flex;
  align-items: center;

  .size {
    color: $grey65;
    margin-left: 16px;
  }

  .actions {
    margin-left: auto;

    a {
      margin-left: 16px;
    }
  }
}
</style>
