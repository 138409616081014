<template>
  <CircleBadge
    v-if="unreadNotificationsCount"
    :content="unreadNotificationsCount"
    :pulse="newNotificationReceived"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CircleBadge from 'src/app/components/badge/CircleBadge.vue';

export default {
  name: 'Navbar',
  components: {
    CircleBadge,
  },
  computed: {
    ...mapState({
      newNotificationReceived: (s) => s.notifications.newNotificationReceived,
    }),
    ...mapGetters({
      unreadNotificationsCount: 'notifications/unreadCount',
    }),
  },
};
</script>
