<template>
  <div class="dialog-size-full preview-template-dialog">
    <div class="modal-header">
      <div>
        <div>
          <h4>{{ translateTemplateName0(data.template) }}</h4>
        </div>
        <div>
          <button
            type="button"
            v-for="size in sizes"
            :key="size"
            @click="setSize(size)"
            class="size-btn"
            :class="{ 'size-btn--active': size === displaySize }"
          >
            <Icon :icon="size.toLowerCase()" />
          </button>
        </div>
        <div>
          <button
            type="button"
            @click="modalInstance.close(data.template)"
            class="btn btn-primary"
          >
            {{ $t('POS.TEMPLATES.OVERVIEW.BUTTON_TRYIT') }}
          </button>
          <button
            type="button"
            class="modal-close"
            @click="modalInstance.dismiss"
          >
            <Icon icon="close" /><span class="modal-close-label">{{
              $t('POS.TEMPLATES.OVERVIEW.BACK')
            }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body text-center">
      <iframe
        :src="data.url"
        frameborder="0"
        :style="{ maxWidth: maxWidth }"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Icon from '@/icon/component/Icon.vue';
import { translateTemplateName } from './translateTemplateName';

export default {
  name: 'PreviewTemplateDialog',
  components: {
    Icon,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      sizes: ['MOBILE', 'TABLET', 'DESKTOP'],
      displaySize: 'DESKTOP',
    };
  },
  computed: {
    maxWidth() {
      switch (this.displaySize) {
        case 'MOBILE':
          return '480px';
        case 'TABLET':
          return '768px';
        default:
          return 'none';
      }
    },
  },
  methods: {
    setSize(size) {
      this.displaySize = size;
    },
    translateTemplateName0(name) {
      return translateTemplateName(name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.select-template-dialog {
  & > div {
    padding: 0 24px;
  }

  .modal-body {
    background-color: $grey5;
    padding-top: 24px;
  }
}

.modal-header {
  & > div {
    display: flex;

    & > div {
      display: flex;
      flex: 1 1 0;
      justify-content: center;

      &:first-child {
        align-items: center;
        justify-content: flex-start;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .btn-primary {
    min-width: 200px;
    height: 40px;
    margin-right: 2em;
  }
}

.size-btn {
  width: 40px;
  background: none;
  border: 0;
  color: $sprd-color-medium-grey;
  font-size: 1.5em;

  &:focus {
    outline: none;
  }
}

.size-btn--active {
  color: $sprd-color-darkest-grey;
}

iframe {
  display: block;
  width: 100%;
  height: 100vh;
  margin: -24px auto;
}
</style>
