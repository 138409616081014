<template>
  <transition
    name="collapse"
    @beforeEnter="beforeEnter"
    @afterEnter="afterEnter"
    @beforeLeave="beforeLeave"
    @afterLeave="afterLeave"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'CollapseAnimation',
  methods: {
    beforeEnter(el) {
      const clone = el.cloneNode(true);
      clone.style.width = el.style.width;
      clone.style.visibility = 'hidden';
      clone.style.removeProperty('display');

      el.parentNode.appendChild(clone);
      const h = clone.clientHeight;
      el.parentNode.removeChild(clone);

      el.style.height = '0px';
      setTimeout(() => (el.style.height = h + 'px'), 1);
    },
    afterEnter(el) {
      el.style.removeProperty('height');
    },
    beforeLeave(el) {
      el.style.height = el.clientHeight + 'px';
      setTimeout(() => (el.style.height = '0px'), 1);
    },
    afterLeave(el) {
      el.style.removeProperty('height');
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-enter-active,
.collapse-leave-active {
  transition: height 0.25s ease;
  overflow: hidden;
}
</style>
