<template>
  <Tile
    :data-idea-id="idea.id"
    :name="currentTranslation.name"
    :isSelectable="isSelectable"
    :selected="selected"
    @toggleSelection="$emit('toggleSelection')"
    :clickable="!state.isProcessing()"
    @onClick="!state.isProcessing() && $emit('edit', idea)"
    :class="`${state.class}`"
  >
    <template v-slot:image>
      <IdeaPreviewImage
        :idea="idea"
        :enableOverlay="false"
        :checkedBackground="true"
        :width="300"
        :height="300"
      />
    </template>
    <template v-slot:overlay v-if="stuckInProcessing">
      <IdeaStuckInProcressing :idea="idea" />
    </template>
    <template v-slot:status>
      <IdeaStatus :idea="idea" />
    </template>
    <template v-slot:sideinfo>
      <div class="channels">
        <Icon
          icon="sprd-heart"
          class="mp-color"
          v-if="isPublishedOnMarketplace"
        />
        <Icon icon="shop" class="shop-color" v-if="isPublishedOnShop" /></div
    ></template>
  </Tile>
</template>

<script>
import ideaHelper from '@/ideaHelper/ideaHelper';
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';
import { mapGetters } from 'vuex';
import IdeaStatus from 'src/app/partnerarea/idea/detail/IdeaStatus.vue';
import IdeaStuckInProcressing from '../IdeaStuckInProcressing.vue';
import Tile from '@/tileView/Tile.vue';

export default {
  name: 'IdeaCard',
  components: {
    IdeaPreviewImage,
    IdeaStatus,
    IdeaStuckInProcressing,
    Tile,
  },
  props: ['idea'],
  emits: ['toggleSelection'],
  data() {
    return {
      stuckInProcessing: ideaHelper.isStuckInProcessing(this.idea),
    };
  },
  computed: {
    ...mapGetters({
      isSelected: 'ideas/isSelected',
    }),
    selected() {
      return this.isSelected(this.idea);
    },
    currentTranslation() {
      return ideaHelper.getCurrentTranslation(this.idea) || {};
    },
    isPublishedOnMarketplace() {
      return ideaHelper.isPublishedOnMarketplace(this.idea);
    },
    isPublishedOnShop() {
      return ideaHelper.isPublishedOnShop(this.idea);
    },
    isSelectable() {
      return ideaHelper.isSelectable(this.idea);
    },
    state() {
      return ideaHelper.getState(this.idea);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';

.channels {
  .icon {
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }
}
</style>
