<template>
  <div class="dialog-size-md publishing-create-dialog">
    <div class="modal-body">
      <div v-if="currentMode === MODES.SELECTION">
        <h1>{{ $t('PUBLISHING.CREATE.HEADING') }}</h1>
        <p class="subheading">
          {{ $t('PUBLISHING.CREATE.SUBHEADING') }}
        </p>

        <label
          for="hiddenFileInput"
          id="upload-btn"
          class="box-section design-upload-area"
        >
          <h3>{{ $t('PUBLISHING.CREATE.UPLOAD.HEADING') }}</h3>
          <p class="subheading">
            {{ $t('DESIGNS.UPLOAD.FILE_TYPES') }} .jpeg, .png, .gif, .svg, .ai,
            .cdr
          </p>
          <button
            class="link link-main"
            type="submit"
            @click="() => $refs.hiddenFileInput.click()"
          >
            {{ $t('PUBLISHING.CREATE.UPLOAD.CTA') }}
          </button>
          <input
            type="file"
            multiple
            id="hiddenFileInput"
            ref="hiddenFileInput"
            @change="uploadFiles"
            class="hidden-file-input"
          />

          <div class="upload-icon-circle">
            <Icon icon="upload" />
          </div>
        </label>
        <div class="text-sm copyright-hint">
          <small v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"></small>
        </div>
        <div class="section-row">
          <div class="box-section">
            <h3>{{ $t('PUBLISHING.CREATE.FROM_EXISTING.HEADING') }}</h3>
            <p class="subheading">
              {{ $t('PUBLISHING.CREATE.FROM_EXISTING.SUBHEADING') }}
            </p>
            <button class="link link-main" @click="selectDesign">
              {{ $t('PUBLISHING.CREATE.FROM_EXISTING.CTA') }}
            </button>
          </div>
          <div class="box-section">
            <h3>{{ $t('PUBLISHING.CREATE.FROM_FREE.HEADING') }}</h3>
            <p class="subheading">
              {{ $t('PUBLISHING.CREATE.FROM_FREE.SUBHEADING') }}
            </p>
            <button class="link link-main">
              {{ $t('PUBLISHING.CREATE.FROM_FREE.CTA') }}
            </button>
          </div>
        </div>
        <div class="box-section">
          <GraphicsServiceBanner
            condensed
            linkIcon="outside"
            class="graphics-service"
          />
        </div>
      </div>
      <PublishingUploadList
        v-if="currentMode === MODES.UPLOAD"
        :files="files"
        @onDone="onCreationDone"
      />
      <PublishingCreateFromDesign
        v-if="currentMode === MODES.SELECT_DESIGN"
        @onDone="onCreationDone"
      />
    </div>
  </div>
</template>

<script>
import PublishingCreateFromDesign from './PublishingCreateFromDesign.vue';
import PublishingUploadList from './PublishingUploadList.vue';
import GraphicsServiceBanner from 'src/app/components/banners/GraphicsServiceBanner.vue';

export default {
  name: 'PublishingCreateDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object },
  },
  components: {
    PublishingUploadList,
    PublishingCreateFromDesign,
    GraphicsServiceBanner,
  },
  data() {
    return {
      files: null,
      MODES: {
        SELECTION: 'SELECTION',
        UPLOAD: 'UPLOAD',
        SELECT_DESIGN: 'SELECT_DESIGN',
      },
      currentMode: null,
    };
  },
  async created() {
    this.currentMode = this.MODES.SELECTION;
  },
  computed: {},
  methods: {
    uploadFiles(evt) {
      this.files = [...evt.target.files];
      this.currentMode = this.MODES.UPLOAD;
    },
    onCreationDone({ errorCount } = {}) {
      if (!errorCount) {
        this.modalInstance.close();
      }
    },
    selectDesign() {
      this.currentMode = this.MODES.SELECT_DESIGN;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

h1 {
  margin: 0 0 12px 0;
}

.subheading {
  color: $grey60;
  margin: 0;
}

.section-row {
  display: flex;
  gap: 24px;
}

.box-section {
  border: 1px solid $grey15;
  border-radius: 8px;
  display: block;
  padding: 16px;
  margin: 24px 0 0 0;
  position: relative;

  button {
    margin: 24px 0 0 0;
  }
}

.upload-icon-circle {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey5;

  .icon {
    width: 24px;
    height: 24px;
  }
}

h3 {
  margin: 0 0 8px 0;
}

.copyright-hint {
  margin-top: 8px;
  color: $grey60;
}

:deep(.copyright-hint) {
  a {
    @extend .link-main;
  }
}

.graphics-service {
  margin: 0;
}
</style>
