const phoneByCountry = {
  DE: '0341 25 049 946',
  AT: '+43 7 20883949',
  CH: '+41 22 518 07 65',
  GB: '+44 20 3966 1818',
  IE: '00800 8005 0606',
  FR: '+33 8 05 08 22 16',
  NL: '00800 8005 0606',
  BE: '+32 2 808 72 88',
  NO: '00800 8005 0606',
  FI: '+358 9 424 560 81',
  SE: '+46 84 468 36 39',
  DK: '+45 69 91 82 53',
  ES: '+34 932 711 686',
  IT: '800 72 80 58',
  PL: '+48 22 307 40 49',
  US: '+1 800 381 0815',
  CA: '+1 800 381 0815',
  AU: '03 8362 1059',
};

const mpMailByPlatform = {
  EU: 'partner@spreadshirt.net',
  NA: 'partner@spreadshirt.com',
};

const shopMailByPlatform = {
  EU: 'service@spreadshop.com',
  NA: 'help@spreadshop.com',
};

export default {
  getPhoneForCountry(country) {
    return phoneByCountry[country];
  },
  getMpMail() {
    return mpMailByPlatform[SPRD.PLATFORM];
  },
  getShopMail() {
    return shopMailByPlatform[SPRD.PLATFORM];
  },
  getBestMailForPartnerType(partnerType) {
    if (partnerType === 'SHOP') {
      return this.getShopMail();
    } else {
      return this.getMpMail();
    }
  },
};
