<template>
  <div :class="`circle-badge ${pulse ? 'pulse' : null} ${color}`">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'CircleBadge',
  props: {
    pulse: {
      type: Boolean,
    },
    content: {
      type: [Number, String],
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/mixins';

.circle-badge {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: $pa-color-main;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 8px;

  &.pulse {
    @include pulse-animation($pa-color-main, 2s, infinite);
  }

  &.red {
    background-color: $pa-color-red;
  }

  &.yellow {
    background-color: $pa-color-yellow;
  }

  &.primary {
    background-color: $pa-color-main;
  }
}
</style>
