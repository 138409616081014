<template>
  <div class="help-panel-wrapper">
    <div class="modal-backdrop help-panel-backdrop" v-if="expanded"></div>
    <div class="help-panel" :class="{ expanded, highlight }">
      <a class="indicator" @click="triggerExpanded">
        <div
          class="chat-badge"
          v-if="liveChatEnabled && liveChatAgentAvailable && !liveChatRunning"
        >
          <p>Chat</p>
          <div class="chat-online-circle"></div>
        </div>
        <div class="question-badge">
          <Icon icon="question" />
        </div>
        <Icon :icon="expanded ? 'expand' : 'collapse'" class="collapse-icon" />
      </a>
      <div
        class="help-panel-content overflow-y"
        v-click-outside="{ isActive: expanded, handler: triggerExpanded }"
      >
        <div class="help-center">
          <h2>{{ $t('HELP_PANEL.HEADING') }}</h2>
          <div class="userid">
            <Copyable id="pid" :content="userData.id" inline>
              <p>{{ $t('DASHBOARD.USER_ID') }}: {{ userData.id }}</p>
            </Copyable>
          </div>
          <div class="common-links">
            <a
              v-if="hasMarketplace"
              :href="mpHelpLink"
              target="_blank"
              v-trackClickEvent="{
                name: 'help-panel-mp-center-link-click',
              }"
            >
              <div>
                <MarketplaceLogo class="logo" />
                <p>
                  {{ $t('HELP_PANEL.MP_HELP_CENTER.SUBHEADING') }}
                </p>
              </div>

              <Icon icon="outside" />
            </a>
            <a
              v-if="hasShops"
              :href="shopHelpLink"
              target="_blank"
              v-trackClickEvent="{
                name: 'help-panel-mds-center-link-click',
              }"
            >
              <div>
                <SpreadshopLogo class="logo" />
                <p>
                  {{ $t('HELP_PANEL.MDS_HELP_CENTER.SUBHEADING') }}
                </p>
              </div>
              <Icon icon="outside" />
            </a>
            <a
              href="#"
              @click.prevent="openHelpDialog"
              v-trackClickEvent="{
                name: 'help-panel-help-dialog-click',
              }"
            >
              <div>
                <strong>{{ $t('GENERAL.HELP') }}</strong>
                <p>{{ $t('HELP_PANEL.HELP.SUBHEADING') }}</p>
              </div>
              <Icon icon="help" />
            </a>
            <a
              v-if="liveChatEnabled"
              href="#"
              @click.prevent="startLiveChat"
              v-trackClickEvent="{
                name: 'help-panel-chat-click',
              }"
              :class="{
                disabled: !liveChatAgentAvailable,
                primary: liveChatAgentAvailable,
              }"
              :disabled="!liveChatAgentAvailable"
            >
              <div>
                <strong>{{ $t('GENERAL.CHAT') }}</strong>
                <p v-if="liveChatAgentAvailable">
                  {{ $t('HELP_PANEL.CHAT.SUBHEADING') }}
                </p>
                <p v-else>
                  {{ $t('HELP_PANEL.CHAT.NO_AGENTS_AVAILABLE') }}
                </p>
              </div>
              <Icon icon="livechat" />
            </a>
          </div>
        </div>
        <div class="divider"></div>
        <SmartFaq />

        <div class="legal-links">
          <a :href="privacyPolicyLink" class="link-main" target="_blank">{{
            $t('GENERAL.PRIVACY_POLICY')
          }}</a>
          <a :href="imprintLink" class="link-main" target="_blank">{{
            $t('GENERAL.IMPRINT')
          }}</a>
        </div>
        <div class="version">{{ partnerAreaVersion }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import Copyable from 'src/app/commons/copyable/Copyable.vue';
import liveChatService from 'src/app/commons/liveChat/liveChatService';
import {
  getArticleByKey,
  articleKeys,
  getHref,
} from 'src/app/partnerarea/tipsAndTools/tipsAndToolsConfig';
import localeService from 'src/app/commons/localeService/localeService';
import SmartFaq from './SmartFaq.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import { getExternalLinks } from '@/externalLinks/externalLinks';
import HelpDialog from 'src/app/components/helpDialog/HelpDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'HelpPanel',
  components: {
    Copyable,
    SmartFaq,
    MarketplaceLogo,
    SpreadshopLogo,
  },
  data() {
    return {
      liveChatEnabled: liveChatService.isEnabled(),
      liveChatAgentAvailable: false,
      liveChatRunning: false,
      privacyPolicyLink: getExternalLinks().PRIVACY_POLICY,
      imprintLink: getExternalLinks().IMPRINT,
      chatStatusCheckInterval: null,
    };
  },
  created() {
    if (this.liveChatEnabled) {
      this.checkLiveChatStatus();
      this.chatStatusCheckInterval = setInterval(
        this.checkLiveChatStatus,
        1000 * 60 * 5
      );
    }
  },
  unmounted() {
    clearInterval(this.chatStatusCheckInterval);
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
      partnerType: 'user/partnerType',
    }),
    ...mapState({
      userData: (s) => s.user.data,
      expanded: (s) => s.application.helpPanelExpanded,
      highlight: (s) => s.application.helpPanelHighlighted,
    }),
    shopHelpLink() {
      return getHref(
        getArticleByKey(articleKeys.HELP_CENTER_SHOP),
        null,
        localeService.getLanguage(),
        SPRD.PLATFORM
      );
    },
    mpHelpLink() {
      return getHref(
        getArticleByKey(articleKeys.HELP_CENTER),
        localeService.getSprdUrl(),
        localeService.getLanguage(),
        SPRD.PLATFORM
      );
    },
    partnerAreaVersion() {
      return `Partner Area ${BUILD.VERSION} (${this.date(
        BUILD.TIME,
        'short'
      )})`;
    },
    liveChatCustomVariables() {
      return [
        { name: 'user_id', value: this.userData.id },
        { name: 'registration_date', value: this.userData.created },
        { name: 'partner_type', value: this.partnerType },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setHelpPanelExpansion: 'application/setHelpPanelExpansion',
    }),
    triggerExpanded() {
      this.setHelpPanelExpansion(!this.expanded);
    },
    openHelpDialog() {
      dialogService.openDialog(HelpDialog);
      this.setHelpPanelExpansion(false);
    },
    checkLiveChatStatus() {
      if (!liveChatService.isEnabled()) {
        return;
      }

      liveChatService
        .init(this.liveChatCustomVariables)
        .then((chatInstance) => {
          this.liveChatRunning = chatInstance.chat_running();
          this.liveChatAgentAvailable = chatInstance.agents_are_available();
        });
    },
    startLiveChat() {
      if (!liveChatService.isEnabled() || !this.liveChatAgentAvailable) {
        return;
      }

      liveChatService
        .init(this.liveChatCustomVariables)
        .then((chatInstance) => {
          chatInstance.set_custom_variables(this.liveChatCustomVariables);
          chatInstance.open_chat_window();
        });
    },
    triggerHighlight() {
      this.highlight = true;
      setTimeout(() => {
        this.highlight = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.help-panel-backdrop {
  z-index: 1060;
}

.help-panel {
  position: fixed;
  height: 100vh;
  right: -355px;
  z-index: 1060;
  display: flex;
  align-items: flex-end;
  transition: right 0.1s linear;
  pointer-events: none;

  &:not(.expanded) {
    &:hover,
    &.highlight {
      right: -350px;

      .indicator {
        opacity: 1;
      }
    }
  }

  &.expanded {
    right: 0;

    .indicator {
      opacity: 1;
      color: $white;
    }
  }
}

.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  opacity: 0.9;
  margin-bottom: 10px;

  & > div {
    background-color: $pa-color-main;
    border-radius: 4px 0 0 4px;
    height: 72px;
    width: 44px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 24px;
      height: 24px;
      color: $white;
    }

    & + div {
      border-radius: 0 0 0 4px;
    }
  }

  .chat-badge {
    border-radius: 4px 0 0 0;
    padding: 4px;
    height: auto;
    align-items: flex-start;
    margin-bottom: 1px;

    p {
      @extend .text-sm;

      margin: 0;
      color: $white;
    }
  }

  .chat-online-circle {
    background-color: #9be487;
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }

  .collapse-icon {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 4px;
  }
}

.help-panel-content {
  background-color: $white;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
  width: 350px;
  height: 100%;
  pointer-events: auto;
  padding: 8px 0;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0 0 12px 0;
  }

  .common-links {
    a {
      margin-bottom: 12px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid $grey20;
      display: flex;
      align-items: flex-start;

      &:hover {
        border-color: $pa-color-main;
      }

      &.disabled {
        opacity: 0.5;
      }

      &.primary {
        background-color: $pa-color-main;
        border-color: $pa-color-main;

        &:hover {
          background-color: $pa-color-main-dark;
        }

        strong {
          color: $white;
        }

        p,
        .icon {
          color: #e5e5e5;
        }
      }
    }

    .logo {
      height: 16px;
    }

    strong {
      color: $grey80;
    }

    p {
      margin: 8px 0 0 0;
      color: $grey60;
    }

    .icon {
      flex-shrink: 0;
      margin-left: auto;
      width: 16px;
      height: 16px;
      color: $pa-color-main;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;

    &.divider {
      padding: 0;
      height: 2px;
      width: 100%;
      background-color: $grey5;
    }
  }

  .userid {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    p {
      @extend .text-sm;
      margin: 0;
    }

    :deep(.icon) {
      width: 16px;
      height: 16px;
    }
  }

  .legal-links {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    a {
      @extend .text-sm;
      margin-top: 8px;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .version {
    @extend .text-sm;
    padding-top: 8px;
    padding-bottom: 0;
  }
}
</style>
