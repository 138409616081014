<template>
  <div
    class="taxation-form"
    :class="{ expires: accountData.current.user.isTaxationFormAboutToExpire }"
  >
    <h4 v-if="accountData.current.user.isTaxationFormAboutToExpire">
      <Icon icon="attention" class="attention-icon"></Icon>
      <span
        v-html="$t('ACCOUNT.TAXATION.US_FORM.HEADING_ABOUT_TO_EXPIRE')"
      ></span>
    </h4>
    <h4 v-if="!accountData.current.user.isTaxationFormAboutToExpire">
      {{ $t('ACCOUNT.TAXATION.US_FORM.HEADING_NOT_EXPIRED') }}
    </h4>
    <p v-html="$t('ACCOUNT.TAXATION.US_FORM.REASON')"></p>
    <strong>{{ $t('ACCOUNT.TAXATION.US_FORM.REASON_CONSEQUENCES') }}</strong>
    <div class="taxation-form-info">
      <ol>
        <li>
          <p>
            {{
              $t('ACCOUNT.TAXATION.US_FORM.DOWNLOAD_LABEL', {
                formName: requiredTaxForm.formName,
              })
            }}
          </p>
          <div class="taxation-form-link">
            <a
              class="btn btn-ghost btn-lg"
              :href="requiredTaxForm.link"
              target="_blank"
              rel="noopener"
            >
              <Icon icon="save"></Icon>
              <span v-html="$t('GENERAL.DOWNLOAD')"></span>
            </a>
          </div>
        </li>
        <li
          v-html="
            $t('ACCOUNT.TAXATION.US_FORM.INSTRUCTION', {
              userId,
              line: requiredTaxForm.line,
            })
          "
        ></li>
        <li
          v-if="taxationType === 'no tax'"
          v-html="$t('ACCOUNT.TAXATION.US_FORM.NA_NO_TAX.INSTRUCTION1')"
        ></li>
        <li>
          <span v-html="$t('ACCOUNT.TAXATION.US_FORM.ACTION')"></span>
          <a class="link-main" href="mailto:taxation@spreadshirt.com"
            >taxation@spreadshirt.com</a
          >
        </li>
      </ol>
      <small class="text-grey">
        {{ $t('ACCOUNT.TAXATION.US_FORM.ALTERNATIVE') }}
      </small>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const taxationForms = {
  W9: {
    link: 'https://www.irs.gov/pub/irs-pdf/fw9.pdf',
    line: '7',
    formName: 'W9',
  },
  W8BENE: {
    link: 'https://www.irs.gov/pub/irs-pdf/fw8bene.pdf',
    line: '10',
    formName: 'W-8BEN-E',
  },
  W8BEN: {
    link: 'https://www.irs.gov/pub/irs-pdf/fw8ben.pdf',
    line: '7',
    formName: 'W8-BEN',
  },
};

export default {
  name: 'TaxFormNA',
  props: {
    taxationType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      accountData: (state) => state.account,
      userId: (s) => s.user.data.id,
    }),
    requiredTaxForm() {
      if (this.accountData.current.user.countryId === 'US') {
        return taxationForms.W9;
      } else if (this.accountData.current.isCompany) {
        return taxationForms.W8BENE;
      } else {
        return taxationForms.W8BEN;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.taxation-form {
  margin-top: 24px;

  &.expires {
    border: 2px solid $sunset;
    padding: 8px 16px;

    h4 {
      line-height: 1.4;

      & > span {
        padding-left: 5px;
      }
    }
  }

  h4 {
    display: flex;
    align-items: center;
  }

  .attention-icon {
    color: $sunset;
  }
}

.attention-icon {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.taxation-form-info {
  .taxation-form-link {
    display: inline-block;
  }

  small {
    display: block;
    margin-top: 8px;
  }
}

ol {
  list-style: none;
  padding: 0;
  margin: 16px 0 0 0;

  :deep(li) a {
    @extend .link-main;
  }

  li .taxation-form-link a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;

    & > .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  li:not(:last-of-type) {
    padding-bottom: 20px;
  }
}
</style>
