<template>
  <div
    v-if="unavailableSellables && unavailableSellables.length"
    class="unavailable-catalog-list"
  >
    <div class="header">
      <h3>
        {{ $t('DESIGNS.CONFIGURATION.UNAVAILABLE.PRODUCTS') }} ({{
          unavailableSellables.length
        }})
      </h3>
      <p>
        {{ $t('DESIGNS.CONFIGURATION.UNAVAILABLE.EXPLANATION') }}
      </p>
    </div>

    <div class="catalog-list-content">
      <ProductCard
        v-for="sellable in unavailableSellables"
        :key="sellable.id"
        :product="sellable"
        :options="{
          showColors: false,
          showToggle: true,
          lazy: true,
        }"
        disableSelection
        :flags="flags"
      />
      <div
        v-for="i in [1, 2, 3, 4, 5]"
        :key="`unavailable-placeholder-${i}`"
        class="unavailable-placeholder-tile"
      ></div>
    </div>
  </div>
</template>

<script>
import ProductCard from '../productCard/ProductCard.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'UnavailableCatalogList',
  props: {
    entries: {
      type: Object,
    },
    flags: {
      type: Array,
    },
  },
  components: {
    ProductCard,
  },
  computed: {
    unavailableSellables() {
      return assortmentHelper
        .getTotalUnavailableSellables(this.entries)
        .filter((s) => !!s.primarySellable);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.header {
  h3 {
    margin: 0 0 12px 0;
  }

  p {
    color: $grey60;
    max-width: 550px;
  }
}
</style>
