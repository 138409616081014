<template>
  <nav class="navbar-main" role="navigation">
    <div class="navbar-header">
      <RouterLink to="/dashboard">
        <MarketplaceLogo class="logo-small" :small="true" disabled />
      </RouterLink>
      <div v-if="isAdmin" class="ribbon-wrapper">
        <div class="ribbon">Admin</div>
      </div>
    </div>

    <div class="navbar-spacer"></div>
    <div class="nav-items" id="navitems-container">
      <ul class="navbar-list">
        <li :class="{ active: $route.name === 'partnerarea.dashboard' }">
          <RouterLink class="partnerarea-dashboard" to="/dashboard">
            <Icon class="nav-bar-icon" icon="gauge" />
            <span class="nav-item-label">{{ $t('NAV.DASHBOARD') }}</span>
          </RouterLink>
        </li>
        <li
          v-if="
            currentUsertest !== USERTEST_VARIANT.NEW_PUBLISHING_JOURNEY_ONLY
          "
          :class="{
            active:
              $route.path.startsWith('/designs') ||
              ($route.name && $route.name.includes('partnerarea.idea')),
          }"
        >
          <RouterLink class="partnerarea-ideas" to="/designs">
            <Icon class="nav-bar-icon" icon="shirt-design" />
            <span class="nav-item-label">{{ $t('NAV.DESIGNS') }}</span>
          </RouterLink>
        </li>
        <li
          v-if="publishingCoreEnabled && !currentUsertest"
          :class="{
            active:
              $route.path.startsWith('/publishing') ||
              ($route.name && $route.name.includes('partnerarea.publishing')),
          }"
        >
          <RouterLink class="partnerarea-publishing" to="/publishing">
            <Icon class="nav-bar-icon" icon="shirt-design" />
            <span class="nav-item-label">{{ $t('NAV.PUBLISHING') }}</span>
          </RouterLink>
        </li>
        <li
          v-if="digitalProductsEnabled"
          :class="{
            active:
              $route.path.startsWith('/digitalProducts') ||
              ($route.name &&
                $route.name.includes('partnerarea.digitalProducts')),
          }"
        >
          <RouterLink
            class="partnerarea-digital-products"
            to="/digitalProducts"
          >
            <Icon class="nav-bar-icon" icon="download-file" />
            <span class="nav-item-label">{{ $t('NAV.DIGITAL_PRODUCTS') }}</span>
          </RouterLink>
        </li>
      </ul>
    </div>
    <div class="navbar-spacer"></div>
    <div class="nav-items pos">
      <span class="text-sm nav-item-label pos-label">{{ $t('NAV.POS') }}</span>
      <ul class="navbar-list">
        <li v-if="showroomEnabled" class="expansion-element">
          <a @click="toggleMpExpanded">
            <span class="nav-item-label nav-item-label-image">
              <MarketplaceLogo />
            </span>
            <!-- <Icon class="level2-link" :icon="mpExpanded ? 'up' : 'down'" /> -->
          </a>
        </li>
        <template v-if="showroomEnabled && mpExpanded">
          <li
            class="navbar-sub-element"
            :class="{ active: $route.name && $route.name.includes('showroom') }"
          >
            <RouterLink to="/showroom">
              <span class="nav-item-label">
                {{ $t('GENERAL.SHOWROOM') }}
              </span>
            </RouterLink>
          </li>
          <li
            v-if="
              currentUsertest === USERTEST_VARIANT.NEW_PUBLISHING_JOURNEY_ONLY
            "
            class="navbar-sub-element"
            :class="{
              active:
                $route.path.startsWith('/publishing') ||
                ($route.name && $route.name.includes('partnerarea.publishing')),
            }"
          >
            <RouterLink class="partnerarea-publishing" to="/publishing">
              <span class="nav-item-label">{{ $t('NAV.DESIGNS') }}</span>
            </RouterLink>
          </li>
        </template>
        <li v-if="shops.length" class="expansion-element">
          <a @click="toggleShopExpanded">
            <span class="nav-item-label nav-item-label-image">
              <SpreadshopLogo />
            </span>
            <!-- <Icon
              class="level2-link"
              :icon="shopExpanded ? 'up' : 'down'"
            /> -->
          </a>
        </li>
        <template v-if="shops.length && shopExpanded">
          <li
            v-for="shop in multipleShops"
            :key="shop.name"
            class="navbar-sub-element"
            :class="{
              active:
                $route.name &&
                $route.name.includes('shop-') &&
                $route.params.shopId === shop.target.id,
            }"
          >
            <RouterLink
              :to="{
                name: 'shop-area',
                params: {
                  shopId: shop.target.id,
                  reload: true,
                },
              }"
              class="shop"
            >
              <div class="nav-item-label">
                <span class="text-truncate">{{ shop.name }}</span>
              </div>
            </RouterLink>
          </li>
          <li
            v-if="shops.length > 1"
            class="navbar-sub-element"
            :class="{ active: $route.name && $route.name.includes('shop-') }"
          >
            <VDropdown
              placement="right"
              :triggers="['click']"
              theme="tooltip-navbar"
              :shown="shopSelectionOpen"
              autoHide
            >
              <a href="#" @click.prevent class="shop">
                <div class="nav-item-label">
                  {{ $t('GENERAL.SHOPS') }}
                </div>
                <Icon class="level2-link" icon="right" />
              </a>
              <template #popper>
                <ul>
                  <li
                    v-for="shop in shops"
                    :key="shop.name"
                    :class="{
                      active:
                        $route.name &&
                        $route.name.includes('shop-') &&
                        $route.params.shopId === shop.target.id,
                    }"
                  >
                    <RouterLink
                      v-close-popper
                      :to="{
                        name: 'shop-area',
                        params: {
                          shopId: shop.target.id,
                          reload: true,
                        },
                      }"
                      class="shop"
                    >
                      <div class="nav-item-label">
                        <small>{{ $t('GENERAL.SHOP') }}</small>
                        <span class="text-truncate">{{ shop.name }}</span>
                      </div>
                    </RouterLink>
                  </li>
                </ul>
              </template>
            </VDropdown>
          </li>
        </template>
      </ul>
    </div>
    <ul class="navbar-list">
      <li
        class="open-pos-element"
        :class="{ 'navbar-sub-element': showroomEnabled && shops.length }"
      >
        <a
          href="#"
          @click.prevent="openSalesChannelSelection()"
          class="link icon-link"
        >
          <Icon icon="plus" />
          <span>{{
            $t(showroomEnabled ? 'NAV.OPEN_SHOP' : 'NAV.OPEN_POS')
          }}</span>
        </a>
      </li>
    </ul>

    <ul class="navbar-list nav-bottom">
      <li :class="{ active: $route.name === 'partnerarea.statistics' }">
        <RouterLink class="partnerarea-statistics" to="/statistics">
          <Icon class="nav-bar-icon" icon="statistics" />
          <span class="nav-item-label">{{ $t('NAV.STATISTICS') }}</span>
        </RouterLink>
      </li>
      <li
        :class="{
          active:
            $route.name && $route.name.includes('partnerarea.tipsAndTools'),
        }"
      >
        <RouterLink class="partnerarea-tips-and-tools" to="/tipsAndTools">
          <Icon class="nav-bar-icon" icon="lightbulb" />
          <span class="nav-item-label">{{ $t('NAV.TIPS_AND_TOOLS') }}</span>
        </RouterLink>
      </li>
      <li
        :class="{
          active: $route.name === 'partnerarea.notifications',
        }"
      >
        <RouterLink class="partnerarea-notifications" to="/notifications">
          <Icon class="nav-bar-icon" icon="bell" />
          <span class="nav-item-label"
            >{{ $t('NAV.NOTIFICATIONS') }}
            <div class="alert">
              <NotificationsBadge />
            </div>
          </span>
        </RouterLink>
      </li>
      <li :class="{ active: $route.name === 'partnerarea.account' }">
        <RouterLink
          to="/account"
          class="account-link partnerarea-account"
          :data-account-id="userId"
        >
          <Icon class="nav-bar-icon" icon="account" />
          <span class="nav-item-label">
            {{ $t('NAV.ACCOUNT') }}
          </span>
          <div class="alert" v-if="accountAlerts.length">
            <CircleBadge :color="accountAlerts[0].severity" />
          </div>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script>
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import { mapGetters, mapState } from 'vuex';
import CircleBadge from 'src/app/components/badge/CircleBadge.vue';
import NotificationsBadge from 'src/app/partnerarea/notifications/NotificationsBadge.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import OpenSalesChannelDialog from 'src/app/partnerarea/pos/salesChannel/OpenSalesChannelDialog.vue';
import { currentUsertest, USERTEST_VARIANT } from '@/usertests/usertests';

export default {
  name: 'Navbar',
  components: {
    MarketplaceLogo,
    SpreadshopLogo,
    CircleBadge,
    NotificationsBadge,
  },
  data() {
    return {
      shopSelectionOpen: false,
      mpExpanded: true,
      shopExpanded: true,
      USERTEST_VARIANT,
      currentUsertest,
    };
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    shops() {
      return this.$store.state.user.pointsOfSale.filter(
        (pos) => pos.type === 'SHOP'
      );
    },
    ...mapGetters({
      getSetting: 'settings/getSetting',
      accountAlerts: 'account/alerts',
      isAdmin: 'account/isAdmin',
      partnerType: 'user/partnerType',
      digitalProductsEnabled: 'settings/digitalProductsEnabled',
      publishingCoreEnabled: 'settings/publishingCoreEnabled',
    }),
    showroomEnabled() {
      return this.getSetting('MP_ENABLED');
    },
    multipleShops() {
      return this.shops.length <= 1 ? this.shops : [];
    },
  },
  methods: {
    openSalesChannelSelection() {
      dialogService.openDialog(OpenSalesChannelDialog, null, {
        dialogContentClass: 'dialog-content-no-min-width',
      });
    },
    toggleMpExpanded() {
      // todo
    },
    toggleShopExpanded() {
      // todo
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';
@import 'src/scss/mixins';

.level2-link {
  position: absolute;
  top: 15px;
  right: 4px;
  width: 12px;
  height: 12px;
  transition: margin 0.1s linear;
}

.level2-link {
  margin-right: 20px;
}

.alert {
  margin-left: 10px;
}

.pos {
  overflow: hidden;
  flex-shrink: 0;
}

.pos-label {
  display: block !important;
  height: 16px;
  margin-left: 20px !important;
  margin-bottom: 8px;
  color: $grey60;
}

.expand-link {
  justify-content: center !important;
}

.navbar-header {
  padding: 24px 17px 0 17px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .logo-small {
    display: block;
    width: auto;
    height: 40px;
    margin: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar-main {
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-right: 2px solid $grey5;
  width: 65px;
  flex-shrink: 0;
  z-index: 1031;
  display: flex;
  flex-direction: column;
  transition: width 0.1s linear;
  width: 224px;
  overflow-y: auto;

  .nav-item-label-image {
    margin-left: 0;

    & > img {
      height: 20px;
    }
  }

  .nav-item-label {
    display: flex;
    animation: show 0.1s linear forwards;
    animation-delay: 0.12s;
  }

  .account-link {
    .icon {
      flex-shrink: 0;
    }
  }

  .nav-items {
    position: relative;
  }

  .navbar-spacer {
    max-height: 40px;
    min-height: 12px;
    flex: 1;
  }

  .navbar-list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      position: relative;
      float: none;
      padding: 0;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: transparent !important;
        color: $grey80;
        justify-content: flex-start;
        padding: 10px 20px;
        height: 44px;
        position: relative;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
          color: $grey75;
        }
      }

      &.active {
        background-color: $grey5;
      }

      &.active,
      &:hover {
        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 5px;
          background-color: $pa-color-main;
        }
      }

      &.disabled {
        a {
          color: grey;
        }
      }
    }
  }
}

.navbar-list.nav-bottom {
  margin-top: auto;
  padding-bottom: 12px;
}

:deep(.trigger) {
  display: block !important;
}
.ribbon-wrapper {
  left: 0px;
  position: absolute;
  top: 0px;
  height: 100px;
  width: 65px;
  overflow: hidden;
}

.ribbon {
  left: -80px;
  position: absolute;
  top: 10px;
  height: 24px;
  width: 206px;
  transform: rotate(-45deg);
  background-color: transparentize($pa-color-main, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.nav-bar-icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.expansion-element {
  a {
    cursor: initial;
  }
}

.expansion-element,
.open-pos-element {
  &:hover:before {
    content: none !important;
  }
}

.pos ul .nav-item-label,
.shop .nav-item-label {
  display: flex;
  flex-direction: column;
  @extend .text-default;

  small {
    color: $grey60;
    font-weight: normal;
  }
}

.shop .nav-item-label {
  max-width: 180px;
}

.open-pos-element a {
  padding: 12px 20px;
  font-weight: normal;
  color: $grey90;
}

.navbar-sub-element a {
  padding: 0 0 0 48px !important;
}
</style>
