const colHeaders = [
  'ProductType ID',
  'ProductType Name',
  'Base price',
  'Final price',
];
const colHeaderLine = colHeaders.join(';');

export const downloadCSVFile = (productTypeList, priceMap, currency) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += colHeaderLine;
  csvContent += '\r\n';
  productTypeList.forEach((pt) => {
    const escapedPtName = pt.name.replace(/[;"']/g, '');
    const row = [
      pt.id,
      escapedPtName,
      priceMap[pt.id].base.toFixed(currency.decimalCount),
      priceMap[pt.id].final.toFixed(currency.decimalCount),
    ].join(';');
    csvContent += row + '\r\n';
  });
  const link = document.createElement('a');
  link.setAttribute('href', encodeURI(csvContent));
  link.setAttribute('download', 'pricing.csv');
  link.click();
};

export const parseUploadedCsv = async (fileToRead) => {
  const text = await readFileAsync(fileToRead);
  return processData(text);
};

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    // Read file into memory as UTF-8
    reader.readAsText(file);
  });
};

// excel and other tools silently change the csv format and do not seem to follow any pattern
// we thus guess here what the separator might be
const guessSeparator = (line) => {
  if (line.length !== colHeaderLine.length) {
    throw new Error('Column header missing or broken');
  }
  return line.substr(colHeaders[0].length, 1);
};

// excel and other tools also localize number formats for no reason
const parseNumber = (numberStr) => Number(numberStr.replace(',', '.'));

const processData = (csv) => {
  const allTextLines = csv.split(/\r\n|\n/);
  const result = {};
  let colSeparator = null;
  for (let i = 0; i < allTextLines.length; i++) {
    if (allTextLines[i].trim().length < 1) {
      continue;
    }
    if (colSeparator === null) {
      colSeparator = guessSeparator(allTextLines[i]);
      continue;
    }

    const data = allTextLines[i].split(colSeparator);
    if (data.length !== 4) {
      // just log skip faulty lines
      // eslint-disable-next-line no-console
      console.error(
        'Could not parse CSV. Line must have 4 columns. Skipped: ' +
          allTextLines[i]
      );
      continue;
    }

    const productTypeId = parseNumber(data[0]);
    const basePrice = parseNumber(data[2]);
    const finalPrice = parseNumber(data[3]);
    if (isNaN(productTypeId) || isNaN(basePrice) || isNaN(finalPrice)) {
      // eslint-disable-next-line no-console
      console.error(
        'Could not parse CSV. One or more columns are non numeric for line: ' +
          allTextLines[i]
      );
      continue;
    }

    result[productTypeId] = Math.max(0, finalPrice - basePrice);
  }
  return result;
};
