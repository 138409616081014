import parse from 'date-fns/parseISO';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import getISOWeek from 'date-fns/getISOWeek';
import getYear from 'date-fns/getYear';
import { i18n } from '@/translate/i18n';
import date from '@/date/date';

const { t } = i18n.global;

export default {
  MONTH: {
    key: 'MONTH',
    from: new Date(
      getYear(subDays(Date.now(), 30)),
      getMonth(subDays(Date.now(), 30)),
      getDate(subDays(Date.now(), 30))
    ),
    metaLabel: (current) => {
      return date(parse(current.date));
    },
    metaGroupUnit: 'GENERAL.DAYS',
    label: (current) => ` ${getDate(parse(current.date))} `,
    group: (current) =>
      `${getMonth(parse(current.date))}-${getDate(parse(current.date))}`,
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.MONTH'),
  },
  QUARTER: {
    key: 'QUARTER',
    from: new Date(
      getYear(subMonths(Date.now(), 3)),
      getMonth(subMonths(Date.now(), 3)),
      getDate(subMonths(Date.now(), 3))
    ),
    metaLabel: (current) =>
      `${t('GENERAL.WEEK')} ${getISOWeek(parse(current.date))} ${getYear(
        parse(current.date)
      )}`,
    metaGroupUnit: 'GENERAL.WEEK',
    label: (current) => `${getISOWeek(parse(current.date))}`,
    group: (current) =>
      `${getYear(parse(current.date))}-${getISOWeek(parse(current.date))}`,
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.QUARTER'),
  },
  HALF_YEAR: {
    key: 'HALF_YEAR',
    from: new Date(
      getYear(subMonths(Date.now(), 6)),
      getMonth(subMonths(Date.now(), 6)),
      getDate(subMonths(Date.now(), 6))
    ),
    metaLabel: (current) =>
      `${t('GENERAL.WEEK')} ${getISOWeek(parse(current.date))} ${getYear(
        parse(current.date)
      )}`,
    metaGroupUnit: 'GENERAL.WEEK',
    label: (current) => `${getISOWeek(parse(current.date))}`,
    skipEverySecondLabel: true,
    group: (current) =>
      `${getYear(parse(current.date))}-${getISOWeek(parse(current.date))}`,
    translation: 'GENERAL.WEEK',
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.HALF_YEAR'),
  },
  YEAR: {
    key: 'YEAR',
    from: new Date(getYear(Date.now()), 0, 1),
    to: new Date(getYear(Date.now()), 11, 31, 23, 59, 59),
    metaLabel: (current) =>
      `${date(parse(current.date), 'MMMM')} ${getYear(parse(current.date))}`,
    metaGroupUnit: 'GENERAL.MONTH',
    label: (current) => `${date(parse(current.date), 'MMM')}`,
    group: (current) =>
      `${getYear(parse(current.date))}-${getMonth(parse(current.date))}`,
    dropdownLabel: () => `${getYear(Date.now())}`,
  },
  TWO_YEARS: {
    key: 'TWO_YEARS',
    from: new Date(getYear(Date.now()) - 1, 0, 1),
    to: new Date(getYear(Date.now()), 11, 31, 23, 59, 59),
    metaLabel: (current) =>
      `${date(parse(current.date), 'MMMM')} ${getYear(parse(current.date))}`,
    metaGroupUnit: 'GENERAL.MONTH',
    label: (current) => `${date(parse(current.date), 'MMM')}`,
    group: (current) =>
      `${getYear(parse(current.date))}-${getMonth(parse(current.date))}`,
    dropdownLabel: () => `${getYear(Date.now()) - 1} - ${getYear(Date.now())}`,
    isTwoYears: true,
  },
};
