// these names are not supposed to be translated. we thus keep this mapping out of the translations table.
const templateUINames = {
  CLASSIC: 'Classic',
  HOBBY: 'Minimal',
  BRAND: 'Focus',
  PORTFOLIO: 'Impulse',
  SHOWCASE: 'Synapse',
  BUSINESS: 'Scale',
};

export const translateTemplateName = (name) => templateUINames[name];
