<template>
  <DetailPage heading="PUBLISHING.DETAIL.PRODUCTS.SETTINGS_DIALOG.HEADING">
    <template v-slot:left-nav>
      <button class="link link-main icon-link" @click="modalInstance.dismiss">
        <Icon icon="left" /> {{ $t('GENERAL.BACK') }}
      </button>
    </template>
    <template v-slot:right-nav>
      <ProgressButton
        class="btn btn-primary save-btn"
        :onClick="save"
        label="GENERAL.SAVE"
      />
    </template>
    <template v-slot:content>
      <div class="product-settings" v-if="initialized">
        <ConfigSection
          heading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.HEADING"
          subheading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.SELECTION_TEXT"
          :inline="false"
        >
          <strong>
            {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.APPAREL') }}
          </strong>
          <ProductSettingsHotspots
            :composition="composition"
            :selectedHotspot="hotspot"
            @onSelect="onHotspotSelection"
          />
        </ConfigSection>
        <ConfigSection
          heading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.HEADING"
          subheading="PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.SELECTION_TEXT"
          :inline="false"
        >
          <ProductSettingsAppearanceGroups
            :composition="composition"
            :selectedAppearanceGroup="appearanceGroup"
            @onSelect="onAppearanceGroupSelection"
          />
        </ConfigSection>
      </div>
    </template>
  </DetailPage>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import DetailPage from 'src/app/components/detailPage/DetailPage.vue';
import ConfigSection from 'src/app/components/publishing/ConfigSection.vue';
import ProductSettingsHotspots from './ProductSettingsHotspots.vue';
import ProductSettingsAppearanceGroups from './ProductSettingsAppearanceGroups.vue';
import compositionHelper from '@/composition/compositionHelper';

export default {
  name: 'PublishingProductSettingsDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object },
  },
  components: {
    ProgressButton,
    DetailPage,
    ConfigSection,
    ProductSettingsHotspots,
    ProductSettingsAppearanceGroups,
  },
  data() {
    return {
      hotspot: null,
      appearanceGroup: null,
      initialized: false,
    };
  },
  async created() {
    this.hotspot = compositionHelper.getDefaultHotspot(this.composition);
    this.appearanceGroup = compositionHelper.getDefaultAppearanceGroup(
      this.composition
    )?.id;
    this.initialized = true;
  },
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
  },
  methods: {
    ...mapActions({
      updateCompositionSellables: 'composition/updateCompositionSellables',
    }),
    back() {},
    async save() {
      await this.updateCompositionSellables({
        defaultHotspot: this.hotspot,
        defaultAppearanceGroup: this.appearanceGroup,
      });

      this.modalInstance.close();
    },
    async onHotspotSelection(hotspot) {
      this.hotspot = hotspot;
    },
    async onAppearanceGroupSelection(appearanceGroup) {
      this.appearanceGroup = appearanceGroup;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.product-settings {
  margin: 24px 0 0 0;
  padding: 0 24px;

  strong {
    display: inline-block;
    margin: 0 0 16px 0;
  }
}
</style>
