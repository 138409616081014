<template>
  <div class="credit-details-rollback">
    <div class="header">
      <h3>{{ $t('STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.ROLLBACK') }}</h3>
      <SmartFaqLink :link="helpTarget" />
    </div>
    <h1 class="text-danger">
      {{ sprdCurrency(data.payoutAmount, data.currencyId) }}
    </h1>
    <div class="sub-details">
      {{ $t('DATE.BOOKED') }}:
      {{ date(data.dateBooked, 'longDate') }}
    </div>
    <div class="reasons">
      <strong>{{ $t('ROLLBACK.EXPLANATION') }}</strong>
      <p>{{ $t('ROLLBACK.REASON1') }}</p>
    </div>
  </div>
</template>

<script>
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'CreditDetailsRollback',
  components: {
    SmartFaqLink,
  },
  props: ['data'],
  data() {
    return {
      helpTarget: smartFaqLinks.COMMISSION_INFO,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.credit-details-rollback {
  h1,
  h3 {
    margin: 0;
  }

  h1 {
    margin-top: 8px;
  }

  .sub-details {
    display: flex;
    margin-top: 4px;
    @extend .text-sm;
    @extend .text-grey;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reasons {
    margin-top: 24px;
    text-align: left;
    max-width: 440px;

    strong {
      display: block;
      margin-bottom: 4px;
    }

    p {
      margin: 0;
      @extend .text-sm;
      @extend .text-grey;
    }
  }
}
</style>
