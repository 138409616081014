<template>
  <div class="font">
    <button
      class="font__toggle"
      :class="{ 'font__toggle--open': isOpen, 'font__toggle--padded': padded }"
      @click="$emit('toggle')"
    >
      <span class="font__label">{{ label }}</span>
      <span
        class="font__value"
        :style="{ backgroundImage: `url(${selected.previewImageUrl})` }"
        >{{ selected.caption }}</span
      >
    </button>
    <ul
      class="font__choices"
      :class="{ 'font__choices--notpadded': !padded }"
      v-if="isOpen"
    >
      <li class="font__choice" v-for="font in options" :key="font.name">
        <button
          type="button"
          class="font__select"
          :class="{
            current: font.name === value,
            'font__select--padded': padded,
          }"
          @click="$emit('change', font.name)"
          :style="{ backgroundImage: `url(${font.previewImageUrl})` }"
        >
          {{ font.caption }}
          <Icon icon="accept-big" v-if="font.name === value" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FontSelector',
  props: {
    label: String,
    value: String,
    options: Array,
    isOpen: Boolean,
    padded: Boolean,
  },
  emits: ['change', 'toggle'],
  computed: {
    selected() {
      return (
        this.options &&
        this.options.find((option) => option.name === this.value)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.font {
  position: relative;
}

.font__toggle {
  display: flex;
  width: 100%;
  text-align: left;
  background: none;
  border: 0;
  padding: 16px 0;

  &--padded {
    padding: 15px 25px;
  }

  &:hover,
  &--open {
    background-color: $sprd-color-lightest-grey;
  }

  &:focus {
    outline: none;
  }
}

.font__choices {
  margin: 4px 0;
  position: relative;
  background-color: $sprd-color-lightest-grey;
  border-radius: 0.25em;
  list-style: none;
  padding: 0;

  &--notpadded {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.font__value {
  flex: 1;
  color: transparent;
  background: transparent no-repeat 100% 50% / auto 20px;
}

.icon {
  margin: -0.5em 0 -0.5em 0.5em;
  width: 2em;
  height: 2em;
}

.font__select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  color: transparent;
  background: transparent no-repeat 0 50% / auto 20px;
  border: 0;
  text-align: left;

  &--padded {
    padding: 0 25px;
    background-position: 25px;
  }

  .icon {
    width: 1.25em;
    height: 1.2em;
    color: $sprd-color-darkest-grey;
  }

  &:hover {
    background-color: #fff;
  }

  &.current {
    background-color: #fff;
  }
}
</style>
