<template>
  <div class="dialog-size-full product-type-sorting-dialog">
    <SaveCancelModalHeader
      :canConfirm="dirty"
      :onConfirm="save"
      :onCancel="modalInstance.dismiss"
    />
    <div class="modal-body">
      <div>
        <h4>
          {{ $t('POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES') }}
        </h4>
        <p>
          {{ $t('POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES_DESCRIPTION') }}
        </p>
        <ItemDragger
          class="dragger"
          resetLabel="POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES_RESET"
          :orderedItems="productTypes"
          :mapItem="mapItem"
          @change:position="positionChanged"
          @reset="resetOrder"
          reset-icon="refresh"
          :hasSelectAll="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { shopBackendAPI } from 'src/app/commons/api/shop/shopBackendAPI';
import ItemDragger from '../common/itemDragger/ItemDragger.vue';
import SaveCancelModalHeader from '../common/SaveCancelModalHeader.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'ProductTypeSortingDialog',
  components: {
    ItemDragger,
    SaveCancelModalHeader,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      currentSorting: [],
      sortedProductTypes: [],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    productTypes() {
      return this.currentSorting.map(
        (productTypeOffset) => this.sortedProductTypes[productTypeOffset]
      );
    },
    dirty() {
      return this.currentSorting.some((position, i) => position !== i);
    },
    shopId() {
      return this.$store.state.shop.currentShop.id;
    },
  },
  methods: {
    async initialize() {
      const [sortedProductTypes, availableProductTypes] = await Promise.all([
        shopBackendAPI.getProductTypeSorting(this.$store),
        shopAPI.getAvailableProductTypes(this.$store),
      ]);
      this.sortedProductTypes = sortedProductTypes.filter((pt) =>
        availableProductTypes.includes(pt.id)
      );
      this.currentSorting = this.sortedProductTypes.map((_, i) => i);
    },
    mapItem({ id, name, imageUrl }) {
      return {
        id,
        imageUrl,
        name,
        class: 'product-type-item', // background-color: $white;
      };
    },
    async save() {
      await shopBackendAPI.saveProductTypeSorting(
        this.$store,
        this.productTypes.map(({ id }) => ({ id }))
      );
      this.modalInstance.dismiss();
    },
    positionChanged({ id, position }) {
      const startPosition = this.sortedProductTypes.findIndex(
        ({ id: sortedId } = {}) => sortedId === id
      );
      const currentPosition = this.currentSorting.indexOf(startPosition);
      this.currentSorting.splice(
        position,
        0,
        ...this.currentSorting.splice(currentPosition, 1)
      );
    },
    async resetOrder() {
      try {
        await dialogService.openDialog(ConfirmDialog, {
          heading:
            'POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES_RESET_CONFIRM_HEADING',
          description:
            'POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES_RESET_CONFIRM_DESCRIPTION',
          cancel: 'GENERAL.CANCEL',
          confirm: 'POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES_RESET',
        });
        await shopBackendAPI.resetProductTypeSorting(this.$store);
        this.initialize();
      } catch (e) {
        // modal dismissed
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.dragger :deep(.product-type-item) {
  background-color: $white;
}
</style>
