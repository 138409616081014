<template>
  <div class="digital-products overflow-y">
    <div v-if="initialized">
      <div class="header">
        <h1>{{ $t('NAV.DIGITAL_PRODUCTS') }}</h1>
        <BetaIndicator />
        <button @click="createNewDigitalProduct" class="sprd-btn-primary">
          {{ $t('DIGITAL_PRODUCTS.ADD.CTA') }}
        </button>
      </div>
      <ul v-if="digitalProducts.length">
        <li v-for="digitalProduct in digitalProducts" :key="digitalProduct.id">
          <DigitalProductTile
            :digitalProduct="digitalProduct"
            @edit="goToDetailPage(digitalProduct)"
          />
        </li>
      </ul>
      <div v-else class="empty">
        <h2>{{ $t('DIGITAL_PRODUCTS.EMPTY_LIST.HEADING') }}</h2>
        <p>{{ $t('DIGITAL_PRODUCTS.EMPTY_LIST.TEXT') }}</p>
        <button @click="createNewDigitalProduct" class="sprd-btn-primary">
          {{ $t('DIGITAL_PRODUCTS.ADD.CTA') }}
        </button>
      </div>
      <Pagination
        class="pagination"
        :total="listMetaData.pageSize * listMetaData.pages"
        :pageSize="listMetaData.pageSize"
        :currentPage="listMetaData.page"
        @pageChange="onPageChange"
      />
    </div>
    <div class="loader" v-if="!initialized">
      <LoadingHeart />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import digitalProductsService from '@/api/digitalProducts/digitalProductsService';
import dialogService from '@/dialogs/wrapper/dialogService';
import DigitalProductsCategoriesDialog from './DigitalProductsCategoriesDialog.vue';
import DigitalProductTile from './DigitalProductTile.vue';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import Pagination from '@/pagination/Pagination.vue';
import BetaIndicator from 'src/app/components/indicator/BetaIndicator.vue';

export default {
  name: 'DigitalProductsList',
  components: {
    DigitalProductTile,
    LoadingHeart,
    Pagination,
    BetaIndicator,
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    ...mapState({
      digitalProducts: (s) => s.digitalProducts.list,
      listMetaData: (s) => s.digitalProducts.meta,
    }),
    ...mapGetters({
      digitalProductsEnabled: 'settings/digitalProductsEnabled',
    }),
  },
  async created() {
    await this.$store.state.settings.onDataLoaded;

    if (!this.digitalProductsEnabled) {
      this.$router.push({ name: 'partnerarea.ideas' });
    }
    await this.fetchDigitalProducts();
    this.initialized = true;
  },
  methods: {
    ...mapActions({
      fetchDigitalProducts: 'digitalProducts/fetchDigitalProducts',
    }),
    async createNewDigitalProduct() {
      try {
        const category = await dialogService.openDialog(
          DigitalProductsCategoriesDialog
        );
        const { productId } = await digitalProductsService.createDigitalProduct(
          category
        );
        await this.fetchDigitalProducts();
        this.$router.push({
          name: 'partnerarea.digitalProduct',
          params: { digitalProductId: productId },
        });
      } catch (error) {
        console.error('create new digital product failed');
      }
    },
    goToDetailPage(digitalProduct) {
      this.$router.push({
        name: 'partnerarea.digitalProduct',
        params: { digitalProductId: digitalProduct.id },
      });
    },
    async onPageChange(page) {
      await this.fetchDigitalProducts({ page });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.digital-products {
  display: flex;
  justify-content: center;
  padding: 24px;

  & > div {
    flex-grow: 1;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;

  h1 {
    margin: 0;
  }

  button {
    margin-left: auto;
  }
}

ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 24px;
  padding: 0 0 24px 0;
  margin: 0;
}

.pagination {
  padding: 0 0 24px 0;
}

.empty {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0;
  }

  p {
    color: $grey65;
    margin-top: 16px;
  }

  .btn {
    margin-top: 24px;
  }
}
</style>

<!-- <style lang="scss">
#application.state-partnerarea-digitalProducts {
  min-width: auto;
}
</style> -->
