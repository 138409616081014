<template>
  <div class="dropdown" :class="{ disabled: disabled }">
    <slot
      name="dropdown-button"
      :item="selectedItem"
      :toggleMenu="toggleMenu"
      :onKeyDown="onKeyDown"
      :isOpen="isOpen"
      :reset="reset"
      :showMenu="showMenu"
      :buttonId="buttonId"
    >
      <label
        v-if="label"
        class="dropdown-button-label"
        :class="{ filled: Boolean(selectedItem) }"
        >{{ label }}</label
      >
      <div
        class="dropdown-button"
        tabindex="0"
        :id="buttonId"
        @click.prevent="toggleMenu()"
        @keydown.prevent="onKeyDown"
        :aria-expanded="isOpen"
        role="button"
        aria-haspopup="true"
        :data-value="selectedItem ? itemKey(selectedItem) : ''"
      >
        <span class="selected-item-text">{{
          selectedItem ? selectedItemText(selectedItem) : placeholder || ''
        }}</span>
        <div class="dropdown-icon-container">
          <Icon
            icon="chevron"
            class="dropdown-icon"
            :class="{ open: isOpen }"
          ></Icon>
        </div>
      </div>
    </slot>

    <slot
      name="items"
      :highlightedIndex="highlightedIndex"
      :selectItem="selectItem"
      :itemMove="onItemMouseMove"
      :isOpen="isOpen"
      :direction="direction"
      :reset="reset"
    >
      <ul
        class="dropdown-items"
        :class="{ open: isOpen, up: direction === 'up' }"
        role="menu"
        :aria-labelledby="buttonId"
        v-if="items && items.length && items[0]"
      >
        <li
          v-for="(item, index) in visibleItems"
          class="item"
          role="menuitem"
          :class="{ active: highlightedIndex === index }"
          :key="index"
          @click="selectItem(item)"
          @mousemove="onItemMouseMove($event, index)"
        >
          {{ itemToString(item) }}
        </li>
      </ul>
    </slot>
  </div>
</template>

<script>
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { genId, isOrContainsNode } from 'src/app/commons/utils';
import { setTimeout, clearTimeout } from 'timers';
/** TODO:
  - focus trap?
**/

function scrollIntoView(node, rootNode) {
  if (node === null) {
    return;
  }

  scrollIntoViewIfNeeded(node, {
    boundary: rootNode,
    block: 'nearest',
    scrollMode: 'if-needed',
    behavior: 'instant',
  });
}

function normalizeArrowKey(event) {
  const { key, keyCode } = event;
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0) {
    return `Arrow${key}`;
  }
  return key;
}

export default {
  name: 'Dropdown',
  emits: ['change'],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemToString: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    selectedItemToString: {
      type: Function,
      required: false,
    },
    value: {
      required: false,
    },
    itemKey: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    direction: {
      type: String,
      required: false,
      default: 'down',
      validator: function (value) {
        return ['up', 'down'].indexOf(value) !== -1;
      },
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: null,
      selectedItem: null,
      isMouseDown: false,
      highlightedIndex: null,
      buttonId: genId('button'),
      searchValue: '',
      resetSearchTimeoutId: null,
    };
  },
  created() {
    if (this.value) {
      this.selectedItem = this.value;
    }
    document.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mouseup', this.mouseUp);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.mouseDown);
    document.removeEventListener('mouseup', this.mouseUp);
  },
  computed: {
    visibleItems() {
      return this.isOpen ? this.items : [];
    },
  },
  watch: {
    value: function (newValue) {
      if (this.selectedItem !== newValue) {
        this.selectedItem = newValue;
      }
    },
  },
  methods: {
    toggleMenu() {
      if (this.isOpen) {
        this.reset();
      } else {
        this.showMenu();
      }
    },
    selectedItemText(item) {
      return this.selectedItemToString
        ? this.selectedItemToString(item)
        : this.itemToString(item);
    },
    showMenu() {
      if (this.selectedItem) {
        const index = this.items.indexOf(this.selectedItem);
        this.highlightedIndex = index >= 0 ? index : 0;
      } else {
        this.highlightedIndex = 0;
      }
      this.isOpen = true;
    },
    selectItem(item) {
      this.selectedItem = item;
      this.$emit('change', item);
      this.reset();
    },
    mouseDown() {
      this.isMouseDown = true;
    },
    mouseUp(event) {
      this.isMouseDown = false;
      if (this.isOpen && !isOrContainsNode(this.$el, event.target)) {
        this.reset();
      }
    },
    onItemMouseMove(event, index) {
      if (this.highlightedIndex === index) {
        return;
      }
      this.highlightedIndex = index;
    },
    onKeyDown(event) {
      const key = normalizeArrowKey(event);
      switch (key) {
        case 'ArrowUp':
          this.moveHighlightedIndex(-1);
          break;
        case 'ArrowDown':
          if (this.isOpen) {
            this.moveHighlightedIndex(1);
          } else {
            this.showMenu();
          }
          break;
        case 'Enter':
          if (this.highlightedIndex >= 0 && this.isOpen) {
            const itemToSelect = this.items[this.highlightedIndex];
            this.selectItem(itemToSelect);
          }
          break;
        case 'Escape':
          if (this.isOpen) {
            this.reset();
          }
          break;
        default: {
          if (
            !/[a-zA-Z0-9]/.test(String.fromCharCode(event.keyCode)) ||
            !event.key
          ) {
            return;
          }

          this.resetSearchTimeout();

          this.resetSearchTimeoutId = setTimeout(() => {
            this.searchValue = '';
            this.resetSearchTimeoutId = null;
          }, 250);

          this.searchValue += event.key.toLowerCase();
          const index = this.items.findIndex((item) =>
            this.itemToString(item).toLowerCase().startsWith(this.searchValue)
          );

          if (index >= 0) {
            this.setHighlightedIndex(index);
          }
          break;
        }
      }
    },
    moveHighlightedIndex(moveAmount) {
      const itemsLastIndex = this.items.length - 1;
      if (itemsLastIndex < 0) {
        return;
      }

      let baseIndex = this.highlightedIndex;
      if (baseIndex === null) {
        baseIndex = moveAmount > 0 ? -1 : itemsLastIndex + 1;
      }
      let newIndex = baseIndex + moveAmount;
      if (newIndex < 0) {
        newIndex = itemsLastIndex;
      } else if (newIndex > itemsLastIndex) {
        newIndex = 0;
      }

      this.setHighlightedIndex(newIndex);
    },
    setHighlightedIndex(index) {
      this.highlightedIndex = index;
      setTimeout(() => {
        scrollIntoView(this.$el.querySelector('.active'), this.$el);
      }, 100);
    },
    reset() {
      this.isOpen = null;
      this.highlightedIndex = null;
      this.resetSearchTimeout();
    },
    resetSearchTimeout() {
      if (this.resetSearchTimeoutId) {
        clearTimeout(this.resetSearchTimeoutId);
        this.resetSearchTimeoutId = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/inputs';

.dropdown {
  position: relative;
  color: $sprd-color-darkest-grey;
}

.dropdown-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-icon {
  transform: rotate(90deg);

  &.open {
    transform: rotate(270deg);
  }
}

.selected-item-text {
  padding-left: 16px;
}

.dropdown-button {
  @include input-states();

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid $sprd-color-medium-grey;
  border-radius: 2px;
  background-color: white;
  padding: 10px 0;
  height: 40px;
  outline: none;

  cursor: pointer;
}

.dropdown-button-label {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 10px; //12px - 4px padding
  transition: top 0.2s linear;
  z-index: 2;
  color: $sprd-color-darkest-grey;
  pointer-events: none;
  padding: 0 4px;

  &:before {
    content: ' ';
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: -1;
    left: 0;
    top: 50%;
  }

  &.filled {
    top: -25%;
  }
}

.dropdown-items {
  display: none;
  list-style: none;
  z-index: 99;

  border: 1px solid $sprd-color-medium-grey;
  border-top: none;
  background-color: white;

  padding: 1px;
  margin-top: 0;
  position: absolute;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0px;

  &.open {
    display: block;
  }

  &.up {
    left: 0;
    bottom: 100%;
    margin-bottom: auto;
    border: 1px solid $sprd-color-medium-grey;
    border-bottom: none;
  }

  .item {
    padding: 12px 16px;
    background-color: white;
    text-align: left;
    cursor: pointer;

    &.active {
      background-color: $sprd-color-lightest-grey;
    }
  }
}

.dropdown.disabled {
  .dropdown-button-label {
    color: $grey20;
  }

  .dropdown-button {
    pointer-events: none;
    cursor: not-allowed;
    border-color: $grey20;
    color: $grey20;
  }
}
</style>
