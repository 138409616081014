<template>
  <div id="shop-information-wrapper">
    <p class="subTitle">
      {{ $t('POS.SHOP_SETTINGS.INFORMATION.NAME.LABEL') }}
    </p>

    <p class="paragraph">
      {{ $t('SA.shopsettings.description') }}
    </p>

    <LegalLabelInput
      class="shop-name"
      v-model="config.shopName"
      maxlength="100"
      field="name"
      :label="$t('SA.shopsettings.information.name')"
    />
    <LegalLabelInput
      class="shop-desc"
      v-model="config.shopDescription"
      type="textarea"
      maxlength="3000"
      :label="$t('SA.shopsettings.information.description.label')"
    />

    <p class="subTitle">
      {{ $t('SA.shopsettings.information.url.label') }}
    </p>

    <div class="url-section">
      <ValidLabelInput
        class="shop-urlname"
        v-model="config.shopUrlName"
        maxlength="32"
        :label="$t('SA.shopsettings.information.domain.name')"
        :validators="validatorsShopUrlName"
      />
      <strong class="divider">...</strong>
      <Dropdown
        :items="localization.domains"
        :item-key="(option) => option.domain"
        :itemToString="(option) => option.domain"
        :value="shopDomain"
        @change="setShopDomain"
      />
    </div>

    <p class="subTitle">Preview</p>
    <p class="preview-url">
      https://<strong>{{ config.shopUrlName }}</strong
      >.myspreadshop.<strong>{{ shopDomain.domain }}</strong>
    </p>
    <div class="serp-box">
      <div class="serp-title">{{ config.shopName || config.shopUrlName }}</div>
      <div class="serp-link">
        https://{{ config.shopUrlName }}.myspreadshop.{{ shopDomain.domain }}
      </div>
      <div class="serp-descr">{{ serpDescription }}</div>
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import LegalLabelInput from '../../shop/common/LegalLabelInput.vue';
import ValidLabelInput from '../../shop/common/ValidLabelInput.vue';
import Dropdown from '../../../../commons/Dropdown/Dropdown.vue';

import { getBlacklistValidator } from '../../shop/common/validators/blacklistValidator';
import { getLengthValidator } from '../../shop/common/validators/lengthValidator';

export default {
  name: 'ShopNameAndUrl',
  mixins: [SettingsData],
  components: {
    Dropdown,
    LegalLabelInput,
    ValidLabelInput,
  },
  data: function () {
    const shopUrlValidator = async (value) => {
      if (value === this.shopId) {
        // shopId is valid
        return;
      }

      // error if value matches only numbers or any character outside A-z0-9_- or starts / ends with a dash
      if (
        value.match(/^\d+$/) ||
        value.match(/[^\w-]/) ||
        value.startsWith('-') ||
        value.endsWith('-')
      ) {
        throw new Error(this.$t('SA.shared.form.error.whitespace'));
      }

      if (value !== this.currentShopName) {
        let data;
        try {
          data = await shopAPI.checkShopNameAvailabilty(value);
        } catch (error) {
          console.error('Failed to check if shop name is available', error);
        }

        if (!data || !data.available) {
          throw new Error(this.$t('SA.shared.form.error.unique'));
        }
      }
    };

    return {
      localization: {},
      validatorsShopUrlName: [
        getLengthValidator({ min: 4, max: 32 }),
        shopUrlValidator,
        getBlacklistValidator(),
      ],
    };
  },
  created: function () {
    this.$store.commit('shop/setLivePreview', { path: '' });

    shopAPI
      .getLocalization(this.$store)
      .then((loc) => (this.localization = loc))
      .catch((e) => console.error('Failed to load localization', e));
  },
  computed: {
    currentShopName() {
      return this.$store.state.shop.shopSavable.originalData.config.shopUrlName;
    },
    shopDomain() {
      if (!this.localization.domains) {
        return {};
      }
      return this.localization.domains.find(
        (d) => d.country === this.config.shopCountry
      );
    },
    serpDescription() {
      const descr = this.config.shopDescription;
      return descr ? descr.substring(0, 160) : '';
    },
  },
  methods: {
    setShopDomain(domain) {
      this.config.shopCountry = this.localization.domains.find(
        (d) => d.domain === domain.domain
      ).country;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.shop-name,
.shop-desc {
  margin-bottom: 24px;
}

.shop-urlname {
  flex: 1;
}

.url-section {
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;

  .divider {
    text-align: center;
    margin: 0 8px;
    font-size: 1.3em;
    font-weight: normal;
  }
}

h5 {
  margin: 16px 0 8px;
}

p {
  &.paragraph {
    margin-bottom: 24px;
  }
  &.subTitle {
    margin-bottom: 16px;
  }
}

.preview-url {
  word-wrap: break-word;
  margin-bottom: 16px;
  strong {
    color: $pa-color-main;
  }
}

.serp-box {
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: arial, sans-serif;
  line-height: 1.3;
  padding: 10px;
}

.serp-title {
  color: #1a0dab;
  font-size: 20px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
}

.serp-link {
  color: #006621;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
}

.serp-descr {
  color: #545454;
  font-size: 14px;
  line-height: 1.57;
  word-wrap: break-word;
}
</style>
