import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

export const PromotionsData = {
  beforeCreate() {
    if (!this.$store.state.shop.shopSavable.clientData.promotions) {
      this.$store.commit('shop/addDynamicSection', {
        key: 'promotions',
        initialData: {},
      });
      shopAPI.getPromotions(this.$store).then((data) =>
        this.$store.commit('shop/updateSectionOriginalData', {
          sectionKey: 'promotions',
          origData: data,
        })
      );
    }
  },
  computed: {
    promotions() {
      return this.$store.state.shop.shopSavable.clientData.promotions || {};
    },
  },
};
