<template>
  <div class="open-sales-channel">
    <div v-if="!showAddShopData" class="sales-channel-content">
      <h2 class="dark-grey">{{ $t('POS.OPEN.HEADING') }}</h2>
      <p class="dark-grey">{{ $t('POS.OPEN.SUBHEADING') }}</p>
      <div class="sales-channels">
        <div
          class="sales-channel shop"
          :class="{ 'limit-reached': shopLimitReached }"
        >
          <SpreadshopLogo class="logo" :disabled="shopLimitReached" />
          <h3 class="sales-channel-caption">
            {{
              shopLimitReached
                ? $t('POS.OPEN.SHOP_LIMIT.HEADING')
                : $t('POS.OPEN.SUBHEADING_SHOP')
            }}
          </h3>
          <div class="pos-benefits" v-if="!shopLimitReached">
            <div class="benefit">
              <div>
                <Icon icon="chart" />
              </div>
              <p class="text" v-html="$t('REGISTER.SHOP.BENEFIT_COMMISSION')" />
            </div>
            <div class="benefit">
              <div>
                <Icon icon="control" />
              </div>
              <p
                class="text"
                v-html="$t('REGISTER.SHOP.BENEFIT_CONTROL_LOOK')"
              />
            </div>
            <div class="benefit">
              <div>
                <Icon icon="star-product" />
              </div>
              <p
                class="text"
                v-html="$t('REGISTER.SHOP.BENEFIT_CONTROL_PRODUCTS')"
              />
            </div>
          </div>
          <div v-else class="pos-benefits">
            <div class="benefit">
              <p class="text">
                {{ $t('POS.OPEN.SHOP_LIMIT.TEXT', { limit: shopLimit }) }}
              </p>
            </div>
          </div>
          <div class="open-channel-cta">
            <button
              type="button"
              @click="setShowAddShop()"
              :disabled="shopLimitReached"
              class="btn btn-primary"
            >
              {{ $t('SA.shopDashboard.createNewShop.label') }}
            </button>
          </div>
        </div>
        <div v-if="!showroomEnabled" class="sales-channel marketplace">
          <MarketplaceLogo class="logo" />
          <h3 class="sales-channel-caption">
            {{ $t('POS.OPEN.SUBHEADING_MP') }}
          </h3>
          <div class="pos-benefits">
            <div class="benefit">
              <div>
                <Icon icon="globe_light" />
              </div>
              <p
                class="text"
                v-html="$t('REGISTER.MP.BENEFIT_TRAFFIC_MARKETING')"
              />
            </div>
            <div class="benefit">
              <div>
                <Icon icon="money_light" />
              </div>
              <p class="text" v-html="$t('REGISTER.MP.BENEFIT_COMMISSION')" />
            </div>
            <div class="benefit">
              <div>
                <Icon icon="target" />
              </div>
              <p
                class="text"
                v-html="
                  $t('REGISTER.MP.BENEFIT_DESIGN_ON_BESTSELLING_PRODUCTS')
                "
              />
            </div>
          </div>
          <div class="open-channel-cta">
            <button
              type="button"
              @click="activateMarketplace()"
              class="btn btn-primary"
            >
              {{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.PUBLISH_TO_SPRD') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="add-shop-content">
      <h2 class="dark-grey">{{ $t('POS.OPEN.HEADING') }}</h2>
      <p class="text">{{ $t('POS.OPEN.SUBHEADING') }}</p>
      <LabelInput class="shop-name" :class="{ 'has-error': !!errorMsg }">
        <label class="text-medium-grey">{{
          $t('SHOPS.CREATE.SHOP_NAME')
        }}</label>
        <input type="text" v-model="shopName" />
        <div class="field-info">
          <small class="error-info error-info-name" v-if="!!errorMsg">
            {{ errorMsg }}
          </small>
        </div>
      </LabelInput>
      <ProgressButton
        class="btn btn-primary"
        :onClick="openShop"
        :label="$t('SA.shopDashboard.createNewShop.label')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LabelInput from '@/labelInput/LabelInput.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import dialogService from '@/dialogs/wrapper/dialogService';
import SelectTemplateDialog from 'src/app/partnerarea/pos/shop/design/templates/SelectTemplateDialog.vue';
import analyticsService from '@/tracking/analytics';

export default {
  name: 'OpenSalesChannelDialog',
  components: {
    MarketplaceLogo,
    ProgressButton,
    SpreadshopLogo,
    LabelInput,
  },
  data() {
    return {
      showAddShopData: false,
      shopName: '',
      errorMsg: null,
      shopLimit: 10,
    };
  },
  computed: {
    ...mapGetters({
      getSetting: 'settings/getSetting',
      shops: 'user/shops',
      partnerType: 'user/partnerType',
      onboardingFinished: 'onboarding/finished',
    }),
    shopLimitReached() {
      return this.shops.length >= this.shopLimit;
    },
    showroomEnabled() {
      return this.getSetting('MP_ENABLED');
    },
  },
  methods: {
    ...mapActions({
      setSetting: 'settings/setSetting',
      fetchShops: 'user/fetchShops',
      fetchPointsOfSale: 'user/fetchPointsOfSale',
    }),
    activateMarketplace() {
      analyticsService.logEvent('open_sales_channel', {
        pos: 'mp',
        partnerType: this.partnerType,
        onboardingFinished: this.onboardingFinished,
      });

      this.setSetting({ key: 'MP_ENABLED', value: true });

      this.$router.push({ name: 'partnerarea.showroom' });
    },
    setShowAddShop() {
      if (this.shopLimitReached) {
        return;
      }

      this.showAddShopData = true;
    },
    validateShopName(shopName) {
      return shopName && /[a-zA-Z]/.test(shopName) && shopName.length >= 4;
    },
    mapToErrorMessage(res) {
      const errorCode = res.data && res.data.error;
      if (errorCode === '333001') {
        const blacklistedTerms = res.data.causes
          .map((c) => c.message)
          .join(',');
        return `${this.$t(
          'DESIGNS.VALIDATION.BLACKLIST.TERMS'
        )}: ${blacklistedTerms}`;
      } else if (errorCode === '99001') {
        return this.$t('ONBOARDING.ERRORS.SHOPNAME_IN_USE');
      } else {
        return this.$t('ACCOUNT.ERRORS.GENERAL_ERROR');
      }
    },
    async openShop() {
      if (!this.validateShopName(this.shopName)) {
        this.errorMsg = this.$t('ACCOUNT.ERRORS.INVALID_FORMAT');
        throw new Error(this.errorMsg);
      }
      const availableTemplates = await shopAPI.getTemplates(this.$store);

      let selectedTemplate;
      try {
        selectedTemplate = await dialogService.openDialog(
          SelectTemplateDialog,
          {
            availableTemplates,
            isNewShop: true,
          },
          {
            displayClose: false,
            fullSize: true,
          }
        );
      } catch (e) {
        throw new Error('dismissed');
      }

      let createSpreadshopResponse;
      try {
        analyticsService.logEvent('open_sales_channel', {
          pos: 'shop',
          partnerType: this.partnerType,
          onboardingFinished: this.onboardingFinished,
        });

        createSpreadshopResponse = await shopAPI.createShop(
          this.$store.state.user.data.id,
          this.shopName,
          this.shopName,
          selectedTemplate
        );
      } catch (e) {
        this.errorMsg = this.mapToErrorMessage(e);
        throw new Error(this.errorMsg);
      }

      await Promise.all([this.fetchShops(), this.fetchPointsOfSale()]);
      this.$router.push({
        name: 'shop-area',
        params: {
          shopId: createSpreadshopResponse.data.shopId,
        },
      });

      this.$emit('shop-created');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

h2,
h3 {
  margin: 0 0 8px 0;
}

.btn {
  min-width: 220px;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
}

.add-shop-content {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .shop-name {
    width: 400px;
    margin: 24px 0 16px 0;
  }

  .shop-error {
    margin-top: 16px;
    width: 400px;

    .shop-error-message {
      margin: 0;
    }
  }
}

.sales-channel-content {
  display: flex;
  flex-direction: column;

  .dark-grey {
    color: $grey80;
  }
  p.dark-grey {
    margin: 0;
  }
  .sales-channels {
    display: flex;
    gap: 32px;
    margin-top: 32px;
    height: 460px;

    .sales-channel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      border-radius: 4px;
      border: 1px solid $grey20;
      position: relative;

      .sales-channel-caption {
        display: block;
        color: $grey70;
        padding: 0 16px;
        height: 40px;
        min-height: 40px;
        text-align: center;
        margin: 0;
      }

      &.marketplace {
        .icon {
          color: $mermaid;
        }
      }
      &.shop {
        .icon {
          color: $pa-color-spreadshop;
        }
      }

      .logo {
        height: 40px;
        margin: 40px;
      }

      .pos-benefits {
        margin-top: 8px;
        display: flex;
        flex-direction: column;

        .benefit {
          flex-grow: 1;
          display: flex;
          align-items: center;
          height: 60px;

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
          }

          p {
            color: $grey70;
            width: 220px;
            text-align: left;
            margin: 0;
          }
        }
      }

      .open-channel-cta {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &.limit-reached {
        background-color: $grey5;
        border-color: $grey5;

        .benefit p {
          width: 360px;
          text-align: center;
        }

        .open-channel-cta button {
          background-color: $grey20;
        }
      }
    }
  }
}
</style>
