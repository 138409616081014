import analytics from '@/tracking/analytics';

export default {
  beforeMount: function (el, binding) {
    el.addEventListener('click', () => {
      let value = binding.value;

      if (value && value.name) {
        analytics.logEvent(value.name, value.details);
      }
    });
  },
};
