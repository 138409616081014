<template>
  <div class="dialog-size-lg sale-details-dialog">
    <div class="modal-body" v-if="saleData">
      <div class="details-heading">
        <h3 v-if="item.designs && item.designs.length">
          {{ item.designs[0].name }}
        </h3>
        <h3 v-else-if="item.digital">
          {{ item.digital.name }}
        </h3>
        <div class="sub-details">
          <p v-if="productTypeName">{{ productTypeName }}</p>
          <p>{{ posName }}</p>
          <p>
            {{ sprdNumber(item.salesAmount) }}
            {{ $t('STATISTICS.SALE_DETAILS.ITEMS') }}
          </p>
        </div>
      </div>
      <div class="details-body">
        <SaleImageSwitcher
          class="sales-images"
          :images="item.images"
          :width="130"
          :height="130"
          :requestProps="{ width: 200, height: 200 }"
        />
        <div class="details">
          <OrderInfo
            :shippingCountry="saleData.shippingCountry"
            :shippingZip="saleData.shippingZipCode"
            :saleDate="saleData.saleDate"
            :orderId="saleData.orderId"
            :pos="item.pointOfSale.pointOfSale || item.pointOfSale.pos"
            :status="item.combinedStatus"
            :customerPrice="{
              amount: item.price.price,
              currencyId: item.price.priceCurrencyId,
            }"
            :profit="{
              amount: item.commission.totalCommission,
              currencyId: item.commission.commissionCurrencyId,
            }"
            :discounts="item.commission.commissionMultiplierTypes"
            displayLabels
          />
          <OrderIndicators
            :bonus="{
              active: item.commission.volumeShareEligibility,
              amount: item.commission.performanceBonus,
              currency: item.commission.commissionCurrencyId,
            }"
            :customized="item.product.customized"
            :pos="item.pointOfSale.pointOfSale || item.pointOfSale.pos"
            container=".modal-container"
          />
        </div>
      </div>
    </div>
    <div class="modal-body" v-else>
      <img src="/images/loader.svg" class="sprd-loader loader" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SaleImageSwitcher from 'src/app/partnerarea/statistics/sales/saleImage/SaleImageSwitcher.vue';
import OrderInfo from 'src/app/partnerarea/statistics/sales/OrderInfo.vue';
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';
import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import OrderIndicators from 'src/app/components/indicator/OrderIndicators.vue';
import { getDigitalProductCategory } from '@/api/models/digitalProducts/digitalProductsCategories';

export default {
  name: 'SaleDetailsDialog',
  components: {
    SaleImageSwitcher,
    OrderInfo,
    OrderIndicators,
  },
  props: {
    modalInstance: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: null,
      saleData: null,
    };
  },
  computed: {
    ...mapState({
      productTypes: (s) => s.user.productTypes,
    }),
    posName() {
      return pointOfSaleName({
        pointOfSale:
          this.item.pointOfSale.pointOfSale || this.item.pointOfSale.pos,
        shopName: this.item.pointOfSale.shopName,
      });
    },
    productTypeName() {
      if (this.item.digital) {
        return this.$t(
          getDigitalProductCategory(this.item.digital.productClass).name
        );
      }

      const productType = this.productTypes[this.item.product.productTypeId];

      if (productType) {
        return productType.name;
      } else {
        return null;
      }
    },
  },
  methods: {
    async fetchSalesDetails() {
      this.saleData = await statisticsService.getSalesDetails(
        this.data.orderItemId
      );
      this.item = this.saleData.items[0];
    },
  },
  async created() {
    await this.fetchSalesDetails();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.modal-body {
  display: flex;
  flex-direction: column;
  position: relative;
}

.loader {
  height: 120px;
  margin: 24px auto;
}

.details-heading {
  h3 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    text-align: left;
  }

  .sub-details {
    margin: 4px 0 16px 0;
    display: flex;

    & > p {
      margin: 0 24px 0 0;
      max-width: 200px;

      @extend .text-truncate;
      @extend .text-grey;
      @extend .text-sm;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.details-body {
  display: flex;
}

.sales-images {
  margin-bottom: auto;
}
</style>
