<template>
  <div>
    <h4 v-if="heading" class="heading">{{ $t(heading) }}</h4>
    <p class="subheading paragraph">{{ $t(subheading) }}</p>
    <RadioButtonGroup
      class="pushStateRadios"
      :items="pushStateOptions"
      :itemKey="(opt) => opt.value"
      :itemToString="(opt) => opt.text"
      v-model="selectedPushStateOption"
    />
    <Copyable id="pfeed" :content="url" inline class="copyInput">
      <input type="text" v-model="url" v-selectOnClick readonly />
    </Copyable>
    <p v-if="additionalHelp" class="additional-help">
      {{ $t(additionalHelp) }}
    </p>
    <SmartFaqLink class="help-link" :link="faqLink" />
  </div>
</template>

<script>
import Copyable from 'src/app/commons/copyable/Copyable.vue';
import RadioButtonGroup from 'src/app/commons/RadioButtonGroup/RadioButtonGroup.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'ShopProductFeed',
  components: {
    Copyable,
    RadioButtonGroup,
    SmartFaqLink,
  },
  props: {
    heading: {
      type: String,
      required: false,
    },
    subheading: {
      type: String,
      required: true,
    },
    additionalHelp: {
      type: String,
    },
    targetPlatform: {
      type: String,
      required: true,
    },
    faqLink: {
      type: Object,
      required: true,
    },
  },
  data() {
    const pushStateOptions = [
      {
        text: this.$t('POS.SHOP_SETTINGS.PRODUCT_FEED.PARAM_STANDARD'),
        value: false,
      },
      {
        text: this.$t('POS.SHOP_SETTINGS.PRODUCT_FEED.PARAM_PUSHSTATE'),
        value: true,
      },
    ];
    return {
      pushStateOptions,
      selectedPushStateOption: pushStateOptions[0],
    };
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
  computed: {
    url() {
      const baseUrl = this.$store.getters['shop/getSpreadshopUrl']('', false);
      if (!baseUrl) {
        return '';
      }
      // hack: when rendering links on the shopname.myspreadshop domain, we must still include the shopId in the path.
      // this seems to be redundant as the shopname is already in the domain, but the resource is routed this way.
      const infix =
        SPRD &&
        SPRD.ENVIRONMENT === 'OPS' &&
        !window.location.host.startsWith('rentrap')
          ? `${this.$store.state.shop.currentShop.id}/`
          : '';

      return `${baseUrl}${infix}products.rss?pushState=${this.selectedPushStateOption.value}&targetPlatform=${this.targetPlatform}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/type';

.heading {
  margin: 24px 0 8px 0;
}

.subheading {
  margin: 0 !important;
}

.pushStateRadios {
  margin: 16px 0;
  flex-direction: column;
  & > :deep(label) {
    margin: 8px 0;
  }
}

.additional-help {
  @extend .text-sm;
  margin: 8px 0;
  color: $grey65;
}

.help-link {
  margin: 8px 0 0 0;
}

.copyInput {
  position: relative;

  :deep(.copy-content) {
    width: 100%;
  }

  :deep(input) {
    padding-right: 32px;
  }

  :deep(a) {
    position: absolute;
    top: 8px;
    right: 4px;
  }
}
</style>
