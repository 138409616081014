export const findEntry = (url, object) => {
  if (!url) {
    return null;
  }

  if (url === 'shop-area') {
    url = 'shop-area.staticShopPreview';
  }

  if (object.url === url) {
    return object;
  }

  if (object.children) {
    for (const child of object.children) {
      const result = findEntry(url, child);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const setNavParents = (o) => {
  const r = Object.assign({}, o);
  if (r.children) {
    r.children.forEach((child) => {
      child.parent = r;
      setNavParents(child);
    });
  }
  return r;
};
