<template>
  <div>
    <p>{{ $t('ACCOUNT.TAXATION.EU_SMALL_BUSINESS.HEADING') }}</p>
  </div>
</template>

<script>
export default {
  name: 'SmallBusiness',
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
};
</script>
