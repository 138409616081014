<template>
  <svg class="icon" :key="icon">
    <use :xlink:href="iconKey" />
  </svg>
</template>

<script>
import iconService from 'src/app/commons/icon/service/iconService';

export default {
  name: 'Icon',
  props: {
    icon: { type: String, required: true },
  },
  computed: {
    iconKey() {
      return `${iconService.getIconRef()}#${this.icon}`;
    },
  },
};
</script>
