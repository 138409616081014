<template>
  <div class="global-toasts" v-if="toast">
    <Toast
      class="toast"
      :variant="toast.variant"
      :timeout="toast.timeout"
      :dismissible="toast.dismissble"
      @dismiss="removeToast"
      :hideIcon="toast.hideIcon"
      >{{ $t(toast.text) }}</Toast
    >
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Toast from 'src/app/components/toast/Toast.vue';

export default {
  name: 'GlobalToasts',
  components: {
    Toast,
  },
  computed: {
    ...mapState({
      toast: (s) => s.toasts.currentToast,
    }),
  },
  methods: {
    ...mapMutations({
      removeToast: 'toasts/removeToast',
    }),
  },
};
</script>

<style lang="scss" scoped>
.global-toasts {
  position: absolute;
  bottom: 0;
  left: 24px;
  height: auto !important;
  right: 24px;
  z-index: 1061;
  padding-bottom: 24px;
}

.toast {
  margin: 0;
}
</style>
