<template>
  <div>
    <SalesEarningsGraph
      class="graph"
      heading="DESIGNS.DETAILS.STATISTICS.HEADING"
      subheading="DESIGNS.DETAILS.STATISTICS.SUBHEADING"
      salesSubheading="DESIGNS.DETAILS.STATISTICS.TOTAL_SALES_INFO"
      earningsSubheading="DESIGNS.DETAILS.STATISTICS.TOTAL_EARNINGS_INFO"
      displayPosAggregate
      displayChartHeading
      @onLoadData="fetchStatistics"
      :data="ideaStatisticData"
      :initialized="initialized"
    />
    <div
      class="bestsellers"
      v-if="initialized && bestsellerData && bestsellerData.length"
    >
      <div class="bestsellers-heading">
        <h4>{{ $t('STATISTICS.BESTSELLERS.HEADING') }}</h4>
      </div>
      <div class="bestsellers">
        <BestsellerList :data="bestsellerData" />
      </div>
    </div>
  </div>
</template>

<script>
import statisticsService from '@/api/statisticsService/statisticsService';
import BestsellerList from 'src/app/partnerarea/statistics/bestseller/BestsellerList.vue';
import SalesEarningsGraph from 'src/app/components/charts/sales/SalesEarningsGraph.vue';

export default {
  name: 'IdeaStatistics',
  components: {
    BestsellerList,
    SalesEarningsGraph,
  },
  props: {
    idea: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      initialized: false,
      bestsellerData: null,
      ideaStatisticData: null,
    };
  },
  computed: {},
  methods: {
    async fetchStatistics(filters) {
      this.initialized = false;

      const data = await statisticsService.getIdeaStatistics(this.idea.id, {
        pos: filters.pos.value,
        dateFrom: filters.time.from,
        dateTo: filters.time.to,
      });
      this.ideaStatisticData = data.timeline ? data : null;

      const bestsellerData = await statisticsService.getBestsellers({
        pos: filters.pos.value,
        dateFrom: filters.time.from,
        dateTo: filters.time.to,
        ideaId: this.idea.id,
        type: 'PTIDEA',
      });
      this.bestsellerData = bestsellerData.list;

      this.initialized = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.bestsellers-heading {
  background-color: $white;
  padding: 16px 24px;

  h4 {
    margin: 0;
  }
}

.bestsellers {
  :deep(.stat-item:first-child) {
    border-radius: 0;
  }
}

.graph {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
