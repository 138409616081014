import confetti from 'canvas-confetti';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function randomCanon() {
  confetti({
    angle: randomInRange(35, 145),
    spread: randomInRange(50, 70),
    particleCount: randomInRange(50, 100),
    origin: { y: 0.4, x: 0.5 },
    startVelocity: randomInRange(40, 60),
    zIndex: 1050,
    ticks: 600,
    colors: [
      '#FFFFFF',
      '#80D1FF',
      '#3075B4',
      '#97b9d9',
      '#C14E48',
      '#108043',
      '#E69D23',
    ],
  });
}

export default {
  randomCanons() {
    for (let i = 0; i < 5; i++) {
      setTimeout(randomCanon, 300 * i);
    }
  },
};
