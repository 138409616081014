<template>
  <transition name="slidein">
    <div class="idea-list-filter" v-if="enabled">
      <div class="filterpanel">
        <div class="header">
          <h2>{{ $t(heading) }}</h2>
          <a href="#" @click.prevent="close" class="link-main icon-link">
            {{ $t('GENERAL.CLOSE') }}
            <Icon icon="close" />
          </a>
        </div>
        <div class="filters">
          <slot></slot>
        </div>
      </div>
      <div class="backdrop" @click="close"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FilterPanel',
  props: {
    enabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    heading: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

@keyframes slidein {
  0% {
    margin-left: -320px;
    opacity: 0;
  }
  50% {
    margin-left: -320px;
    opacity: 1;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

.slidein-enter-active {
  animation: slidein 0.5s;
}
.slidein-leave-active {
  animation: slidein 0.5s reverse;
}

.idea-list-filter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1030;
  background-color: transparentize(
    $dialog-backdrop-color,
    1 - $dialog-backdrop-opacity
  );
  display: flex;
}

.filterpanel {
  background-color: #fff;
  width: 320px;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px 16px;

  h2 {
    margin: 0;
  }

  a {
    margin-left: auto;
  }

  .icon {
    margin: 0 0 0 8px;
  }
}

.filters {
  padding: 0 16px 16px 16px;
}

.backdrop {
  flex: 1;
}
</style>
