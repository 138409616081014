<template>
  <div class="media-selector">
    <p class="headline">
      {{ $t('POS.SHOP_SETTINGS.MEDIA_SELECTOR.HEADLINE') }}
    </p>
    <RadioButtonGroup
      class="radiogroup"
      :items="mediaOptions"
      :itemKey="(item) => item.value"
      :itemToString="(item) => item.label"
      :model-value="selectedMediaOption"
      :disabled="disabled"
      @update:model-value="onChangeType"
    />
    <div v-if="selectedMediaOption.value === IMAGE_TYPE">
      <ImageUpload
        v-if="countActiveImages < maxImages"
        @file-uploaded="onImageUploaded"
        @file-deleted="onImageDeleted"
        :image="image"
        :disabled="disabled"
      />
      <div v-if="filteredMedia.length">
        <draggable
          v-model="filteredMedia"
          item-key="media"
          :options="{ handle: '.handle', forceFallback: true }"
          v-if="maxImages > 1"
        >
          <template #item="{ element, index }">
            <div
              class="sortable-wrapper"
              v-bind:class="{
                'sortable-in-deletion': imagesInDeletion.includes(
                  element.media
                ),
              }"
            >
              <button class="handle"></button>
              <ImageUpload
                class="additional-image sortable-image"
                @file-uploaded="onImageUploaded($event, index)"
                @file-deleted="onImageDeleted(index)"
                @file-deletion-started="onImageDeletionStarted(element.media)"
                @file-deletion-finished="onImageDeletionFinished(element.media)"
                :image="image"
                :activeImageUrl="element.previewImage"
                :activeImageName="element.media"
                :disabled="disabled"
                :file-input-id="'hiddenFileInput' + index"
              />
            </div>
          </template>
        </draggable>
        <ImageUpload
          v-else
          @file-uploaded="onImageUploaded($event, 0)"
          @file-deleted="onImageDeleted(0)"
          :image="image"
          :activeImageUrl="filteredMedia[0].previewImage"
          :activeImageName="filteredMedia[0].media"
          :disabled="disabled"
          file-input-id="hiddenFileInput0"
        />
      </div>
    </div>
    <div v-else>
      <VideoEmbed
        v-if="filteredMedia.length < maxVideos"
        :key="filteredMedia.length"
        :active="!disabled"
        @change="onChange"
      />
      <VideoEmbed
        v-for="(medium, index) in filteredMedia"
        :key="index"
        :active="!disabled"
        :preview-image="medium.previewImage"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import RadioButtonGroup from '@/RadioButtonGroup/RadioButtonGroup.vue';
import ImageUpload from '../../imageUpload/ImageUpload.vue';
import VideoEmbed from './VideoEmbed.vue';
import { IMAGE_TYPE, NO_TYPE, VIDEO_TYPE, isVideoType } from './mediaTypes';

export default {
  name: 'MediaSelector',
  props: {
    disabled: Boolean,
    media: Array,
    onChange: Function,
    image: {
      type: Object,
      required: true,
    },
    maxImages: {
      type: Number,
      default: 1,
    },
    maxVideos: {
      type: Number,
      default: 1,
    },
  },
  components: {
    RadioButtonGroup,
    ImageUpload,
    VideoEmbed,
    draggable,
  },
  data() {
    return {
      IMAGE_TYPE,
      selectedMediaOption: null,
      mediaOptions: [
        {
          value: IMAGE_TYPE,
          label: this.$t('POS.SHOP_SETTINGS.MEDIA_SELECTOR.UPLOAD_IMG.LABEL'),
        },
        {
          value: VIDEO_TYPE,
          label: this.$t('POS.SHOP_SETTINGS.MEDIA_SELECTOR.EMBED_VIDEO.LABEL'),
        },
      ],
      imagesInDeletion: [],
    };
  },
  computed: {
    filteredMedia: {
      get() {
        const currentType = this.selectedMediaOption.value;

        if (currentType === IMAGE_TYPE) {
          return this.media.filter((medium) => medium.mediaType === IMAGE_TYPE);
        } else if (currentType === VIDEO_TYPE) {
          return this.media.filter((medium) => isVideoType(medium.mediaType));
        }

        return [];
      },
      set(elements) {
        elements.forEach((element, key) => {
          this.onChange({
            ...element,
            key,
          });
        });
      },
    },
    countActiveImages() {
      return this.media.filter(
        (medium) =>
          medium.mediaType === IMAGE_TYPE &&
          !this.imagesInDeletion.includes(medium.media)
      ).length;
    },
  },
  methods: {
    onChangeType(mediaOption) {
      this.selectedMediaOption = mediaOption;
    },
    onImageUploaded({ filename: media, url: previewImage }, key) {
      this.onChange({
        media,
        previewImage,
        mediaType: IMAGE_TYPE,
        key,
      });
    },
    onImageDeleted(key) {
      this.onChange({
        mediaType: NO_TYPE,
        key: key,
      });
    },
    onImageDeletionStarted(image) {
      if (!this.imagesInDeletion.includes(image)) {
        this.imagesInDeletion.push(image);
      }
    },
    onImageDeletionFinished(image) {
      const index = this.imagesInDeletion.indexOf(image);
      if (image !== -1) {
        this.imagesInDeletion.splice(index, 1);
      }
    },
  },
  watch: {
    mediaOptions: {
      handler() {
        // default if unspecified
        let mediaType = IMAGE_TYPE;

        if (this.media && this.media.length > 0) {
          // currently all medias are supposed to have the same type
          mediaType = this.media[0].mediaType;
        }

        if (isVideoType(mediaType)) {
          // video types are specified by platform but for the RadioButtonGroup we are only interested in the main type
          mediaType = VIDEO_TYPE;
        }

        this.selectedMediaOption =
          this.mediaOptions.find(
            (mediaOption) => mediaOption.value === mediaType
          ) || this.mediaOptions[0];
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.media-selector,
.radiogroup {
  margin-bottom: 15px;
}

.sortable-wrapper {
  position: relative;
  margin: 1em 0;
  padding: 0.5em 1em 0.5em 2em;
  background: $grey0;

  .handle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2em;
    padding: 0;
    background: none;
    border: 0;

    &::after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 28px;
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 2 2' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h1v1H0z'/%3E%3C/svg%3E")
        0 0 / 8px 8px;
    }

    &:focus {
      outline: none;
    }
  }
}

.headline {
  font-weight: 700;
  margin: 32px 0 16px;
}

.sortable-in-deletion {
  display: none;
}

.sortable-image {
  :deep(.image-upload) {
    margin-bottom: 0;
  }

  :deep(.current-image) {
    display: flex;
    align-items: center;
  }

  :deep(.active-img) {
    margin-bottom: 0;

    img {
      object-fit: contain;
    }
  }

  :deep(.restrictions-text-outside) {
    display: none;
  }

  :deep(.ud-row) {
    flex: 1 0 0;
    justify-content: space-around;
  }

  :deep(.ud-btn) {
    background: none;
    border: 0;
  }

  :deep(.legaltxt) {
    display: none;
  }
}
</style>
