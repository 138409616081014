<template>
  <div class="color-filter">
    <div class="header">
      <h4>{{ $t('DESIGNS.CONFIGURATION.COLORS_FILTER_HEADING') }}</h4>
    </div>

    <p>{{ $t('DESIGNS.CONFIGURATION.COLORS_FILTER_SUBHEADING') }}</p>

    <ul>
      <li v-for="metaColor in allMetaColors" :key="metaColor.id">
        <ColorCircle
          size="24"
          :color="metaColor.rgb"
          :active="activeColorFilter.id === metaColor.id"
          :deactivated="!availableMetaColors.includes(metaColor.id)"
          @onClick="$emit('onColorSelect', metaColor)"
          :name="metaColor.name"
        />
      </li>
    </ul>

    <div class="actions">
      <a
        href="#"
        type="button"
        class="link-main"
        :disabled="!activeColorFilter.id"
        @click.prevent="apply"
      >
        {{ $t('DESIGNS.CONFIGURATION.COLOR_APPLY_SHORT') }}
      </a>
      <a
        class="link link-light clear-link"
        @click="$emit('onColorSelect', null)"
        :class="{ disabled: !activeColorFilter.id }"
        v-trackClickEvent="{ name: 'spx-bulk-color-reset' }"
        >{{ $t('GENERAL.CLEAR') }}</a
      >
    </div>
  </div>
</template>

<script>
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';
import { mapState } from 'vuex';
import analytics from '@/tracking/analytics';

export default {
  name: 'ColorFilter',
  props: ['activeFilter', 'activeColorFilter', 'assortment'],
  components: {
    ColorCircle,
  },
  computed: {
    ...mapState({
      allMetaColors: (s) => s.platform.metaColors,
    }),
    availableMetaColors() {
      return Object.values(
        assortmentHelper
          .getTotalAvailableSellables(this.activeFilter)
          .filter((group) => group.primarySellable)
          .reduce((result, current) => {
            if (current.primarySellable.colors) {
              current.primarySellable.colors.forEach((color) => {
                color.metaColors.forEach((m) => {
                  result[m.metaColor] = m.metaColor;
                });
              });
            }

            return result;
          }, {})
      );
    },
  },
  methods: {
    apply() {
      analytics.logEvent('spx-bulk-color-apply', {
        color: this.activeColorFilter.name,
      });

      this.$emit('onBulkApply', this.activeColorFilter);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.color-filter {
  padding: 24px;

  .header {
    margin: 0 0 12px 0;
    display: flex;
    align-items: center;

    a {
      margin-left: auto;
      padding-left: 4px;
    }
  }

  h4 {
    margin: 0;
  }

  & > p {
    margin: 0;
    color: $grey60;
  }
}

ul {
  padding: 16px 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.clear-link {
  margin-left: 16px;
}
</style>
