export default {
  inserted: function (el, bindings) {
    const button = el.querySelector(bindings.value);
    if (button) {
      button.addEventListener('keydown', function (event) {
        if (event.keyCode === 188) {
          event.preventDefault();
          const e = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 'Enter',
            shiftKey: false,
          });
          button.dispatchEvent(e);
        }
      });
    }
  },
};
