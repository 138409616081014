export const STATE_PENDING = 'PENDING';
export const STATE_TO_BE_REMOVED = 'TO_BE_REMOVED';
export const STATE_SYNCED = 'SYNCED';

const STATE_TITLE_TRANSLATIONS = {
  [STATE_PENDING]: 'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_PENDING_TITLE',
  [STATE_TO_BE_REMOVED]:
    'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_REMOVE_TITLE',
  [STATE_SYNCED]: 'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_SYNCED_TITLE',
};

export const getStateTitle = (k) => STATE_TITLE_TRANSLATIONS[k];

const STATE_DESCRIPTION_TRANSLATIONS = {
  [STATE_PENDING]:
    'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_PENDING_DESCRIPTION',
  [STATE_TO_BE_REMOVED]:
    'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_REMOVE_DESCRIPTION',
  [STATE_SYNCED]:
    'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.STATES_SYNCED_DESCRIPTION',
};

export const getStateDescription = (k) => STATE_DESCRIPTION_TRANSLATIONS[k];

const STATE_COLORS = {
  [STATE_PENDING]: '#999999',
  [STATE_TO_BE_REMOVED]: '#C14E48',
  [STATE_SYNCED]: '#108043',
};

export const getStateColor = (state) => STATE_COLORS[state];

export const getStateListHtmlId = (state) => `${state}Tiles`;
