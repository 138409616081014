<template>
  <div class="tips-and-tools overflow-y">
    <div class="tips-and-tools-header">
      <h1>{{ $t('NAV.TIPS_AND_TOOLS') }}</h1>
      <p>{{ $t('TIPS_AND_TOOLS.DESCRIPTION') }}</p>
    </div>
    <div class="tips-and-tools-filter">
      <div class="tips-and-tools-pos-filter" v-if="partnerType === 'BOTH'">
        <Dropdown
          :items="pointOfSaleFilterItems"
          :value="currentPointOfSaleFilterItem"
          :itemKey="(item) => item.key"
          :itemToString="(posFilter) => posFilter.name"
          @change="setPointOfSaleFilter"
        >
        </Dropdown>
      </div>
    </div>
    <div class="tips-and-tools-content">
      <TipsAndToolsCards :articles="filteredArticles" />
    </div>
  </div>
</template>

<script>
import Dropdown from 'src/app/commons/Dropdown/Dropdown.vue';
import TipsAndToolsCards from 'src/app/partnerarea/tipsAndTools/TipsAndToolsCards.vue';
import localeService from 'src/app/commons/localeService/localeService';
import {
  articles,
  getHref,
  pointOfSaleKeys,
} from 'src/app/partnerarea/tipsAndTools/tipsAndToolsConfig';
import { mapGetters } from 'vuex';
import analytics from '@/tracking/analytics';

export default {
  name: 'TipsAndTools',
  components: {
    TipsAndToolsCards,
    Dropdown,
  },
  data() {
    return {
      selectedPointOfSaleFilterItem: null,
    };
  },
  methods: {
    setPointOfSaleFilter(pointOfSaleFilter) {
      analytics.logEvent('tips-tools-filter', {
        filter: pointOfSaleFilter.key,
      });

      this.currentPointOfSaleFilterItem = pointOfSaleFilter;
    },
  },
  computed: {
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
    filteredArticles() {
      const language = localeService.getLanguage();
      const domain = localeService.getSprdUrl();

      return articles
        .filter((article) => {
          return (
            !this.currentPointOfSaleFilterItem ||
            this.currentPointOfSaleFilterItem.key === pointOfSaleKeys.ALL ||
            article.pointOfSales.includes(this.currentPointOfSaleFilterItem.key)
          );
        })
        .map((article) => {
          const href = getHref(article, domain, language, SPRD.PLATFORM);

          return Object.assign({}, article, {
            headline: this.$t(article.headlineKey),
            description: this.$t(article.descriptionKey),
            onClick: article.path
              ? () => {
                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                  this.$router.push(article.path);
                }
              : () => {
                  window.open(href, '_blank');
                },
            enabled: article.path || href,
          });
        })
        .filter((article) => article.enabled);
    },
    pointOfSaleFilterItems() {
      let posFilterItems =
        this.partnerType === 'BOTH'
          ? [
              {
                key: pointOfSaleKeys.ALL,
                name: this.$t('DASHBOARD.POS_FILTER.ALL'),
              },
            ]
          : [];

      if (this.partnerType === 'BOTH' || this.partnerType === 'MP') {
        posFilterItems = [
          ...posFilterItems,
          ...[
            {
              key: pointOfSaleKeys.MP,
              name: this.$t('DASHBOARD.POS_FILTER.SPREADSHIRT'),
            },
          ],
        ];
      }

      if (this.partnerType === 'BOTH' || this.partnerType === 'SHOP') {
        posFilterItems = [
          ...posFilterItems,
          ...[
            {
              key: pointOfSaleKeys.SHOPS,
              name: this.$t('DASHBOARD.POS_FILTER.SHOPS'),
            },
          ],
        ];
      }

      return posFilterItems;
    },
    currentPointOfSaleFilterItem: {
      get: function () {
        return (
          this.selectedPointOfSaleFilterItem ||
          (this.pointOfSaleFilterItems && this.pointOfSaleFilterItems[0])
        );
      },
      set: function (selectedPointOfSaleFilterItem) {
        this.selectedPointOfSaleFilterItem = selectedPointOfSaleFilterItem;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.tips-and-tools {
  position: relative;
}

.tips-and-tools-header {
  max-width: 1400px;
  padding: 0 20px;
  margin: 24px auto;

  p {
    margin-top: 20px;
    font-size: 14px;
  }
}

.tips-and-tools-filter {
  max-width: 1400px;
  padding: 0 20px;
  margin: 0 auto;
}

.tips-and-tools-pos-filter {
  width: 250px;
}

.tips-and-tools-content {
  max-width: 1400px;
  padding: 0 5px;
  margin: 20px auto;
}
</style>
