import Vue from 'vue';
import { deepCopy } from '@/utils';
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import compositionHelper from '@/composition/compositionHelper';

const defaultState = {
  list: [],
  originalList: [],
  meta: {
    count: null,
    limit: null,
    offset: null,
  },
  filter: {
    page: 0,
    pageSize: 20,
  },
};

export default {
  namespaced: true,
  state: deepCopy(defaultState),
  getters: {
    totalCompositionCount: (state) => state.meta.count,
  },
  mutations: {
    reset(state) {
      Object.assign(state, defaultState);
    },
    setList(state, { compositions, meta }) {
      state.list = compositions.map(
        compositionHelper.addEmptyDataStructureForDefaultMetaData
      );
      state.meta = meta;
      state.originalList = deepCopy(state.list);
    },
    replaceComposition(state, composition) {
      Vue.set(
        state.list,
        state.list.findIndex((i) => i.id === composition.id),
        deepCopy(composition)
      );
    },
    updateOriginal(state) {
      state.originalList = deepCopy(state.list);
    },
    resetToOriginal(state) {
      state.list = deepCopy(state.originalList);
    },
    setFilter(state, filter) {
      Object.assign(state.filter, filter);
    },
    resetFilter(state) {
      state.filter = deepCopy(defaultState.filter);
    },
  },
  actions: {
    fetchCompositions: async ({ state, commit }) => {
      const {
        list: compositions,
        count,
        limit,
        offset,
      } = await publishingCoreService.getCompositions({
        limit: state.filter.pageSize,
        offset: state.filter.pageSize * state.filter.page,
      });

      commit('setList', { compositions, meta: { count, limit, offset } });
    },
    replaceComposition: ({ commit }, composition) => {
      commit('replaceComposition', composition);
      commit('updateOriginal');
    },
    deleteComposition: async ({ commit, dispatch, state }, compositionId) => {
      await publishingCoreService.deleteComposition(compositionId);
      if (state.list.length <= 1) {
        commit('setFilter', { page: 0 });
      }
      await dispatch('fetchCompositions');
    },
  },
};
