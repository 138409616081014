<template>
  <div class="loader">
    <img
      :src="`/images/loader${white ? '_white' : ''}.svg`"
      class="lovetab"
      alt="loading heart"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingHeart',
  props: {
    white: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.loader {
  color: $pa-color-main;
  width: 100%;
}

.lovetab {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
  animation: loader-pulse 1s ease-in-out infinite alternate;
}

@keyframes loader-pulse {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
