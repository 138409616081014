<template>
  <div class="dialog-size-dm inputDialog">
    <div>
      <div class="headingContainer">
        <h2 class="heading">
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.INPUT.TITLE') }}
        </h2>
      </div>
      <div class="socialChannelInputGroup">
        <LabelInput class="socialChannelInput">
          <label>{{ data.name }}</label>
          <input
            v-model="userInput"
            ref="input"
            type="text"
            spellcheck="false"
            @input="updateInput($event)"
            @keyup.enter="confirm"
          />
        </LabelInput>
      </div>
      <div>
        <p v-if="isError" class="text-danger">
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.INPUT.INVALID') }}
        </p>
        <p v-else class="text-grey">
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.INPUT.HINT') }}
        </p>
      </div>
    </div>
    <div class="btnGroup">
      <button class="btn btn-primary btn-lg btnGroupBtn" @click="confirm">
        {{ $t('GENERAL.SAVE') }}
      </button>
      <button class="btn btn-danger btn-lg btnGroupBtn" @click="onDelete">
        {{ $t('GENERAL.DELETE') }}
      </button>
      <a class="link-main" @click="dismiss">
        {{ $t('GENERAL.CANCEL') }}
      </a>
    </div>
  </div>
</template>

<script>
import LabelInput from '@/labelInput/LabelInput.vue';

export default {
  name: 'SocialMediaProfileInputDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  components: { LabelInput },
  data() {
    return {
      userInput: this.data.initial,
      isError: false,
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    parseAndValidateChannel(value) {
      if (!this.data.patterns) {
        if (this.validateChannel(value)) {
          this.modalInstance.close({ value });
          return true;
        }
        return false;
      }

      const result = this.data.patterns
        .map((rx) => {
          const res = rx.exec(value);
          if (res !== null) {
            return { value: res[1], rule: rx };
          }
          return null;
        })
        .find((res) => res !== null);

      if (result) {
        if (this.validateChannel(result.value)) {
          this.modalInstance.close(result);
          return true;
        }
      }
      return false;
    },
    validateChannel(value) {
      return value.length <= (this.data.charLength || 64);
    },
    updateInput({ target: { value } }) {
      this.userInput = value;
    },
    confirm() {
      if (this.parseAndValidateChannel(this.userInput)) {
        this.modalInstance.close();
      } else {
        this.isError = true;
      }
    },
    dismiss() {
      this.modalInstance.dismiss(true);
    },
    cancel() {
      this.modalInstance.dismiss();
    },
    onDelete() {
      this.data.onDelete();
      this.dismiss();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnGroup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  margin-top: 1.5em;
}

.btnGroupBtn {
  flex-grow: 1;
}

.socialChannelInputGroup {
  margin-bottom: 0.75em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.socialChannelInput {
  flex-grow: 1;
}

.headingContainer {
  margin-bottom: 2em;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
