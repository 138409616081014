<template>
  <div class="tracking-metrics-graph">
    <h3>{{ visitorCount }}</h3>
    <p class="text-grey date-range">{{ dateRange }}</p>
    <TimelineChart
      v-if="timelineData.length"
      class="visits-chart"
      :timeFilter="filters.time"
      :timelineData="timelineData"
      :height="300"
      type="Line"
    />
    <p class="tracking-metrics-footer text-grey">
      {{ $t('STATISTICS.TRACKING_METRICS.HINT') }}
    </p>
  </div>
</template>

<script>
import TimelineChart from 'src/app/components/charts/timeline/TimelineChart.vue';
import { mapState, mapGetters } from 'vuex';
import { subDays, isAfter, parseISO, format } from 'date-fns';

export default {
  name: 'TrackingMetricsGraph',
  components: {
    TimelineChart,
  },
  computed: {
    ...mapState({
      filters: (s) => s.metrics.filters,
    }),
    ...mapGetters({
      shopMetrics: 'metrics/shopMetrics',
      totalVisits: 'metrics/totalVisits',
    }),
    shopTraffic() {
      return this.shopMetrics.map((e) => ({
        date: format(new Date(e.date), 'yyyy-MM-dd'),
        value: e.visits,
      }));
    },
    timelineData() {
      // create array with length of timefilter duration, e.g. 7 (days)
      const array = [...Array(this.filters.time.duration)].map((_, index) => {
        // fill array with dates in the past
        const date = new Date(subDays(new Date(), index + 1));

        return {
          date: format(date, 'yyyy-MM-dd'),
          value: 0,
        };
      });

      array.sort((a, b) =>
        isAfter(parseISO(b.date), parseISO(a.date)) ? -1 : 1
      );

      return array.map(
        (item) => this.shopTraffic.find((day) => day.date === item.date) || item
      );
    },
    dateRange() {
      const first = this.timelineData[0];
      const last = this.timelineData[this.timelineData.length - 1];

      return `${this.date(first.date)} - ${this.date(last.date)}`;
    },
    visitorCount() {
      return `${this.sprdNumber(this.totalVisits)} ${this.$t(
        'STATISTICS.TRACKING_METRICS.VISITS_HEADING'
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.tracking-metrics-graph {
  margin-block: 24px;

  h3 {
    margin: 0 0 16px;
  }

  .visits-chart {
    margin-block: 24px;
  }
}
</style>
