<template>
  <div class="dialog-size-md rejection-reason-dialog">
    <h1>Reason for rejection</h1>
    <p>
      We can only accept designs that meet certain quality criteria and appeal
      to a wide range of potential customers. This is why we will reject designs
      if one of the following reasons apply. If you have any questions as to why
      your design was rejected, please get in touch with our legal service:
    </p>
    <a href="mailto:verify@spreadshirt.co.uk" class="link-main"
      >verify@spreadshirt.co.uk</a
    >
    <h4>{{ $t(name) }}</h4>
    <p>
      {{ $t(description) }}
    </p>
    <SmartFaqLink :link="smartFaqLinks.REJECTION_HELP" class="help-link" />
  </div>
</template>

<script>
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import {
  rejectionReasonName,
  rejectionReasonDescription,
} from '@/api/models/publishingCore/publishingCore';

export default {
  name: 'RejectionReasonDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  components: {
    SmartFaqLink,
  },
  data() {
    return {
      smartFaqLinks,
    };
  },
  computed: {
    name() {
      return rejectionReasonName(this.data.reason);
    },
    description() {
      return rejectionReasonDescription(this.data.reason);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.rejection-reason-dialog {
  h1 {
    margin: 0 0 12px 0;
  }

  h4 {
    margin: 24px 0 8px 0;
    color: $pa-color-red;
  }

  p {
    margin: 0 0 8px 0;
    color: $grey65;
  }
}
</style>
