import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

const HARDCHECK = {
  UNCHECKED: 'UNCHECKED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};
const SOFTCHECK = {
  UNCHECKED: 'UNCHECKED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};
const LETTER = {
  NONE: 'NONE',
  IN_PROGRESS: 'IN_PROGRESS',
};
const STATES = {
  ADDRESS_INPUT: 'ADDRESS_INPUT', // user can still enter address, but softcheck could be already failed with attempts left
  CONFIRM_NEEDED: 'CONFIRM_NEEDED', // user needs to confirm the current address
  FAILED: 'FAILED', // softcheck / hardcheck failed with no attempts left, user needs to contact costumer service
  CODE_INPUT: 'CODE_INPUT', // hardcheck letter sent, user needs to enter code BUT can still edit address if softcheck did not fully fail
  VALID: 'VALID', // current address is valid,
  DEACTIVATED: 'DEACTIVATED', // pdv is deactivated, e.g. for NA platform
};
const UI_STATES = {
  PDV_FAILED: 'PDV_FAILED',
  PDV_FULLY_FAILED: 'PDV_FULLY_FAILED',
  LETTER_SENT: 'LETTER_SENT',
};

const pdvService = {
  getUIState: function (validationState) {
    const state = pdvService.getCurrentState(validationState);
    if (state === pdvService.STATES.CODE_INPUT) {
      return UI_STATES.LETTER_SENT;
    } else if (
      state === pdvService.STATES.ADDRESS_INPUT &&
      pdvService.softCheckFailed(validationState)
    ) {
      return UI_STATES.PDV_FAILED;
    } else if (state === pdvService.STATES.FAILED) {
      return UI_STATES.PDV_FULLY_FAILED;
    }
    return null;
  },
  getCurrentState: function (validationState) {
    if (SPRD.PLATFORM === 'NA') {
      return STATES.DEACTIVATED;
    }

    if (validationState.isUserConfirmationRequired.state) {
      return STATES.CONFIRM_NEEDED;
    } else if (
      validationState.confirmationLetter.state === LETTER.IN_PROGRESS
    ) {
      return STATES.CODE_INPUT;
    } else if (
      validationState.softcheck.state === SOFTCHECK.SUCCEEDED &&
      validationState.hardcheck.state !== HARDCHECK.FAILED
    ) {
      return STATES.VALID;
    } else if (
      (validationState.softcheck.state === SOFTCHECK.FAILED &&
        validationState.softcheck.attemptsLeft <= 0) ||
      (validationState.hardcheck.state === HARDCHECK.FAILED &&
        validationState.hardcheck.attemptsLeft <= 0)
    ) {
      return STATES.FAILED;
    } else {
      return STATES.ADDRESS_INPUT;
    }
  },
  softCheckFullyFailed: function (validationState) {
    return (
      SPRD.PLATFORM === 'EU' &&
      validationState.softcheck.state === SOFTCHECK.FAILED &&
      validationState.softcheck.attemptsLeft <= 0
    );
  },
  softCheckFailed: function (validationState) {
    return (
      SPRD.PLATFORM === 'EU' &&
      validationState.softcheck.state === SOFTCHECK.FAILED
    );
  },
  softCheckSucceeded: function (validationState) {
    return (
      SPRD.PLATFORM === 'EU' &&
      validationState.softcheck.state === SOFTCHECK.SUCCEEDED
    );
  },
  STATES,
  HARDCHECK,
  SOFTCHECK,
  LETTER,
  UI_STATES,
  validateCode: function (code) {
    return apiClient.request({
      method: 'POST',
      url: `/address-check/partners/${store.state.user.data.id}/actions/hardcheck/validate`,
      data: {
        code,
      },
    });
  },
  acceptTermsAndConditions: function () {
    return apiClient.request({
      method: 'POST',
      url: `/address-check/partners/${store.state.user.data.id}/terms-and-conditions`,
    });
  },
  fetchState: function () {
    return apiClient
      .request({
        method: 'GET',
        url: `/address-check/partners/${store.state.user.data.id}/state`,
      })
      .then((res) => res.data);
  },
};

export default pdvService;
