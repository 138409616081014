<template>
  <div class="page-wrapper statistics">
    <nav
      class="page-header statistics-header navbar navbar-secondary navbar-default"
    >
      <div class="menu">
        <h1>{{ $t('NAV.STATISTICS') }}</h1>
        <ul>
          <li
            :class="{ active: currentMode === mode }"
            v-for="(mode, key) in MODES"
            :key="mode"
          >
            <router-link :to="`/statistics/${key.toLowerCase()}`">
              {{ $t(mode) }}
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div
      class="page-content statistics-content"
      v-if="currentMode"
      :key="$route.params.subpage"
    >
      <div class="overflow-y">
        <SpreadAppModule removable statistics class="spreadapp-module" />
        <Sales v-if="currentMode === MODES.SALES" />
        <Credit v-if="currentMode === MODES.CREDIT" />
        <Bestseller v-if="currentMode === MODES.BESTSELLERS" />
        <TrackingMetrics v-if="currentMode === MODES.TRACKING" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Sales from './sales/Sales.vue';
import Credit from './credit/Credit.vue';
import Bestseller from './bestseller/Bestseller.vue';
import TrackingMetrics from './trackingMetrics/TrackingMetrics.vue';
import SpreadAppModule from 'src/app/partnerarea/dashboard/SpreadAppModule.vue';

export default {
  name: 'Statistics',
  components: {
    Sales,
    Credit,
    Bestseller,
    TrackingMetrics,
    SpreadAppModule,
  },
  data() {
    return {
      MODES: {
        CREDIT: 'STATISTICS.CREDIT',
        SALES: 'STATISTICS.SALES',
        BESTSELLERS: 'STATISTICS.BESTSELLERS.NAV_ENTRY',
        TRACKING: 'STATISTICS.TRACKING_METRICS.NAV_ENTRY',
      },
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.ensureValidRoute(to);
  },
  async created() {
    if (!this.hasShops) {
      delete this.MODES.TRACKING;
    }

    await this.fetchShippingCountries();

    this.ensureValidRoute(this.$route);

    setTimeout(() => {
      if (window.smartbanner) {
        window.smartbanner.publish();
        setTimeout(() => {
          const el = document.querySelector('.smartbanner__button');
          if (el) {
            el.addEventListener('click', window.smartbanner.exit);
          }
        }, 500);
      }
    }, 1000);
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
    }),
    currentMode() {
      return this.MODES[this.$route.params?.subpage?.toUpperCase()];
    },
  },
  methods: {
    ...mapActions({
      fetchShippingCountries: 'platform/fetchShippingCountries',
    }),
    ensureValidRoute(route) {
      if (
        !route.params.subpage ||
        !this.MODES[route.params.subpage.toUpperCase()]
      ) {
        this.$nextTick(() => {
          this.$router.replace({
            params: { subpage: 'credit' },
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links.scss';

.statistics {
  display: flex;
  flex-direction: column;

  .statistics-header {
    .menu {
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 20px;
      height: 100%;
      display: flex;
      justify-content: start;
      position: relative;

      h1 {
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
      }

      ul {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden;

        li {
          position: relative;
          padding: 0;
          margin-left: 48px;

          a {
            color: $sprd-color-medium-grey;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            width: 100%;

            &:hover {
              color: $sprd-color-grey;
            }
          }

          &.active {
            a {
              color: inherit;
            }

            &:after {
              content: ' ';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 4px;
              background-color: $pa-color-main;
            }
          }
        }
      }
    }
  }

  .statistics-content > div {
    width: 100%;
    padding: 24px;
  }

  .pagination-wrapper {
    border-top: 1px solid $grey20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 10px;
    margin-top: 32px;

    .export,
    .scroll {
      margin-left: 4px;
      flex-shrink: 0;

      .icon {
        font-size: 2em;
        margin-right: 5px;
      }
    }

    .export a {
      display: flex;
      align-items: center;
    }

    .scroll {
      text-align: right;
      margin-left: auto;

      .icon {
        margin: 0 0 5px 0;
      }
    }
  }

  .empty-message {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon {
      padding: 5px;
      border: 1px solid $sprd-color-dark-grey;
      border-radius: 100%;
      color: $pa-color-red;
      height: 45px;
      width: 45px;
      margin-bottom: 15px;
    }
  }

  .spreadapp-module {
    margin-bottom: 24px !important;
  }
}
</style>
