<template>
  <p
    class="digital-products-legal-disclaimer"
    v-html="$t('DIGITAL_PRODUCTS.LEGAL_DISCLAIMER')"
  ></p>
</template>

<script>
export default {
  name: 'DigitalProductLegalDisclaimer',
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

.digital-products-legal-disclaimer {
  @extend .text-sm;

  color: $grey65;
  margin: 16px 0 0 0;

  :deep(a) {
    @extend .link-main;
  }
}
</style>
