<template>
  <a
    class="help-link link-blue icon-link"
    :class="{ 'icon-only': iconOnly }"
    :href="href"
    :target="href === '#' ? null : '_blank'"
  >
    <Icon v-if="!hideIcon" icon="question"></Icon>
    <span v-if="!iconOnly">
      {{ $t(label) }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'HelpLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    translationKey: {
      type: String,
      required: false,
    },
    iconOnly: {
      type: Boolean,
    },
    hideIcon: {
      type: Boolean,
    },
  },
  computed: {
    label() {
      return this.translationKey || 'GENERAL.GET_HELP';
    },
  },
};
</script>

<style lang="scss" scoped>
.help-link.icon-only {
  .icon {
    margin-right: 0 !important;
  }
}
</style>
