<template>
  <div class="setupHeader">
    <div class="setupHeaderSection">
      <img class="hat" alt="phd hat" src="/images/onboarding.svg" />
      <div class="headerTitle">
        {{ $t('POS.SHOP_SETTINGS.NAVIGATION.SETUP_WIZARD.HEADING') }}
      </div>
    </div>
    <div class="setupHeaderSection">
      <a
        href="#"
        @click.prevent="$emit('onDismiss')"
        class="dismissButton link-main icon-link"
      >
        <Icon icon="close" />
        {{ $t('POS.TEMPLATES.OVERVIEW.SKIP') }}
      </a>
      <button
        @click="$emit('onSave')"
        class="acceptButton btn btn-primary icon-btn"
      >
        <Icon icon="savedisk" />
        {{ $t('GENERAL.SAVE') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopSetupWizardHeader',
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.setupHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background-color: $grey0;
  border-bottom: 1px solid $grey20;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.16);
  z-index: 2;
}

.setupHeaderSection {
  display: flex;
  align-items: center;
}

.hat {
  width: 3rem;
  height: 3rem;
  background-color: $grey5;
  border-radius: 50%;
}

.headerTitle {
  font-weight: bold;
  margin-left: 1rem;
}

.acceptButton {
  height: 2.5rem;
}

.dismissButton {
  height: 2.5rem;
  margin-right: 1.5rem;
}
</style>
