<template>
  <div class="image-crop-dialog">
    <div class="modal-body">
      <ImageCrop
        id="imageCropper"
        :file="data.file"
        :width="data.width"
        :height="data.height"
        :cancel="modalInstance.dismiss"
        :save="save"
      />
    </div>
  </div>
</template>

<script>
import ImageCrop from 'src/app/commons/imageCrop/ImageCrop.vue';

export default {
  name: 'ImageCropDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  components: {
    ImageCrop,
  },
  methods: {
    save(imageBlob) {
      this.modalInstance.close(imageBlob);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-crop-dialog {
  margin: -5px;
}
</style>
