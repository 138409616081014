<template>
  <div class="modal-container help-dialog">
    <div class="modal-body">
      <h1>{{ $t('HELP.HEADING') }}</h1>
      <div v-if="userId">
        <p class="id-explanation">{{ $t('HELP.ID_EXPLANATION') }}</p>
        <div class="copy-wrapper">
          <Copyable id="helpdialog-pid" :content="userId" inline>
            <p class="userid">
              {{ $t('DASHBOARD.USER_ID') }}:
              <strong>{{ userId }}</strong>
            </p>
          </Copyable>
        </div>
      </div>
      <div class="choices">
        <div class="mail">
          <img src="/images/mail.svg" />
          <div class="mails">
            <h3>
              <a :href="mailTo">{{ mail }}</a>
            </h3>
          </div>
          <p>{{ $t('HELP_PANEL.EMAIL_ANSWER_DURATION') }}</p>
        </div>
        <div class="phone">
          <img src="/images/customer-service.svg" />
          <h3>{{ phone }}</h3>
          <p>{{ $t('PDV.FAILED.PHONE_TIMES') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import localeService from 'src/app/commons/localeService/localeService';
import contactData from 'src/app/commons/contactData/contactData';
import Copyable from 'src/app/commons/copyable/Copyable.vue';

export default {
  name: 'HelpDialog',
  props: ['modalInstance', 'data'],
  components: {
    Copyable,
  },
  data() {
    return {
      phone: contactData.getPhoneForCountry(localeService.getCountry()),
    };
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
    mail() {
      return contactData.getBestMailForPartnerType(this.partnerType);
    },
    mailTo() {
      return `mailTo:${this.mail}?subject=(Spread-ID ${this.userId})`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

h1,
h3 {
  margin: 0;
}

.copy-wrapper {
  display: flex;
}

.userid {
  margin: 0;
}

.id-explanation {
  display: block;
  color: $grey60;
  margin: 16px 0 8px 0;
}

img {
  width: 120px;
  margin-bottom: 16px;
}

.choices {
  margin-top: 24px;
  display: flex;

  & > div {
    width: 50%;
    min-width: 350px;
    background-color: $grey5;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }

    p {
      margin: 16px 0 0 0;
      color: $grey60;
      text-align: center;
    }
  }

  .mails {
    a {
      color: $grey80;
    }

    h3:nth-child(2) {
      margin-top: 12px;
    }
  }
}
</style>
