<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.EMPTY.HEADING"
    subHeading="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.EMPTY.SUBHEADING"
    ctaIcon="shop"
    ctaText="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.EMPTY.CONFIGURE"
    ctaTarget="shop-area.advanced.header-social-media-profile"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'NoSocialMediaProfilePlaceholder',
  mixins: [StartPageData],
  components: {
    NoStuffPlaceholder,
  },
  computed: {
    display() {
      return !this.startPage.followUsPossible;
    },
  },
};
</script>
