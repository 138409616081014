<template>
  <div>
    <ToggleSwitch
      class="sprd-toggle-switch--first"
      v-model="settings.searchEnabled"
      label="SA.pages.productList.showSearch.label"
    />
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';

export default {
  name: 'Design',
  mixins: [SettingsData],
  components: { ToggleSwitch },
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
};
</script>

<style lang="scss" scoped>
:deep(.sprd-toggle-switch) {
  font-size: 16px;
}
</style>
