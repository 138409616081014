import { deepCopy } from '@/utils';
import store from '@/store/Store';

export default {
  getMetaDataPart({ composition, part, language }) {
    const metaData = composition.metaData[part];

    return metaData.find(
      (value) =>
        value.language === (language || composition.metaData.originLanguageCode)
    );
  },
  getDefaultMetaData(composition) {
    return {
      name: this.getMetaDataPart({ composition, part: 'name' }),
      description: this.getMetaDataPart({ composition, part: 'description' }),
      tags: this.getMetaDataPart({ composition, part: 'tags' }),
    };
  },
  addEmptyDataStructureForDefaultMetaData(composition) {
    const comp = deepCopy(composition);

    if (
      !comp.metaData.name?.find(
        (v) => v.language === composition.metaData.originLanguageCode
      )
    ) {
      comp.metaData.name = [
        ...(comp.metaData.name || []),
        {
          language: comp.metaData.originLanguageCode,
          value: '',
        },
      ];
    }
    if (
      !comp.metaData.description?.find(
        (v) => v.language === composition.metaData.originLanguageCode
      )
    ) {
      comp.metaData.description = [
        ...(comp.metaData.description || []),
        {
          language: comp.metaData.originLanguageCode,
          value: '',
        },
      ];
    }
    if (
      !comp.metaData.tags?.find(
        (v) => v.language === composition.metaData.originLanguageCode
      )
    ) {
      comp.metaData.tags = [
        ...(comp.metaData.tags || []),
        {
          language: comp.metaData.originLanguageCode,
          values: [],
        },
      ];
    }

    return comp;
  },
  getDesignImage(composition) {
    return store.state.publishingCore.config?.image?.templates?.design?.replace(
      '$DESIGN',
      composition.mainDesign?.id
    );
  },
  getProductImage({ vpString, defaultViewId, viewId }) {
    return store.state.publishingCore.config?.image?.templates?.product
      ?.replace('$VPSTRING', vpString)
      ?.replace('$VIEW', viewId || defaultViewId);
  },
  getProductTypeImage({ productTypeId, defaultViewId, viewId, appearanceId }) {
    return store.state.publishingCore.config?.image?.templates?.productType
      ?.replace('$PRODUCTTYPE', productTypeId)
      ?.replace('$VIEW', viewId || defaultViewId || 1)
      ?.replace('$APPEARANCE', appearanceId);
  },
  getCompositionImage({ vpString, defaultViewId, viewId }) {
    return store.state.publishingCore.config?.image?.templates?.composition
      ?.replace('$VPSTRING', vpString)
      ?.replace('$VIEW', viewId || defaultViewId);
  },
  getAllDeactivatedSellablesFlat(composition) {
    if (!composition?.sellableErrors?.reasons) {
      return;
    }

    return Object.entries(composition.sellableErrors.reasons).reduce(
      (result, [reason, sellableList]) => {
        return result.concat(
          sellableList.map((sellable) => ({
            ...sellable,
            reason,
          }))
        );
      },
      []
    );
  },
  getAllActivatedSellableImages(composition) {
    return composition.sellables.map((sellable) =>
      this.getProductImage(sellable)
    );
  },
  getAppearanceGroupOfAppearanceId(appearanceId) {
    const groups = store.state.publishingCore.appearanceGroups;
    const appearanceGroupTranslations =
      store.state.publishingCore.translations.appearanceGroups;

    if (!groups) {
      return null;
    }

    const group = groups.find((group) =>
      group.appearanceIds.includes(appearanceId)
    );

    return group
      ? {
          ...group,
          name: appearanceGroupTranslations
            ? appearanceGroupTranslations[group.id]?.name
            : null,
        }
      : null;
  },
  getDefaultHotspot(composition) {
    return composition.defaultValues.defaultHotspot;
  },
  getDefaultAppearanceGroup(composition) {
    const appearanceGroupTranslations =
      store.state.publishingCore.translations.appearanceGroups;

    const defaultAppearanceGroup =
      composition.defaultValues.defaultAppearanceGroup;
    return !defaultAppearanceGroup
      ? null
      : {
          ...defaultAppearanceGroup,
          name: appearanceGroupTranslations
            ? appearanceGroupTranslations[defaultAppearanceGroup.id]?.name
            : null,
        };
  },
  getDisabledAppearanceGroupIds(composition) {
    return composition.defaultValues.restrictedAppearanceGroups.map(
      (appearanceGroup) => appearanceGroup.id
    );
  },
};
