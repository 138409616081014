<template>
  <div class="ai-design-generator-error">
    <h2>{{ $t('AI_DESIGN_GENERATOR.ERROR.HEADING') }}</h2>
    <p>{{ $t(text) }}</p>
    <div class="actions">
      <button v-if="retry" class="btn btn-primary" @click="$emit('onRetry')">
        {{ $t('GENERAL.RETRY') }}
      </button>
      <button v-else class="btn btn-primary" @click="$emit('onClose')">
        {{ $t('GENERAL.CLOSE') }}
      </button>
      <a v-if="feedback" :href="mailToLink" class="link link-main icon-link">
        <Icon icon="speech-bubble" /> {{ $t('GENERAL.GIVE_FEEDBACK') }}
      </a>
    </div>
  </div>
</template>

<script>
import contactData from '@/contactData/contactData';
import { mapState } from 'vuex';

export default {
  name: 'AiDesignGeneratorError',
  props: {
    error: {
      type: String,
    },
  },
  computed: {
    retry() {
      return ['CONTENT_POLICY', 'GLOBAL_RATE_LIMIT_EXCEEDED'].includes(
        this.error
      );
    },
    feedback() {
      return !this.retry;
    },
    text() {
      switch (this.error) {
        case 'CONTENT_POLICY':
          return 'AI_DESIGN_GENERATOR.ERROR.CONTENT_POLICY';
        case 'GLOBAL_RATE_LIMIT_EXCEEDED':
          return 'AI_DESIGN_GENERATOR.ERROR.GLOBAL_RATE_LIMIT_EXCEEDED';
        case 'IDEA_CREATING_ISSUE':
          return 'AI_DESIGN_GENERATOR.ERROR.IDEA_CREATION_ISSUE';
        default:
          return 'AI_DESIGN_GENERATOR.ERROR.GENERIC';
      }
    },
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    mailToLink() {
      const mail = contactData.getShopMail();

      return `mailTo:${mail}?bcc=partnerapp@spreadshirt.net&subject=AI Design Generator Feedback (Spread-ID ${this.userId})`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

h2 {
  margin: 0 0 8px 0;
}

p {
  margin: 0;
}

.actions {
  display: flex;
  align-items: center;
  margin: 24px 0 0 0;

  .btn-primary {
    padding: 12px 24px;
    margin: 0 16px 0 0;
  }
}
</style>
