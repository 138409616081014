import colors from 'src/scss/styleguide/_exports.scss';

const IDEA_STATES = {
  PROCESSING: {
    key: 'PROCESSING',
    class: 'idea-state-processing',
    name: 'DESIGNS.STATUS.PROCESSING',
    description: 'DESIGNS.STATUS.PROCESSING_DESCRIPTION',
    icon: 'gear',
    color: colors.grey60,
  },
  ATTENTION: {
    key: 'NEEDS_ATTENTION',
    class: 'idea-state-attention',
    name: 'DESIGNS.STATUS.ATTENTION',
    description: 'DESIGNS.STATUS.ATTENTION_DESCRIPTION',
    icon: 'attention',
    color: colors.paColorYellow,
  },
  UNPUBLISHED: {
    key: 'NEW',
    class: 'idea-state-unpublished',
    name: 'DESIGNS.STATUS.UNPUBLISHED',
    description: 'DESIGNS.STATUS.UNPUBLISHED_DESCRIPTION',
    icon: 'star',
    color: colors.paColorMain,
  },
  PENDING: {
    key: 'PENDING',
    class: 'idea-state-pending',
    name: 'DESIGNS.STATUS.REVIEW',
    description: 'DESIGNS.STATUS.REVIEW_DESCRIPTION',
    icon: 'hourglass',
    color: colors.grey60,
  },
  OFFLINE: {
    key: 'OFFLINE',
    class: 'idea-state-pending',
    name: 'DESIGNS.STATUS.REVIEW',
    description: 'DESIGNS.STATUS.REVIEW_DESCRIPTION',
    icon: 'hourglass',
    color: colors.grey60,
  },
  PUBLISHED: {
    key: 'INVOLVED',
    class: 'idea-state-published',
    name: 'DESIGNS.STATUS.PUBLISHED',
    description: 'DESIGNS.STATUS.PUBLISHED_DESCRIPTION',
    icon: 'rocket',
    color: colors.paColorGreen,
  },
  REJECTED: {
    key: 'REJECTED',
    class: 'idea-state-rejected',
    name: 'DESIGNS.STATUS.REJECTED',
    description: 'DESIGNS.STATUS.REJECTED_DESCRIPTION',
    icon: 'rocket-crashed',
    color: colors.paColorRed,
  },
};

const functionObj = {
  isProcessing: function () {
    return this.key === IDEA_STATES.PROCESSING.key;
  },
  isAttention: function () {
    return this.key === IDEA_STATES.ATTENTION.key;
  },
  isUnpublished: function () {
    return this.key === IDEA_STATES.UNPUBLISHED.key;
  },
  isPublished: function () {
    return this.key === IDEA_STATES.PUBLISHED.key;
  },
  isRejected: function () {
    return this.key === IDEA_STATES.REJECTED.key;
  },
  isPending: function () {
    return this.key === IDEA_STATES.PENDING.key;
  },
  is: function (state) {
    return this.key === state.key;
  },
  isIn: function (states) {
    return states.some((state) => this.key === state.key);
  },
};

Object.values(IDEA_STATES).forEach((state) => {
  Object.assign(state, functionObj);
});

export default IDEA_STATES;
