<template>
  <div>
    <ShopTracking
      :toggle-value="tracking.tiktokEnabled"
      :tracking-id-value="tracking.tiktokPixelId"
      @toggleInput="tracking.tiktokEnabled = $event"
      @trackingIdInput="tracking.tiktokPixelId = $event"
      toggle-label="TikTok Pixel"
      paragraph-text="POS.SHOP_SETTINGS.PINTEREST_MKT.TRACKING_PARAGRAPH"
      input-label="TikTok Pixel"
      :smart-faq-link="smartFaqLink"
      regex="^[A-Za-z0-9]+$"
    />
  </div>
</template>

<script>
import { TrackingData } from 'src/app/partnerarea/pos/shop/shopSavable/TrackingData';
import ShopTracking from './ShopTracking.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'TikTokMarketing',
  mixins: [TrackingData],
  components: {
    ShopTracking,
  },
  data() {
    return {
      smartFaqLink: smartFaqLinks.TIKTOK_TRACKING_PIXEL,
    };
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
};
</script>
