import store from '@/store/Store';
import { deepCopy } from '@/utils';
import router from 'src/app/router/router';

const collectionSaving = {
  async save() {
    if (!collectionSaving.validate()) {
      return Promise.reject();
    }
    await store.dispatch('shop/saveCollection');
    await store.dispatch('shop/saveCollectionChanges');
    await store.dispatch('shop/saveShopSavable');

    // this.search();
  },
  reset() {
    if (store.state.shop.selectedCollection.id) {
      store.commit('shop/resetCollection');
    } else {
      collectionSaving.deletion();
    }
  },
  dirty() {
    const collectionsDirty =
      (store.state.shop?.selectedCollection &&
        !!Object.entries(store.state.shop.selectedCollection).find(
          ([key, value]) =>
            value !== store.state.shop.originalSelectedCollection[key]
        )) ||
      store.state.shop.collectionContentChanges.ideaIdsToAdd.length > 0 ||
      store.state.shop.collectionContentChanges.ideaIdsToRemove.length > 0;
    return collectionsDirty || store.getters['shop/shopSavableDirty'];
  },
  async deletion() {
    const updatedCollections = deepCopy(
      store.state.shop.shopSavable.clientData.collections.collectionList.filter(
        (c) => c.id !== store.state.shop.selectedCollection.id
      )
    );
    await store.dispatch('shop/deleteCollection');
    store.commit('shop/updateSectionOriginalData', {
      sectionKey: 'collections',
      origData: { collectionList: updatedCollections },
    });

    router.push({ name: 'shop-area.pages.collections' });
  },
  validate() {
    store.commit(
      'shop/validateShopSavableSubset',
      'shop-area.pages.collections-detail'
    );
    return !store.getters['shop/hasShopSavableValidationErrors'];
  },
};

export default collectionSaving;
