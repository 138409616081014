<template>
  <div class="bestseller-world-map-tooltip">
    <p class="country-name">{{ name }}</p>
    <p class="atlnts" v-if="countryId === 'ATLNTS'">
      {{ $t('STATISTICS.BESTSELLERS.MAP.ATLANTIS') }}
    </p>
    <h3 class="sales" v-else>{{ sales }}</h3>
  </div>
</template>

<script>
export default {
  name: 'BestsellerWorldMapTooltip',
  props: {
    countryId: {
      type: String,
    },
    name: {
      type: String,
    },
    sales: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';

.bestseller-world-map-tooltip {
  padding: 24px;
  min-width: 200px;

  .country-name {
    margin: 0;
  }

  .sales,
  .atlnts {
    margin: 12px 0 0 0;
  }
}
</style>
