import store from '@/store/Store.js';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

let _registeredComponents = [];
let _userCheck = true;

function tearDown() {
  _registeredComponents = [];
}

function openDialog() {
  return dialogService.openDialog(ConfirmDialog, {
    heading: 'DIALOGS.CONFIRM_UNSAVED.HEADING',
    cancel: 'GENERAL.DISCARD',
    confirm: 'GENERAL.APPLY',
  });
}

export default {
  async onRouteUpdate(to, from) {
    if (
      !_registeredComponents.length ||
      (_userCheck &&
        (!store.state.user.data.id || store.state.user.isUserLoggingOut))
    ) {
      return true;
    }

    const unsavedComponents = _registeredComponents.filter(function (
      component
    ) {
      if (
        component.excludeStates &&
        component.excludeStates.indexOf(to.name) > -1
      ) {
        return false;
      } else {
        return component.check();
      }
    });

    if (unsavedComponents.length) {
      if (!from.meta) {
        from.meta = {};
      }
      from.meta.hasUnsavedComponents = true;
      try {
        await openDialog();
        const promises = [];
        unsavedComponents.forEach(function (component) {
          promises.push(component.save());
        });

        try {
          await Promise.all(promises);
          tearDown();
          from.meta.hasUnsavedComponents = false;
          return true;
        } catch (error) {
          return false;
        }
      } catch (options) {
        if (options?.reset) {
          unsavedComponents.forEach(function (component) {
            if (component.discard) {
              component.discard();
            }
          });

          tearDown();
          from.meta.hasUnsavedComponents = false;
          return true;
        }
      }
    } else {
      tearDown();
    }

    return true;
  },
  registerComponent: function (component) {
    _registeredComponents.push(component);
  },
  unregisterComponent: function (component) {
    const index = _registeredComponents.findIndex((c) => c === component);
    if (index >= 0) {
      _registeredComponents.splice(index, 1);
    }
  },
  triggerCheck: function ({ check, save, cancel, unchanged }) {
    if (check()) {
      openDialog().then(
        () => {
          save();
        },
        (reset) => {
          if (reset) {
            cancel();
          }
        }
      );
    } else {
      unchanged();
    }
  },
};
