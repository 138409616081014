<template>
  <div
    class="application"
    id="application"
    :class="`${currentState} ${
      currentState.includes('state-shop-area-') ? 'state-shop-area' : ''
    }`"
  >
    <div
      class="loader"
      style="
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
        z-index: 1040;
      "
    >
      <img
        src="/images/loader.svg"
        class="sprd-loader"
        style="width: 100px; height: 100px"
      />
    </div>

    <div class="main-content-container">
      <Navbar />

      <div id="main-content" class="clearfix">
        <GlobalToasts />
        <router-view></router-view>
      </div>

      <HelpPanel />
    </div>

    <DialogWrapper />
  </div>
</template>

<script>
import Navbar from 'src/app/partnerarea/navbar/Navbar.vue';
import DialogWrapper from '@/dialogs/wrapper/DialogWrapper.vue';
import GlobalToasts from 'src/app/components/toast/GlobalToasts.vue';
import HelpPanel from 'src/app/components/helpPanel/HelpPanel.vue';
import applicationInitialization from './config/applicationInitialization';

export default {
  name: 'App',
  components: {
    Navbar,
    DialogWrapper,
    HelpPanel,
    GlobalToasts,
  },
  created() {
    applicationInitialization.onAppStart();
  },
  computed: {
    currentState() {
      return this.$route.name?.length
        ? `state-${this.$route.name.replace(/\./g, '-')}`
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

#main-content {
  position: relative;
}

#main-content > div {
  height: 100%;
}
</style>
