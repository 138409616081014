<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.LISTPAGE.EMPTY.HEADING"
    subHeading="POS.SHOP_SETTINGS.LISTPAGE.EMPTY.SUBHEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
    :faqLink="faqLink"
    ctaIcon="shirt"
    ctaText="POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_PREVIEW.EMPTY.LINK_UPLOAD"
    ctaTarget="partnerarea.ideas"
  />
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import NoStuffPlaceholder from './NoStuffPlaceholder.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'NoProductsPlaceholder',
  mixins: [SettingsData],
  components: {
    NoStuffPlaceholder,
  },
  data() {
    return {
      faqLink: smartFaqLinks.IDEA_CREATION_HELP,
    };
  },
  computed: {
    display() {
      // this flag actually means there is nothing published to the shop yet
      return this.config.shopCurrencyEditable === true;
    },
  },
};
</script>
