import vClickOutside from 'click-outside-vue3';
import FloatingVue from 'floating-vue';
import Icon from 'src/app/commons/icon/component/Icon.vue';
import VueTheMask from 'vue-the-mask';

import date from '@/date/date';
import sprdCurrency from '@/currency/filter/currencyFilter';
import sprdNumber from '@/number/numberFilter';
import trackClickEvent from '@/tracking/trackClickEvent';
import maxInputLength from '@/maxInputLength/maxInputLength';
import selectOnBlur from '@/tagInput/selectOnBlur/selectOnBlur';
import selectOnComma from '@/tagInput/selectOnComma/selectOnComma';
import selectOnWhitespace from '@/tagInput/selectOnWhitespace/selectOnWhitespace';
import selectOnClick from '@/selectOnClick/selectOnClick';
import { i18n } from '@/translate/i18n';

export default {
  install(app) {
    app.use(i18n);
    app.use(FloatingVue, {
      themes: {
        'tooltip-dark': {
          $extend: 'tooltip',
        },
        'tooltip-navbar': {
          $extend: 'tooltip',
        },
      },
    });
    app.use(vClickOutside);
    app.use(VueTheMask);

    app.component('Icon', Icon);

    app.mixin({
      methods: {
        sprdCurrency: sprdCurrency,
        sprdNumber: sprdNumber,
        date: date,
      },
    });

    app.directive('trackClickEvent', trackClickEvent);
    app.directive('maxInputLength', maxInputLength);
    app.directive('selectOnBlur', selectOnBlur);
    app.directive('selectOnComma', selectOnComma);
    app.directive('selectOnWhitespace', selectOnWhitespace);
    app.directive('selectOnClick', selectOnClick);
  },
};
