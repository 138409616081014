export const USERTEST_VARIANT = {
  NEW_PUBLISHING_JOURNEY_ONLY: {
    key: 'NEW_PUBLISHING_JOURNEY_ONLY',
    paramValue: 'new',
  },
  OLD_PUBLISHING_JOURNEY_ONLY: {
    key: 'OLD_PUBLISHING_JOURNEY_ONLY',
    paramValue: 'old',
  },
};

export let currentUsertest = null;

function setUsertest(variant) {
  currentUsertest = variant;
  localStorage.setItem('usertest', variant.key);
}

function removeUsertest() {
  currentUsertest = null;
  localStorage.removeItem('usertest');
}

export async function initUsertest() {
  if (window.ORIGINAL_ENTRY_URL?.includes('usertest=none')) {
    removeUsertest();
    return;
  }

  const usertestFromParam =
    USERTEST_VARIANT[
      Object.values(USERTEST_VARIANT).find((variant) =>
        window.ORIGINAL_ENTRY_URL?.includes(`usertest=${variant.paramValue}`)
      )?.key
    ];
  if (usertestFromParam) {
    setUsertest(usertestFromParam);
  } else {
    const previousUsertest = USERTEST_VARIANT[localStorage.getItem('usertest')];
    if (previousUsertest) {
      setUsertest(previousUsertest);
    }
  }
}
