<template>
  <div class="digital-product-infos">
    <div class="info-row">
      <div>
        <strong>ID:</strong>
      </div>
      <div>{{ digitalProduct.id }}</div>
    </div>
    <div class="info-row">
      <div>
        <strong>{{ $t('DESIGNS.CREATED') }}: </strong>
      </div>
      <div>
        {{ date(digitalProduct.createdAt, 'short') }}
      </div>
    </div>
    <div class="info-row">
      <div>
        <strong>{{ $t('DESIGNS.MODIFIED') }}: </strong>
      </div>
      <div>
        {{ date(digitalProduct.workingState.modifiedAt, 'short') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DigitalProductInfos',
  props: {
    digitalProduct: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.digital-product-infos {
  text-align: left;
}

.info-row {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
