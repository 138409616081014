<template>
  <div>
    <p>{{ $t('ACCOUNT.TAXATION.EU_DE_COMPANY.HEADING') }}</p>
    <p>{{ $t('ACCOUNT.TAXATION.EU_DE_COMPANY.INPUT_EXPLANATION1') }}</p>
    <div class="tax-id" :class="{ 'has-error': validation.errors.taxId }">
      <LabelInput>
        <label for="tax-id">{{
          $t('ACCOUNT.TAXATION.EU_DE_COMPANY.TAX_ID')
        }}</label>
        <div class="position-relative">
          <DebounceInput
            id="tax-id"
            type="text"
            :model-value="taxationData.taxId"
            :placeholder="obfuscatedData.taxId"
            @update:model-value="setTaxId"
          />
          <a
            v-if="!taxationData.taxId && obfuscatedData.taxId"
            href
            class="input-clear-button"
            @click.prevent="clearObfuscatedField({ field: 'taxId' })"
          >
            <Icon icon="close"></Icon>
          </a>
        </div>
        <AccountError
          v-if="validation.errors.taxId"
          :reason="validation.errors.taxId.reason"
        ></AccountError>
      </LabelInput>
    </div>
    <p class="taxid-separator">
      {{ $t('ACCOUNT.TAXATION.EU_DE_COMPANY.INPUT_EXPLANATION2') }}
    </p>
    <div class="vat-id" :class="{ 'has-error': validation.errors.vatId }">
      <Dropdown
        :items="countries"
        class="taxation-country"
        :item-key="(country) => country.countryCode"
        :item-to-string="
          (country) => `${country.countryCode} - ${country.name}`
        "
        :selected-item-to-string="(country) => country.countryCode"
        :value="currentTaxCountry"
        @change="setTaxCountry"
      >
      </Dropdown>
      <LabelInput>
        <label for="vat-id"
          >{{ $t('ACCOUNT.TAXATION.EU_DE_COMPANY.VAT_ID') }}:</label
        >
        <div class="position-relative">
          <DebounceInput
            id="vat-id"
            type="text"
            :model-value="taxationData.vatId"
            :placeholder="obfuscatedData.vatId"
            @update:model-value="setVatId"
          />
          <a
            v-if="!taxationData.vatId && obfuscatedData.vatId"
            href
            class="input-clear-button"
            @click.prevent="clearObfuscatedField({ field: 'vatId' })"
          >
            <Icon icon="close"></Icon>
          </a>
        </div>
        <AccountError
          v-if="validation.errors.vatId"
          :reason="validation.errors.vatId.reason"
        ></AccountError>
      </LabelInput>
    </div>
    <p class="taxid-separator">
      {{ $t('ACCOUNT.TAXATION.EU_DE_COMPANY.INPUT_EXPLANATION3') }}
    </p>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import AccountError from './../../errorMessage/AccountError.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'CompanyDE',
  components: {
    AccountError,
    LabelInput,
    Dropdown,
    DebounceInput,
  },
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      taxationData: (state) => state.userTaxation.data,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
      countries: (state) => state.userTaxation.countries,
    }),
    ...mapGetters({
      currentTaxCountry: 'userTaxation/currentTaxCountry',
    }),
  },
  methods: {
    ...mapMutations({
      updateTaxId: 'userTaxation/setTaxId',
      updateVatId: 'userTaxation/setVatId',
      updateTaxCountry: 'userTaxation/setTaxCountry',
      clearObfuscatedField: 'userTaxation/clearObfuscatedField',
    }),
    setTaxId(taxId) {
      this.updateTaxId({ taxId });
    },
    setVatId(vatId) {
      this.updateVatId({ vatId });
    },
    setTaxCountry(country) {
      this.updateTaxCountry({ countryCode: country.countryCode });
    },
  },
};
</script>

<style lang="scss" scoped>
.vat-id,
.tax-id {
  display: flex;
}

.tax-id {
  margin-top: 30px;
}

.tax-id .labelInput,
.vat-id .labelInput {
  width: 250px;
}

.taxid-separator {
  margin: 16px 0;
}

.taxation-country {
  margin-right: 10px;
}

.vat-id-input-container {
  display: flex;
}
</style>
