<template>
  <div>
    <div class="loader" v-if="!initialized || !updatesChecked">
      <img src="/images/loader.svg" />
    </div>
    <div v-else class="page-wrapper idea-assortment-update">
      <nav class="page-header navbar navbar-secondary navbar-default">
        <div class="menu">
          <h3>{{ $t('BULK_ASSORTMENT.HEADING') }}</h3>
          <div v-if="updateInProgress">
            <button @click="close" class="btn btn-primary">
              {{ $t('GENERAL.CLOSE') }}
            </button>
          </div>
          <div v-if="!updateInProgress && displayCatalog">
            <div class="counter">
              {{ $t('GENERAL.SELECTED') }}:
              <strong>
                {{
                  currentSelectedSellables ? currentSelectedSellables.length : 0
                }}
              </strong>
              /5
            </div>
            <a href="#" class="link-main" @click.prevent="closeCatalog()">{{
              $t('GENERAL.CANCEL')
            }}</a>
            <button
              @click="saveCatalogSelection"
              class="btn btn-primary"
              :disabled="!currentSelectedSellables"
            >
              {{ $t('BULK_ASSORTMENT.PT_SELECTION_CTA') }}
            </button>
          </div>
          <div v-if="!updateInProgress && !displayCatalog">
            <a href="#" @click.prevent="close" class="link-main">{{
              $t('GENERAL.CANCEL')
            }}</a>
            <button
              @click="update"
              class="btn btn-primary"
              :disabled="!selectedProducts.length || !this.selectedPos.length"
            >
              {{ $t('BULK_ASSORTMENT.UPDATE_CTA') }}
            </button>
          </div>
        </div>
      </nav>
      <div class="page-content">
        <div v-if="!displayCatalog" class="overflow-y">
          <div>
            <div class="content-panel">
              <div class="explanation">
                <p class="text-grey">
                  {{ $t('BULK_ASSORTMENT.EXPLANATION1') }}
                </p>
                <p class="text-grey">
                  {{ $t('BULK_ASSORTMENT.EXPLANATION2') }}
                </p>
              </div>
              <div class="queue" v-if="queueSize > 30">
                <div class="icon-wrapper">
                  <Icon icon="hourglass" />
                </div>
                <div>
                  <h4>
                    {{ $t('BULK_ASSORTMENT.HIGH_TRAFFIC.HEADING') }}
                  </h4>
                  <p>{{ $t('BULK_ASSORTMENT.HIGH_TRAFFIC.TEXT') }}</p>
                </div>
              </div>
              <div class="selected-products">
                <h3>
                  {{ $t('BULK_ASSORTMENT.PRODUCT_TYPE_HEADING') }}
                </h3>
                <div class="products-wrapper">
                  <div
                    v-for="selectedProduct in selectedProducts"
                    :key="selectedProduct.filerId"
                    class="product"
                  >
                    <div class="product-image">
                      <SprdImage
                        :src="`${selectedProduct.primarySellable.defaultImageUrl},appearanceId=${selectedProduct.primarySellable.appearanceId}`"
                        :alt="selectedProduct.name"
                      />
                    </div>

                    <p>{{ selectedProduct.name }}</p>
                  </div>
                </div>
                <button class="btn btn-light" @click="openCatalog()">
                  {{
                    selectedProducts.length
                      ? $t('BULK_ASSORTMENT.CHANGE_PRODUCT')
                      : $t('BULK_ASSORTMENT.SELECT_PRODUCT')
                  }}
                </button>
              </div>
              <div
                class="pos"
                v-if="
                  (mpEnabled && shops.length) ||
                  (!mpEnabled && shops.length > 1)
                "
              >
                <h3>{{ $t('BULK_ASSORTMENT.POS_HEADING') }}</h3>
                <div
                  v-if="mpEnabled"
                  class="pos-item"
                  :class="{ selected: mp.selected }"
                  @click.prevent="togglePos(mp)"
                >
                  <MarketplaceLogo :disabled="!mp.selected" />
                  <ToggleSwitch :model-value="mp.selected" />
                </div>
                <div
                  class="pos-item"
                  :class="{ selected: generalShopSelectionStatus }"
                  @click.prevent="toggleAllShops"
                >
                  <SpreadshopLogo :disabled="!generalShopSelectionStatus" />
                  <ToggleSwitch :model-value="generalShopSelectionStatus" />
                </div>
                <a
                  v-if="shops.length > 1"
                  href="#"
                  @click.prevent="toggleShopExpansion"
                  class="shop-expand link-main icon-link"
                >
                  <Icon :icon="displayAllShops ? 'up' : 'down'" />
                  {{
                    displayAllShops
                      ? $t('GENERAL.HIDE_ALL_SHOPS')
                      : $t('GENERAL.SHOW_ALL_SHOPS')
                  }}
                </a>
                <div v-if="displayAllShops && shops.length > 1">
                  <div
                    class="pos-item"
                    v-for="shop in shops"
                    :key="shop.id"
                    :class="{ selected: shop.selected }"
                    @click.prevent="togglePos(shop)"
                  >
                    <p class="text-truncate">{{ shop.name }}</p>
                    <ToggleSwitch :model-value="shop.selected" />
                  </div>
                </div>
              </div>

              <div v-if="updateInProgress" class="overlay">
                <div class="update-in-progress">
                  <div>
                    <img src="/images/progress.svg" width="128" />
                    <h2>
                      {{ $t('BULK_ASSORTMENT.UPDATE_IN_PROGRESS.HEADING') }}
                    </h2>
                    <p class="text-grey">
                      {{ $t('BULK_ASSORTMENT.UPDATE_IN_PROGRESS.TEXT') }}
                    </p>
                    <div class="queue-position" v-if="queuePosition">
                      <Icon icon="crowd" />
                      <p>
                        {{
                          $t('BULK_ASSORTMENT.UPDATE_IN_PROGRESS.QUEUE', {
                            position: queuePosition,
                          })
                        }}
                      </p>
                    </div>
                    <button @click="close" class="btn btn-primary">
                      {{ $t('GENERAL.CLOSE') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AssortmentCatalogContent
          v-else
          :assortment="assortment"
          :activeFilter="activeFilter"
          @setFilter="setFilter"
          @onToggle="onToggle"
          @onColorChange="onColorChange"
          :selectionLimit="5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import assortmentService from '@/api/assortment/assortmentService';
import AssortmentCatalogContent from 'src/app/partnerarea/idea/configuration/assortment/catalog/AssortmentCatalogContent.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import SprdImage from 'src/app/commons/image/SprdImage.vue';
import productIdeaService from '@/api/productIdeaService/productIdeaService';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import analytics from '@/tracking/analytics';

export default {
  name: 'IdeaAssortmentUpdate',
  components: {
    ToggleSwitch,
    MarketplaceLogo,
    SpreadshopLogo,
    SprdImage,
    AssortmentCatalogContent,
  },
  data() {
    return {
      updateInProgress: false,
      updatesChecked: false,
      pointsOfSale: [],
      displayAllShops: false,
      displayCatalog: false,
      assortment: null,
      selectedProducts: [],
      initialized: false,
      queueSize: null,
      queuePosition: null,
      entryPoint: 'manual',
      ideaCount: null,
      activeFilter: null,
    };
  },
  computed: {
    ...mapState({
      userPointsOfSale: (s) => s.user.pointsOfSale,
      user: (s) => s.user.data,
    }),
    ...mapGetters({
      getSetting: 'settings/getSetting',
    }),
    shops() {
      return this.pointsOfSale.filter((pos) => pos.type === 'SHOP');
    },
    mp() {
      return this.pointsOfSale.find((pos) => pos.type === 'MARKETPLACE');
    },
    generalShopSelectionStatus() {
      return this.shops.some((shop) => shop.selected);
    },
    currentSelectedSellables() {
      const selectedSellables = assortmentHelper.getSelectedSellables(
        this.assortment
      );
      if (selectedSellables && selectedSellables.length) {
        return selectedSellables;
      } else {
        return null;
      }
    },
    mpEnabled() {
      return this.getSetting('MP_ENABLED');
    },
    selectedPos() {
      return this.pointsOfSale.filter((pos) => pos.selected);
    },
  },
  async created() {
    productIdeaService.getIdeasOfUser().then((data) => {
      this.ideaCount = data.count;
    });

    this.pointsOfSale = this.userPointsOfSale
      .map((pointOfSale) => ({
        selected: true,
        name: pointOfSale.name,
        type: pointOfSale.type,
        target: pointOfSale.target,
        id: pointOfSale.id,
      }))
      .filter((pointOfSale) => pointOfSale.type !== 'CYO');

    this.checkForPreviousUpdates();

    this.assortment = await assortmentService.fetchIdeasAssortment();
    this.activeFilter = this.assortment;
    this.assortment.filterId = 'root';
    Object.values(this.assortment.subFilters).forEach((filter) => {
      filter.available = true;
      filter.hints = [];
    });
    assortmentHelper
      .getTotalAvailableSellables(this.assortment)
      .forEach((entry) => {
        if (entry.primarySellable) {
          entry.primarySellable.defaultImageUrl =
            entry.primarySellable.productTypeImageUrl.match(
              /(.*)\/appearances\/\d/
            )[1];
        }
      });

    if (this.$route.query.ptId) {
      const ptIds = this.$route.query.ptId.split(',').slice(0, 5);
      this.entryPoint = 'notification';

      const groups = assortmentHelper
        .getTotalAvailableSellables(this.assortment)
        .filter((entry) => ptIds.includes(entry.primarySellable.productType));

      groups.forEach((group) => {
        this.onToggle(group);
      });
      this.saveCatalogSelection();
    }

    this.initialized = true;
  },
  methods: {
    update() {
      dialogService
        .openDialog(ConfirmDialog, {
          heading: 'BULK_ASSORTMENT.CONFIRM.HEADING',
          description: 'BULK_ASSORTMENT.CONFIRM.TEXT',
          cancel: 'GENERAL.CANCEL',
          confirm: 'GENERAL.LETS_GO',
          image: '/images/waiting.svg',
        })
        .then(async () => {
          this.selectedProducts.forEach((selectedProduct) => {
            analytics.logEvent('product-wizard-update', {
              productType: selectedProduct.primarySellable.productType,
              entryPoint: this.entryPoint,
              ideaCount: this.ideaCount,
              value: this.selectedProducts.length,
              parallelProducts: this.selectedProducts.length,
            });
          });

          this.updateInProgress = true;
          try {
            await assortmentService.triggerUpdate(
              this.selectedProducts.map(
                (selectedProduct) => selectedProduct.primarySellable.productType
              ),
              this.selectedPos
            );
            setTimeout(this.checkForPreviousUpdates, 500);
          } catch (error) {
            this.updateInProgress = false;
          }
        });
    },
    togglePos(pos) {
      pos.selected = !pos.selected;
    },
    toggleAllShops() {
      const generalStatus = this.generalShopSelectionStatus;
      this.shops.forEach((shop) => (shop.selected = !generalStatus));
    },
    toggleShopExpansion() {
      this.displayAllShops = !this.displayAllShops;
    },
    onToggle(group) {
      assortmentHelper
        .getTotalAvailableSellables(this.assortment)
        .forEach((entry) => {
          if (entry.filterId === group.filterId) {
            entry.included = !entry.included;
          }
          // else {
          //   entry.included = false;
          // }
        });
    },
    openCatalog() {
      assortmentHelper
        .getTotalAvailableSellables(this.assortment)
        .forEach((entry) => {
          if (
            this.selectedProducts.length &&
            this.selectedProducts.some(
              (selectedProduct) => selectedProduct.filterId === entry.filterId
            )
          ) {
            entry.included = true;
          } else {
            entry.included = false;
          }
        });

      this.displayCatalog = true;
    },
    closeCatalog() {
      this.displayCatalog = false;
    },
    saveCatalogSelection() {
      if (this.currentSelectedSellables) {
        this.selectedProducts = this.currentSelectedSellables;
        this.$router.replace({
          query: {
            ptId: this.selectedProducts
              .map(
                (selectedProduct) => selectedProduct.primarySellable.productType
              )
              .join(','),
          },
        });
      }

      this.closeCatalog();
    },
    close() {
      this.$router.push({ name: 'partnerarea.ideas' });
    },
    async checkForPreviousUpdates() {
      const updatesResponse = await assortmentService.getPreviousUpdates();

      if (updatesResponse.updates) {
        this.queueSize = updatesResponse.queueSize;
        this.queuePosition =
          updatesResponse.updates.length &&
          updatesResponse.updates[0].queuePosition + 1;
      }

      this.updatesChecked = true;
      this.updateInProgress =
        (updatesResponse.updates && updatesResponse.updates.length) ||
        updatesResponse.length;
    },
    setFilter(filter) {
      this.activeFilter = filter;
    },
    onColorChange(color, group) {
      assortmentHelper
        .getTotalAvailableSellables(this.assortment)
        .find(
          (entry) => entry.filterId === group.filterId
        ).primarySellable.appearanceId = color.appearanceId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/animations';

.idea-assortment-update {
  height: 100%;

  .menu {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: 100%;

    h3 {
      margin: 0 32px 0 0;
    }

    .btn {
      margin-left: 24px;
    }

    & > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
    }

    .counter {
      display: flex;
      align-items: center;
      margin-right: auto;

      strong {
        margin-left: 4px;
      }
    }
  }

  .overflow-y {
    width: 100%;

    & > div {
      padding: 24px;
    }
  }

  .content-panel {
    padding: 24px;
    background-color: $white;
    border-radius: 8px;
    position: relative;
    min-height: 500px;
  }

  .explanation {
    p {
      margin-bottom: 24px;
      max-width: 600px;
    }
  }

  .queue {
    display: flex;
    align-items: center;

    .icon-wrapper {
      border-radius: 100%;
      background-color: $grey5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin-right: 16px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    h4 {
      margin: 0 0 4px 0;
    }

    p {
      margin: 0;
    }
  }

  .selected-products {
    padding: 24px 0;

    h3 {
      margin-top: 0;
    }

    & > .btn {
      height: 40px;
      padding-right: 24px;
      padding-left: 24px;
    }

    .products-wrapper {
      display: flex;
      flex-wrap: wrap;

      .product {
        margin: 0 16px 16px 0;

        p {
          margin: 8px 0 0 0;
          width: 214px;
        }
      }

      .product-image {
        background-color: $grey5;
        border-radius: 8px;
        padding: 12px;
      }
    }
  }

  .pos {
    h3 {
      margin-top: 0;
    }

    :deep(.radiobutton-group) {
      flex-direction: column;
      align-self: flex-start;
      align-items: flex-start;

      & > label {
        margin-bottom: 16px;
      }
    }

    .pos-item {
      display: flex;
      align-items: center;
      padding: 16px 22px;
      justify-content: space-between;
      width: 350px;
      border: 1px solid $grey20;
      border-radius: 8px;
      margin-bottom: 12px;
      cursor: pointer;

      &.selected {
        border-color: $pa-color-main;
      }

      img {
        height: 24px;
      }

      p {
        margin: 0 24px 0 0;
      }
    }

    .shop-expand {
      margin: 12px 0;
      width: 350px;
      justify-content: center;
    }
  }

  .overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($black, 0.15);
    animation: fadein 0.2s linear both;
    animation-delay: 0.5s;
  }

  .update-in-progress {
    display: flex;
    justify-content: center;
    padding: 24px;

    & > div {
      background-color: $white;
      border-radius: 8px;
      margin-top: 24px;
      padding: 48px;
      max-width: 700px;
    }

    h2 {
      margin: 16px 0 12px 0;
    }

    p {
      margin: 0;
    }

    .btn {
      margin-top: 24px;
    }

    .queue-position {
      display: flex;
      align-items: center;
      margin-top: 14px;

      .icon {
        margin-right: 8px;
        width: 22px;
        height: 22px;
      }

      p {
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .catalog {
    :deep(.product-toggle-switch),
    :deep(.header) {
      display: none;
    }
  }
}
</style>
