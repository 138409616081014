<template>
  <div>
    <ToggleSwitch
      v-model="imprint.isCompany"
      label="POS.SHOP_SETTINGS.IMPRINT.IS_COMPANY"
      class="sprd-shop-input"
    />

    <ValidLabelInput
      v-if="!imprint.isCompany"
      v-model="imprint.person.firstName"
      :label="fieldLabel('firstName', true)"
      class="sprd-shop-input"
      maxlength="255"
      :validators="[]"
      ref="firstName"
    />

    <ValidLabelInput
      v-if="!imprint.isCompany"
      v-model="imprint.person.lastName"
      :label="fieldLabel('lastName', true)"
      class="sprd-shop-input"
      maxlength="255"
      :validators="[]"
      ref="lastName"
    />

    <ValidLabelInput
      v-if="imprint.isCompany"
      v-model="imprint.company"
      :label="fieldLabel('company', true)"
      class="sprd-shop-input"
      maxlength="255"
      :validators="[]"
      ref="company"
    />

    <ValidLabelInput
      type="textarea"
      v-if="imprint.isCompany"
      v-model="imprint.authorizedRepresentative"
      :label="fieldLabel('authorizedRepresentative', true)"
      class="sprd-shop-input"
      maxlength="511"
      :validators="[]"
      ref="authorizedRepresentative"
    />

    <div class="multiple" v-if="isEU">
      <ValidLabelInput
        v-model="imprint.street"
        :label="fieldLabel('street', true)"
        class="sprd-shop-input street"
        maxlength="255"
        :validators="[]"
        ref="street"
      />

      <ValidLabelInput
        v-model="imprint.houseNumber"
        :label="fieldLabel('houseNumber', true)"
        class="sprd-shop-input streetNumber"
        maxlength="255"
        :validators="[]"
        ref="houseNumber"
      />
    </div>

    <ValidLabelInput
      v-if="isEU"
      v-model="imprint.streetAnnex"
      :label="fieldLabel('streetAnnex', false)"
      class="sprd-shop-input"
      maxlength="255"
      :validators="[]"
      ref="streetAnnex"
    />

    <div :class="{ multiple: isEU }">
      <ValidLabelInput
        v-if="isEU"
        v-model="imprint.zipCode"
        :label="fieldLabel('zipCode', true)"
        maxlength="255"
        class="sprd-shop-input zipcode"
        :validators="[]"
        ref="zipCode"
      />

      <ValidLabelInput
        v-model="imprint.city"
        :label="fieldLabel('city', true)"
        class="sprd-shop-input city"
        maxlength="255"
        :validators="[]"
        ref="city"
      />
    </div>

    <div class="sprd-shop-input" :class="{ 'has-error': countryErrorMsg }">
      <Dropdown
        :items="shippingCountries"
        :itemKey="(country) => country.code"
        :itemToString="(country) => country.value"
        :label="fieldLabel('country', true)"
        :value="imprint.country"
        @change="setCountry"
      />
      <div class="error" v-if="countryErrorMsg">
        <small class="error-info">
          <Icon class="icon" icon="close" />
          <span>{{ countryErrorMsg }}</span>
        </small>
      </div>
    </div>

    <div
      class="sprd-shop-input"
      v-if="imprint.country && ['US', 'CA'].includes(imprint.country.code)"
      :class="{ 'has-error': stateErrorMsg }"
    >
      <Dropdown
        :items="availableShippingStates"
        :itemKey="(country) => country.code"
        :itemToString="(country) => country.value"
        :label="fieldLabel('state', true)"
        :value="imprint.state"
        @change="setState"
      />
      <div class="error" v-if="stateErrorMsg">
        <small class="error-info">
          <Icon class="icon" icon="close" />
          <span>{{ stateErrorMsg }}</span>
        </small>
      </div>
    </div>

    <ValidLabelInput
      v-model="imprint.email"
      :label="fieldLabel('email', true)"
      class="sprd-shop-input"
      maxlength="255"
      :validators="[validateEmail]"
      ref="email"
    />

    <ValidLabelInput
      v-model="imprint.phone"
      :label="fieldLabel('phone', false)"
      class="sprd-shop-input"
      maxlength="255"
      :validators="[]"
      ref="phone"
    />

    <ValidLabelInput
      v-model="imprint.tradeRegister"
      :label="fieldLabel('tradeRegister', false)"
      class="sprd-shop-input"
      maxlength="50"
      :validators="[]"
      ref="tradeRegister"
    />

    <ValidLabelInput
      v-model="imprint.vatId"
      :label="fieldLabel('vatId', false)"
      class="sprd-shop-input"
      maxlength="36"
      :validators="[]"
      ref="vatId"
    />

    <ValidLabelInput
      type="textarea"
      v-if="isEU"
      v-model="imprint.additionalInfo"
      :label="fieldLabel('additionalInfo', false)"
      class="sprd-shop-input"
      maxlength="1023"
      :validators="[]"
      ref="additionalInfo"
    />
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

import ValidLabelInput from '../../shop/common/ValidLabelInput.vue';
import Dropdown from '../../../../commons/Dropdown/Dropdown.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';

const fieldLabels = {
  firstName: 'ACCOUNT.PERSONAL.FIRSTNAME',
  lastName: 'ACCOUNT.PERSONAL.LASTNAME',
  company: 'POS.SHOP_SETTINGS.IMPRINT.COMPANY_NAME',
  authorizedRepresentative:
    'POS.SHOP_SETTINGS.IMPRINT.AUTHORIZED_REPRESENTATIVE',
  street: 'ACCOUNT.PERSONAL.STREET_ONLY',
  houseNumber: 'ACCOUNT.PERSONAL.HOUSE_NUMBER',
  streetAnnex: 'ACCOUNT.PERSONAL.STREET2',
  zipCode: 'ACCOUNT.PERSONAL.POSTAL_CODE',
  city: 'ACCOUNT.PERSONAL.CITY',
  country: 'ACCOUNT.PERSONAL.COUNTRY',
  state: 'ACCOUNT.PERSONAL.STATE',
  email: 'ACCOUNT.CONTACT.EMAIL',
  phone: 'ACCOUNT.CONTACT.PHONE',
  tradeRegister: 'SA.imprint.form.registrationNo',
  vatId: 'POS.SHOP_SETTINGS.IMPRINT.VAT_ID',
  additionalInfo: 'POS.SHOP_SETTINGS.IMPRINT.ADDITIONAL_INFO',
};

export default {
  name: 'Imprint',
  mixins: [SettingsData],
  components: {
    Dropdown,
    ValidLabelInput,
    ToggleSwitch,
  },
  data() {
    return {
      shippingCountries: [],
      shippingStates: {},
      isEU: SPRD.PLATFORM === 'EU',
    };
  },
  created() {
    // in case of broken data there might be no imprint at all. this code will force you to submit one immediately.
    this.setDefaultIfEmpty();

    this.$store.commit('shop/setLivePreview', { path: 'imprint' });
    shopAPI
      .getLocalization(this.$store)
      .then((loc) => {
        loc.shippingCountries.forEach((country) => {
          this.shippingCountries.push({
            code: country.isoCode,
            value: country.name,
          });
          this.shippingStates[country.isoCode] = country.shippingStates.map(
            (sstate) => ({
              code: sstate.isoCode,
              value: sstate.name,
            })
          );
        });
      })
      .catch((e) => console.error('Failed to load localization', e));

    // we watch the validation object to get notified when validation of the imprint fails so we can mark our fields red
    this.$store.watch(
      (state) => state.shop.shopSavableValidationError,
      (validationResult) => {
        const errorFields = validationResult.errors
          ? Object.keys(validationResult.errors)
          : [];
        Object.keys(this.$refs).forEach((k) => {
          if (this.$refs[k]) {
            this.$refs[k].errorMessage = errorFields.includes(k)
              ? this.translateRequiredFieldErr(k)
              : null;
          }
        });
      }
    );
  },
  computed: {
    imprint() {
      return this.config.shopAddress;
    },
    availableShippingStates() {
      return (
        (this.imprint.country &&
          this.shippingStates[this.imprint.country.code]) ||
        []
      );
    },
    errorFields() {
      return this.$store.state.shop.shopSavableValidationError.errors
        ? Object.keys(this.$store.state.shop.shopSavableValidationError.errors)
        : [];
    },
    countryErrorMsg() {
      return this.errorFields.includes('country')
        ? this.translateRequiredFieldErr('country')
        : '';
    },
    stateErrorMsg() {
      return this.errorFields.includes('state')
        ? this.translateRequiredFieldErr('state')
        : '';
    },
  },
  watch: {
    // add keys to shopAddress if removed through discard button
    imprint() {
      this.setDefaultIfEmpty();
    },
  },
  methods: {
    validateEmail(email) {
      if (!email) {
        throw new Error(this.translateRequiredFieldErr('email'));
      }
      if (!email.match(/^[^@,;\s]+@[^@,;\s]+\.[^@,;\s]+$/)) {
        throw new Error(
          this.$t('SA.shared.form.error.email', {
            label: this.$t(fieldLabels.email),
          })
        );
      }
    },
    setCountry(country) {
      this.imprint.country = country;
    },
    setState(state0) {
      this.imprint.state = state0;
    },
    fieldLabel(key, required) {
      const label = this.$t(fieldLabels[key]);
      return required ? `${label}*` : label;
    },
    translateRequiredFieldErr(fieldKey) {
      const field = this.$t(fieldLabels[fieldKey]);
      return this.$t('SA.shared.form.error.required', {
        label: field,
      });
    },
    setDefaultIfEmpty() {
      if (!this.config.shopAddress) {
        this.config.shopAddress = {};
      }
      if (!this.config.shopAddress.person) {
        this.config.shopAddress.person = {};
      }
      if (!this.config.shopAddress.country) {
        this.config.shopAddress.country = {};
      }
      if (!this.config.shopAddress.state) {
        this.config.shopAddress.state = {};
      }
      if (!this.config.shopAddress.isCompany) {
        this.config.shopAddress.isCompany = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  :deep(.sprd-toggle-switch) {
    margin: 20px 0 28px;
  }
}

.multiple {
  display: flex;
  margin-bottom: 30px;

  .street,
  .zipcode {
    margin-right: 5px;
  }

  .city,
  .streetNumber {
    margin-left: 5px;
  }

  .street,
  .city {
    flex-grow: 1;
  }

  .streetNumber,
  .zipcode {
    width: 150px;
  }

  .sprd-shop-input {
    margin-bottom: 0;
  }
}
</style>
