import { i18n } from '@/translate/i18n';

const { t } = i18n.global;

export const POINTS_OF_SALE = {
  OWN_SHOP: {
    key: 'OWN_SHOP',
    name: 'GENERAL.SHOP',
    logo: 'shop',
  },
  FOREIGN_SHOP: {
    key: 'FOREIGN_SHOP',
    name: 'GENERAL.FOREIGN_SHOP',
    showDesign: true,
    logo: 'shop',
  },
  CYO: {
    key: 'CYO',
    name: 'GENERAL.CYO',
    showDesign: true,
    logo: 'mp',
  },
  MP: {
    key: 'MP',
    name: 'GENERAL.MARKETPLACE',
    logo: 'mp',
  },
  EMP: {
    key: 'EMP',
    name: 'GENERAL.EXTERNAL_MARKETPLACE',
    logo: 'mp',
  },
  UNKNOWN: {
    key: 'UNKNOWN',
    name: '',
    showDesign: true,
    logo: 'mp',
  },
};

export function pointsOfSaleConfig(pos) {
  return POINTS_OF_SALE[pos] || POINTS_OF_SALE.UNKNOWN;
}

export function pointOfSaleName(posObject) {
  const pos = posObject.pos || posObject.pointOfSale;
  if (pos === POINTS_OF_SALE.OWN_SHOP.key) {
    return posObject.shopName || posObject.shopId;
  }

  if (pos === POINTS_OF_SALE.FOREIGN_SHOP.key) {
    return `${t(POINTS_OF_SALE.FOREIGN_SHOP.name)}: ${
      posObject.shopName || posObject.shopId
    }`;
  }

  return t(pointsOfSaleConfig(pos).name);
}

export const REJECTION_REASONS = {
  EMBROIDERY: 'DESIGNS.STATUS.REJECTION_REASONS.EMBROIDERY',
  COPYRIGHT: 'DESIGNS.STATUS.REJECTION_REASONS.COPYRIGHT',
  UNKNOWN_RIGHT_OF_USE: 'DESIGNS.STATUS.REJECTION_REASONS.UNKNOWN_RIGHT_OF_USE',
  COMMUNITY_GUIDELINES: 'DESIGNS.STATUS.REJECTION_REASONS.COMMUNITY_GUIDELINES',
  MP_GUIDELINES: 'DESIGNS.STATUS.REJECTION_REASONS.MP_GUIDELINES',
  QUALITY: 'DESIGNS.STATUS.REJECTION_REASONS.QUALITY',
  MP_NON_PERFORMING_REMOVAL:
    'DESIGNS.STATUS.REJECTION_REASONS.MP_NON_PERFORMING_REMOVAL',
  OTHER: 'DESIGNS.STATUS.REJECTION_REASONS.OTHER',
};

export function rejectionReasonName(key) {
  return REJECTION_REASONS[key] || REJECTION_REASONS['OTHER'];
}
