<template>
  <div class="dialog-size-md default-translations-dialog">
    <div class="modal-body">
      <h1>
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT_HEADING'
          )
        }}
      </h1>
      <p class="text-grey">
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT_SUBHEADING'
          )
        }}
      </p>
      <table class="translations">
        <tr>
          <th>
            {{
              $t(
                'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.TRANSLATION_LANGUAGES'
              )
            }}
          </th>
          <th>{{ $t('GENERAL.STATUS') }}</th>
          <th>
            {{
              $t(
                'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.TRANSLATION_DEFAULT'
              )
            }}
          </th>
        </tr>
        <tr v-for="translation in translations" :key="translation.locale">
          <td>
            {{ translation.name }}
          </td>
          <td class="text-grey">
            {{ getTranslationHint(translation) }}
          </td>
          <td>
            <RadioButton
              :selected="selectedDefaultTranslation === translation"
              @onSelected="onDefaultChange(translation)"
              name="defaultTranslation"
            />
          </td>
        </tr>
      </table>
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          @click="apply"
          :disabled="!defaultWasChanged"
        >
          {{ $t('GENERAL.APPLY') }}
        </button>
        <button type="button" class="btn btn-light" @click="cancel">
          {{ $t('GENERAL.CANCEL') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import RadioButton from '@/RadioButtonGroup/RadioButton.vue';
import INTPUB_DECISION_STATES from '@/states/intpubDecisionStates';
import analyticsService from '@/tracking/analytics';

export default {
  name: 'DefaultTranslationsDialog',
  props: ['modalInstance', 'data'],
  components: {
    RadioButton,
  },
  data() {
    return {
      currentLanguage: null,
      selectedDefaultTranslation: null,
      translations: null,
    };
  },
  created() {
    this.translations = this.languages
      .map((language) => ({
        locale: language.isoCode,
        name: this.languageByIso(language.isoCode).name,
      }))
      .sort((x, y) =>
        x.locale === this.defaultLanguage
          ? -1
          : y.locale === this.defaultLanguage
          ? 1
          : 0
      );
    this.selectedDefaultTranslation = this.defaultTranslation;
  },
  computed: {
    ...mapGetters({
      defaultLanguage: 'idea/language',
      languageByIso: 'platform/languageByIso',
      requestedAutotranslations: 'idea/requestedAutotranslations',
      intPubDecision: 'idea/intPubDecision',
    }),
    ...mapState({
      idea: (state) => state.idea.current,
      languages: (state) => state.platform.languages,
    }),
    defaultTranslation() {
      return this.translations.find((translation) =>
        this.isDefaultTranslation(translation.locale)
      );
    },
    defaultWasChanged() {
      return this.defaultTranslation !== this.selectedDefaultTranslation;
    },
  },
  methods: {
    ...mapActions({
      changeDefaultLanguage: 'idea/changeDefaultLanguage',
    }),
    isDefaultTranslation(locale) {
      return locale === this.defaultLanguage ? true : false;
    },
    getTranslationHint(translation) {
      const ideaTranslation = this.idea.translations.find(
        (t) => t.locale === translation.locale
      );

      if (this.selectedDefaultTranslation.locale === translation.locale) {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT'
        );
      } else if (this.defaultWasChanged || !ideaTranslation) {
        if (
          this.requestedAutotranslations &&
          this.intPubDecision !== INTPUB_DECISION_STATES.REJECTED
        ) {
          return this.$t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATE_PENDING'
          );
        } else {
          return this.$t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.NOT_ADDED'
          );
        }
      } else if (ideaTranslation && ideaTranslation.autotranslated) {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATED'
        );
      } else {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.MANUAL'
        );
      }
    },
    onDefaultChange(newDefaultTranslation) {
      this.selectedDefaultTranslation = newDefaultTranslation;
    },
    cancel() {
      this.modalInstance.dismiss();
    },
    apply() {
      analyticsService.logEvent('idea_default_language_switch', {
        eventArea: 'idea-translations',
        previousDefaultLanguage: this.defaultLanguage,
        newDefaultLanguage: this.selectedDefaultTranslation.locale,
      });

      this.changeDefaultLanguage(this.selectedDefaultTranslation.locale);
      this.modalInstance.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.default-translations-dialog {
  width: 500px;

  .modal-body {
    & > h1 {
      margin: 0;
    }

    & > p {
      margin: 16px 0 0 0;
    }

    & > h2 {
      margin: 0 0 16px 0;
    }
  }
}

.translations {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 12px 0 -12px;
  table-layout: fixed;

  tr {
    border-bottom: 1px solid $grey20;

    &:last-child {
      border: none;
    }

    td,
    th {
      padding: 12px 4px;
      text-align: left;

      &:last-child {
        display: flex;
        text-align: right;
        margin-left: 8px;
        justify-content: flex-end;
      }
    }
  }
}

.actions {
  display: flex;
  margin: 16px 0 0 0;

  button {
    height: 40px;
    flex: 1;

    &:first-child {
      margin-right: 16px;
    }
  }
}
</style>
