export const HOTSPOTS = {
  FULL_FRONT: {
    key: 'FULL_FRONT',
    image: '/images/hotspots/Front-Full-Front.svg',
    name: 'HOTSPOT.FULL_FRONT',
  },
  MEDIUM_FRONT: {
    key: 'MEDIUM_FRONT',
    image: '/images/hotspots/Front-Medium-Front.svg',
    name: 'HOTSPOT.MEDIUM_FRONT',
  },
  LEFT_CHEST: {
    key: 'LEFT_CHEST',
    image: '/images/hotspots/Front-Left-Chest.svg',
    name: 'HOTSPOT.LEFT_CHEST',
  },
  RIGHT_CHEST: {
    key: 'RIGHT_CHEST',
    image: '/images/hotspots/Front-Right-Chest.svg',
    name: 'HOTSPOT.RIGHT_CHEST',
  },
  FULL_BACK: {
    key: 'FULL_BACK',
    image: '/images/hotspots/Back-Full-Back.svg',
    name: 'HOTSPOT.FULL_BACK',
  },
  MEDIUM_BACK: {
    key: 'MEDIUM_BACK',
    image: '/images/hotspots/Back-Medium-Back.svg',
    name: 'HOTSPOT.MEDIUM_BACK',
  },
  BACK_COLLAR: {
    key: 'BACK_COLLAR',
    image: '/images/hotspots/Back-Back-Collar.svg',
    name: 'HOTSPOT.BACK_COLLAR',
  },
  // RIGHT: {
  //   key: 'RIGHT',
  // },
  // LEFT: {
  //   key: 'LEFT',
  // },
  CENTER_CHEST: {
    key: 'CENTER_CHEST',
    image: '/images/hotspots/Front-Center-Chest.svg',
    name: 'HOTSPOT.CENTER_CHEST',
  },
};

export const HOTSPOTS_ORDERED = [
  HOTSPOTS.FULL_FRONT,
  HOTSPOTS.MEDIUM_FRONT,
  HOTSPOTS.LEFT_CHEST,
  HOTSPOTS.RIGHT_CHEST,
  HOTSPOTS.CENTER_CHEST,
  HOTSPOTS.FULL_BACK,
  HOTSPOTS.MEDIUM_BACK,
  HOTSPOTS.BACK_COLLAR,
];
