<template>
  <div class="email-form">
    <LabelInput :class="{ 'has-error': errors.email }">
      <label for="email">{{ $t('ACCOUNT.CONTACT.EMAIL') }}</label>
      <DebounceInput
        type="text"
        id="email"
        :model-value="email"
        @update:model-value="updateEmail"
      />
      <div class="field-info" v-if="errors.email">
        <small class="error-info">
          {{ $t(errors.email) }}
        </small>
      </div>
    </LabelInput>
    <LabelInput :class="{ 'has-error': errors.password }">
      <label for="emailPassword">{{
        $t('ACCOUNT.ACCOUNT.OLD_PASSWORD')
      }}</label>
      <DebounceInput
        :type="revealPassword ? 'text' : 'password'"
        id="emailPassword"
        :model-value="password"
        @update:modelValue="setPassword"
      />
      <Icon
        class="reveal-password"
        :icon="revealPassword ? 'eye-crossed' : 'eye'"
        @click="toggleRevealPassword"
      ></Icon>
      <div class="field-info" v-if="errors.password">
        <small class="error-info">
          {{ $t(errors.password) }}
        </small>
      </div>
    </LabelInput>
    <AccountPageActions
      :save="changeEmail"
      @onRevert="revertEmail"
      :disabled="!emailChanged"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import AccountPageActions from '../AccountPageActions.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';
import LabelInput from '@/labelInput/LabelInput.vue';

export default {
  name: 'PersonalDataForm',
  components: {
    LabelInput,
    DebounceInput,
    AccountPageActions,
  },
  props: {},
  data() {
    return {
      errors: {
        email: null,
        password: null,
      },
      password: null,
      revealPassword: false,
    };
  },
  created() {},
  unmounted() {
    this.resetEmail();
  },
  computed: {
    ...mapState({
      email: (s) => s.identity.email,
    }),
    ...mapGetters({
      emailChanged: 'identity/emailChanged',
    }),
  },
  methods: {
    ...mapMutations({
      setEmail: 'identity/setEmail',
      resetEmail: 'identity/resetEmail',
    }),
    ...mapActions({
      saveEmail: 'identity/saveEmail',
      fetchIdentity: 'identity/fetchIdentity',
    }),
    updateEmail(email) {
      this.resetErrors();
      this.setEmail(email);
    },
    setPassword(password) {
      this.resetErrors();
      this.password = password;
    },
    toggleRevealPassword() {
      this.revealPassword = !this.revealPassword;
    },
    async changeEmail() {
      try {
        await this.saveEmail(this.password);
        this.password = null;
      } catch (error) {
        this.setErrors(error);
        throw error;
      }
    },
    revertEmail() {
      this.resetErrors();
      this.resetEmail();
    },
    resetErrors() {
      this.errors.email = null;
      this.errors.password = null;
    },
    setErrors(error) {
      switch (error?.status) {
        case 401:
          this.errors.password =
            'ACCOUNT.PASSWORD.ERRORS.CURRENT_PASSWORD_WRONG';
          return;
        case 400:
          if (!this.password) {
            this.errors.password =
              'ACCOUNT.PASSWORD.ERRORS.CURRENT_PASSWORD_WRONG';
            return;
          }
      }

      switch (error?.data?.error) {
        case '-00001':
          this.errors.email = 'ACCOUNT.ERRORS.INVALID_FORMAT';
          return;
        case '000005':
          this.errors.email = 'ACCOUNT.ERRORS.EMAIL_IN_USE';
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.labelInput {
  margin: 0 0 16px 0;

  :deep(.error-icon) {
    display: none !important;
  }
}

.reveal-password {
  position: absolute;
  right: 10px;
  top: 10px;

  width: 18px;
  height: 18px;

  color: $pa-color-main;
  cursor: pointer;
}
</style>
