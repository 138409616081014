<template>
  <div class="htmlAndCss">
    <div>
      <p class="titleHtml">
        <strong>{{ $t('SA.htmlCss.headerHtml.title') }}</strong>
      </p>
      <textarea
        :value="settings.headerHtml || ''"
        @blur="setHeaderHtml"
        @focus="scrollToHeader"
        class="codeBox"
      />
    </div>
    <div>
      <p class="title">
        <strong>{{ $t('SA.htmlCss.footerHtml.title') }}</strong>
      </p>
      <textarea
        :value="settings.footerHtml || ''"
        @blur="setFooterHtml"
        @focus="scrollToFooter"
        class="codeBox"
      />
    </div>
    <div>
      <p class="title">
        <strong>{{ $t('SA.htmlCss.css.title') }}</strong>
      </p>
      <textarea
        :value="settings.customCss || ''"
        @blur="setCustomCss"
        class="codeBox"
      />
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { removeExternalUrls } from './heuristicSanitizer';

export default {
  name: 'HtmlAndCss',
  mixins: [SettingsData],
  created: function () {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
  methods: {
    setHeaderHtml(html) {
      this.settings.headerHtml = html.target.value;
    },
    setFooterHtml(html) {
      this.settings.footerHtml = html.target.value;
    },
    setCustomCss(css) {
      this.settings.customCss = removeExternalUrls(css.target.value);
    },
    scrollToHeader() {
      this.$store.commit('shop/setLivePreview', {
        path: '',
        section: 'sprd-header-container',
      });
    },
    scrollToFooter() {
      this.$store.commit('shop/setLivePreview', {
        path: '',
        section: 'sprd-footer',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.codeBox {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-wrap: nowrap;
}
.title {
  margin: 24px 0 8px;
}

.titleHtml {
  margin: 0 0 8px;
}
</style>
