// Careful: They have to match the actual validation logic done on the server side in spreadshop!
const teaserHint = '1400x400';
export const teaser = {
  width: 1400,
  height: 400,
  hint: teaserHint,
  useCase: 'teaser',
};

const aboutUsHint = '664x400';
export const aboutUs = {
  width: 664,
  height: 400,
  hint: aboutUsHint,
  useCase: 'aboutUs',
};

const aboutPageHint = '664x400';
export const aboutPage = {
  width: 664,
  height: 400,
  hint: aboutPageHint,
  useCase: 'aboutPage',
};

const logoHint = '380x68';
export const logo = {
  width: 380,
  height: 68,
  hint: logoHint,
  useCase: 'logo',
};

const faviconHint = '192x192';
export const favicon = {
  width: 192,
  height: 192,
  hint: faviconHint,
  useCase: 'favicon',
};

const invoiceLogoHint = '1000x250';
export const invoiceLogo = {
  width: 1000,
  height: 250,
  hint: invoiceLogoHint,
  useCase: 'invoiceLogo',
};

export const hints = {
  teaser: teaserHint,
  aboutUs: aboutUsHint,
  logo: logoHint,
  favicon: faviconHint,
  invoiceLogo: invoiceLogoHint,
};

const promoHint = '1400x400';
export const promo = {
  width: 1400,
  height: 225,
  hint: promoHint,
  useCase: 'promo',
};

export default function interpolateElement(element) {
  return { PIXEL_LIMIT: hints[element] };
}
