<template>
  <div>
    <div ref="canary">
      <!-- helper element to know when sticky header is stuck -->
    </div>
    <header :class="{ sticky }">
      <div class="col">
        {{ selectedItems.length }}/{{ items.length }}
        {{ $t('GENERAL.SELECTED') }}
        <button v-if="hasSelectAll" class="text-btn ml" @click="selectAll">
          {{ $t('GENERAL.SELECT_ALL') }}
        </button>
        <button
          class="btn btn-ghost text-btn ml"
          @click="deselectAll"
          :disabled="!hasSelectedItems"
        >
          {{ $t('GENERAL.DESELECT_ALL') }}
        </button>
      </div>
      <div class="col">
        {{ $t('POS.SHOP_SETTINGS.SORT.MOVE_LABEL') }}:
        <button
          class="btn btn-light ml"
          @click="moveTop"
          :disabled="!hasSelectedItems"
        >
          <Icon icon="arrow-end" class="arrowTop" />
          {{ $t('POS.SHOP_SETTINGS.SORT.MOVE_TOP') }}
        </button>
        <button
          class="btn btn-light ml"
          @click="moveBottom"
          :disabled="!hasSelectedItems"
        >
          <Icon icon="arrow-end" class="arrowBottom" />
          {{ $t('POS.SHOP_SETTINGS.SORT.MOVE_BOTTOM') }}
        </button>
        <MoveToPageButton
          v-if="pages > 1"
          :enabled="hasSelectedItems"
          :currentPage="currentPage"
          :maxPage="pages"
          @moveToPage="moveToPage"
        />
      </div>
      <div class="col" v-if="resetLabel">
        <button class="resetBtn btn btn-light icon-btn" @click="reset">
          <Icon v-if="resetIcon" :icon="resetIcon" />
          {{ $t(resetLabel) }}
        </button>
      </div>
    </header>
    <draggable
      v-model="items"
      item-key="id"
      class="list"
      element="ul"
      @change="onSortingChange"
      :options="{ forceFallback: true }"
    >
      <template #item="{ element }">
        <li
          class="item"
          :class="[element.class, { selected: element.selected }]"
        >
          <img
            v-if="element.imageUrl"
            class="image"
            :src="`${element.imageUrl},width=200,height=200`"
            loading="lazy"
          />
          <p class="name">{{ element.name }}</p>
          <Checkbox class="checkbox" v-model="element.selected" />
        </li>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Checkbox from '@/checkbox/Checkbox.vue';
import MoveToPageButton from './MoveToPageButton.vue';

export default {
  name: 'ItemDragger',
  props: {
    orderedItems: { type: Array, required: true },
    mapItem: { type: Function, required: true }, // needs to return { id, name, imageUrl, [class] }
    resetLabel: { type: String, default: '' },
    resetIcon: { type: String, default: '' },
    pages: { type: Number, default: 1 },
    currentPage: { type: Number, default: 0 },
    hasSelectAll: { type: Boolean, default: true },
  },
  components: {
    draggable,
    Checkbox,
    MoveToPageButton,
  },
  data() {
    return {
      items: this.orderedItems.map(this.mapInternalItem),
      sticky: false, // shadow on sticky header
    };
  },
  mounted() {
    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver(
        ([{ isIntersecting }]) => (this.sticky = !isIntersecting)
      );
      this.observer.observe(this.$refs.canary);
    }
  },
  unmounted() {
    this.observer.disconnect();
  },
  watch: {
    orderedItems: {
      handler(updatedItems) {
        this.items = updatedItems.map((item) => {
          const mappedItem = this.mapInternalItem(item);
          return (
            this.items.find(({ id }) => id === mappedItem.id) || mappedItem
          );
        });
      },
      deep: true,
    },
  },
  computed: {
    selectedItems() {
      return this.items.filter(({ selected }) => selected);
    },
    unselectedItems() {
      return this.items.filter(({ selected }) => !selected);
    },
    hasSelectedItems() {
      return this.items.filter(({ selected }) => selected).length > 0;
    },
  },
  methods: {
    mapInternalItem(item) {
      const mappedItem = this.mapItem(item);
      mappedItem.selected = false;
      return mappedItem;
    },
    selectAll() {
      this.unselectedItems.forEach((item) => {
        item.selected = true;
      });
    },
    deselectAll() {
      this.selectedItems.forEach((item) => {
        item.selected = false;
      });
    },
    moveTop() {
      this.selectedItems
        .slice()
        .reverse()
        .forEach(({ id }) =>
          this.$emit('change:position', { id, position: 0 })
        );
    },
    moveBottom() {
      this.selectedItems.forEach(({ id }) =>
        this.$emit('change:position', { id, position: this.items.length - 1 })
      );
    },
    onSortingChange({ moved: { element: { id } = {}, newIndex } } = {}) {
      if (id) {
        this.$emit('change:position', {
          id,
          position: Math.min(newIndex, this.items.length - 1),
        });
      }
    },
    moveToPage(targetPage) {
      if (
        Number.isNaN(targetPage) ||
        targetPage < 0 ||
        targetPage > this.pages - 1
      ) {
        // just a safe guard, the UI component should not even provide such a value
        throw new Error(`Page ${targetPage} invalid`);
      }

      this.$emit('change:page', {
        ids: this.selectedItems.map(({ id }) => id),
        page: targetPage,
      });
    },
    reset() {
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 1em -1em;
  padding: 0;
}

.item {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% / 6 - 2em + 4px);
  max-width: 200px;
  margin: calc(1em - 2px);
  position: relative;
  border: 2px solid $sprd-color-lightest-grey;
  border-radius: 0.5em;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.selected {
  border-color: $pa-color-main;
}

.name {
  flex: 1;
  padding: 0.5em;
  border-radius: 0 0 0.375em 0.375em;
}

.selected .name {
  font-weight: bold;
}

.checkbox {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
}

header {
  position: sticky;
  top: -24px;
  z-index: 1;
  margin-top: 1em;
  padding: 1em 0 1em;
  font-weight: bold;
  background-color: $sprd-color-lightest-grey;
}

.sticky {
  &:after {
    content: '';
    height: 0.5em;
    position: absolute;
    right: -2px;
    bottom: 0;
    left: -2px;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.16);
  }
}

header,
.col {
  display: flex;
  flex-wrap: wrap;

  button {
    min-width: 6em;
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}

.col {
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  margin-left: 2em;

  &:first-child {
    justify-content: flex-start;
    margin-left: 0;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.ml {
  margin-left: 1em;
}

.arrowTop {
  transform: rotate(-90deg);
  margin: 0 2px 0 -2px;
  vertical-align: -2px;
}

.arrowBottom {
  transform: rotate(90deg);
  margin: 0 2px 0 -2px;
}

.label {
  display: flex;
  align-items: center;
}

@keyframes invalidShake {
  0%,
  100% {
    transform: translateX(0);
  }
  16.6667%,
  83.3335% {
    transform: translateX(-0.25em);
  }
  50% {
    transform: translateX(0.25em);
  }
}

.resetBtn {
  padding-top: 5px;
  padding-bottom: 6px;
  height: 100%;
}
</style>
