export const SettingsData = {
  computed: {
    settings() {
      return this.$store.state.shop.shopSavable.clientData.settings;
    },
    config() {
      return this.$store.state.shop.shopSavable.clientData.config;
    },
    shopId() {
      return this.$store.state.shop.currentShop.id;
    },
  },
  methods: {
    updateSettings(prop, value) {
      this.$store.commit('shop/updateSectionData', {
        key: 'settings',
        data: { [prop]: value },
      });
    },
  },
};
