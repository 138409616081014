import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export const DMS_GROUPS = {
  STARTER: {
    key: 'STARTER',
    meta: {
      name: 'DMS.GROUP_NAME.STARTER',
      image: '/images/dms/starter.png',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.STARTER',
      helpLinkTarget: smartFaqLinks.DMS_STARTER,
    },
  },
  PILOT: {
    key: 'PILOT',
    meta: {
      name: 'DMS.GROUP_NAME.PILOT',
      image: '/images/dms/pilot.png',
      changeHeading: 'DMS.NEW_GROUP.HEADING.PILOT',
      changeDescription: 'DMS.NEW_GROUP.DESCRIPTION.PILOT',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.DEFAULT',
      helpLinkTarget: smartFaqLinks.DMS_PILOT,
    },
  },
  ASTRONAUT: {
    key: 'ASTRONAUT',
    meta: {
      name: 'DMS.GROUP_NAME.ASTRONAUT',
      image: '/images/dms/astronaut.png',
      changeHeading: 'DMS.NEW_GROUP.HEADING.ASTRONAUT',
      changeDescription: 'DMS.NEW_GROUP.DESCRIPTION.ASTRONAUT',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.DEFAULT',
      helpLinkTarget: smartFaqLinks.DMS_ASTRONAUT,
    },
  },
  SPACE_CAPTAIN: {
    key: 'SPACE_CAPTAIN',
    meta: {
      name: 'DMS.GROUP_NAME.SPACE_CAPTAIN',
      image: '/images/dms/space_captain.png',
      changeHeading: 'DMS.NEW_GROUP.HEADING.SPACE_CAPTAIN',
      changeDescription: 'DMS.NEW_GROUP.DESCRIPTION.SPACE_CAPTAIN',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.DEFAULT',
      helpLinkTarget: smartFaqLinks.DMS_SPACE_CAPTAIN,
    },
  },
  MASTER_OF_THE_GALAXY: {
    key: 'MASTER_OF_THE_GALAXY',
    meta: {
      name: 'DMS.GROUP_NAME.MASTER_OF_THE_GALAXY',
      image: '/images/dms/master_of_the_galaxy.png',
      changeHeading: 'DMS.NEW_GROUP.HEADING.MASTER_OF_THE_GALAXY',
      changeDescription: 'DMS.NEW_GROUP.DESCRIPTION.MASTER_OF_THE_GALAXY',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.DEFAULT',
      helpLinkTarget: smartFaqLinks.DMS_MASTER_OF_THE_GALAXY,
    },
  },
  LOST_IN_SPACE: {
    key: 'LOST_IN_SPACE',
    meta: {
      name: 'DMS.GROUP_NAME.LOST_IN_SPACE',
      image: '/images/dms/lost_in_space.png',
      changeHeading: 'DMS.NEW_GROUP.HEADING.LOST_IN_SPACE',
      changeDescription: 'DMS.NEW_GROUP.DESCRIPTION.LOST_IN_SPACE',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.DEFAULT',
      helpLinkTarget: smartFaqLinks.DMS_LOST_IN_SPACE,
    },
  },
  INTERNAL_USER: {
    key: 'INTERNAL_USER',
    meta: {
      name: 'Internal User',
      image: '/images/dms/internal_user.png',
      changeHeading: 'Hey Spreadster!',
      changeDescription:
        'You just became an internal user. Use it on your own risk.',
      limitReached: 'DMS.PUBLISHING_LIMIT_REACHED.DEFAULT',
      helpLinkTarget: smartFaqLinks.DMS_MASTER_OF_THE_GALAXY,
    },
  },
};

export const DMS_PROPERTIES = {
  DESIGN_CHECKLIST: {
    key: 'DESIGN_CHECKLIST',
    value: (group) =>
      group.designerGroup === DMS_GROUPS.STARTER.key ? 10 : null,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.PUBLISHING_LIMIT.NAME.STARTER',
        description: 'DMS.PROPERTY.PUBLISHING_LIMIT.DESCRIPTION.STARTER',
        icon: 'approval',
      };
    },
  },
  PUBLISHING_LIMIT: {
    key: 'PUBLISHING_LIMIT',
    value: (group) => group.publishingLimit,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.PUBLISHING_LIMIT.NAME.DEFAULT',
        description: 'DMS.PROPERTY.PUBLISHING_LIMIT.DESCRIPTION.DEFAULT',
        icon: 'image',
      };
    },
  },
  STAR_COUNT: {
    key: 'STAR_COUNT',
    value: (group) => group.starCount,
    meta: () => {
      return {
        icon: 'star',
      };
    },
  },
  INT_PUB: {
    key: 'INT_PUB',
    value: (group) => group.hasInternationalPublishingEnabled,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.INT_PUB.NAME',
        description: 'DMS.PROPERTY.INT_PUB.DESCRIPTION',
        icon: 'globe_light',
      };
    },
  },
  INT_PUB_ADVANCED: {
    key: 'INT_PUB_ADVANCED',
    value: (group) => group.hasEnhancedInternationalPublishingEnabled,
    meta: () => {
      return {
        icon: 'globe_light',
      };
    },
  },
  EMBROIDERY_TEMPLATES: {
    key: 'EMBROIDERY_TEMPLATES',
    value: (group) => group.canCreateEmbroideryTemplate,
    meta: () => {
      return {
        icon: 'globe_light',
      };
    },
  },
  EMAIL_SUPPORT: {
    key: 'EMAIL_SUPPORT',
    value: (group) => group.hasEmailSupport,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.EMAIL_SUPPORT.NAME',
        description: 'DMS.PROPERTY.EMAIL_SUPPORT.DESCRIPTION',
        icon: 'graduation',
      };
    },
  },
  CAMPAIGN_ACCESS: {
    key: 'CAMPAIGN_ACCESS',
    value: (group) => group.hasCampaignAccess,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.CAMPAIGN_ACCESS.NAME',
        description: 'DMS.PROPERTY.CAMPAIGN_ACCESS.DESCRIPTION',
        icon: 'announcement',
      };
    },
  },
  INSTANT_PUBLISHING: {
    key: 'INSTANT_PUBLISHING',
    value: (group) => group.hasInstantPublishingEnabled,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.INSTANT_PUBLISHING.NAME',
        description: 'DMS.PROPERTY.INSTANT_PUBLISHING.DESCRIPTION',
        icon: 'rocket',
      };
    },
  },
  SOCIAL_MEDIA_EVENTS: {
    key: 'SOCIAL_MEDIA_EVENTS',
    value: (group) => group.hasExclusiveEventsAndSocialMediaGroupAccess,
    meta: () => {
      return {
        name: 'DMS.PROPERTY.SOCIAL_MEDIA_EVENTS.NAME',
        description: 'DMS.PROPERTY.SOCIAL_MEDIA_EVENTS.DESCRIPTION',
        icon: 'facebook',
      };
    },
  },
};

export const DMS_SORTED_BENEFIT_LIST = [
  DMS_PROPERTIES.EMAIL_SUPPORT,
  DMS_PROPERTIES.INT_PUB,
  DMS_PROPERTIES.CAMPAIGN_ACCESS,
  DMS_PROPERTIES.INSTANT_PUBLISHING,
  DMS_PROPERTIES.SOCIAL_MEDIA_EVENTS,
];
