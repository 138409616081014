<template>
  <div>
    <ToggleSwitch
      v-model="settings.promoChecked"
      label="POS.SHOP_SETTINGS.PROMOTIONS.PROMOTIONS_ENABLED"
    />
    <SmartFaqLink :link="linkTarget" />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'PromotionsLeftMenu',
  components: {
    ToggleSwitch,
    SmartFaqLink,
  },
  mixins: [SettingsData],
  data() {
    return {
      linkTarget: smartFaqLinks.PROMO_FAQ,
    };
  },
};
</script>

<style lang="scss" scoped>
.promotionLink {
  svg {
    width: 24px;
    height: 24px;
  }
}
</style>
