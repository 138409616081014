<template>
  <div class="dialog-size-md glossary-list">
    <div class="modal-body text-center">
      <h1>{{ $t(heading) }}</h1>
      <ul>
        <li v-for="item in glossary" :key="item.key">
          <div class="glossary-text">
            <h4>
              <Icon
                v-if="item.icon"
                :icon="item.icon"
                :style="{ color: item.color }"
              />
              <div
                v-else-if="item.color"
                class="circle"
                :style="{ backgroundColor: item.color }"
              ></div>
              {{ $t(item.name) }}
            </h4>
            <p>{{ $t(item.description) }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlossaryList',
  props: {
    heading: {
      type: String,
      required: true,
    },
    glossary: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/page-layout.scss';

.idea-status {
  flex-grow: 0;
}

.glossary-list {
  h1 {
    text-align: left;
    margin: 0 0 24px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex-grow: 0;
      align-items: center;
      padding: 24px 16px;
      border-bottom: 1px solid $sprd-color-light-grey;

      &:last-child {
        border: none;
      }

      .icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin: 0 8px 0 0;
      }

      .circle {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        margin: 0 8px 0 0;
        flex-shrink: 0;
      }

      .glossary-text {
        text-align: left;

        p {
          margin: 0;
        }

        h4 {
          margin: 0 0 8px 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
