<template>
  <div class="taxation-eu-company">
    <div>
      <div class="taxation-form-row">
        <h4>
          {{ $t('ACCOUNT.TAXATION.EU_NO_EU.QUELLENSTEUER_HEADING') }}
        </h4>
        <p>{{ $t('ACCOUNT.TAXATION.EU_COMPANY.EXPLANATION2') }}</p>
        <p>
          {{
            $t('ACCOUNT.TAXATION.EU_NO_EU.QUELLENSTEUER_TOOLTIP_EXPLANATION')
          }}
        </p>
        <SmartFaqLink :link="withHoldingTaxFAQLink" />
        <div class="tax-id" :class="{ 'has-error': validation.errors.taxId }">
          <LabelInput>
            <label for="tax-id">{{
              $t('ACCOUNT.TAXATION.EU_COMPANY.TAX_ID')
            }}</label>
            <div class="position-relative">
              <DebounceInput
                id="tax-id"
                type="text"
                :model-value="taxationData.taxId"
                :placeholder="obfuscatedData.taxId"
                @update:model-value="setTaxId"
              />
              <a
                v-if="!taxationData.taxId && obfuscatedData.taxId"
                href
                class="input-clear-button"
                @click.prevent="clearObfuscatedField({ field: 'taxId' })"
              >
                <Icon icon="close"></Icon>
              </a>
            </div>
            <AccountError
              v-if="validation.errors.taxId"
              :reason="validation.errors.taxId.reason"
            ></AccountError>
          </LabelInput>
          <a
            href="https://ec.europa.eu/taxation_customs/tin/tinByCountry.html?locale=en"
            target="_blank"
            rel="noopener"
            tabindex="-1"
            class="link text-sm"
            >{{ $t('ACCOUNT.TOOLTIPS.TOOLTIP_LABEL') }}</a
          >
        </div>
      </div>
    </div>
    <div>
      <div class="taxation-form-row">
        <h4>
          {{ $t('ACCOUNT.TAXATION.EU_NO_EU.UMSATZSTEUER_HEADING') }}
        </h4>
        <p>{{ $t('ACCOUNT.TAXATION.EU_COMPANY.EXPLANATION3') }}</p>
        <p>{{ $t('ACCOUNT.TAXATION.EU_COMPANY.EXPLANATION4') }}</p>
        <p>
          {{ $t('ACCOUNT.TAXATION.EU_NO_EU.UMSATZSTEUER_TOOLTIP_EXPLANATION') }}
        </p>
        <SmartFaqLink :link="taxationFAQLink" />
        <div class="vat-id" :class="{ 'has-error': validation.errors.vatId }">
          <Dropdown
            :items="countries"
            class="taxation-country"
            :item-key="(country) => country.countryCode"
            :item-to-string="
              (country) => `${country.countryCode} - ${country.name}`
            "
            :selected-item-to-string="(country) => country.countryCode"
            :value="currentTaxCountry"
            @change="setTaxCountry"
          >
          </Dropdown>
          <LabelInput>
            <label for="vat-id"
              >{{ $t('ACCOUNT.TAXATION.EU_COMPANY.VAT_ID') }}:</label
            >
            <div class="position-relative">
              <DebounceInput
                id="vat-id"
                type="text"
                :model-value="taxationData.vatId"
                :placeholder="obfuscatedData.vatId"
                @update:model-value="setVatId"
              />
              <a
                v-if="!taxationData.vatId && obfuscatedData.vatId"
                href
                class="input-clear-button"
                @click.prevent="clearObfuscatedField({ field: 'vatId' })"
              >
                <Icon icon="close"></Icon>
              </a>
            </div>
            <AccountError
              v-if="validation.errors.vatId"
              :reason="validation.errors.vatId.reason"
            ></AccountError>
          </LabelInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import AccountError from './../../errorMessage/AccountError.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';

import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'CompanyDE',
  components: {
    AccountError,
    LabelInput,
    Dropdown,
    SmartFaqLink,
    DebounceInput,
  },
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      withHoldingTaxFAQLink: smartFaqLinks.WITHHOLDING_TAX_INFO,
      taxationFAQLink: smartFaqLinks.TAXATION_INFO,
    };
  },
  computed: {
    ...mapState({
      taxationData: (state) => state.userTaxation.data,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
      countries: (state) => state.userTaxation.countries,
    }),
    ...mapGetters({
      currentTaxCountry: 'userTaxation/currentTaxCountry',
    }),
  },
  methods: {
    ...mapMutations({
      updateTaxId: 'userTaxation/setTaxId',
      updateVatId: 'userTaxation/setVatId',
      updateTaxCountry: 'userTaxation/setTaxCountry',
      clearObfuscatedField: 'userTaxation/clearObfuscatedField',
    }),
    setTaxId(taxId) {
      this.updateTaxId({ taxId });
    },
    setVatId(vatId) {
      this.updateVatId({ vatId });
    },
    setTaxCountry(country) {
      this.updateTaxCountry({ countryCode: country.countryCode });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.taxation-eu-company {
  & > div {
    display: flex;
  }

  .taxation-form-row {
    margin-top: 24px;
  }

  .link {
    margin-left: 10px;
    color: $grey40;
  }

  .vat-id {
    display: flex;
  }

  :deep(.dropdown-items) {
    width: 220%;
  }

  .tax-id .labelInput,
  .vat-id .labelInput {
    width: 250px;
  }

  .tax-id,
  .vat-id {
    margin-top: 16px;
  }

  .taxid-separator {
    margin: 16px 0;
  }

  .vat-id-input-container {
    display: flex;
  }

  .taxation-country {
    margin-right: 10px;
  }
}
</style>
