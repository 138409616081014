import { createI18n } from 'vue-i18n';
import localeService from '@/localeService/localeService';

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  missingWarn: false,
  fallbackWarn: false,
});

const loadedLocales = [];

function setI18nLocale(locale) {
  i18n.global.locale.value = locale;
  document.documentElement.setAttribute(
    'lang',
    localeService.getLanguageFromLocale(locale) || 'en'
  );
  return locale;
}

export async function loadLocaleAsync(locale) {
  if (loadedLocales.includes(locale)) {
    return;
  }

  try {
    const response = await fetch(`/locales/${locale}.json`);
    const messages = await response.json();
    i18n.global.setLocaleMessage(locale, messages);
    loadedLocales.push(locale);

    return;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function initializeTranslations({ displayKeys } = {}) {
  const fallbackLocale =
    SPRD.PLATFORM.toUpperCase() === 'NA' ? 'en_US' : 'en_GB';
  const domainLocale = localeService.getLocale();

  if (!displayKeys) {
    i18n.global.fallbackLocale.value = fallbackLocale;
    setI18nLocale(domainLocale);
  }

  const localesToLoad = [loadLocaleAsync(domainLocale)];
  if (domainLocale !== fallbackLocale) {
    localesToLoad.push(loadLocaleAsync(fallbackLocale));
  }

  await Promise.allSettled(localesToLoad);

  if (loadedLocales.length) {
    var element = document.querySelector('body');
    element.classList.remove('translations-pending');
  }
}
