<template>
  <div class="shop-indicators" v-if="customized || bonus.active">
    <CustomizedIndicator v-if="customized" :pos="pos" />
    <PerformanceIndicator v-if="bonus.active" :amount="amount" />
  </div>
</template>

<script>
import CustomizedIndicator from 'src/app/components/indicator/CustomizedIndicator.vue';
import PerformanceIndicator from 'src/app/components/indicator/PerformanceIndicator.vue';

export default {
  name: 'OrderIndicators',
  components: {
    CustomizedIndicator,
    PerformanceIndicator,
  },
  props: {
    bonus: {
      type: Object,
    },
    customized: {
      type: Boolean,
      default: false,
    },
    pos: {
      type: String,
    },
  },
  computed: {
    amount() {
      let bonusAmount;

      if (this.bonus.amount && this.bonus.currency) {
        bonusAmount = this.sprdCurrency(this.bonus.amount, this.bonus.currency);
      }

      return bonusAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-indicators {
  display: flex;
  margin-top: 8px;

  & > div:not(:first-child) {
    margin-left: 16px;
  }
}
</style>
