import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

export const TrackingData = {
  beforeCreate() {
    if (!this.$store.state.shop.shopSavable.clientData.tracking) {
      this.$store.commit('shop/addDynamicSection', {
        key: 'tracking',
        initialData: {},
      });

      shopAPI.getTracking(this.$store).then((data) =>
        this.$store.commit('shop/updateSectionOriginalData', {
          sectionKey: 'tracking',
          origData: data,
        })
      );
    }
  },
  computed: {
    tracking() {
      return this.$store.state.shop.shopSavable.clientData.tracking || {};
    },
  },
};
