<template>
  <div class="digital-product-status text-truncate">
    <p v-if="label">{{ $t('GENERAL.STATUS') }}:</p>
    <span :style="{ color: displayState.color }">{{
      $t(displayState.name)
    }}</span>
    <button
      v-if="displayGuide"
      class="text-btn link-main icon-link glossary-link"
      @click="openStatusGlossary"
    >
      <Icon icon="question" />
    </button>
  </div>
</template>

<script>
import { getDigitalProductState } from '@/api/models/digitalProducts/digitalProductsStates';
import dialogService from '@/dialogs/wrapper/dialogService';
import DigitalProductsStatusDialog from './DigitalProductsStatusDialog.vue';

export default {
  name: 'DigitalProductStatus',
  props: {
    digitalProduct: {
      type: Object,
    },
    state: {
      type: Object,
    },
    label: {
      type: Boolean,
    },
    displayGuide: {
      type: Boolean,
    },
  },
  computed: {
    displayState() {
      if (this.digitalProduct) {
        return getDigitalProductState(this.digitalProduct);
      } else {
        return this.state;
      }
    },
  },
  methods: {
    openStatusGlossary() {
      dialogService.openDialog(DigitalProductsStatusDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.digital-product-status {
  display: flex;
  align-items: center;
}

p {
  display: inline;
  margin: 0 4px 0 0;
}

button {
  margin-left: 8px;
}
</style>
