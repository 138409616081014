<template>
  <div class="connect-device-dialog">
    <div v-if="!showSuccess">
      <div class="code">
        <div v-if="displayCode" class="qrcode">
          <QRCanvas :options="options" />

          <div class="expire-overlay" v-if="expired">
            <Icon icon="refresh" />
            <p>{{ $t('CONNECT_DEVICES.QR.EXPIRED') }}</p>
            <button class="btn btn-ghost" @click="connectDevice">
              {{ $t('CONNECT_DEVICES.QR.RELOAD') }}
            </button>
          </div>
        </div>
        <div v-else class="loader">
          <LoadingHeart />
        </div>
      </div>
      <div class="explanation">
        <h1>{{ $t('CONNECT_DEVICES.QR.HEADING') }}</h1>

        <div class="step">
          <strong>1.</strong>
          {{ $t('CONNECT_DEVICES.QR.EXPLANATION1') }}
        </div>
        <div class="step">
          <strong>2.</strong>
          {{ $t('CONNECT_DEVICES.QR.EXPLANATION2') }}
        </div>
        <div class="step">
          <strong>3.</strong>
          {{ $t('CONNECT_DEVICES.QR.EXPLANATION3') }}
        </div>

        <div class="help-link">
          <a href="#" class="link-main" @click.prevent="openHelp">{{
            $t('CONNECT_DEVICES.QR.HELP')
          }}</a>
        </div>
      </div>
    </div>
    <div v-else class="success-msg">
      <img src="/images/party.svg" />
      <h1>{{ $t('CONNECT_DEVICES.QR.SUCCESS.HEADING') }}</h1>
      <p>{{ $t('CONNECT_DEVICES.QR.SUCCESS.SUBHEADING') }}</p>
    </div>
  </div>
</template>

<script>
async function importQRCanvas() {
  const { QRCanvas } = await import(
    /* webpackChunkName: "qrcanvas" */ 'qrcanvas-vue'
  );
  return QRCanvas;
}

import { mapGetters } from 'vuex';
import { isEqual } from '@/utils';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import parseISO from 'date-fns/parseISO';
import deviceService from '@/api/deviceService/deviceService';
import HelpDialog from 'src/app/components/helpDialog/HelpDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';

export default {
  name: 'ConnectDeviceDialog',
  props: {
    modalInstance: Object,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    QRCanvas: () => importQRCanvas(),
    LoadingHeart,
  },
  data() {
    return {
      redirectUrl: this.$route.query.redirectUrl,
      displayCode: false,
      qrCodeData: null,
      image: null,
      expireTimer: null,
      expired: false,
      connectedDevices: null,
      devicePollingInterval: null,
      initialized: false,
      showSuccess: false,
    };
  },
  watch: {
    connectedDevices: {
      handler(newDevices, oldDevices) {
        if (
          oldDevices &&
          !isEqual(newDevices, oldDevices) &&
          newDevices.length >= oldDevices.length
        ) {
          this.showSuccess = true;
          this.clearDevicePollingInterval();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: 'account/isAdmin',
    }),
    options() {
      return {
        size: 500,
        correctLevel: 'L',
        foreground: '#3075B4',
        data: this.qrCodeData,
      };
    },
  },
  async created() {
    await this.connectDevice();

    await this.getConnectedDevices();
    this.devicePollingInterval = setInterval(() => {
      this.getConnectedDevices();
    }, 2000);
  },
  methods: {
    async connectDevice() {
      this.displayCode = false;

      const { token, expireDate } = await deviceService.getToken();

      this.clearExpireTimer();
      this.expireTimer = setTimeout(() => {
        this.expired = true;
      }, differenceInMilliseconds(parseISO(expireDate), new Date()) - 10000);

      this.qrCodeData = `partnerapp://auth/signIn?platform=${
        SPRD.PLATFORM
      }&token=${token}${this.isAdmin ? '&admin=true' : ''}`;
      this.displayCode = true;
    },
    clearExpireTimer() {
      this.expired = false;
      if (this.expireTimer) {
        clearTimeout(this.expireTimer);
      }
    },
    clearDevicePollingInterval() {
      if (this.devicePollingInterval) {
        clearInterval(this.devicePollingInterval);
      }
    },
    openHelp() {
      this.modalInstance.dismiss();
      dialogService.openDialog(HelpDialog);
    },
    async getConnectedDevices() {
      this.connectedDevices = await deviceService.fetchConnectedDevices();
    },
  },
  beforeUnmount() {
    this.clearExpireTimer();
    this.clearDevicePollingInterval();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.connect-device-dialog > div {
  display: flex;
}

.explanation {
  text-align: left;
  padding-left: 24px;

  h1 {
    margin: 0 0 16px 0;
  }

  .step {
    color: $grey60;
    margin-bottom: 16px;
  }

  .help-link {
    margin-top: 16px;
  }
}

.code,
canvas {
  width: 200px;
  height: 200px;
}

.qrcode {
  position: relative;

  canvas {
    width: 200px;
    height: 200px;
  }
}

.loader {
  width: 100%;
  height: 100%;
}

.expire-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparentize($pa-color-main, 0.1);

  .icon {
    width: 64px;
    height: 64px;
    color: $white;
  }

  p {
    color: $white;
    margin: 24px 0;
    font-weight: bold;
  }

  .btn {
    color: $white;
    border-color: $white;

    &:hover {
      border-color: $grey10;
    }
  }
}

.success-msg {
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
  }

  h1 {
    margin: 32px 0 16px 0;
    font-size: 32px;
  }

  p {
    color: $grey60;
  }
}
</style>
