<template>
  <div class="error-page">
    <img src="images/chillout.svg" />
    <h2>
      {{ $t('STATISTICS.TRACKING_METRICS.ERROR_HEADING') }}
    </h2>
    <p class="text-grey">
      {{ $t('STATISTICS.TRACKING_METRICS.ERROR_DESCRIPTION') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TrackingMetricsErrorPage',
};
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 125px;
  background-color: transparent !important;

  img {
    height: auto;
    width: 20em;
  }

  h2 {
    margin-block: 24px 16px;
  }
}
</style>
