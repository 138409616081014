<template>
  <div class="idea-preview-image">
    <div
      v-if="
        !ideaState.isProcessing() &&
        (!ideaState.isAttention() ||
          (uploadInteractions && uploadInteractions.images))
      "
      class="preview-image-container"
      :class="{
        'design-checked-background': checkedBackground,
        dark: darkBackground && checkedBackground,
      }"
    >
      <div :class="{ 'preview-image': allowBackgroundChange }">
        <SprdImage
          v-if="!uploadInteractions"
          :src="currentImage"
          class="design-image"
          alt="Design Image"
        />
        <img
          v-if="uploadInteractions"
          :src="`${uploadInteractions.images[0].href}?width=${
            width || 300
          }&height=${height || 200}`"
          class="design-image"
          alt="Design Interaction Needed"
        />
      </div>
      <div
        class="overlay"
        v-if="enableOverlay"
        :class="{ 'display-overlay': displayOverlay }"
      >
        <div>
          <div class="overlay-content">
            <div class="edit-icon">
              <Icon :icon="hoverIcon || 'pen'" />
            </div>
          </div>
        </div>
      </div>

      <div class="image-switcher" v-if="images.length > 1" @click.stop>
        <div class="options">
          <a
            href="#"
            v-for="(image, index) in images"
            :key="`${image.href}-${index}`"
            @click.prevent.stop="currentImageIndex = index"
            :class="{ active: index === currentImageIndex }"
          >
            <div class="dot"></div>
          </a>
        </div>
      </div>
    </div>
    <div
      class="preview-image-container preview-image-loader"
      v-if="ideaState.isProcessing()"
    >
      <div class="preview-image-content">
        <Icon icon="preloader" />
      </div>
    </div>
    <div
      class="preview-image-container preview-image-placeholder"
      v-if="
        ideaState.isAttention() &&
        !(uploadInteractions && uploadInteractions.images)
      "
    >
      <div class="preview-image-content">
        <Icon icon="broken-file" />
      </div>
      <div
        class="overlay"
        v-if="enableOverlay"
        :class="{ 'display-overlay': displayOverlay }"
      >
        <div>
          <div class="overlay-content">
            <div class="edit-icon">
              <Icon :icon="hoverIcon || 'pen'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      href="#"
      class="dark-background-toggle"
      v-if="allowBackgroundChange"
      @click.prevent="$emit('toggleBackgroundColor')"
      v-tooltip.left="{
        content: backgroundTooltip,
        theme: 'tooltip-dark',
      }"
    >
      <Icon
        icon="background-color"
        :class="{ 'rotate-icon-180': idea.backgroundColor }"
      />
    </a>
  </div>
</template>

<script>
import SprdImage from '@/image/SprdImage.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';

export default {
  name: 'IdeaPreviewImage',
  components: {
    SprdImage,
  },
  props: {
    idea: {
      type: Object,
      required: true,
    },
    enableOverlay: {
      type: Boolean,
    },
    displayOverlay: {
      type: Boolean,
    },
    allowBackgroundChange: {
      type: Boolean,
    },
    checkedBackground: {
      type: Boolean,
    },
    hoverIcon: {
      type: String,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      currentImageIndex: 0,
    };
  },
  computed: {
    images() {
      return this.idea.resources
        ? this.idea.resources.filter((r) => r.type !== 'default')
        : [];
    },
    currentImage() {
      return `${this.images[this.currentImageIndex].href},width=${
        this.width || 300
      },height=${this.height || 200}`;
    },
    backgroundTooltip() {
      return `
                <strong>
                    ${this.$t(
                      this.idea.backgroundColor
                        ? 'DESIGNS.DETAILS.CHANGE_TO_LIGHT_BACKGROUND'
                        : 'DESIGNS.DETAILS.CHANGE_TO_DARK_BACKGROUND'
                    )}
                </strong>
                <br>
                <span class="text-xs">
                    ${this.$t('DESIGNS.DETAILS.CHANGE_TO_DARK_BACKGROUND_HINT')}
                </span>
            `;
    },
    ideaState() {
      return ideaHelper.getState(this.idea);
    },
    darkBackground() {
      return ideaHelper.hasDarkBackground(this.idea);
    },
    uploadInteractions() {
      return (
        this.idea.bitmapUploadInteractions || this.idea.vectorUploadInteractions
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.idea-preview-image {
  position: relative;
}

.preview-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-switcher {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  .options {
    display: flex;
    background-color: #fff;
    padding: 6px 16px;
    border: 2px solid $grey5;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    cursor: default;

    a {
      padding: 2px;

      .dot {
        width: 8px;
        height: 8px;
        border: 1px solid $grey70;
        border-radius: 100%;
      }

      &.active .dot {
        background-color: $grey70;
      }
    }
  }
}

.dark-background-toggle {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  color: $grey80;

  .icon {
    height: 16px;
    width: 16px;
  }

  &:hover {
    color: $grey90;
  }

  .dark + & {
    color: $grey20;

    &:hover {
      color: $grey10;
    }
  }
}

.preview-image-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  :deep(img) {
    padding: 5px;
    display: block;
    max-width: 100%;
    height: auto;
  }

  &:hover {
    .overlay {
      display: block;
    }
  }

  .preview-image {
    position: relative;
  }
}

.overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;

  &.display-overlay {
    display: block;
  }

  & > div {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .overlay-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .edit-icon {
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $pa-color-main;

        .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.preview-image-loader,
.preview-image-placeholder {
  .preview-image-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: $sprd-color-light-grey;

    .icon {
      width: 70px;
      height: 70px;
      color: #fff;
    }
  }

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.preview-image-placeholder {
  .preview-image-content {
    .icon {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
