<template>
  <div class="tracking-metrics-hint">
    <h3>
      {{ $t(heading) }}
    </h3>
    <p class="text-grey few-metrics-hint">
      {{ $t(description) }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TrackingMetricsHint',
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.tracking-metrics-hint {
  margin-inline: -24px;
  padding: 24px;
  border-block: 1px solid $grey5;

  h3 {
    margin: 0;
  }

  h3,
  p {
    margin-bottom: 16px;
  }
}
</style>
