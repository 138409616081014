import { deepCopy } from '@/utils';
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import compositionHelper from '@/composition/compositionHelper';
import { isEqual } from '@/utils';
import compositionValidator from '@/api/validators/composition/compositionValidator';

export default {
  namespaced: true,
  state: {
    current: null,
    original: null,
  },
  getters: {
    dirty: (state) => !isEqual(state.current, state.original),
    isPublic: (state) =>
      state.current?.publishingDetails?.d2c?.mp ||
      state.current.publishingDetails.spreadShops.shops.length,
    shouldValidate: (state, getters) => getters.isPublic,
    defaultAppearanceGroup: (state) =>
      compositionHelper.getDefaultAppearanceGroup(state.current),
  },
  mutations: {
    setComposition(state, { composition }) {
      state.current =
        compositionHelper.addEmptyDataStructureForDefaultMetaData(composition);
      state.original = deepCopy(state.current);
    },
    resetToOriginal(state) {
      state.current = deepCopy(state.original);
    },
    setName: (state, { name, language }) => {
      compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'name',
        language,
      }).value = name;
    },
    setDescription: (state, { description, language }) => {
      compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'description',
        language,
      }).value = description;
    },
    addTag: (state, { tag, language }) => {
      const tagsMetaData = compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'tags',
        language,
      });

      if (!tagsMetaData.values.includes(tag)) {
        tagsMetaData.values.push(tag);
      }
    },
    removeTag: (state, { tag, language }) => {
      const tagsMetaData = compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'tags',
        language,
      });
      tagsMetaData.values = tagsMetaData.values.filter((t) => t !== tag);
    },
    setMpPublishing: (state, mpState) => {
      state.current.publishingDetails.d2c.mp = mpState;
      state.current.publishingDetails.publish = true;
    },
    publishToShop: (state, shopId) => {
      if (
        state.current.publishingDetails.spreadShops.shops.some(
          (shop) => shop.id === parseInt(shopId)
        )
      ) {
        return;
      }
      state.current.publishingDetails.spreadShops.shops.push({
        id: parseInt(shopId),
      });
    },
    unpublishFromShop: (state, shopId) => {
      state.current.publishingDetails.spreadShops.shops =
        state.current.publishingDetails.spreadShops.shops.filter(
          (shop) => shop.id !== parseInt(shopId)
        );
    },
    setDefaultLanguage: (state, { language }) => {
      state.current.metaData.originLanguageCode = language;
      state.current = compositionHelper.addEmptyDataStructureForDefaultMetaData(
        state.current
      );
    },
    setAutoTranslate: (state, { autoTranslate }) => {
      state.current.metaData.autoTranslate = autoTranslate;
    },
    toggleBackgroundColor: (state) => {
      state.current.properties.darkBackground =
        !state.current.properties.darkBackground;
    },
    setMpTransformerMode(state, mode) {
      if (state.current?.publishingDetails?.d2c?.mpTransformerMode) {
        state.current.publishingDetails.d2c.mpTransformerMode = mode;
      }
    },
    updateCompositionAfterSellableUpdate(state, updatedComposition) {
      const propertiesToCopy = [
        'defaultValues',
        'sellableErrors',
        'sellables',
        'templateProducts',
      ];
      propertiesToCopy.forEach((property) => {
        state.current[property] = deepCopy(updatedComposition[property]);
      });
    },
  },
  actions: {
    fetchComposition: async ({ commit }, compositionId) => {
      const composition = await publishingCoreService.getComposition(
        compositionId
      );
      commit('setComposition', { composition });
    },
    updateComposition: async ({ commit, state, getters }) => {
      let validation = {
        result: true,
      };
      if (getters.shouldValidate) {
        validation = compositionValidator.validate(state.current);
      }
      if (!validation.result) {
        return Promise.reject(validation);
      }
      const composition = await publishingCoreService.updateComposition(
        state.current
      );
      commit('setComposition', { composition });
      commit('compositions/replaceComposition', composition, { root: true });
    },
    updateCompositionSellables: async (
      { commit, state },
      { defaultHotspot, defaultAppearanceGroup, preview } = {}
    ) => {
      const templateProduct = {
        defaultHotspot,
      };

      if (defaultAppearanceGroup) {
        templateProduct.appearanceRestriction = {
          includedAppearanceGroups: [defaultAppearanceGroup],
        };
      }

      const data = await publishingCoreService.updateCompositionSellables({
        composition: state.current,
        payload: [
          {
            templateProduct,
          },
        ],
        preview: preview || false,
      });
      if (!preview) {
        commit('updateCompositionAfterSellableUpdate', data);
      }
      return data;
    },
    publishToAllShops: ({ commit, rootState }) => {
      rootState.publishingCore.shops.forEach((shop) =>
        commit('publishToShop', shop.shopId)
      );
    },
    unpublishFromAllShops: ({ commit, rootState }) => {
      rootState.publishingCore.shops.forEach((shop) =>
        commit('unpublishFromShop', shop.shopId)
      );
    },
    setPublishedShops: ({ commit, state }, shopIds) => {
      const newShopIds = shopIds.map((shopId) => parseInt(shopId));
      state.current.publishingDetails.spreadShops.shops
        .filter((shop) => !newShopIds.includes(shop.id))
        .forEach((shop) => commit('unpublishFromShop', shop.id));

      newShopIds.forEach((shopId) => commit('publishToShop', shopId));
    },
  },
};
