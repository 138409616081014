<template>
  <TagInput
    v-bind="$props"
    :min="validatorOptions.tags.min"
    :max="validatorOptions.tags.max"
    :maxLength="validatorOptions.tags.maxlength"
    :isValid="valid"
    :name="name"
  />
</template>

<script>
import ideaValidator from '@/api/validators/idea/ideaValidator';
import ideaHelper from '@/ideaHelper/ideaHelper';
import TagInput from './TagInput.vue';

export default {
  name: 'IdeaTagInput',
  components: {
    TagInput,
  },
  props: {
    validate: {
      type: Boolean,
    },
    idea: {
      type: Object,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      validatorOptions: ideaValidator.getDefaultOptions(),
    };
  },
  computed: {
    name() {
      return this.idea
        ? ideaHelper.getTranslation(this.idea, this.locale)?.name
        : null;
    },
    valid() {
      if (!this.idea || !this.validate) {
        return true;
      }
      return ideaValidator.validateTags(this.idea, { locale: this.locale });
    },
  },
};
</script>
