import localeService from 'src/app/commons/localeService/localeService';
import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import uploadService from '@/api/upload/uploadService';

export default {
  fetchCurrentUser() {
    return apiClient.request({
      method: 'GET',
      url: `api/v1/users/current`,
      params: {
        mediaType: 'json',
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
    });
  },
  fetchUserLocale() {
    return apiClient.request({
      method: 'GET',
      url: `api/v1/users/${store.state.user.data.id}/locale`,
    });
  },
  fetchUserPointsOfSale() {
    return apiClient.request({
      method: 'GET',
      url: `api/v1/users/${store.state.user.data.id}/pointsOfSale`,
    });
  },
  updateProfileImage(file) {
    const data = {
      filedata: file,
    };

    return uploadService.upload({
      url: `/api/v1/users/${store.state.user.data.id}/profile-uploads`,
      data: data,
      params: {
        mediaType: 'json',
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
    });
  },
};
