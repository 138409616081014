import apiClient from '@/api/client/apiClient';
import localeService from '@/localeService/localeService';

// this actually performs api-core requests, not spreadshop requests!

export default {
  delete(shopId) {
    return apiClient.request({
      method: 'DELETE',
      url: `/api/v1/shops/${shopId}`,
      params: {
        mediaType: 'json',
      },
    });
  },
  getProductTypes(shopId) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/shops/${shopId}/productTypes`,
        params: {
          mediaType: 'json',
          limit: 1000,
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
  getMdsCommissions(userId, shopId) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/users/${userId}/shops/${shopId}/mdsCommissions`,
        params: {
          mediaType: 'json',
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
  patchMdsCommissions(userId, shopId, patchData) {
    return apiClient.request({
      method: 'PATCH',
      url: `/api/v1/users/${userId}/shops/${shopId}/mdsCommissions`,
      params: {
        mediaType: 'json',
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
      data: JSON.stringify(patchData),
    });
  },
  async getPromotionsForShop(shopId) {
    const settingsRes = await apiClient.request({
      method: 'GET',
      url: `/shopsettings/shops/${shopId}/settings`,
    });

    const promotionsRes = await apiClient.request({
      method: 'GET',
      url: `shopsettings/shops/${shopId}/promotions`,
    });
    return {
      promotionsActive: settingsRes.data.promoChecked,
      promotionsData: promotionsRes.data,
    };
  },
};
