import localeService from 'src/app/commons/localeService/localeService';
import { Base64 } from 'js-base64';
import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import jsonpatch from 'fast-json-patch';
import { deepCopy } from '@/utils';

const accountService = {
  async getData() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/users/${store.state.user.data.id}/account`,
      params: {
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
    });
    data.origin = this.prepareData(data.origin);
    return data;
  },
  prepareData(data) {
    const preparedData = deepCopy(data);

    preparedData.isCompany =
      !!preparedData.user.company && !!preparedData.user.company.length;

    return preparedData;
  },
  async saveData(oldAccountData, newAccountData) {
    const oldData = deepCopy(oldAccountData);
    const newData = deepCopy(newAccountData);
    const config = {
      params: {
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
    };

    if (!oldData.isCompany) {
      oldData.user.company = '';
    }

    if (!newData.isCompany) {
      newData.user.company = '';
    }

    delete oldData.isCompany;
    delete newData.isCompany;
    delete newData.user.taxationValid;
    delete oldData.user.taxationValid;

    const { data } = await apiClient.request({
      method: 'PATCH',
      url: `/api/v1/users/${store.state.user.data.id}/account`,
      data: jsonpatch.compare(oldData, newData),
      ...config,
    });
    data.origin = this.prepareData(data.origin);
    return data;
  },
  async deleteAccount(password) {
    const config = {
      params: {
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
      headers: {
        'password-base64': Base64.encode(password),
        'Content-Type': 'application/json',
      },
      data: {},
    };

    await apiClient.request({
      method: 'DELETE',
      url: `rest/user/${store.state.user.data.id}/account`,
      ...config,
    });
    return store.dispatch('user/logout');
  },
  updatePdvState() {
    return accountService.getData().then((data) => {
      store.commit('account/setPdvState', data.addressValidationState);
    });
  },
  async getNewsletterData() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/users/${store.state.user.data.id}/newsletter`,
    });
    return data;
  },
  updateNewsletterData(data) {
    return apiClient.request({
      method: 'PUT',
      url: `/api/v1/users/${store.state.user.data.id}/newsletter`,
      data,
    });
  },
};

export default accountService;
