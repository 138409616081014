import statisticsService from '@/api/statisticsService/statisticsService';

export default {
  namespaced: true,
  state: {
    lifetime: null,
  },
  getters: {
    lifetimeSales: (state) => (state.lifetime ? state.lifetime.sales : null),
  },
  mutations: {
    setLifetimeStatistics(state, data) {
      state.lifetime = data;
    },
  },
  actions: {
    async fetchLifetimeStatistics({ commit }) {
      const data = await statisticsService.getLifetimeStatistics();
      commit('setLifetimeStatistics', data);
    },
  },
};
