<template>
  <div class="ai-design-generator-image-selection">
    <h2>{{ $t('AI_DESIGN_GENERATOR.IMAGE.HEADING') }}</h2>
    <p>{{ $t('AI_DESIGN_GENERATOR.IMAGE.SUBHEADING') }}</p>
    <img
      :src="`data:image/png;base64,${image}`"
      alt=""
      ref="imageElement"
      @load="prepareImage"
    />
    <div class="actions">
      <button class="btn btn-primary btn-xl" @click="submit">
        {{ $t('AI_DESIGN_GENERATOR.IMAGE.CONTINUE') }}
      </button>
      <button class="link-main retry" @click="retry()" :disabled="!openUsage">
        {{ $t('GENERAL.RETRY') }}
      </button>
      <AiDesignGeneratorLimit
        :usageLimit="usageLimit"
        :openUsage="openUsage"
        @buyMore="$emit('survey')"
      />
    </div>
  </div>
</template>

<script>
import AiDesignGeneratorLimit from './AiDesignGeneratorLimit.vue';

export default {
  name: 'AiDesignGeneratorImageSelection',
  props: {
    image: {
      type: String,
      required: true,
    },
    usageLimit: {
      type: Number,
      required: true,
    },
    openUsage: {
      type: Number,
      required: true,
    },
  },
  components: { AiDesignGeneratorLimit },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    async prepareImage() {
      const width = 1024;
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = width;
      const ctx = canvas.getContext('2d');
      const image = this.$refs.imageElement;

      ctx.drawImage(image, 0, 0);

      this.file = await fetch(canvas.toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File(
            [blob],
            `${this.$t('AI_DESIGN_GENERATOR.IMAGE.FILE_NAME')}.png`,
            {
              type: 'image/png',
            }
          );
        });
    },
    async submit() {
      this.$emit('onSubmit', this.file);
    },
    retry() {
      this.$emit('onRetry');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.ai-design-generator-image-selection {
  & > h2 {
    margin: 0 0 8px 0;
  }

  & > p {
    margin: 0 0 24px 0;
    color: $grey65;
    max-width: 70%;
  }
}

.actions {
  display: flex;
  align-items: center;
  margin: 24px 0 0 0;

  .btn-primary {
    padding: 16px 24px;
  }

  .retry {
    margin: 0 8px 0 16px;
  }
}

img {
  max-width: 400px;
}
</style>
