<template>
  <div class="existing-address">
    <p
      class="verified-label"
      v-if="softCheckSucceeded && currentPdvState !== PDV_STATES.CODE_INPUT"
    >
      <Icon icon="verified"></Icon>
      {{ $t('PDV.EXISTING_ADDRESS.VERIFIED') }}
    </p>
    <div class="letter-label" v-if="currentPdvState === PDV_STATES.CODE_INPUT">
      <div class="main-label">
        <div class="icon-wrapper">
          <Icon icon="hourglass"></Icon>
        </div>
        {{ $t('PDV.EXISTING_ADDRESS.LETTER.HEADING') }}
      </div>
      <p v-html="$t('PDV.EXISTING_ADDRESS.LETTER.DESCRIPTION')"></p>
    </div>

    <div class="current-address">
      <p class="address-label" v-html="$t('PDV.EXISTING_ADDRESS.ADDRESS')"></p>
      <p class="street">{{ data.user.street }}</p>
      <p class="street2" v-if="data.user.street2">{{ data.user.street2 }}</p>
      <p class="city">{{ data.user.postalCode }} {{ data.user.city }}</p>
      <p class="country">{{ getCountryNameForId(data.user.countryId) }}</p>
    </div>

    <div class="edit" v-if="!softCheckFailed">
      <a
        href
        class="link link-main"
        @click.prevent="() => $emit('editAddress')"
        v-html="$t('PDV.EXISTING_ADDRESS.EDIT.LINK')"
      ></a>
      <p class="edit-hint" v-html="$t('PDV.EXISTING_ADDRESS.EDIT.INFO')"></p>
    </div>
  </div>
</template>

<script>
import pdvService from '@/partnerDataValidation/service/pdvService';

export default {
  name: 'PdvExistingAddress',
  props: {
    data: {
      type: Object,
      required: true,
    },
    pdvState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PDV_STATES: pdvService.STATES,
    };
  },
  computed: {
    currentPdvState() {
      return pdvService.getCurrentState(this.pdvState);
    },
    softCheckSucceeded() {
      return pdvService.softCheckSucceeded(this.pdvState);
    },
    softCheckFailed() {
      return pdvService.softCheckFailed(this.pdvState);
    },
  },
  methods: {
    getCountryNameForId(countryId) {
      return this.data.user.countries.find(
        (country) => country.countrycode === countryId
      ).name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.existing-address {
  padding: 15px;
  background-color: $sprd-color-lightest-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin-bottom: 5px;
  }

  .address-label {
    color: $sprd-color-medium-grey;
    @extend .text-sm;
  }

  .edit-hint {
    margin: 5px 0 0 0;
    color: $sprd-color-medium-grey;
    @extend .text-sm;
  }

  .verified-label {
    display: flex;
    align-items: center;

    .icon {
      color: $pa-color-main;
      font-size: 1.5em;
      margin-right: 10px;
    }
  }

  .letter-label {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-label {
      display: flex;
      align-items: center;

      .icon-wrapper {
        background-color: $pa-color-main;
        color: #fff;
        border-radius: 100%;
        padding: 5px;
        margin-right: 10px;

        .icon {
          font-size: 1.5em;
        }
      }
    }

    p {
      margin: 5px 0 0 0;
      @extend .text-sm;
      color: $sprd-color-medium-grey;
    }
  }

  .current-address {
    padding: 20px 0;
  }
}
</style>
