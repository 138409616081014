import store from '@/store/Store';
import sprdNumber from '@/number/numberFilter';

const dotCurrencyCodes = ['USD', 'GBP'];

function isDotCurrency(isoCode) {
  return dotCurrencyCodes.includes(isoCode);
}

export default {
  formatValue: function (value, currencyIdOrIso, decimals, hideCurrencySymbol) {
    const currency =
      store.getters['platform/currencyById'](currencyIdOrIso) ||
      store.getters['platform/currencyByIso'](currencyIdOrIso);

    if (!currency) {
      return value;
    }

    const decimalCount =
      decimals !== undefined ? decimals : currency.decimalCount;

    value = sprdNumber(value, decimalCount);

    if (currency.pattern) {
      value = currency.pattern
        .replace('%', value)
        .replace('$', hideCurrencySymbol ? '' : currency.symbol);
    }
    return value.trim();
  },
  parseValue: function (val, currencyIdOrIso) {
    const currency =
      store.getters['platform/currencyById'](currencyIdOrIso) ||
      store.getters['platform/currencyByIso'](currencyIdOrIso);
    if (!currency) {
      return val;
    }
    const dotCurrency = isDotCurrency(currency.isoCode);
    if (!dotCurrency) {
      val = val.replace(',', '.');
    }
    val = parseFloat(val) || null;

    const pow = Math.pow(10, currency.decimalCount);
    return Math.round(val * pow) / pow;
  },
};
