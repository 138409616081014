import { deepCopy } from 'src/app/commons/utils';

const fieldsToValidateByState = {
  'shop-area.general.imprint': [
    'firstName',
    'lastName',
    'street',
    'streetAnnex',
    'houseNumber',
    'zipCode',
    'city',
    'country',
    'state',
    'email',
    'company',
    'authorizedRepresentative',
    'phone',
    'vatId',
    'additionalInfo',
  ],
  'shop-area.marketing.tracking': [
    'googleAnalyticsTrackingId',
    'facebookPixelId',
    'googleWebmasterId',
  ],
  'shop-area.pages.collections-detail': ['collectionName'],
};

const singleValidate = {
  firstName(data) {
    return data.config.shopAddress &&
      !data.config.shopAddress.isCompany &&
      data.config.shopAddress.person
      ? validateStringAttribute(
          'firstName',
          data.config.shopAddress.person.firstName,
          { required: true, maxLength: 255 }
        )
      : getValidResultObject();
  },
  lastName(data) {
    return data.config.shopAddress &&
      !data.config.shopAddress.isCompany &&
      data.config.shopAddress.person
      ? validateStringAttribute(
          'lastName',
          data.config.shopAddress.person.lastName,
          { required: true, maxLength: 255 }
        )
      : getValidResultObject();
  },
  authorizedRepresentative(data) {
    return data.config.shopAddress && data.config.shopAddress.isCompany
      ? validateStringAttribute(
          'authorizedRepresentative',
          data.config.shopAddress.authorizedRepresentative,
          { required: true, maxLength: 511 }
        )
      : getValidResultObject();
  },
  street(data) {
    return data.config.shopAddress && SPRD.PLATFORM !== 'NA'
      ? validateStringAttribute('street', data.config.shopAddress.street, {
          required: true,
          maxLength: 255,
        })
      : getValidResultObject();
  },
  streetAnnex(data) {
    return data.config.shopAddress && SPRD.PLATFORM !== 'NA'
      ? validateStringAttribute(
          'streetAnnex',
          data.config.shopAddress.streetAnnex,
          { maxLength: 255 }
        )
      : getValidResultObject();
  },
  phone(data) {
    return data.config.shopAddress
      ? validateStringAttribute('phone', data.config.shopAddress.phone, {
          maxLength: 255,
        })
      : getValidResultObject();
  },
  vatId(data) {
    return data.config.shopAddress
      ? validateStringAttribute('vatId', data.config.shopAddress.vatId, {
          maxLength: 36,
        })
      : getValidResultObject();
  },
  tradeRegister(data) {
    return data.config.shopAddress
      ? validateStringAttribute(
          'tradeRegister',
          data.config.shopAddress.tradeRegister,
          { maxLength: 50 }
        )
      : getValidResultObject();
  },
  additionalInfo(data) {
    return data.config.shopAddress && SPRD.PLATFORM !== 'NA'
      ? validateStringAttribute(
          'additionalInfo',
          data.config.shopAddress.additionalInfo,
          { maxLength: 1023 }
        )
      : getValidResultObject();
  },
  houseNumber(data) {
    return data.config.shopAddress && SPRD.PLATFORM !== 'NA'
      ? validateStringAttribute(
          'houseNumber',
          data.config.shopAddress.houseNumber,
          { required: true }
        )
      : getValidResultObject();
  },
  zipCode(data) {
    return data.config.shopAddress && SPRD.PLATFORM !== 'NA'
      ? validateStringAttribute('zipCode', data.config.shopAddress.zipCode, {
          required: true,
          maxLength: 255,
        })
      : getValidResultObject();
  },
  city(data) {
    return data.config.shopAddress
      ? validateStringAttribute('city', data.config.shopAddress.city, {
          required: true,
          maxLength: 255,
        })
      : getValidResultObject();
  },
  country(data) {
    if (
      data.config.shopAddress &&
      (!data.config.shopAddress.country ||
        !data.config.shopAddress.country.code)
    ) {
      return getErrorResultObject('country', 'REQUIRED');
    } else {
      return getValidResultObject();
    }
  },
  state(data) {
    if (
      data.config.shopAddress &&
      data.config.shopAddress.country &&
      ['US', 'CA'].includes(data.config.shopAddress.country.code) &&
      (!data.config.shopAddress.state || !data.config.shopAddress.state.code)
    ) {
      return getErrorResultObject('state', 'REQUIRED');
    } else {
      return getValidResultObject();
    }
  },
  email(data) {
    return data.config.shopAddress
      ? validateStringAttribute('email', data.config.shopAddress.email, {
          required: true,
          maxLength: 255,
        })
      : getValidResultObject();
  },
  company(data) {
    return data.config.shopAddress && data.config.shopAddress.isCompany
      ? validateStringAttribute('company', data.config.shopAddress.company, {
          required: true,
          maxLength: 255,
        })
      : getValidResultObject();
  },
  googleWebmasterId(data) {
    return validateStringAttribute(
      'googleWebmasterId',
      data.settings.googleWebmasterId,
      { pattern: /^[a-zA-Z0-9-_]*$/ }
    );
  },
  googleAnalyticsTrackingId(data) {
    return data.tracking && data.tracking.googleAnalyticsEnabled
      ? validateStringAttribute(
          'googleAnalyticsTrackingId',
          data.tracking.googleAnalyticsTrackingId,
          { pattern: /^(UA|YT|MO)-\d+-\d+$/ }
        )
      : getValidResultObject();
  },
  facebookPixelId(data) {
    return data.tracking && data.tracking.facebookEnabled
      ? validateStringAttribute(
          'facebookPixelId',
          data.tracking.facebookPixelId,
          { pattern: /^\d+$/ }
        )
      : getValidResultObject();
  },
  collectionName(data) {
    if (!data.collections) {
      return getValidResultObject();
    }
    const invalidCollection = data.collections.collectionList.find(
      (c) => (!c.name && !c.name.length) || c.name.length > 35
    );
    return invalidCollection
      ? validateStringAttribute('name', invalidCollection.name, {
          required: true,
          maxLength: 35,
        })
      : getValidResultObject();
  },
};

function getValidResultObject() {
  return {
    result: true,
    errors: {},
  };
}

function getErrorResultObject(field, reason) {
  return {
    result: false,
    errors: {
      [field]: {
        result: 'ERROR',
        reason,
      },
    },
  };
}

function validateString(text = '', options) {
  if (options.required && (!text || !text.length)) {
    return {
      result: 'ERROR',
      reason: 'REQUIRED',
    };
  } else if (options.minLength && text.length < options.minLength) {
    return {
      result: 'ERROR',
      reason: 'INVALID_FORMAT',
    };
  } else if (options.maxLength && text.length > options.maxLength) {
    return {
      result: 'ERROR',
      reason: 'INVALID_FORMAT',
    };
  } else if (options.pattern && !options.pattern.test(text)) {
    return {
      result: 'ERROR',
      reason: 'INVALID_FORMAT',
    };
  }
  return {
    result: 'SUCCESS',
  };
}

function validateStringAttribute(fieldName, text, options) {
  const stringValidation = validateString(text, options);
  let result;
  if (stringValidation.result === 'ERROR') {
    result = {
      result: false,
      errors: {},
    };
    result.errors[fieldName] = {
      reason: stringValidation.reason,
    };
  } else {
    result = {
      result: true,
      errors: {},
    };
  }

  return result;
}

function mergeResults(previous, single) {
  if (!previous.errors) {
    previous = {
      result: true,
      errors: {},
    };
  }
  let overall = deepCopy(previous);

  overall.result = previous.result && single.result;
  overall.errors = Object.assign({}, previous.errors, single.errors);

  return overall;
}

export const validateEverything = (data) => {
  return Object.keys(singleValidate)
    .map((field) => singleValidate[field](data))
    .reduce((previous, current) => mergeResults(previous, current), {});
};

export const validateStateSubset = (data, stateName) => {
  if (fieldsToValidateByState[stateName]) {
    return fieldsToValidateByState[stateName]
      .map((field) => singleValidate[field](data))
      .reduce((previous, current) => mergeResults(previous, current), {});
  } else {
    return getValidResultObject();
  }
};
