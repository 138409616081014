<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.DESIGNS_WITH_PRODUCTS.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.designsWithProductsEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.DESIGNS_WITH_PRODUCTS.ENABLE_DESIGNS_WITH_PRODUCTS"
      :disabled="!startPage.designsWithProductsPossible"
    />
    <LegalLabelInput
      class="sprd-shop-input"
      v-model="startPage.designsWithProductsCaption"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      class="sprd-shop-input"
      v-model="startPage.designsWithProductsButtonCaption"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.BUTTON_LABEL')"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'DesignsWithProducts',
  mixins: [StartPageData],
  components: {
    ToggleSwitch,
    LegalLabelInput,
  },
  computed: {
    designsWithProductsPossible() {
      return this.startPage.designsWithProductsPossible;
    },
    disabled() {
      return (
        !this.startPage.designsWithProductsEnabled ||
        !this.designsWithProductsPossible
      );
    },
  },
  watch: {
    designsWithProductsPossible: {
      handler() {
        this.$store.commit('shop/setLivePreview', {
          path: '',
          section: 'sprd-startpage-designs-with-products',
          visible: this.designsWithProductsPossible,
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon';
</style>
