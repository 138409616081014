<template>
  <div class="dialog-size-lg pdv-failed-dialog">
    <div class="modal-header alert-danger">
      <a href class="modal-close-button" @click.prevent="modalInstance.dismiss">
        <Icon icon="close" />
      </a>
    </div>
    <div class="modal-body text-center">
      <PdvFailedMsg :address="data.lastAddress"></PdvFailedMsg>
    </div>
  </div>
</template>

<script>
import PdvFailedMsg from '@/partnerDataValidation/failedMsg/PdvFailedMsg.vue';

export default {
  name: 'PdvFailedDialog',
  components: { PdvFailedMsg },
  props: ['modalInstance', 'data'],
};
</script>
