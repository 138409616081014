import apiClient from '@/api/client/apiClient';

export default {
  getToken() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/auth/partner/app/authorize`,
        params: {
          mediaType: 'json',
        },
      })
      .then((res) => res.data);
  },
  fetchConnectedDevices() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/auth/partner/app/devices`,
        params: {
          mediaType: 'json',
        },
      })
      .then((res) => res.data);
  },
  removeDevice(deviceId) {
    return apiClient.request({
      method: 'POST',
      url: `/api/v1/auth/partner/app/logout/${deviceId}`,
      params: {
        mediaType: 'json',
      },
    });
  },
};
