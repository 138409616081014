<template>
  <div class="page-wrapper overflow-y">
    <div class="api-key-management" v-if="apiKeys">
      <h1>My API Key</h1>

      <p>
        Spreadshirt's API provides access to our platform's functionality. You
        can use the API to create baskets or build your own custom shops and
        applications.
      </p>

      <p>
        To find out even more information about our API and how to use it, visit
        the
        <a href="https://developer.spreadshirt.net/" target="_blank"
          >Developer Blog</a
        >
        or stop by the
        <a href="https://www.spreadshop.com/forum/" target="_blank">Forum</a>.
      </p>

      <button
        class="btn btn-primary generate-api-key-btn"
        v-if="!apiKey"
        @click="createApiKey"
      >
        Generate New API Key
      </button>

      <table v-if="apiKey">
        <tr>
          <td><strong>API Key</strong></td>
          <td>
            <Copyable :content="apiKey.key" inline id="key">
              <p>{{ apiKey.key }}</p>
            </Copyable>
          </td>
        </tr>
        <tr>
          <td><strong>Secret</strong></td>
          <td>
            <Copyable :content="apiKey.secret" inline id="secret">
              <p>{{ apiKey.secret }}</p>
            </Copyable>
          </td>
        </tr>
      </table>

      <button class="btn btn-danger" v-if="apiKey" @click="deleteApiKey">
        Delete API Key
      </button>
    </div>
    <LoadingHeart v-else />
  </div>
</template>

<script>
import apiKeysService from '@/api/apiKeys/apiKeysService';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import Copyable from 'src/app/commons/copyable/Copyable.vue';

export default {
  name: 'ApiKeyManagement',
  components: {
    LoadingHeart,
    Copyable,
  },
  data() {
    return {
      apiKeys: null,
    };
  },
  created() {
    this.updateApiKeys();
  },
  computed: {
    apiKey() {
      return this.apiKeys ? this.apiKeys[0] : null;
    },
  },
  methods: {
    async updateApiKeys() {
      const res = await apiKeysService.getApiKeys();
      this.apiKeys = res.apiKeys;
    },
    async createApiKey() {
      await apiKeysService.createApiKey();
      this.updateApiKeys();
    },
    async deleteApiKey() {
      await apiKeysService.deleteApiKey(this.apiKey.id);
      this.updateApiKeys();
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.api-key-management {
  max-width: 1400px;
  margin: 24px auto;

  & > p {
    max-width: 700px;
    margin-top: 24px;

    a {
      @extend .link-main;
    }
  }

  table {
    margin: 24px 0;

    tr {
      td {
        &:last-child {
          text-align: right;
          padding-left: 48px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .generate-api-key-btn {
    margin-top: 24px;
  }
}
</style>
