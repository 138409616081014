import localeService from '@/localeService/localeService';
import axios from 'axios';
import '@/api/apiAuthentication/apiAuthHTTPInterceptor';

export default {
  request(opts = {}) {
    const options = Object.assign(
      {
        withCredentials: true,
      },
      opts
    );

    if (
      !(options.url.startsWith('http://') || options.url.startsWith('https://'))
    ) {
      if (options.url && options.url[0] !== '/') {
        options.url = `/${options.url}`;
      }

      if (options.baseUrl && options.baseUrl[SPRD.PLATFORM]) {
        options.url = `${options.baseUrl[SPRD.PLATFORM]}${options.url}`;
      } else if (options.subdomain) {
        options.url = `${localeService.getSprdUrl({
          subdomain: options.subdomain,
          omitLanguage: true,
        })}${options.url}`;
      }
    }

    options.url = `${
      (options.baseUrl && options.baseUrl[SPRD.PLATFORM]) || ''
    }${options.url}`;

    options.params = {
      locale: localeService.getLocale(), // todo: optionally sprd locale,
      ...opts.params,
    };

    return axios(options);
  },
};
