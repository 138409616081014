import store from '@/store/Store';
import { i18n } from '@/translate/i18n';
import { mergeResults, validateStringAttribute } from '../genericValidator';

const { t } = i18n.global;

const defaultOptions = {
  name: {
    min: 3,
    max: 50,
    required: true,
  },
  description: {
    min: 1,
    max: 1000,
    required: true,
  },
  tags: {
    min: 3,
    max: 10,
    maxlength: 50,
  },
  previewImages: {
    min: 1,
    max: 5,
    maxSize: 20,
    fileTypes: '.png, .jpg, .jpeg',
    required: true,
  },
  inventoryFiles: {
    min: 1,
    max: 100,
    maxSize: 100,
    totalMaxSize: 300,
    fileTypes: '.pdf, .jpg, .jpeg, .png, .svg, .dng, .xmp',
    required: true,
  },
  price: {
    min: 0,
    max: 500,
    required: true,
  },
};

const digitalProductValidator = {
  defaultOptions,
  validate(data) {
    return Object.keys(digitalProductValidator.singleValidate)
      .map((field) => digitalProductValidator.singleValidate[field](data))
      .reduce((previous, current) => mergeResults(previous, current), {});
  },
  singleValidate: {
    name(data) {
      return validateStringAttribute(
        'name',
        data.workingState.name,
        defaultOptions.name
      );
    },
    description(data) {
      return validateStringAttribute(
        'description',
        data.workingState.description,
        defaultOptions.description
      );
    },
    tags(data) {
      if (
        !data.workingState.tags ||
        data.workingState.tags.length < defaultOptions.tags.min ||
        data.workingState.tags.length > defaultOptions.tags.max
      ) {
        return {
          result: false,
          errors: {
            tags: {
              reason: t('VALIDATION.ERRORS.TAGS_COUNT', defaultOptions.tags),
            },
          },
        };
      } else {
        return {
          result: true,
        };
      }
    },
    previewImages(data) {
      if (data.workingState.images?.length < defaultOptions.previewImages.min) {
        return {
          result: false,
          errors: {
            previewImages: {
              reason: t('VALIDATION.ERRORS.FILE_REQUIRED'),
            },
          },
        };
      } else if (
        data.workingState.images?.length > defaultOptions.previewImages.max
      ) {
        return {
          result: false,
          errors: {
            previewImages: {
              reason: t('VALIDATION.ERRORS.INVALID_FORMAT'),
            },
          },
        };
      } else {
        return {
          result: true,
        };
      }
    },
    inventoryFiles(data) {
      if (
        data.workingState.payloadFiles?.length <
        defaultOptions.inventoryFiles.min
      ) {
        return {
          result: false,
          errors: {
            inventoryFiles: {
              reason: t('VALIDATION.ERRORS.FILE_REQUIRED'),
            },
          },
        };
      } else if (
        data.workingState.payloadFiles?.length >
        defaultOptions.inventoryFiles.max
      ) {
        return {
          result: false,
          errors: {
            inventoryFiles: {
              reason: t('VALIDATION.ERRORS.INVALID_FORMAT'),
            },
          },
        };
      } else {
        return {
          result: true,
        };
      }
    },
    price(data) {
      const options = {
        ...defaultOptions.price,
        max: store.getters['digitalProduct/priceLimit'],
      };

      if (options.required && data.contentPrice.amount === null) {
        return {
          result: false,
          errors: {
            price: {
              reason: t('VALIDATION.ERRORS.TEXT_REQUIRED'),
            },
          },
        };
      } else if (
        data.contentPrice.amount < options.min ||
        data.contentPrice.amount > options.max
      ) {
        return {
          result: false,
          errors: {
            price: {
              reason: t('VALIDATION.ERRORS.INVALID_FORMAT'),
            },
          },
        };
      } else {
        return {
          result: true,
        };
      }
    },
  },
  validateByFieldName(fieldName, data, obfuscatedData) {
    if (digitalProductValidator.singleValidate[fieldName]) {
      return digitalProductValidator.singleValidate[fieldName](
        data,
        obfuscatedData
      );
    } else {
      return {};
    }
  },
  validateMultipleFieldNames(fieldNames, data, obfuscatedData) {
    if (!fieldNames || !fieldNames.length) {
      return {
        result: true,
        errors: {},
      };
    }

    return fieldNames
      .map((fieldName) =>
        digitalProductValidator.validateByFieldName(
          fieldName,
          data,
          obfuscatedData
        )
      )
      .reduce((previous, current) => {
        return mergeResults(previous, current);
      }, {});
  },
  mergeResults,
};

export default digitalProductValidator;
