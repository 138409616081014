import { checkTerm } from 'src/app/commons/api/blacklistService/blacklistService';
import { i18n } from '@/translate/i18n';

const { t } = i18n.global;

/**
 * @see blacklistService for available types
 */
export function getBlacklistValidator(type = 'description') {
  return async function (text) {
    try {
      await checkTerm({ field: type, terms: text });
      return;
    } catch (error) {
      const data = error ? error.data : null;
      if (data && data.list) {
        const blacklistedTerms = data.list[0].blacklisted
          .reduce((terms, blacklist) => {
            return terms.concat(blacklist.terms);
          }, [])
          .join(', ');
        throw new Error(
          `${t('DESIGNS.VALIDATION.BLACKLIST.TERMS')}: ${blacklistedTerms}`
        );
      }

      console.error(
        'Failed to perform blacklist check for unexpected reasons',
        error
      );
    }

    throw new Error(t('ACCOUNT.ERRORS.GENERAL_ERROR'));
  };
}
