import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

export default {
  fetchVolumeCommission() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/users/${store.state.user.data.id}/statistics/commissioner/statistics`,
      })
      .then((res) => res.data);
  },
  async fetchVolumeCommissionDetails() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/users/${store.state.user.data.id}/statistics/commissioner/details`,
    });
    return data;
  },
  fetchProductTypeShares(currencyId) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/statistics/commissioner/productTypeShares`,
        params: {
          currencyId: currencyId,
        },
      })
      .then((res) => res.data);
  },
};
