<template>
  <input v-bind:value="modelValue" v-on:input="emitSanitizedEvent" />
</template>

<script>
export default {
  name: 'SafeInput',
  props: ['modelValue'],

  methods: {
    emitSanitizedEvent(event) {
      const input = event && event.target && event.target.value;
      if (typeof input === 'string') {
        // re-emits the event with all chars from astral planes removed. this is necessary because mysql fails to store these chars properly (and possibly other crap)
        this.$emit(
          'update:modelValue',
          input.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '')
        );
      }
    },
  },
};
</script>
