import apiClient from '@/api/client/apiClient';

export default {
  fetchCurrencies(options = {}) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/currencies`,
        params: {
          ...options,
          mediaType: 'json',
          fullData: true,
        },
      })
      .then((res) => res.data);
  },
};
