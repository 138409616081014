<template>
  <img :src="imageUrl" alt="Spreadshop Logo" />
</template>

<script>
export default {
  name: 'SpreadshopLogo',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageUrl() {
      if (this.small) {
        return this.disabled
          ? '/images/spreadshop_logo_small_grey40.svg'
          : '/images/spreadshop_logo_small.svg';
      } else {
        return this.disabled
          ? '/images/spreadshop_logo_wide_grey40.svg'
          : '/images/spreadshop_logo_wide.svg';
      }
    },
  },
};
</script>
