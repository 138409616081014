<template>
  <div class="box">
    <div class="box-header">
      <div>
        <h2>{{ $t('CONNECT_DEVICES.HEADING') }}</h2>
        <p class="subheading">{{ $t('DASHBOARD.SPREADAPP.HEADING') }}</p>
        <p>{{ $t('CONNECT_DEVICES.GET_IT_ON') }}</p>
        <p>
          <a
            @click.prevent="goToStore(spreadAppStoreLinks.ios)"
            class="link link-white"
            target="_blank"
            >iOS</a
          >
          {{ $t('GENERAL.OR') }}
          <a
            @click.prevent="goToStore(spreadAppStoreLinks.android)"
            class="link link-white"
            target="_blank"
            >Android</a
          >
        </p>
      </div>
      <SpreadAppStores />
    </div>
    <div class="box-content">
      <div class="devices">
        <h3>{{ $t('CONNECT_DEVICES.LIST.HEADING') }}</h3>
        <LoadingHeart v-if="loading" class="loader" />
        <div v-else>
          <ul v-if="connectedDevices.length">
            <li v-for="device in connectedDevices" :key="device.deviceId">
              <Icon icon="phone-case" class="phone-icon" />
              <div class="device-name">{{ device.deviceInfo }}</div>
              <div class="device-date">
                {{ date(device.dateModified, 'short') }}
              </div>
              <a
                href="#"
                class="link-red"
                @click.prevent="removeDevice(device.deviceId)"
              >
                {{ $t('CONNECT_DEVICES.DISCONNECT') }}
              </a>
            </li>
          </ul>
          <p class="empty" v-else>
            {{ $t('CONNECT_DEVICES.LIST.EMPTY') }}
          </p>
        </div>
      </div>

      <div class="actions">
        <button
          class="btn btn-primary connect-btn"
          :disabled="isAdmin"
          @click="connectDevice"
          v-tooltip.top-start="{
            content: isAdmin ? 'not allowed as admin' : null,
            hideOnTargetClick: false,
          }"
        >
          {{ $t('CONNECT_DEVICES.CONNECT_CTA') }}
        </button>
        <a href="#" class="link-main" @click.prevent="refreshConnectedDevices">
          {{ $t('GENERAL.REFRESH') }}
        </a>
      </div>
    </div>
    <div class="beta" v-if="betaAvailable">
      <h3>{{ $t('CONNECT_DEVICES.BETA.HEADING') }}</h3>
      <p>{{ $t('CONNECT_DEVICES.BETA.SUBHEADING') }}</p>
      <div class="actions">
        <a
          @click.prevent="goToStore(spreadAppStoreLinks.iosBeta)"
          class="link-main"
          target="_blank"
          >{{ $t('CONNECT_DEVICES.BETA.JOIN_IOS') }}</a
        >
        <a
          @click.prevent="goToStore(spreadAppStoreLinks.androidBeta)"
          class="link-main"
          target="_blank"
          >{{ $t('CONNECT_DEVICES.BETA.JOIN_ANDROID') }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import deviceService from '@/api/deviceService/deviceService';
import SpreadAppStores from 'app/partnerarea/tipsAndTools/SpreadAppStores.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import ConnectDeviceDialog from 'src/app/components/connectDeviceDialog/ConnectDeviceDialog.vue';
import spreadAppStoreLinks from 'src/app/commons/spreadAppStoreLinks';
import SpreadAppStoreDialog from 'src/app/components/connectDeviceDialog/SpreadAppStoreDialog.vue';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';

export default {
  name: 'DeviceConnection',
  components: {
    SpreadAppStores,
    LoadingHeart,
  },
  data() {
    return {
      connectedDevices: [],
      loading: true,
      spreadAppStoreLinks,
      betaAvailable: false,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'account/isAdmin',
    }),
  },
  created() {
    this.refreshConnectedDevices();
  },
  methods: {
    async refreshConnectedDevices() {
      this.loading = true;
      this.connectedDevices = await deviceService.fetchConnectedDevices();
      if (!this.isAdmin) {
        this.connectedDevices = this.connectedDevices.filter(
          (device) => !device.deviceInfo.includes('Spreadshirt Test Device')
        );
      }
      this.connectedDevices.sort((a, b) => {
        return new Date(b.dateCreated) - new Date(a.dateCreated);
      });
      this.loading = false;
    },
    async removeDevice(deviceId) {
      await deviceService.removeDevice(deviceId);
      await this.refreshConnectedDevices();
    },
    connectDevice() {
      if (this.isAdmin) {
        return;
      }

      dialogService
        .openDialog(ConnectDeviceDialog)
        .finally(this.refreshConnectedDevices);
    },
    goToStore(config) {
      dialogService.openDialog(SpreadAppStoreDialog, config);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.box {
  padding: 0 !important;
  border-radius: 4px;
  overflow: hidden;
}

.box-content {
  padding: 24px;
}

.box-header {
  padding: 24px 24px 16px 24px;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    ),
    url('/images/spreadapp-teaser.jpg');
  background-size: cover;
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    margin-right: 24px;
    max-width: 225px;
  }

  h2 {
    margin: 0 0 16px 0;
    color: $white;
  }

  p {
    color: $white;
    margin: 0;

    &.subheading {
      margin-bottom: 24px;
    }
  }

  a {
    text-decoration: underline;
  }
}

.loader {
  padding: 24px;
}

.empty {
  color: $grey60;
  padding: 16px 0;
}

.devices {
  width: fit-content;

  .device-heading {
    display: flex;
    justify-content: space-between;
  }

  ul {
    margin: 8px 0 0 0;
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $grey20;
    }
  }

  .phone-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .device-name {
    margin-left: 12px;
    font-weight: bold;
    @extend .text-truncate;
  }

  .device-date {
    flex-shrink: 0;
    margin: 0 16px 0 8px;
    color: $grey60;
  }
}

h3 {
  margin: 0;
}

.actions {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .connect-btn {
    padding: 12px 24px;
    margin-right: 16px;
  }
}

.beta {
  padding: 24px;
  border-top: 1px solid $grey5;

  p {
    color: $grey60;
    margin: 16px 0;
    max-width: 650px;
  }

  a {
    margin-right: 16px;
  }
}
</style>
