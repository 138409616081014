<template>
  <div class="digital-products-config-inventory-files">
    <ul>
      <li v-if="shops.length">
        <SpreadshopLogo class="logo" />
        <ToggleSwitch
          :model-value="atLeastOneShopSelected"
          @update:modelValue="toggleAllShops"
        />
      </li>
      <li v-if="shops.length > 1">
        <p>{{ $t('GENERAL.SHOPS_SELECTED') }}</p>
        <div class="actions">
          <a
            href="#"
            class="link-dark icon-link icon-right"
            @click.prevent="openShopDialog"
          >
            {{ digitalProduct.shopIds.length }} / {{ shops.length }}
            <Icon icon="right" />
          </a>
        </div>
      </li>
      <li>
        <p>{{ $t('DIGITAL_PRODUCTS.CATEGORY') }}</p>
        <div class="actions">
          {{ $t(category.name) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { getDigitalProductCategory } from '@/api/models/digitalProducts/digitalProductsCategories';
import dialogService from '@/dialogs/wrapper/dialogService';
import ShopSelectionDialog from 'src/app/components/publishing/ShopSelectionDialog.vue';

export default {
  name: 'DigitalProductConfigVisibility',
  components: {
    SpreadshopLogo,
    ToggleSwitch,
  },
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      userId: (s) => s.user.data.id,
      shops: (s) => s.user.shops,
      selectedShops: (s) => s.digitalProduct.current.shopIds,
    }),
    ...mapGetters({}),
    atLeastOneShopSelected() {
      return this.digitalProduct.shopIds.length;
    },
    category() {
      return getDigitalProductCategory(this.digitalProduct.productClass);
    },
  },
  methods: {
    ...mapMutations({
      setShopIds: 'digitalProduct/setShopIds',
    }),
    ...mapActions({
      uploadDigitalProductInventoryFiles:
        'digitalProduct/uploadDigitalProductInventoryFiles',
    }),
    toggleAllShops(val) {
      if (val) {
        this.setShopIds(this.shops.map((shop) => shop.id));
      } else {
        this.setShopIds([]);
      }
    },
    async openShopDialog() {
      await dialogService
        .openDialog(ShopSelectionDialog, {
          shops: this.shops,
          selectedShopIds: this.selectedShops,
          heading: 'DIGITAL_PRODUCTS.SHOP_SELECTION_DIALOG.HEADING',
          subheading: 'DIGITAL_PRODUCTS.SHOP_SELECTION_DIALOG.SUBHEADING',
        })
        .then(this.setShopIds);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey10;
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  a {
    margin-left: 16px;
    font-weight: normal;
  }
}

.logo {
  height: 24px;
}
</style>
