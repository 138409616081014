<template>
  <NoStuffPlaceholder
    :display="!settings.designer"
    heading="POS.SHOP_SETTINGS.STARTPAGE.CYO_INTRO.EDITOR_DISABLED.HEADING"
    subHeading="POS.SHOP_SETTINGS.STARTPAGE.CYO_INTRO.EDITOR_DISABLED.SUBHEADING"
    ctaIcon="shop"
    ctaText="POS.SHOP_SETTINGS.STARTPAGE.CYO_INTRO.EDITOR_DISABLED.LINK"
    ctaTarget="shop-area.pages.designer"
  />
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';

export default {
  name: 'CYONoDesignerPlaceholder',
  mixins: [SettingsData],
  components: {
    NoStuffPlaceholder,
  },
};
</script>
