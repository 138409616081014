import store from '@/store/Store';
import { Upload } from 'tus-js-client';

const _fileLimit = 20;
const _retryDelays = [0, 1000, 3000, 5000];
const _acceptedFileTypes = [
  {
    format: 'Bitmap',
    extensions: ['.jpeg', '.jpg'],
    types: ['image/jpeg'],
  },
  {
    format: 'Bitmap',
    extensions: ['.png'],
    types: ['image/png'],
  },
  {
    format: 'Bitmap',
    extensions: ['.gif'],
    types: ['image/gif'],
  },
  {
    format: 'Vector',
    extensions: ['.svg'],
    types: ['image/svg+xml'],
  },
  {
    format: 'Vector',
    extensions: ['.ai'],
    types: ['application/illustrator'],
  },
  {
    format: 'Vector',
    extensions: ['.cdr'],
    types: [
      'application/cdr',
      'application/coreldraw',
      'application/x-cdr',
      'application/x-coreldraw',
      'image/cdr',
      'image/x-cdr',
      'application/zz-winassoc-cdr',
      'application/vnd.corel-draw',
    ],
  },
];

export default {
  uploadFile(file, callbacks, flags = []) {
    callbacks = callbacks || {};
    let upload;

    const promise = new Promise((resolve, reject) => {
      upload = new Upload(file, {
        endpoint: `/api/v1/image-uploader/users/${store.state.user.data.id}/ideas`,
        retryDelays: _retryDelays,
        metadata: {
          filename: file.name || 'unnamed',
          flags: [...new Set(['partnerarea', ...flags])].join(','),
        },
        resume: false,
        chunkSize: 5 * 1024 * 1024,
        onProgress: function (loaded, total) {
          if (loaded / total > 0.98) {
            loaded = total * 0.98;
          }
          callbacks.progress && callbacks.progress(file, { loaded, total });
        },
        onError: function (error) {
          let errorPayload;
          try {
            errorPayload = {
              ...error,
              status: error.originalResponse.getStatus(),
            };
          } catch (e) {
            errorPayload = { errorCode: null };
          }
          callbacks.error && callbacks.error(file, errorPayload);
          reject(errorPayload);
        },
        onSuccess: function () {
          callbacks.success && callbacks.success(file);
          resolve(file);
        },
      });

      upload.start();
    });

    return {
      promise: promise,
      upload,
    };
  },
  uploadFiles(files, callbacks, flags = []) {
    const uploads = files.map((file) => {
      return this.uploadFile(file, callbacks, flags);
    });

    let counter = 0;
    let failureCounter = 0;
    const results = [];
    const errors = [];

    const promise = new Promise((resolve, reject) => {
      uploads.forEach(function (upload) {
        counter++;
        upload.promise
          .then(
            function (value) {
              results.push(value);
            },
            function (reason) {
              failureCounter++;
              errors.push(reason);
            }
          )
          .finally(function () {
            if (!--counter) {
              if (!failureCounter) {
                resolve(results);
              } else {
                reject(errors);
              }
            }
          });
      });
    });

    return {
      promise,
      uploads,
    };
  },
  getAcceptedFileTypes() {
    return _acceptedFileTypes;
  },
  getFileLimit: function () {
    return _fileLimit;
  },
};
