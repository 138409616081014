<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.collectionPreviewEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.ENABLE_COLLECTION_PREVIEW"
      :disabled="!collectionPreviewPossible"
    />
    <LegalLabelInput
      class="sprd-shop-input"
      v-model="startPage.collectionPreviewCaption"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      class="sprd-shop-input"
      v-model="startPage.collectionPreviewButtonCaption"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.BUTTON_LABEL')"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'CollectionPreview',
  mixins: [StartPageData],
  components: {
    ToggleSwitch,
    LegalLabelInput,
  },
  computed: {
    collectionPreviewPossible() {
      const { collectionPreviewPossible } = this.startPage;
      return (
        typeof collectionPreviewPossible !== 'boolean' ||
        collectionPreviewPossible
      );
    },
    collectionPreviewEnabled() {
      return this.startPage.collectionPreviewEnabled;
    },
    disabled() {
      return !this.collectionPreviewPossible || !this.collectionPreviewEnabled;
    },
  },
  watch: {
    collectionPreviewPossible: {
      handler() {
        this.$store.commit('shop/setLivePreview', {
          path: '',
          section: 'sprd-startpage-collection-preview',
          visible: this.collectionPreviewPossible,
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
</style>
