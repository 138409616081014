import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

export default {
  async changePassword({ currentPassword, newPassword }) {
    const res = await apiClient.request({
      method: 'POST',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/changePassword`,
      data: {
        currentPassword,
        newPassword,
      },
    });
    return res;
  },
  async getMfaStatus() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/mfa/totp/status`,
    });
    return data;
  },
  async setupMfa() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/mfa/totp/setup`,
    });
    return data;
  },
  async finishMfaSetupAndCreateRecoveryCodes(code) {
    const { data } = await apiClient.request({
      method: 'POST',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/mfa/totp/recovery-codes`,
      data: {
        code,
      },
    });
    return data;
  },
  async deleteMfa() {
    const res = await apiClient.request({
      method: 'DELETE',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/mfa/totp/delete`,
    });
    return res;
  },
  async changeEmail({ newEmail, password }) {
    const { data } = await apiClient.request({
      method: 'POST',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/email`,
      data: {
        newEmail,
        password,
      },
    });

    return data;
  },
  async changeUsername(newUsername) {
    const { data } = await apiClient.request({
      method: 'POST',
      url: `/api/v1/identities/${store.state.user.data.identity.id}/username`,
      data: {
        newUsername,
      },
    });
    return data;
  },
  async getCurrentIdentity() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/identities/current`,
    });
    return data;
  },
};
