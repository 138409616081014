<template>
  <div class="stores">
    <div>
      <a @click.prevent="goToStore(spreadAppStoreLinks.ios)" target="_blank">
        <img src="/images/appstore.png" />
      </a>
    </div>
    <div>
      <a
        @click.prevent="goToStore(spreadAppStoreLinks.android)"
        target="_blank"
      >
        <img src="/images/googleplay.png" />
      </a>
    </div>
  </div>
</template>

<script>
import spreadAppStoreLinks from 'src/app/commons/spreadAppStoreLinks';
import dialogService from '@/dialogs/wrapper/dialogService';
import SpreadAppStoreDialog from 'src/app/components/connectDeviceDialog/SpreadAppStoreDialog.vue';

export default {
  name: 'SpreadAppStores',
  data() {
    return {
      spreadAppStoreLinks,
    };
  },
  methods: {
    goToStore(config) {
      dialogService.openDialog(SpreadAppStoreDialog, config);
    },
  },
};
</script>

<style lang="scss" scoped>
.stores {
  display: flex;

  & > div:first-child {
    margin-right: 16px;
  }

  img {
    height: 40px;
  }
}
</style>
