<template>
  <div class="assortment-filter">
    <div>
      <h4>
        <a @click="selectFilter(assortment, true)" class="link">{{
          $t('DESIGNS.CONFIGURATION.CATEGORIES_FILTER_HEADING')
        }}</a>
      </h4>
      <ul class="mainfilters">
        <li
          v-for="(mainFilter, mainFilterKey) in assortment.subFilters"
          :key="mainFilterKey"
          class="mainfilter"
          :class="{
            active: mainFilterKey === activeMainFilterId,
            filtered: mainFilter.$filtered,
          }"
          :data-filter-id="mainFilter.filterId"
        >
          <a
            href="#"
            @click.prevent="selectFilter(mainFilter, true)"
            class="link"
            :class="{ disabled: !mainFilter.available }"
            :disabled="!mainFilter.available"
          >
            <AdvancedPopover
              class="unavailable-hint"
              :disabled="hintKeyMap[mainFilterKey] === null"
              heading="DESIGNS.CONFIGURATION.UNAVAILABLE.PRODUCT"
              :description="hintKeyMap[mainFilterKey]"
              :smartFaqLink="
                isPosterAssortmentGroup(mainFilter) ? posterHelpLink : null
              "
              popoverPlacement="left"
              popoverContainer="body"
              popoverClass="assortment-category-disabled-hint-popover"
            >
              <div class="filter-label">
                {{ mainFilter.name }}
                <span v-if="getSelectedCount(mainFilter) > 0"
                  >({{ getSelectedCount(mainFilter) }})</span
                >
                <Icon
                  class="icon-info"
                  icon="attention"
                  v-if="hintKeyMap[mainFilterKey] !== null"
                />
              </div>
            </AdvancedPopover>
          </a>
          <CollapseAnimation>
            <ul v-show="expanded === mainFilterKey" class="subfilters">
              <li
                v-for="subFilter in getSortedSubfilter(mainFilter.subFilters)"
                :key="subFilter.filterId"
                class="subfilter"
                :class="{
                  active: activeFilter.filterId === subFilter.filterId,
                  filtered: subFilter.$filtered,
                }"
                :data-filter-id="subFilter.filterId"
              >
                <a
                  @click="selectFilter(subFilter)"
                  class="link"
                  :disabled="!subFilter.available"
                  :class="{ disabled: !subFilter.available }"
                >
                  <AdvancedPopover
                    class="unavailable-hint"
                    :disabled="hintKeyMap[subFilter.filterId] === null"
                    heading="DESIGNS.CONFIGURATION.UNAVAILABLE.PRODUCT"
                    :description="hintKeyMap[subFilter.filterId]"
                    :smartFaqLink="
                      isPosterAssortmentGroup(subFilter) ? posterHelpLink : null
                    "
                    popoverPlacement="left"
                    popoverContainer="body"
                    popoverClass="assortment-category-disabled-hint-popover"
                  >
                    <div class="filter-label">
                      {{ subFilter.shortName }}
                      <span v-if="getSelectedCount(subFilter) > 0"
                        >({{ getSelectedCount(subFilter) }})</span
                      >
                      <Icon
                        class="icon-info"
                        :icon="'attention'"
                        v-if="hintKeyMap[subFilter.filterId] !== null"
                      />
                    </div>
                  </AdvancedPopover>
                </a>
              </li>
            </ul>
          </CollapseAnimation>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import CollapseAnimation from 'src/app/commons/CollapseAnimation/CollapseAnimation.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';

export default {
  name: 'AssortmentFilter',
  props: ['assortment', 'activeFilter', 'flags'],
  components: {
    CollapseAnimation,
    AdvancedPopover,
  },
  data() {
    return {
      expanded: null,
      hintKeyMap: {},
      posterHelpLink: smartFaqLinks.POSTER_TEMPLATES,
    };
  },
  computed: {
    activeMainFilterId() {
      const activeMainFilter = Object.values(this.assortment.subFilters).find(
        (mainFilter) => {
          return (
            mainFilter.filterId === this.activeFilter.filterId ||
            Object.values(mainFilter.subFilters).some(
              (subFilter) => subFilter.filterId === this.activeFilter.filterId
            )
          );
        }
      );

      return activeMainFilter ? activeMainFilter.filterId : null;
    },
  },
  mounted() {
    Object.entries(this.assortment.subFilters).forEach(([key, filter]) => {
      this.hintKeyMap[key] =
        assortmentHelper.getTranslationKeyForAssortmentHints(
          filter.hints,
          this.flags,
          filter.available
        );
      if (filter.subFilters) {
        Object.entries(filter.subFilters).forEach(([subKey, subFilter]) => {
          this.hintKeyMap[subKey] =
            assortmentHelper.getTranslationKeyForAssortmentHints(
              subFilter.hints,
              this.flags,
              subFilter.available
            );
        });
      }
    });
  },
  methods: {
    getSortedSubfilter(subfilters) {
      if (subfilters) {
        return Object.values(subfilters)
          .filter((subFilter) => subFilter.subFilters)
          .sort((a, b) => a.orderWeight - b.orderWeight);
      } else {
        return [];
      }
    },
    isPosterAssortmentGroup(filter) {
      return assortmentHelper.isPosterAssortmentGroup(filter);
    },
    toggleFilterExpansion(filter) {
      if (this.expanded === filter.filterId) {
        this.expanded = null;
      } else {
        this.expanded = filter.filterId;
      }
    },
    selectFilter(filter, mainFilter) {
      if (!filter.available) {
        return;
      }

      if (mainFilter) {
        this.toggleFilterExpansion(filter);
      }

      this.$emit('setFilter', filter);
    },
    getSelectedCount(filter) {
      return assortmentHelper.getSelectedSellables(filter).length;
    },
  },
};
</script>

<style lang="scss">
.assortment-category-disabled-hint-popover {
  left: 250px !important;
  width: 270px;
}
</style>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.assortment-filter {
  & > div {
    h4 {
      margin: 0;
      padding: 24px 24px 6px 24px;

      a {
        color: $grey80;
      }
    }
  }
}

ul {
  padding: 0;
  list-style: none;

  li {
    a {
      display: block;
      padding: 6px 0;
      font-weight: normal;
    }

    &.active > a {
      font-weight: 700;
      color: $pa-color-main;

      .icon {
        color: $sprd-color-dark-grey;
      }
    }

    &.filtered > a {
      color: $sprd-color-medium-grey;
    }

    &.disabled > a {
      color: $sprd-color-medium-grey;
    }

    .icon-info {
      color: $pa-color-yellow;
    }
  }
}

.unavailable-hint :deep(.trigger) {
  display: block !important;
}

.icon-expand {
  font-size: 1.7em;
  margin-left: auto;
}

.icon-info {
  margin-left: 8px;
  width: 24px;
  height: 24px;
}

.mainfilters {
  margin: 0;
}

.mainfilter {
  padding: 0 24px;

  & > a {
    padding: 0;

    .filter-label {
      padding: 6px 0;
    }
  }

  span {
    margin-left: 5px;
  }
}

.filter-label {
  width: 100%;
  display: flex !important;
  align-items: center;
}

.subfilters {
  margin-left: 10px;
}
</style>
