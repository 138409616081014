<template>
  <div class="dialog-size-md user-name-and-image-dialog">
    <div class="modal-body">
      <div class="profile-image-container">
        <div class="profile-image">
          <input
            id="hiddenFileInput"
            ref="hiddenFileInput"
            class="hidden-file-input"
            type="file"
            :disabled="imageUploadInProgress"
            @change="onProfileImageChange"
          />
          <label for="hiddenFileInput">
            <img
              class="account-image"
              :src="profileImage"
              alt="profile image"
            />
          </label>
          <div v-if="imageUploadInProgress" class="loading-overlay">
            <LoadingHeart />
          </div>
        </div>
        <div>
          <p
            class="text-sm text-grey profile-image-hint"
            :class="{ highlight: displayUploadError }"
          >
            {{ $t('ACCOUNT.ACCOUNT.PROFILE_IMAGE_HINT') }}
          </p>
          <label
            class="link-main image-upload"
            for="hiddenFileInput"
            :disabled="imageUploadInProgress"
          >
            {{ $t('ACCOUNT.ACCOUNT.PROFILE_IMAGE_UPLOAD') }}
          </label>
        </div>
      </div>
      <LabelInput :class="{ 'has-error': errors.username }">
        <label for="accountUserName">{{
          $t('ACCOUNT.ACCOUNT.USERNAME')
        }}</label>
        <DebounceInput
          type="text"
          id="accountUserName"
          :model-value="username"
          @update:model-value="updateUsername"
          maxLength="50"
        />
        <AccountError
          v-if="errors.username"
          :reason="errors.username"
        ></AccountError>
        <p class="text-sm text-grey username-disclaimer">
          {{ $t('ACCOUNT.ACCOUNT.USERNAME_HINT') }}
          <span v-if="hasMarketplace">
            {{ $t('ACCOUNT.ACCOUNT.SHOWROOM_HINT') }}
          </span>
        </p>
      </LabelInput>
      <div class="actions">
        <ProgressButton
          class="btn btn-primary btn-lg"
          :label="$t('GENERAL.SAVE')"
          :onClick="onSave"
          :disabled="!usernameChanged"
        >
        </ProgressButton>
        <button :class="'btn btn-tonal-filled'" @click="cancel">
          {{ $t('GENERAL.CANCEL') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import LabelInput from '@/labelInput/LabelInput.vue';
import userService from '@/api/userService/userService';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import AccountError from './errorMessage/AccountError.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';

export default {
  name: 'UserNameAndImageDialog',
  components: {
    LabelInput,
    LoadingHeart,
    AccountError,
    ProgressButton,
    DebounceInput,
  },
  props: ['modalInstance'],
  data() {
    return {
      uploadProfileImagePromise: null,
      imageUploadInProgress: false,
      displayUploadError: false,
      errors: {
        username: null,
      },
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.account.current,
      userData: (s) => s.user.data,
      validation: (state) => state.account.validation,
    }),
    ...mapGetters({
      hasMarketplace: 'user/hasMarketplace',
      username: 'identity/customUsername',
      profileImage: 'user/profileImage',
      usernameChanged: 'identity/usernameChanged',
    }),
  },
  unmounted() {
    this.resetUsername();
  },
  methods: {
    ...mapMutations({
      setUsername: 'identity/setUsername',
      reloadProfileImage: 'user/reloadProfileImage',
      resetUsername: 'identity/resetUsername',
    }),
    ...mapActions({
      saveUsername: 'identity/saveUsername',
    }),
    cancel() {
      this.resetUsername();
      this.modalInstance.dismiss();
    },
    updateUsername(username) {
      this.resetErrors();
      this.setUsername(username);
    },
    async onSave() {
      try {
        await this.saveUsername(this.username);
        this.modalInstance.close();
      } catch (error) {
        this.setErrors(error);
        throw error;
      }
    },
    resetErrors() {
      this.errors.username = null;
    },
    setErrors(error) {
      switch (error?.data?.error) {
        case '000000':
          this.errors.username = 'INVALID_FORMAT';
          break;
        case '000004':
          this.errors.username = 'USERNAME_EXISTS';
          break;
      }
    },
    async onProfileImageChange(e) {
      const file = e.srcElement.files[0];
      this.$refs.hiddenFileInput.value = '';
      this.imageUploadInProgress = true;

      try {
        this.uploadProfileImagePromise = await userService.updateProfileImage(
          file
        );
        this.reloadProfileImage();
      } catch (error) {
        this.displayUploadError = true;
        setTimeout(() => {
          this.displayUploadError = false;
        }, 2500);
      }
      this.imageUploadInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/buttons';
.hidden-file-input {
  // hacky way of achieving display:none without side effects, see https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.account-image {
  display: block;
  height: 120px;
  width: 120px;
  cursor: pointer;
  border-radius: 50%;
}
.profile-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    position: relative;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .profile-image-hint {
    margin: 0 0 8px 0;
    transition: all 0.5s ease;
    &.highlight {
      color: red;
      transform: scale(1.1);
    }
  }

  .image-upload {
    margin: 0;
    position: relative;

    .icon {
      font-size: inherit;
    }
  }
}

.username-disclaimer {
  margin: 8px 0 0 0;
}

.actions {
  display: flex;
  gap: 16px;
  margin-top: 16px;

  .btn {
    flex: 1;
    padding: 8px 24px;
  }
}
</style>
