<template>
  <div class="dms" v-if="hasMarketplace && currentGroup">
    <div class="dms-box">
      <div class="dms-level">
        <img :src="currentGroup.meta.image" alt="Star Class" />
        <div>
          <p v-if="starCount" class="text-grey text-sm">
            {{ $t('DMS.STAR_CLASS', { number: starCount }) }}
          </p>
          <h1>{{ $t(currentGroup.meta.name) }}</h1>
        </div>
      </div>
      <div class="dms-features">
        <DmsPropertyList condensed />
        <SmartFaqLink
          :link="currentGroup.meta.helpLinkTarget"
          iconOnly
          class="help-link"
        />
      </div>
      <MarketplaceLogo class="logo" />
    </div>
  </div>
</template>

<script>
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import { mapGetters } from 'vuex';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import DmsPropertyList from 'src/app/components/dms/DmsPropertyList.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'DmsModule',
  components: {
    MarketplaceLogo,
    SmartFaqLink,
    DmsPropertyList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      hasMarketplace: 'user/hasMarketplace',
      getProperty: 'dms/getProperty',
      currentGroup: 'dms/currentGroup',
    }),
    starCount() {
      return this.getProperty(DMS_PROPERTIES.STAR_COUNT).value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/mixins/media';

.dms {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding: 0 !important;
}

.dms-box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px;
  background-color: $white;
  border-radius: 8px;
  position: relative;
}

.dms-level {
  display: flex;
  padding: 0 0 24px 0;
  align-items: center;
  flex-grow: 1;
  position: relative;

  img {
    width: 56px;
    height: 56px;
    margin-right: 16px;
  }

  h1 {
    margin: 0;
  }
}

.dms-features {
  display: flex;
  align-items: flex-end;

  .help-link {
    margin-left: auto;
  }
}

.logo {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 12px;
}
</style>
