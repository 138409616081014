<template>
  <SalesEarningsGraph
    class="graph"
    heading="DASHBOARD.SALES_GRAPH.HEADING"
    subheading="DASHBOARD.SALES_GRAPH.SUBHEADING"
    salesSubheading="DASHBOARD.SALES_GRAPH.SALES_SUBHEADING"
    earningsSubheading="DASHBOARD.SALES_GRAPH.EARNINGS_SUBHEADING"
    :defaultTimeFilter="defaultTimeFilter"
    :data="timelineData"
    @onLoadData="update"
    :initialized="initialized"
  />
</template>

<script>
import statisticsService from '@/api/statisticsService/statisticsService';
import { mapGetters } from 'vuex';
import SalesEarningsGraph from 'src/app/components/charts/sales/SalesEarningsGraph.vue';
import timeFilters from 'src/app/components/charts/sales/timeFilters';

export default {
  name: 'Sales',
  components: {
    SalesEarningsGraph,
  },
  data() {
    return {
      timelineData: null,
      initialized: false,
      defaultTimeFilter: timeFilters.MONTH,
    };
  },
  computed: {
    ...mapGetters({
      lifetimeSales: 'statistics/lifetimeSales',
      onboardingFinished: 'onboarding/finished',
    }),
    display() {
      return this.lifetimeSales || this.onboardingFinished;
    },
  },
  methods: {
    async update(filters) {
      this.initialized = false;

      const data = await statisticsService.getSalesOverview({
        pos: filters.pos.value,
        dateFrom: filters.time.from,
        dateTo: filters.time.to,
      });
      this.timelineData = data.timeline ? data : null;
      this.initialized = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
</style>
