<template>
  <div
    class="notification-item"
    :class="{
      read: read,
      menuOpen: menuOpen,
      highlight:
        enableHighlighting && !read && notification.topic === 'ANNOUNCEMENTS',
    }"
    @click="onNotificationClick"
  >
    <div class="indicators">
      <div class="topic-icon">
        <Icon :icon="topic.icon" />
      </div>
      <div v-if="!read" class="unread-badge"></div>
    </div>
    <div class="data">
      <div class="title">
        <p v-html="notification.title"></p>
      </div>
      <div class="body">
        <p class="text-grey" v-html="notification.body"></p>
      </div>
      <div class="text-sm date">
        {{ dateFormat }}
      </div>
    </div>
    <VDropdown
      :shown="menuOpen"
      @hide="closeMenu"
      popperClass="vue-popover-theme notification-actions"
      popoverArrowClass="hidden"
      placement="bottom-end"
    >
      <a class="menu" @click.prevent.stop="openMenu">
        <Icon icon="dots-horizontal" />
      </a>
      <template #popper>
        <div class="menu-content">
          <div>
            <button
              v-for="action in actions"
              :key="action.name"
              @click.stop.prevent="executeAction(action)"
              class="icon-link"
              :class="action.class || 'link-main'"
            >
              <Icon :icon="action.icon"></Icon>
              {{ $t(action.name) }}
            </button>
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';
import {
  notificationTopicConfig,
  triggerNotificationAction,
} from '@/api/notifications/notificationTopics';

export default {
  name: 'NotificationItem',
  components: {},
  props: {
    notification: {
      type: Object,
      required: true,
    },
    enableHighlighting: {
      type: Boolean,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      getReadState: 'notifications/getReadState',
    }),
    read() {
      return this.getReadState(this.notification);
    },
    dateFormat() {
      const minutesDifference =
        differenceInMinutes(
          Date.now(),
          parseISO(this.notification.deliveryTime)
        ) + 1;
      const hoursDifference = differenceInHours(
        Date.now(),
        parseISO(this.notification.deliveryTime)
      );

      if (minutesDifference <= 59) {
        return `${minutesDifference}min`;
      } else if (hoursDifference <= 23) {
        return `${hoursDifference}h`;
      } else {
        return this.date(this.notification.deliveryTime);
      }
    },
    actions() {
      return [
        this.read
          ? {
              name: 'NOTIFICATIONS.MARK_AS_UNREAD',
              icon: 'checkmark',
              execute: () =>
                this.setReadState({
                  notification: this.notification,
                  readState: false,
                }),
            }
          : {
              name: 'NOTIFICATIONS.MARK_AS_READ',
              icon: 'checkmark',
              execute: () =>
                this.setReadState({
                  notification: this.notification,
                  readState: true,
                }),
            },
        {
          name: 'NOTIFICATIONS.REMOVE',
          icon: 'bin',
          execute: () => this.hideNotification(this.notification),
          class: 'link-red',
        },
        ...(!this.topic.nonExcludable
          ? [
              {
                name: 'NOTIFICATIONS.UNSUBSCRIBE',
                icon: 'eye-crossed',
                execute: () => this.addExcludedTopic(this.notification.topic),
                class: 'link-dark',
              },
            ]
          : []),
      ];
    },
    topic() {
      return notificationTopicConfig(this.notification.topic);
    },
  },
  methods: {
    ...mapActions({
      setReadState: 'notifications/setReadState',
      hideNotification: 'notifications/hide',
      setSetting: 'settings/setSetting',
      addExcludedTopic: 'notifications/addExcludedTopic',
    }),
    openMenu() {
      this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    executeAction(action) {
      action.execute();
      this.closeMenu();
    },
    onNotificationClick() {
      this.setReadState({
        notification: this.notification,
        readState: true,
        skipTracking: true,
      });
      triggerNotificationAction(this.notification);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.notification-item {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  .data {
    margin-right: auto;
    padding-right: 12px;
  }

  .indicators {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    margin-right: 16px;
  }

  .unread-badge {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: $pa-color-main;
    margin-top: 8px;
  }

  .topic-icon,
  .menu {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  .topic-icon {
    background-color: $grey5;
    .icon {
      color: $grey80;
    }
  }

  .date {
    font-weight: bold;
    white-space: nowrap;
    color: $pa-color-main;
    margin-top: 8px;
  }

  .title,
  .body {
    display: flex;
    align-items: center;
  }

  .title {
    p {
      margin: 0;
      font-weight: bold;
    }
  }

  .body {
    margin-top: 8px;

    p {
      margin: 0;
    }
  }

  .menu {
    background-color: $white;

    .icon {
      color: $pa-color-main;
    }

    &:hover {
      background-color: #3075b41a;
      .icon {
        color: $pa-color-main-dark;
      }
    }
  }

  &:hover {
    background-color: $grey5;

    .topic-icon {
      background-color: $white;
      .icon {
        color: $grey80;
      }
    }
  }

  .menu:hover,
  &.menuOpen .menu {
    background-color: $pa-color-main;
    .icon {
      color: $white;
    }
  }

  &.read {
    .title p,
    .date,
    .body p {
      color: $grey60;
    }

    .topic-icon {
      .icon {
        color: $grey60;
      }
    }

    .date {
      font-weight: normal;
    }
  }

  &.highlight .topic-icon {
    background-color: $pa-color-main;
    .icon {
      color: $white;
    }
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';
.notification-actions {
  button {
    padding: 8px;
    border-radius: 4px;
    width: 100%;

    &:hover {
      background-color: $grey5;
    }
  }

  .icon-link .icon {
    width: 20px;
    height: 20px;
  }
}
</style>
