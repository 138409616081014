<template>
  <div class="modal-header">
    <div class="flex-center-vertical">
      <div class="headerBtns">
        <button
          v-if="canConfirm"
          @click="onConfirm"
          class="headerBtnConfirm text-btn"
        >
          {{ $t('GENERAL.SAVE') }}
        </button>
        <button
          v-if="canGoBack"
          @click="onGoBack"
          class="headerBtnConfirm text-btn"
        >
          {{ $t('GENERAL.BACK') }}
        </button>
        <button @click="onCancel" class="headerBtnCancel text-btn">
          {{ $t('SA.action.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaveCancelModalHeader',
  props: {
    canConfirm: { type: Boolean, default: false },
    canGoBack: { type: Boolean, default: false },
    onConfirm: { type: Function, default: () => {} },
    onGoBack: { type: Function, default: () => {} },
    onCancel: { type: Function, required: true },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.headerBtns {
  width: 100%;
  font-weight: bold;
}

.headerBtnConfirm {
  margin-right: 1.5em;
}

.headerBtnCancel {
  color: $sprd-color-grey;
}
</style>
