<template>
  <transition name="fadeSlide" mode="out-in">
    <span class="loading-words" :key="currentIndex">
      &nbsp;{{ $t(currentWord) }}&nbsp;
    </span>
  </transition>
</template>

<script>
export default {
  name: 'LoadingWords',
  props: {
    words: {
      type: Array,
      default: () => [
        'RECAP.LOADING_SCREEN.PHRASE1',
        'RECAP.LOADING_SCREEN.PHRASE2',
        'RECAP.LOADING_SCREEN.PHRASE3',
        'RECAP.LOADING_SCREEN.PHRASE4',
        'RECAP.LOADING_SCREEN.PHRASE5',
        'RECAP.LOADING_SCREEN.PHRASE6',
      ],
    },
  },
  data() {
    return {
      currentIndex: -1,
      interval: null,
    };
  },
  async created() {
    this.interval = setInterval(() => {
      if (this.currentIndex >= this.words.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex += 1;
      }
    }, 3000);

    await this.$nextTick();
    this.currentIndex = 0;
  },

  computed: {
    currentWord() {
      if (this.currentIndex === -1) {
        return '';
      }

      return this.words[this.currentIndex];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/colors';

.loading-words {
  display: block;
  opacity: 1;
  text-align: center;
  margin-top: 12px;
}
</style>
