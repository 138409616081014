const specialTlds = ['uk', 'au', 'vuk', 'vau'];

// returns something like ["spreadshirt", "co.uk"]
const splitCurrentBaseLocation = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  let tld = parts.pop();

  // check special top level domains; add the second last part to belong to the tld
  if (specialTlds.indexOf(tld) >= 0) {
    tld = [parts.pop(), tld].join('.');
  }

  const sld = parts.pop();
  return [sld, tld];
};

// returns something like spreadshirt.de or vm298.co.vuk
const getBaseHost = () => splitCurrentBaseLocation().join('.');

const isEnv = (env) => SPRD && SPRD.ENVIRONMENT === env;

const getStaticSpreadshopUrl = (shopName, path, livePreviewMode) => {
  let subDomain;
  if (window.location.hostname.startsWith('rentrap')) {
    subDomain = 'lhc'; // special prerelease environment
  } else {
    subDomain = 'spreadshop-admin';
  }
  const base = `https://${subDomain}.${getBaseHost()}`;
  const suffix = livePreviewMode ? '?noCache=true&liveEdit=true' : '';
  return `${base}/${shopName}/${path}${suffix}`;
};

const getMyspreadshopUrl = (shopName, path) => {
  const [sld, tld] = splitCurrentBaseLocation();
  const base = isEnv('QA') ? `${sld}-myspreadshop` : 'myspreadshop';

  return `https://${shopName}.${base}.${tld}/${path}`;
};

export const getSpreadshopUrl = (shop, path, livePreviewMode) => {
  if (!shop || !shop.id) {
    return '';
  }
  const shopName = shop.name || shop.id;
  if (livePreviewMode || window.location.hostname.startsWith('rentrap')) {
    return getStaticSpreadshopUrl(shopName, path, livePreviewMode);
  }
  return getMyspreadshopUrl(shopName, path);
};

export const getSpreadshopFontUrl = (fontDir, fontName) => {
  return `https://spreadshop-admin.${getBaseHost()}/shopfiles/fonts/${fontDir}/${fontName}`;
};

export const getSpreadshopSharingImageUrl = (
  shopId,
  tld, // something like de or co.vuk
  tag,
  ideaId = null,
  sellableId = null,
  appearanceId = null
) => {
  const baseUrl = `${getMyspreadshopUrl(shopId, shopId)}/shopData/share/${tag}`;
  if (!ideaId) {
    return baseUrl;
  }
  if (!sellableId) {
    return `${baseUrl}?ideaId=${ideaId}`;
  }
  if (!appearanceId) {
    return `${baseUrl}?ideaId=${ideaId}&sellableId=${sellableId}`;
  }
  return `${baseUrl}?ideaId=${ideaId}&sellableId=${sellableId}&appearanceId=${appearanceId}`;
};
