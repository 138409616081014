import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import localeService from '@/localeService/localeService';

export default {
  getApiKeys() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/users/${store.state.user.data.id}/myPartnerApiKeys`,
        params: {
          mediaType: 'json',
          fullData: true,
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
  createApiKey() {
    return apiClient
      .request({
        method: 'POST',
        url: `/api/v1/users/${store.state.user.data.id}/myPartnerApiKeys`,
        params: {
          mediaType: 'json',
          fullData: true,
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
  deleteApiKey(apiKeyId) {
    return apiClient
      .request({
        method: 'DELETE',
        url: `/api/v1/users/${store.state.user.data.id}/myPartnerApiKeys/${apiKeyId}`,
        params: {
          mediaType: 'json',
          fullData: true,
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
};
