<template>
  <div class="stock-img-selector" :class="{ disabled: disabled }">
    <div class="stock-imgs">
      <div
        class="stock-img"
        v-for="image in previewImages"
        :key="image.filename"
        @click="onImageSelected(image.filename)"
      >
        <img
          :class="{ selected: image.filename == selectedImageFilename }"
          :src="image.url"
          alt=""
        />
      </div>
      <button
        type="button"
        class="stock-img stock-img--more"
        v-if="images.length > 4"
        @click="showImageDialog"
      >
        <Icon icon="plus" />
      </button>
    </div>
    <small class="stock-img-link" v-if="showUnsplashHint">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.TEASER.IMAGE.SAMPLES_LINK') }}
      <a class="link-main" target="_blank" href="https://unsplash.com/"
        >unsplash</a
      >
    </small>
  </div>
</template>

<script>
import dialogService from '@/dialogs/wrapper/dialogService';
import ImageSelectorDialog from 'src/app/partnerarea/pos/shop/common/ImageSelectorDialog.vue';

export default {
  name: 'ImageSelector',
  props: {
    images: Array,
    disabled: Boolean,
    selectedImageFilename: String,
    onImageSelected: Function,
    manyImagesLayout: {
      type: Boolean,
      default: false,
    },
    showUnsplashHint: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    previewImages: {
      get() {
        const previewImages = this.images.slice(0, 4);
        this.images.forEach((img) => {
          img.active = img.filename === this.selectedImageFilename;
          if (img.active && !previewImages.includes(img)) {
            previewImages.unshift(img);
            previewImages.pop();
          }
        });
        return previewImages;
      },
    },
  },
  methods: {
    showImageDialog() {
      dialogService
        .openDialog(ImageSelectorDialog, {
          images: this.images,
          manyImagesLayout: this.manyImagesLayout,
          showUnsplashHint: this.showUnsplashHint,
        })
        .then(({ filename } = {}) => {
          if (filename) {
            this.onImageSelected(filename);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.disabled {
  color: $grey20;
  pointer-events: none;

  img {
    opacity: 0.5;
  }
}

.selected {
  box-shadow: 0 0 0 1px $pa-color-main;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.radiobutton-group {
  display: block;
  margin: -1.5em 0 2em;
}

:deep(.radiobutton-container) {
  padding: 0.5em 0;
}

.stock-imgs {
  display: flex;
  margin-left: -0.5em;
}

.stock-img {
  display: flex;
  width: calc(20% - 0.5em);
  margin-left: 0.5em;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    border: 2px solid transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &--more {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    background-color: $sprd-color-lightest-grey;
    outline: none;

    &:hover {
      background-color: $sprd-color-light-grey;
    }
  }

  .icon {
    width: 1.5em;
    height: 1.5em;
  }
}

.stock-img-link {
  color: $grey60;
  margin: 8px 0 24px !important;
}
</style>
