import { deepCopy } from '@/utils';
import digitalProductsService from '@/api/digitalProducts/digitalProductsService';

const defaultState = {
  list: [],
  meta: {
    page: null,
    pageSize: null,
    pages: null,
  },
};

export default {
  namespaced: true,
  state: deepCopy(defaultState),
  getters: {},
  mutations: {
    setList(state, { digitalProducts, meta }) {
      state.list = digitalProducts;
      state.meta = meta;
    },
  },
  actions: {
    fetchDigitalProducts: async ({ commit }, options) => {
      const {
        list: digitalProducts,
        page,
        pageSize,
        pages,
      } = await digitalProductsService.getDigitalProducts(options);

      commit('setList', { digitalProducts, meta: { pages, pageSize, page } });
    },
  },
};
