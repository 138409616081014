<template>
  <div class="toggle-button" :class="{ disabled, inline }">
    <slot name="items" :selectItem="selectItem" :value="value">
      <div
        class="item"
        v-for="item in items"
        :key="itemKey(item)"
        :class="{ active: itemSelected(item, value) }"
        @click="!disabled && selectItem(item)"
      >
        <slot :item="item">
          <Icon :icon="item.icon" v-if="item.icon"></Icon>
          <span>{{ $t(itemToString(item)) }}</span>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    items: {},
    value: {},
    itemKey: {
      type: Function,
      default: (item) => item,
    },
    itemSelected: {
      type: Function,
      default: (item, value) => item === value,
    },
    itemToString: {
      type: Function,
      default: (item) => item.text,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
    },
  },
  emits: ['change'],
  methods: {
    selectItem(item) {
      if (this.value !== item) {
        this.$emit('change', item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey60;
  border-radius: 4px;
  color: $grey60;
  background-color: transparent;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    border-color: $pa-color-main;
    color: $pa-color-main;
  }

  &.active {
    border-color: $pa-color-main;
    color: $pa-color-main;
    box-shadow: 0 0 0 1px $pa-color-main;
    font-weight: bold;
  }

  .icon {
    width: 1.5em;
    height: 1.5em;
    margin-bottom: 0.25em;
  }
}

.toggle-button.inline {
  flex-wrap: wrap;

  .item {
    flex-grow: 0;
    margin: 0;
  }
}

.toggle-button.disabled {
  .item,
  .item.active {
    cursor: not-allowed;
    color: $grey20;
    border-color: $grey20;
    box-shadow: none;
  }
}
</style>
