<template>
  <FilterPanel heading="GENERAL.ADVANCED" :enabled="enabled" @close="close">
    <div class="section">
      <h3>{{ $t('BULK_ASSORTMENT.HEADING') }}</h3>
      <p class="text-grey">{{ $t('ADVANCED.BULK_ASSORTMENT.TEXT') }}</p>
      <button class="btn btn-ghost" @click="openIdeaAssortmentUpdate">
        {{ $t('ADVANCED.BULK_ASSORTMENT.CTA') }}
      </button>
    </div>
    <div class="section">
      <h3>{{ $t('EMPTY_ASSORTMENT.HEADING') }}</h3>
      <p class="text-grey">
        {{ $t('ADVANCED.EMPTY_ASSORTMENT.TEXT') }}
      </p>
      <button class="btn btn-ghost" @click="openIdeaAssortmentEmpty">
        {{ $t('ADVANCED.EMPTY_ASSORTMENT.CTA') }}
      </button>
    </div>
  </FilterPanel>
</template>

<script>
import FilterPanel from './FilterPanel.vue';

export default {
  name: 'IdeaListFilter',
  components: {
    FilterPanel,
  },
  props: ['enabled'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    close() {
      this.$emit('close');
    },
    openIdeaAssortmentUpdate() {
      this.$router.push({ name: 'partnerarea.assortmentUpdate' });
    },
    openIdeaAssortmentEmpty() {
      this.$router.push({ name: 'partnerarea.assortmentEmpty' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.section {
  padding: 24px 0 16px 0;
}

h3 {
  margin: 0;
}

p {
  margin: 12px 0;
}
</style>
