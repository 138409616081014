import apiClient from '@/api/client/apiClient';
import store from '@/store/Store.js';

export default {
  fetchNotifications() {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/notifications`,
        subdomain: 'hermes',
      })
      .then((res) => res.data);
  },
  updateNotifications(data) {
    return apiClient
      .request({
        method: 'PUT',
        url: `api/v1/users/${store.state.user.data.id}/notifications`,
        subdomain: 'hermes',
        data,
      })
      .then((res) => res.data);
  },
};
