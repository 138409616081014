<template>
  <PosAndTimeFilter
    :timeFilters="timeFilters"
    :defaultTimeFilter="defaultTimeFilter"
    :posFilters="posFilters"
    :minPosFilters="2"
    @change="emitCurrentFilters"
  />
</template>

<script>
import PosAndTimeFilter from 'src/app/components/charts/timeline/PosAndTimeFilter.vue';
import statisticsService from '@/api/statisticsService/statisticsService';
import timeFilters from 'src/app/components/charts/sales/timeFilters';

export default {
  name: 'SalesEarningsFilter',
  components: {
    PosAndTimeFilter,
  },
  emits: ['change'],
  props: {
    defaultTimeFilter: {
      type: Object,
      required: false,
      default() {
        return timeFilters.HALF_YEAR;
      },
    },
  },
  data() {
    return {
      timeFilters: timeFilters,
    };
  },
  computed: {
    posFilters: () => statisticsService.getPosFilters(),
  },
  methods: {
    emitCurrentFilters(filters) {
      this.$emit('change', filters);
    },
  },
};
</script>
