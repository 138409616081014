<template>
  <div class="taxation-form" v-if="taxFormLink">
    <h4>
      {{ $t('ACCOUNT.TAXATION.EU_FORM.HEADING') }}
    </h4>
    <p class="subheading" v-html="$t('ACCOUNT.TAXATION.EU_FORM.REASON')"></p>

    <strong>{{ $t('ACCOUNT.TAXATION.EU_FORM.REASON_CONSEQUENCES') }}</strong>
    <div class="taxation-form-info">
      <ol>
        <li>
          <div>1. {{ $t('ACCOUNT.TAXATION.EU_FORM.DOWNLOAD_LABEL') }}</div>
          <div class="taxation-form-link">
            <a
              class="btn btn-ghost btn-lg"
              :href="taxFormLink"
              target="_blank"
              rel="noopener"
            >
              <Icon icon="save"></Icon>
              <span v-html="$t('GENERAL.DOWNLOAD')"></span>
            </a>
          </div>
        </li>
        <li>
          <span
            >2. {{ $t('ACCOUNT.TAXATION.EU_DE_COMPANY.EXPLANATION2') }}</span
          >
          <a class="link-main" href="mailto:partner@spreadshirt.net"
            ><strong>partner@spreadshirt.net</strong></a
          >
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import localeService from '@/localeService/localeService';

export default {
  name: 'TaxFormEU',
  props: {
    taxationType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deTaxFormLink: null,
      euTaxFormLink: null,
    };
  },
  created() {
    this.deTaxFormLink = `https://assets.spreadshirt.net/creative-services/pdf/acc_UstID_DE_${this.getFormLanguage()}.pdf`;
    this.euTaxFormLink = `https://assets.spreadshirt.net/creative-services/pdf/acc_UstID_EU_${this.getFormLanguage()}.pdf`;
  },
  methods: {
    getFormLanguage() {
      const specialCases = {
        DA: 'DK',
        SV: 'SE',
        EN: 'UK',
      };

      const language = localeService.getLanguage()
        ? localeService.getLanguage().toUpperCase()
        : 'UK';

      return specialCases[language] || language;
    },
  },
  computed: {
    taxFormLink() {
      if (['DE company', 'DE artist'].includes(this.taxationType)) {
        return this.deTaxFormLink;
      } else if (this.taxationType === 'EU company') {
        return this.euTaxFormLink;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.taxation-form {
  margin-top: 24px;

  & > h4 {
    margin-bottom: 0;
  }

  & > strong {
    display: block;
    margin-top: 8px;
  }
}

.taxation-form-info {
  display: flex;

  .taxation-form-link {
    display: inline-block;
    margin-top: 12px;
  }
}

.subheading {
  color: $grey60;
  margin: 0;
}

ol {
  list-style: none;
  padding: 0;
  margin: 16px 0 0 0;

  li .taxation-form-link a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;

    & > .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  li:not(:last-of-type) {
    padding-bottom: 20px;
  }
}
</style>
