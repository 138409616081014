<template>
  <div class="publishing-mp-config">
    <ConfigList>
      <ul>
        <li>
          <SpreadshopLogo class="logo" />
          <ToggleSwitch
            :model-value="atLeastOneShopPublished"
            @update:model-value="setPublishingForAllShops"
          />
        </li>
        <li>
          <p>{{ $t('PUBLISHING.DETAIL.POS.STATUS.LABEL') }}</p>
          <CompositionPosStatusBadge
            :posTypes="[posStatus.posType]"
            :status="posStatus.status"
            hideIcon
          />
        </li>
        <li v-if="shops.length > 1">
          <p>{{ $t('PUBLISHING.DETAIL.SHOP.SHOPS_SELECTED.LABEL') }}</p>
          <button @click="openShopDialog" class="shop-dialog-btn">
            {{ publishedShops.length }}/{{ shops.length }}
            <Icon icon="right" />
          </button>
        </li>
        <li v-if="rejected">
          <p>{{ $t('PUBLISHING.DETAIL.POS.REJECTION_REASON.LABEL') }}</p>
          <button
            class="link rejection-reason-link"
            @click="openRejectionReasonDialog"
          >
            {{ $t(rejectionReasonName) }} <Icon icon="right" />
          </button>
        </li>
      </ul>
    </ConfigList>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import ConfigList from 'src/app/components/publishing/ConfigList.vue';
import RejectionReasonDialog from 'src/app/components/publishing/RejectionReasonDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import { rejectionReasonName } from '@/api/models/publishingCore/publishingCore';
import ShopSelectionDialog from 'src/app/components/publishing/ShopSelectionDialog.vue';
import {
  getCompositionPosStateForPosType,
  COMPOSITION_POS_TYPES,
} from '@/api/models/publishingCore/compositionPosStates';
import CompositionPosStatusBadge from 'src/app/components/publishing/statusBadges/CompositionPosStatusBadge.vue';

export default {
  name: 'PublishingShopConfig',
  components: {
    ToggleSwitch,
    SpreadshopLogo,
    ConfigList,
    CompositionPosStatusBadge,
  },
  props: {},
  data() {
    return {
      mpSelected: false,
    };
  },
  created() {},
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
      publishedShops: (s) =>
        s.composition.current.publishingDetails.spreadShops.shops,
      shops: (s) => s.publishingCore.shops,
    }),
    ...mapGetters({
      registeredUserShops: 'publishingCore/registeredUserShops',
    }),
    atLeastOneShopPublished() {
      return !!this.publishedShops.length;
    },
    rejected() {
      return (
        this.composition.publishingDetails.d2c.publishingStatus === 'REJECTED'
      );
    },
    online() {
      return (
        this.composition.publishingDetails.d2c.publishingStatus === 'ONLINE'
      );
    },
    rejectionReasonName() {
      return rejectionReasonName('COPYRIGHT');
    },
    posStatus() {
      return getCompositionPosStateForPosType({
        composition: this.composition,
        posType: COMPOSITION_POS_TYPES.SHOP,
      });
    },
  },
  methods: {
    ...mapMutations({
      publishToShop: 'composition/publishToShop',
      unpublishFromShop: 'composition/unpublishFromShop',
    }),
    ...mapActions({
      publishToAllShops: 'composition/publishToAllShops',
      unpublishFromAllShops: 'composition/unpublishFromAllShops',
      setPublishedShops: 'composition/setPublishedShops',
    }),
    openRejectionReasonDialog() {
      dialogService.openDialog(RejectionReasonDialog, { reason: 'COPYRIGHT' });
    },
    setPublishingForAllShops(publishStatus) {
      if (publishStatus) {
        this.publishToAllShops();
      } else {
        this.unpublishFromAllShops();
      }
    },
    async openShopDialog() {
      await dialogService
        .openDialog(ShopSelectionDialog, {
          shops: this.registeredUserShops,
          selectedShopIds: this.publishedShops.map((shop) => shop.id),
          heading: 'PUBLISHING.DETAIL.SHOP.SELECTION_DIALOG.HEADING',
          subheading: 'PUBLISHING.DETAIL.SHOP.SELECTION_DIALOG.SUBHEADING',
        })
        .then(this.setPublishedShops);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.logo {
  height: 24px;
}

.rejection-reason-link {
  font-weight: normal;
  color: $grey80;
  .icon {
    margin-left: 8px;
  }
}

.shop-dialog-btn {
  .icon {
    margin-left: 8px;
  }
}
</style>
