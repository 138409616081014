<template>
  <div class="product-settings-hotspots" v-if="availableHotspots.length">
    <ToggleButton
      :items="availableHotspots"
      :itemKey="(hotspot) => hotspot.key"
      :itemSelected="(item, value) => item.key === value.key"
      :value="currentHotspot"
      @change="selectHotspot"
      inline
      v-slot="{ item }"
    >
      <div class="hotspot">
        <img :src="item.image" />
        <span>{{ $t(item.name) }}</span>
      </div>
    </ToggleButton>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import compositionHelper from '@/composition/compositionHelper';
import { HOTSPOTS_ORDERED, HOTSPOTS } from '@/states/hotspots';
import { useStore } from 'vuex';
import ToggleButton from 'src/app/commons/ToggleButton/ToggleButton.vue';

const store = useStore();

const emit = defineEmits(['onSelect']);
const props = defineProps({
  composition: {
    type: Object,
    required: true,
  },
  selectedHotspot: {
    type: String,
  },
});

const availableHotspots = computed(() => {
  const compositionHotspots = compositionCategoryHelper.getAllAvailableHotspots(
    store.state.publishingCore.categories
  );
  return HOTSPOTS_ORDERED.filter((hotspot) =>
    compositionHotspots.includes(hotspot.key)
  );
});
const currentHotspot = computed(
  () =>
    HOTSPOTS[
      props.selectedHotspot ||
        compositionHelper.getDefaultHotspot(props.composition)
    ]
);
const selectHotspot = (hotspot) => {
  emit('onSelect', hotspot.key);
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.hotspot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 100px;

  span {
    text-align: center;
  }
}
</style>
