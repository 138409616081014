export default {
  inserted: function (el, bindings) {
    const button = el.querySelector(bindings.value);
    const input = el.querySelector('input');
    if (button) {
      input.addEventListener('keydown', function (event) {
        if (event.keyCode === 32) {
          const value = input.value;
          const strippedInput = value.replace(/ /g, '');
          if (
            value &&
            value.length - strippedInput.length >= 3 - 1 &&
            /\S/.test(value)
          ) {
            event.preventDefault();
            input.value = value.trim();
            const e = new KeyboardEvent('keydown', {
              bubbles: true,
              cancelable: true,
              key: 'Enter',
              shiftKey: false,
            });
            button.dispatchEvent(e);
          }
        }
      });
    }
  },
};
