import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

export const SocialMediaChannelsData = {
  beforeCreate() {
    if (!this.$store.state.shop.shopSavable.clientData.channels) {
      this.$store.commit('shop/addDynamicSection', {
        key: 'channels',
        initialData: {},
      });

      shopAPI.getShopSocialMediaChannels(this.$store).then((data) =>
        this.$store.commit('shop/updateSectionOriginalData', {
          sectionKey: 'channels',
          origData: data,
        })
      );
    }
  },
  computed: {
    channels() {
      return this.$store.state.shop.shopSavable.clientData.channels || {};
    },
  },
};
