import axios from 'axios';
import store from '@/store/Store';
import ideaHelper from '@/ideaHelper/ideaHelper';
import { delay, deepCopy } from '@/utils';
import productIdeaService from '@/api/productIdeaService/productIdeaService';
import apiClient from '@/api/client/apiClient';
import assortmentSystemService from '@/api/assortmentSystemService/assortmentSystemService';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  async generateImage(prompt) {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/splitbrain/partner/${store.state.user.data.id}/generate/image`,
      data: {
        prompt,
        language: store.getters['user/language'].toUpperCase() || 'EN',
      },
    });
    return {
      image: res.data.images[0],
      metaData: res.data.metadata,
    };
  },
  async getCredits() {
    const res = await axios({
      method: 'GET',
      url: `/api/v1/splitbrain/partner/${store.state.user.data.id}/credits`,
    });
    return res.data;
  },
  async setupIdea(metaData) {
    let idea = await this.waitUntilIdeaIsProcessed();
    idea = await this.fixIdeaActionRequired(idea);
    await this.setDefaultIdeaAssortment(idea);
    let metaDataPrefilled = true;
    try {
      await this.setDefaultIdeaMetaData(idea, metaData);
    } catch (error) {
      metaDataPrefilled = false;
    }
    return {
      idea,
      metaDataPrefilled,
    };
  },
  async waitUntilIdeaIsProcessed(
    { maxTime, interval } = { maxTime: 20000, interval: 5000 },
    iteration = 0
  ) {
    // we expect that no idea was uploaded in between, so first idea is our latest AI upload

    const { list: ideas } = await productIdeaService.getIdeasOfUser({
      limit: 1,
    });
    // todo: error handling;
    const idea = ideas[0];

    if (ideaHelper.getState(idea).isProcessing()) {
      if (interval * iteration > maxTime) {
        return Promise.reject();
      } else {
        await delay(interval);
        return this.waitUntilIdeaIsProcessed(
          { maxTime, interval },
          iteration + 1
        );
      }
    } else {
      return idea;
    }
  },
  async fixIdeaActionRequired(idea) {
    if (idea.bitmapUploadInteractions) {
      await apiClient.request({
        method: 'POST',
        url: idea.bitmapUploadInteractions.userActions.find(
          (action) => action.type === 'ACCEPT'
        ).href,
      });

      const { list: ideas } = await productIdeaService.getIdeasOfUser({
        limit: 1,
      });

      return ideas[0];
    } else {
      return idea;
    }
  },
  async setDefaultIdeaAssortment(originalIdea) {
    const idea = deepCopy(originalIdea);
    const labels = await assortmentSystemService.getLabels('PARTNER');
    const productTypeIds = labels[0].productTypeIds.map((ptId) => `${ptId}`);

    idea.assortment = await productIdeaService.getAssortment(idea);
    const sellables = assortmentHelper.getTotalAvailableSellables(
      idea.assortment
    );
    sellables.forEach((sellable) => {
      if (
        sellable.primarySellable &&
        productTypeIds.includes(sellable.primarySellable.productType)
      ) {
        sellable.included = true;
      } else {
        sellable.included = false;
      }
    });
    await productIdeaService.updateAssortment(idea);
  },
  enableFeatureWithProbability(probabilityInPercent) {
    store.dispatch('settings/setSetting', {
      key: 'AI_ENABLED',
      value:
        Math.random() < probabilityInPercent / 100 ? 'ENABLED' : 'DISABLED',
    });
  },
  async setDefaultIdeaMetaData(originalIdea, metaData) {
    if (!metaData) {
      return;
    }

    const idea = deepCopy(originalIdea);
    const currentTranslation = ideaHelper.getCurrentTranslation(idea);
    currentTranslation.name = metaData.name;
    currentTranslation.tags = metaData.tags;
    currentTranslation.description = metaData.description;

    await productIdeaService.updateIdea(idea);
  },
};
