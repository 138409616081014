<template>
  <div style="min-height: 200px">
    <div
      class="product"
      :class="{
        selected: options.showToggle && product.included,
        selectable: options.showToggle && !toggleSwitchDisabled,
        unavailable: !product.available,
      }"
      v-if="intersects"
      :data-filter-id="product.filterId"
    >
      <ToggleSwitch
        v-if="options.showToggle"
        :disabled="toggleSwitchDisabled"
        :model-value="product.included"
        @update:model-value="!toggleSwitchDisabled && $emit('onToggle')"
        condensed
        class="product-toggle-switch"
      />
      <a
        class="close-link"
        v-if="options.showRemove"
        @click="!options.disableRemove && $emit('onRemove')"
        :disabled="options.disableRemove"
        v-tooltip="{
          content: options.disableRemove ? $t(options.disableRemoveMsg) : null,
        }"
      >
        <div class="icon-wrapper">
          <Icon icon="close" />
        </div>
      </a>
      <div class="product-image-container" @click="onImageClick">
        <component
          :is="options.lazy ? 'SprdImage' : 'SprdLazyImage'"
          :src="imageUrl"
          :alt="product.name"
        />
        <div v-if="hoverOverlay" class="hover-overlay">
          <div class="icon-wrapper">
            <Icon icon="pen" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="product-name text">{{ product.name }}</div>
        <AdvancedPopover
          v-if="!product.available"
          heading="DESIGNS.CONFIGURATION.UNAVAILABLE.PRODUCT"
          :description="assortmentHint"
        >
          <div class="unavailable-hint">
            <Icon class="icon-info" icon="attention" />
            {{ $t('DESIGNS.CONFIGURATION.UNAVAILABLE.PRODUCT') }}
          </div>
        </AdvancedPopover>
        <div
          class="colors-wrapper"
          v-click-outside="() => setDisplayAdditionalColors(false)"
        >
          <div
            class="colors"
            v-if="options.showColors && product.primarySellable.colors"
          >
            <ColorCircle
              size="22"
              :color="color.rgbs"
              :active="
                color.appearanceId === product.primarySellable.appearanceId
              "
              v-for="color in previewColors"
              :key="color.appearanceId"
              @click="$emit('onColorChange', color)"
              :name="color.localizedName"
              :glossy="color.hints && color.hints.includes('GLOSSY')"
              :transparent="color.hints && color.hints.includes('TRANSPARENT')"
            />
            <a
              class="link link-main more-link"
              v-if="
                !displayAdditionalColors &&
                product.primarySellable.colors.length > 6
              "
              @click.stop="setDisplayAdditionalColors(true)"
            >
              +{{ additionalColorCount }}
            </a>
            <div
              class="additional-colors-overlay"
              v-if="displayAdditionalColors"
            >
              <div class="colors">
                <ColorCircle
                  size="22"
                  :color="color.rgbs"
                  :active="
                    color.appearanceId === product.primarySellable.appearanceId
                  "
                  v-for="color in product.primarySellable.colors.slice(
                    6,
                    product.primarySellable.colors.length
                  )"
                  :key="color.appearanceId"
                  @click="$emit('onColorChange', color)"
                  :name="color.localizedName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SprdImage from 'src/app/commons/image/SprdImage.vue';
import SprdLazyImage from 'src/app/commons/image/SprdLazyImage.vue';
import ToggleSwitch from 'src/app/commons/toggleSwitch/ToggleSwitch.vue';
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';
import { mapGetters } from 'vuex';
import 'intersection-observer';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'ProductCard',
  props: {
    product: {
      required: true,
    },
    hoverOverlay: {
      default: false,
    },
    options: {
      default() {
        return {
          showRemove: false,
          disableRemove: false,
          showToggle: false,
          showColors: false,
          lazy: false,
        };
      },
    },
    disableSelection: {
      type: Boolean,
    },
    flags: {
      type: Array,
    },
  },
  data() {
    return {
      intersects: !this.options.lazy,
      observer: null,
      displayAdditionalColors: false,
    };
  },
  components: {
    SprdImage,
    SprdLazyImage,
    ToggleSwitch,
    ColorCircle,
    AdvancedPopover,
  },
  computed: {
    ...mapGetters({
      designImages: 'idea/designImages',
    }),
    designCount() {
      return this.designImages.length;
    },
    imageUrl() {
      const image = !this.product.available
        ? this.product.primarySellable.productTypeImageUrl
        : this.product.primarySellable.defaultImageUrl;
      return `${image},appearanceId=${this.product.primarySellable.appearanceId}`;
    },
    previewColors() {
      if (
        this.product.primarySellable.colors &&
        this.product.primarySellable.colors.length > 6
      ) {
        if (this.displayAdditionalColors) {
          return this.product.primarySellable.colors.slice(0, 6);
        } else {
          return this.product.primarySellable.colors.slice(0, 5);
        }
      } else {
        return this.product.primarySellable.colors;
      }
    },
    additionalColorCount() {
      if (!this.product.primarySellable.colors) {
        return 0;
      }

      return this.product.primarySellable.colors.length - 5;
    },
    toggleSwitchDisabled() {
      return !this.product.included && this.disableSelection;
    },
    assortmentHint() {
      return assortmentHelper.getTranslationKeyForAssortmentHints(
        this.product.hints,
        this.flags,
        this.product.available
      );
    },
  },
  methods: {
    onImageClick() {
      if (this.options.showToggle) {
        if (!this.toggleSwitchDisabled) {
          this.$emit('onToggle');
        }
      } else {
        this.$emit('onEdit');
      }
    },
    setDisplayAdditionalColors(status) {
      this.displayAdditionalColors = status;
    },
  },
  mounted() {
    if (this.options.lazy) {
      this.observer = new IntersectionObserver(
        (entries) => {
          const card = entries[0];
          if (card.isIntersecting) {
            this.intersects = true;
            this.observer.disconnect();
          }
        },
        {
          rootMargin: '0px 0px 1000px 0px',
        }
      );
      this.observer.observe(this.$el);
    }
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';

.product {
  position: relative;
  background-color: white;
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid white;
  border-radius: 4px;

  :deep(.sprd-toggle-switch) {
    position: absolute;
    top: 1px;
    left: 1px;
    padding: 8px 5px;
    background-color: white;
    z-index: 1;
  }

  &.unavailable {
    .product-image-container {
      :deep(img) {
        opacity: 0.5;
      }
    }
  }
}

.selectable {
  .product-image-container :deep(img) {
    opacity: 0.5;
    transition: opacity 0.2s linear;
  }

  .product-image-container {
    cursor: pointer;
  }

  &.selected {
    border: 2px solid $pa-color-main;
  }

  &.selected,
  &:hover {
    .product-image-container :deep(img) {
      opacity: 1;
    }
  }
}

.footer {
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
}

.product-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 4px 4px 0 0;
  background-color: $sprd-color-lightest-grey;
  height: 210px;
  padding: 10px;

  :deep(img) {
    width: 100%;
    backface-visibility: hidden;
  }

  &:hover {
    .hover-overlay {
      opacity: 1;
    }
  }
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: transparentize(#000000, 0.25);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s linear;

  .icon-wrapper {
    border-radius: 100%;
    border: 2px solid $pa-color-main;
    color: $pa-color-main;
    background-color: #fff;
    padding: 12px;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.close-link {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .icon-wrapper {
    border-radius: 100%;
    border: 1px solid $sprd-color-lightest-grey;
    background-color: $sprd-color-lightest-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: color 0.1s linear;
  }

  .icon {
    width: 12px;
    height: 12px;
  }

  &:hover:not([disabled]) {
    color: $pa-color-red;
  }
}

.unavailable-hint {
  display: flex;
  align-items: center;

  .icon {
    color: $pa-color-yellow;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.colors-wrapper {
  display: flex;
  margin: 0 -5px;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  align-items: start;
  justify-content: center;
  max-width: 180px;

  :deep(.color) {
    transition: transform 0.1s linear;

    &:hover {
      transform: scale(1.1);
    }
  }

  :deep(.active) .color {
    box-shadow: none;
    transform: scale(1.1);
  }

  .more-link {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin: 4px;
    font-size: 12px;
  }

  .additional-colors-overlay {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: -2px;
    right: -2px;
    z-index: 2;
    margin-top: -10px;
    display: flex;
    box-shadow: 0 2px 4px -3px rgba(0, 0, 0, 0.5);
    padding-bottom: 5px;

    .colors {
      justify-content: flex-start;
      width: 180px;
      margin: 0 3px;
    }
  }
}

.selected .additional-colors-overlay {
  border: 2px solid $pa-color-main;
  border-top: none;
}

.product-name {
  height: 37px;
  overflow: hidden;

  @include small-desktop {
    height: 30px;
  }
}
</style>
