import apiClient from '@/api/client/apiClient';

export default {
  fetchCountries() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/countries`,
        params: {
          mediaType: 'json',
          fullData: true,
        },
      })
      .then((res) => res.data.countries);
  },
};
