<template>
  <div
    class="digital-products-config-inventory-files"
    :class="{ 'has-error': validation.errors.inventoryFiles }"
  >
    <ul v-if="digitalProduct.workingState.payloadFiles.length">
      <li
        v-for="inventoryFile in digitalProduct.workingState.payloadFiles.slice(
          0,
          collapsedCount
        )"
        :key="inventoryFile.id"
      >
        <DigitalProductConfigInventoryFilesItem
          :inventoryFile="inventoryFile"
        />
      </li>
    </ul>
    <div
      v-if="digitalProduct.workingState.payloadFiles.length > collapsedCount"
    >
      <CollapseAnimation>
        <ul v-show="expanded" class="extended-list">
          <li
            v-for="inventoryFile in digitalProduct.workingState.payloadFiles.slice(
              collapsedCount
            )"
            :key="inventoryFile.id"
          >
            <DigitalProductConfigInventoryFilesItem
              :inventoryFile="inventoryFile"
            />
          </li>
        </ul>
      </CollapseAnimation>
      <div class="expand-control">
        <a href="#" @click.prevent="expanded = !expanded" class="link-main">
          {{ $t(expanded ? 'GENERAL.SHOW_LESS' : 'GENERAL.SHOW_MORE') }}
        </a>
      </div>
    </div>
    <UploadArea
      :uploadFn="uploadInventoryFile"
      :condensed="digitalProduct.workingState.payloadFiles.length > 0"
      fileTypes=".pdf, .jpg, .jpeg, .png, .svg, .dng, .xmp"
      :error="validation.errors.inventoryFiles"
    />
    <DigitalProductLegalDisclaimer />
    <p class="error-info" v-if="validation.errors.inventoryFiles">
      <Icon icon="error" />
      {{ validation.errors.inventoryFiles.reason }}
    </p>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import UploadArea from 'src/app/components/upload/UploadArea.vue';
import { bytesToSize } from 'src/app/commons/utils';
import DigitalProductLegalDisclaimer from './DigitalProductLegalDisclaimer.vue';
import CollapseAnimation from '@/CollapseAnimation/CollapseAnimation.vue';
import DigitalProductConfigInventoryFilesItem from './DigitalProductConfigInventoryFilesItem.vue';
import digitalProductValidator from '@/api/validators/digitalProduct/digitalProductValidator';

export default {
  name: 'DigitalProductConfigInventoryFiles',
  components: {
    UploadArea,
    DigitalProductLegalDisclaimer,
    CollapseAnimation,
    DigitalProductConfigInventoryFilesItem,
  },
  props: {},
  data() {
    return {
      collapsedCount: 5,
      expanded: false,
    };
  },
  created() {},
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      userId: (s) => s.user.data.id,
      validation: (state) => state.digitalProduct.validation,
    }),
    ...mapGetters({}),
  },
  methods: {
    bytesToSize,
    ...mapMutations({
      addToast: 'toasts/addToast',
    }),
    ...mapActions({
      uploadDigitalProductInventoryFiles:
        'digitalProduct/uploadDigitalProductInventoryFiles',
    }),
    async uploadInventoryFile(files) {
      if (files?.length > 0) {
        try {
          await this.uploadDigitalProductInventoryFiles(files);
          this.addToast({
            variant: 'black',
            text: 'TOASTS.UPLOAD_SUCCESS',
            hideIcon: true,
          });
        } catch (error) {
          let errorMessage = this.$options.filters.translate('Upload failed');

          switch (error.data.code) {
            case 'UNSUPPORTED_FILE_EXTENSION':
              errorMessage = this.$options.filters.translate(
                'Unsupported File Extension'
              );
              break;
            case 'FILE_SIZE_LIMIT_EXCEEDED':
              errorMessage = this.$options.filters.translate(
                'File Size Limit Error',
                {
                  limit:
                    digitalProductValidator.defaultOptions.inventoryFiles
                      .maxSize,
                }
              );
              break;
            case 'PAYLOAD_SIZE_LIMIT_EXCEEDED':
              errorMessage = this.$options.filters.translate(
                'Payload Size Limit Error',
                {
                  limit:
                    digitalProductValidator.defaultOptions.inventoryFiles
                      .totalMaxSize,
                }
              );
              break;
          }

          this.addToast({
            variant: 'error',
            text: errorMessage,
            hideIcon: true,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

ul {
  list-style: none;
  margin: 0;
  padding: 0 0 16px 0;

  li {
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey10;
    }
  }
}

.extended-list {
  padding-top: 16px;
  border-top: 1px solid $grey10;
}

.expand-control {
  padding: 16px 0;
  border-top: 1px solid $grey10;
  border-bottom: 1px solid $grey10;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.error-info {
  padding: 0;
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
}
</style>
