<template>
  <div class="error-page text-center">
    <img src="images/404.svg" />
    <h1>
      {{ $t('UNKNOWN_PAGE.HEADER') }}
    </h1>
    <p>
      {{ $t('UNKNOWN_PAGE.DESCRIPTION') }}
    </p>
    <button type="button" class="btn btn-primary btn-lg" @click="goToDashboard">
      {{ $t('UNKNOWN_PAGE.BUTTON') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    goToDashboard() {
      this.$router.push({ name: 'partnerarea.dashboard' });
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';

#application.state-partnerarea-404 {
  min-width: 0;
  overflow-y: initial;

  #main-content {
    overflow: initial;
  }

  .help-panel {
    display: none;
  }

  .error-page {
    width: 100%;
    height: 100px;
    display: block;
    margin: 0 auto;

    p {
      margin: 24px 0;
      color: $grey60;
    }

    img {
      display: block;
      width: 50%;
      min-width: 500px;
      margin: 100px auto 50px;
    }
  }
}
</style>
