<template>
  <div>
    <ToggleSwitch
      v-model="startPageEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.ENABLE_STARTPAGE"
      class="sprd-toggle-switch--first bold"
    />
    <ul>
      <draggable
        v-bind="{ handle: '.handle', forceFallback: true }"
        v-model="dynamicSections"
        item-key="name"
      >
        <template #item="{ element }">
          <DraggableElement
            :label="$t(element.label)"
            :active="element.active"
            @edit="goToSection(element.url)"
          />
        </template>
      </draggable>
    </ul>
    <div v-if="errorMessageShowing" class="hint-box">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.ACTIVATING.HINT') }}
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import DraggableElement from 'src/app/partnerarea/pos/shop/common/DraggableElement.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import {
  StartPageData,
  atLeastOneSectionEnabled,
} from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';

// careful, the content of this map has to match the content in navigationConfig.js !
const sectionMeta = {
  TEASER: [
    'shop-area.pages.start-page.teaser',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.TEASER.HEADING',
    (startPage) => startPage.teaserEnabled,
  ],
  PRODUCT_PREV: [
    'shop-area.pages.start-page.product-preview',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.PRODUCT_PREVIEW.HEADING',
    (startPage) => startPage.productPreviewEnabled,
  ],
  ABOUT_US: [
    'shop-area.pages.start-page.about-us',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.ABOUT_US.HEADING',
    (startPage) => startPage.aboutUsEnabled,
  ],
  FOLLOW_US: [
    'shop-area.pages.start-page.follow-us',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.FOLLOW_US.HEADING',
    (startPage) => startPage.followUsEnabled,
  ],
  CYO_INTRO: [
    'shop-area.pages.start-page.cyo-intro',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.CYO_INTRO.HEADING',
    (startPage) => startPage.cyoIntroEnabled,
  ],
  TRUST_ELEMENTS: [
    'shop-area.pages.start-page.trust-elements',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.TRUST_ELEMENTS.HEADING',
    (startPage) => startPage.trustElementsEnabled,
  ],
  PROMOTION: [
    'shop-area.pages.start-page.promotion',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.PROMOTION.HEADING',
    (startPage) => startPage.promotionEnabled,
  ],
  PRODUCT_TYPE_CATEGORY: [
    'shop-area.pages.start-page.product-type-category',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.PRODUCT_RANGE.HEADING',
    (startPage) => startPage.productTypeCategoriesEnabled,
  ],
  COLLECTION_PREV: [
    'shop-area.pages.start-page.collection-preview',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.COLLECTION_PREVIEW.HEADING',
    (startPage) => startPage.collectionPreviewEnabled,
  ],
  DESIGN_PREV: [
    'shop-area.pages.start-page.design-preview',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.DESIGN_PREVIEW.HEADING',
    (startPage) => startPage.designPreviewEnabled,
  ],
  DESIGNS_WITH_PRODUCTS: [
    'shop-area.pages.start-page.designs-with-products',
    'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.DESIGNS_WITH_PRODUCTS.HEADING',
    (startPage) => startPage.designsWithProductsEnabled,
  ],
};

export default {
  name: 'StartPage',
  mixins: [StartPageData, SettingsData],
  components: {
    ToggleSwitch,
    draggable,
    DraggableElement,
  },
  data() {
    return {
      errorMessageShowing: false,
    };
  },
  computed: {
    startPagePossible() {
      return atLeastOneSectionEnabled(this.startPage);
    },
    startPageEnabled: {
      set(newValue) {
        if (newValue && !atLeastOneSectionEnabled(this.startPage)) {
          this.errorMessageShowing = true;
        } else {
          this.settings.startPageEnabled = newValue;
        }
      },
      get() {
        return this.settings.startPageEnabled;
      },
    },
    orderOfSections() {
      return this.startPage.orderOfSections;
    },
    dynamicSections: {
      get() {
        if (!this.orderOfSections) {
          return [];
        }

        return this.orderOfSections
          .filter((sectionName) => sectionMeta[sectionName])
          .map((sectionName) => {
            const [url, label, isActive] = sectionMeta[sectionName];

            return {
              name: sectionName,
              url,
              label,
              active: isActive(this.startPage),
            };
          });
      },
      set(newOrder) {
        this.startPage.orderOfSections = newOrder.map((entry) => entry.name);
      },
    },
  },
  methods: {
    goToSection(url) {
      this.$router.push({ name: url });
    },
  },
  watch: {
    startPagePossible: {
      handler() {
        this.$store.commit('shop/setLivePreview', {
          path: '',
          visible: this.startPagePossible,
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './startPageCommon.scss';
@import 'src/scss/styleguide/type';

.hint-box {
  background-color: $pa-color-main;
  color: $grey0;
  padding: 5px 10px;
  margin-bottom: 10px;
}
</style>
