import shopCollectionsService from 'src/app/commons/api/shopCollectionsService/shopCollectionsService';
import { getSpreadshopUrl } from 'src/app/commons/api/shop/urlBuilder';
import shopService from '@/api/shopService/shopService';
import { i18n } from '@/translate/i18n';
import { deepCopy, isEqual } from 'src/app/commons/utils';
import { validateStateSubset } from './validators/shopSavableValidator';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

const { t } = i18n.global;

const patchCommissionChanges = (store, data, shopId, originalData) => {
  const patchData = {};
  Object.keys(data.productTypeCommissions).forEach((ptId) => {
    if (
      !isEqual(
        data.productTypeCommissions[ptId],
        originalData.productTypeCommissions[ptId]
      )
    ) {
      patchData[ptId] = data.productTypeCommissions[ptId];
    }
  });
  return shopService.patchMdsCommissions(store.state.user.data.id, shopId, {
    productTypeCommissions: patchData,
  });
};

// updated state is returned from the put that we need to remember
const updatePromotions = (store, data) =>
  shopAPI.editPromotions(store, data).then((response) =>
    store.commit('shop/updateSectionOriginalData', {
      sectionKey: 'promotions',
      origData: { promotions: response.promotions },
    })
  );

const savingFunctions = {
  settings: (store, data) => shopAPI.editShopSettings(store, data),
  config: (store, data) => shopAPI.editShopConfigs(store, data),
  aboutPage: (store, data) => shopAPI.editAboutPage(store, data),
  collections: (store, data) =>
    shopCollectionsService.saveCollectionsOrder(data.collectionList),
  pricing: (store, data, shopId, originalData) =>
    patchCommissionChanges(store, data, shopId, originalData),
  promotions: (store, data) => updatePromotions(store, data),
  channels: (store, data) => shopAPI.editShopSocialMediaChannels(store, data),
  startPage: (store, data) => shopAPI.editStartPage(store, data),
  tracking: (store, data) => shopAPI.saveTracking(store, data),
};

export default {
  namespaced: true,
  state: {
    selectedCollection: null,
    originalSelectedCollection: null,
    collectionContent: null,
    collectionContentChanges: {
      ideaIdsToAdd: [],
      ideaIdsToRemove: [],
    },
    livePreview: {
      path: '',
      section: '',
      visible: false,
      fullScreen: false,
      mode: 'desktop',
    },
    shopSavable: {
      clientData: {
        settings: {},
        config: {},
      },
      originalData: {
        settings: {},
        config: {},
      },
    },
    shopSavableValidationError: {},
    shopSavableSavingError: '',
    currentShop: {},
    loadingJobs: [],
    youtubeChannels: null,
    youtubeSelectedChannelId: null,
    youtubeSellables: {},
  },
  mutations: {
    setCollection(state, collection) {
      state.selectedCollection = collection;
      state.originalSelectedCollection = Object.assign({}, collection);
    },
    updateCollection(state, payload) {
      Object.assign(state.selectedCollection, payload);
    },
    updateOriginalCollection(state) {
      state.originalSelectedCollection = Object.assign(
        {},
        state.selectedCollection
      );
    },
    resetCollection(state) {
      Object.assign(state.selectedCollection, state.originalSelectedCollection);
      state.collectionContent.list.forEach((idea) => {
        idea.selected = idea.originalSelected;
      });
      state.collectionContentChanges.ideaIdsToAdd = [];
      state.collectionContentChanges.ideaIdsToRemove = [];
    },
    addCollectionContent(state, payload) {
      state.collectionContent = payload;
    },
    changeIdeaSelection(state, payload) {
      const idea = state.collectionContent.list.find(
        (i) => i.id === payload.ideaId
      );
      idea.selected = payload.selected;

      state.collectionContentChanges.ideaIdsToAdd =
        state.collectionContentChanges.ideaIdsToAdd.filter(
          (id) => id !== payload.ideaId
        );
      state.collectionContentChanges.ideaIdsToRemove =
        state.collectionContentChanges.ideaIdsToRemove.filter(
          (id) => id !== payload.ideaId
        );

      if (idea.selected && !idea.originalSelected) {
        state.collectionContentChanges.ideaIdsToAdd.push(idea.id);
      } else if (!idea.selected && idea.originalSelected) {
        state.collectionContentChanges.ideaIdsToRemove.push(idea.id);
      }

      state.selectedCollection.ideaCount =
        state.originalSelectedCollection.ideaCount +
        state.collectionContentChanges.ideaIdsToAdd.length -
        state.collectionContentChanges.ideaIdsToRemove.length;
    },
    updateOriginalIdeaSelection(state) {
      state.collectionContent.list.forEach((idea) => {
        idea.originalSelected = idea.selected;
      });
      state.collectionContentChanges.ideaIdsToAdd = [];
      state.collectionContentChanges.ideaIdsToRemove = [];
    },
    resetCollectionData(state) {
      state.selectedCollection = null;
      state.originalSelectedCollection = null;
      state.collectionContent = null;
      state.collectionContentChanges.ideaIdsToAdd = [];
      state.collectionContentChanges.ideaIdsToRemove = [];
    },
    setCollectionHasChanged(state, changed) {
      if (state.shopSavable.originalData.collections) {
        state.shopSavable.clientData.collections.collectionHasChanged = changed;
        state.shopSavable.originalData.collections.collectionHasChanged =
          changed;
      }
    },
    setLivePreviewVisible(state, visible) {
      state.livePreview.visible = visible;
    },

    /**
     * Navigates the spreadshop-livepreview to a certain page and scrolls to a certain section without hard-refreshing the spreadshop.
     * Hard refreshes only take place when the shopId changes.
     * @param path relative path you want to navigate to, without leading slash
     * @param section dom element id that you want to scroll to (optional)
     * @param visible general killswitch in case you need the right hand side for something else
     */
    setLivePreview(state, { path, section = '', visible = true }) {
      state.livePreview = Object.assign({}, state.livePreview, {
        path,
        section,
        visible,
      });
    },
    setLivePreviewMode(state, mode) {
      state.livePreview.mode = mode;
    },
    setLivePreviewFullScreen(state, fullScreen) {
      state.livePreview.fullScreen = fullScreen;
    },
    setShopSavableSavingError(state, error) {
      state.shopSavableSavingError = error;
    },
    addLoadingJob(state, id) {
      state.loadingJobs.push(id);
    },
    removeLoadingJob(state, id) {
      const index = state.loadingJobs.indexOf(id);
      if (index > -1) {
        state.loadingJobs.splice(index, 1);
      }
    },
    bootstrapShopSavable(state, { shop, settings, config }) {
      state.currentShop = shop;
      Object.keys(state.shopSavable.originalData).forEach((key) => {
        state.shopSavable.originalData[key] = null;
        state.shopSavable.clientData[key] = null;
      });
      state.shopSavable.originalData.settings = deepCopy(settings);
      state.shopSavable.clientData.settings = deepCopy(settings);
      state.shopSavable.originalData.config = deepCopy(config);
      state.shopSavable.clientData.config = deepCopy(config);
    },
    addDynamicSection(state, { key, initialData }) {
      state.shopSavable.originalData[key] = deepCopy(initialData);
      state.shopSavable.clientData[key] = deepCopy(initialData);
    },
    updateSectionOriginalData(state, { sectionKey, origData }) {
      if (Array.isArray(origData)) {
        // in case of arrays, we have to override the whole section and hope for the best
        state.shopSavable.originalData[sectionKey] = deepCopy(origData);
        state.shopSavable.clientData[sectionKey] = deepCopy(origData);
      } else {
        // we modify the existing section objects in-place to keep the reactivity working
        Object.keys(origData).forEach((propKey) => {
          state.shopSavable.originalData[sectionKey][propKey] = deepCopy(
            origData[propKey]
          );
          state.shopSavable.clientData[sectionKey][propKey] = deepCopy(
            origData[propKey]
          );
        });
      }
    },
    updateSectionData(state, { key, data }) {
      Object.keys(data).forEach((k) => {
        state.shopSavable.clientData[key][k] = data[k];
      });
    },
    saveShopSavable(state) {
      Object.assign(
        state.shopSavable.originalData,
        deepCopy(state.shopSavable.clientData)
      );
      state.shopSavableSavingError = '';
    },
    resetShopSavable(state) {
      Object.keys(state.shopSavable.originalData).forEach((key) => {
        if (
          !isEqual(
            state.shopSavable.clientData[key],
            state.shopSavable.originalData[key]
          )
        ) {
          Object.assign(
            state.shopSavable.clientData[key],
            deepCopy(state.shopSavable.originalData[key])
          );
        }
      });
      state.shopSavableValidationError = {};
    },
    validateShopSavableSubset(state, stateName) {
      const validation = validateStateSubset(
        state.shopSavable.clientData,
        stateName
      );
      state.shopSavableValidationError = validation.result ? {} : validation;
    },
    setYoutubeChannels(state, channels) {
      state.youtubeChannels = channels;
    },
    setYoutubeSelectedChannelId(state, channelId) {
      state.youtubeSelectedChannelId = channelId;
    },
    setYoutubeSellables(state, { channelId, sellableData }) {
      state.youtubeSellables[channelId] = sellableData;
    },
  },
  actions: {
    async saveCollection({ commit, state }) {
      const savedCollection = await shopCollectionsService.saveCollection(
        state.selectedCollection
      );
      commit('updateCollection', savedCollection);
      commit('updateOriginalCollection');
      commit('setCollectionHasChanged', true);
    },
    async deleteCollection({ commit, state }) {
      if (state.selectedCollection.id) {
        await shopCollectionsService.deleteCollection(state.selectedCollection);
      }
      commit('updateOriginalCollection');
      commit('setCollectionHasChanged', true);
    },
    async loadIdeasOfPos({ commit, state }, collectionContent) {
      collectionContent.list.forEach((idea) => {
        idea.selected = idea.shopCollectionIds.includes(
          state.selectedCollection.id
        );
        idea.originalSelected = idea.selected;

        if (state.collectionContentChanges.ideaIdsToAdd.includes(idea.id)) {
          idea.selected = true;
        } else if (
          state.collectionContentChanges.ideaIdsToRemove.includes(idea.id)
        ) {
          idea.selected = false;
        }
      });
      commit('addCollectionContent', collectionContent);
    },
    async saveCollectionChanges({ commit, state }) {
      await shopCollectionsService.changeIdeasInCollection(
        state.selectedCollection,
        state.collectionContentChanges
      );
      commit('updateOriginalIdeaSelection');
      commit('setCollectionHasChanged', true);
    },
    changeSelectionOfAllIdeas({ commit, state }, payload) {
      state.collectionContent.list.forEach((idea) =>
        commit('changeIdeaSelection', {
          ideaId: idea.id,
          selected: payload.selected,
        })
      );
    },
    async bootstrapShopSavable({ commit }, { userId, shopId }) {
      const [shop, settings, config] = await Promise.all([
        shopAPI.getShop(userId, shopId),
        shopAPI.getShopSettings(this, shopId),
        shopAPI.getShopConfigs(this, shopId),
      ]);
      commit('bootstrapShopSavable', { shop, settings, config });
    },
    saveShopSavable({ state, commit }) {
      const shopId = state.currentShop.id;
      return Promise.all(
        Object.keys(state.shopSavable.originalData).map((key) =>
          !isEqual(
            state.shopSavable.clientData[key],
            state.shopSavable.originalData[key]
          )
            ? savingFunctions[key](
                this,
                state.shopSavable.clientData[key],
                shopId,
                state.shopSavable.originalData[key]
              )
            : Promise.resolve()
        )
      )
        .then(() => {
          commit('saveShopSavable');
        })
        .catch((e) => {
          const errorCode = e.data && e.data.error;
          if (!errorCode) {
            return Promise.reject();
          }
          let message = `${t('GENERAL.ERROR.HEADING')} (${errorCode})`;
          if (
            errorCode === '333001' ||
            errorCode === '333002' ||
            errorCode === '333003'
          ) {
            const blacklistedTerms = e.data.causes
              .map((c) => c.message)
              .join(', ');
            message = `${t(
              'DESIGNS.VALIDATION.BLACKLIST.TERMS'
            )}: ${blacklistedTerms}`;
          }
          commit('setShopSavableSavingError', message);
          return Promise.reject();
        });
    },
    async setYoutubeSelectedChannelId({ commit, state }, channelId) {
      commit('setYoutubeSelectedChannelId', channelId);

      if (!channelId || state.youtubeSellables[channelId]) {
        return;
      }

      commit('setYoutubeSellables', {
        channelId,
        sellableData: { loading: true },
      });
      const data = await shopAPI.getYoutubeSellables(this, channelId);
      data.loading = false;
      commit('setYoutubeSellables', {
        channelId,
        sellableData: data,
      });
    },
  },
  getters: {
    getSpreadshopUrl: (state) => (path, livePreviewMode) => {
      return getSpreadshopUrl(state.currentShop, path, livePreviewMode);
    },
    shopSavableDirty(state) {
      return !isEqual(
        state.shopSavable.clientData,
        state.shopSavable.originalData
      );
    },
    hasShopSavableValidationErrors(state) {
      return (
        state.shopSavableValidationError.errors &&
        Object.keys(state.shopSavableValidationError.errors).length > 0
      );
    },
    isShopEmpty(state) {
      return state.shopSavable.clientData.config.shopCurrencyEditable;
    },
  },
};
