<template>
  <div class="empty-timeline">
    <Icon :icon="icon"></Icon>
    <h2>{{ $t(heading) }}</h2>
    <p>{{ $t(subheading) }}</p>
    <p>
      <slot name="links"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptyTimeline',
  props: {
    icon: {
      type: String,
      default: 'lightbulb',
    },
    heading: {
      required: true,
      type: String,
    },
    subheading: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.empty-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2,
  p {
    margin-bottom: 16px;
  }

  .icon {
    width: 48px;
    height: 48px;
    color: $pa-color-main;
  }

  :slotted(a) {
    &:first-child {
      margin-right: 11px;
    }

    &:last-child {
      margin-left: 11px;
    }
  }
}
</style>
