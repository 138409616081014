<template>
  <component
    :is="clickable ? 'button' : 'div'"
    class="banner"
    :class="{ inverted, condensed, primary }"
    @click="clickable ? $emit('onLinkClick') : null"
  >
    <div class="left" v-if="!noIcon">
      <slot name="left">
        <div class="image-wrapper">
          <img v-if="!icon" :src="image" alt="" />
          <Icon v-else :icon="icon" />
        </div>
      </slot>
    </div>
    <div class="banner-content">
      <!-- <div v-if="!$slots.left" class="icon-wrapper">
              <Icon />
          </div> -->
      <!-- <div class="designs">
              <div class="design" v-for="design in designs" :key="design.designId">
                  <div class="design-checked-background" :class="{'dark': design.backgroundColor}">
                      <SprdImage :src="`${design.preview.href},height=100,width=100`" alt="Design Image" />
                  </div>
              </div>
          </div> -->
      <div class="explanation">
        <h4>{{ $t(heading) }}</h4>
        <p>{{ $t(subheading) }}</p>
      </div>
      <a
        v-if="link"
        class="link-main"
        :class="{ 'icon-link': linkIcon }"
        href="#"
        @click.prevent="$emit('onLinkClick')"
      >
        <Icon v-if="linkIcon" :icon="linkIcon" />
        {{ $t(link) }}
      </a>
    </div>
  </component>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
    image: {
      type: String,
      default: '/images/onboarding.svg',
    },
    link: {
      type: String,
      default: '',
    },
    linkIcon: {
      type: String,
    },
    inverted: {
      type: Boolean,
    },
    primary: {
      type: Boolean,
    },
    condensed: {
      type: Boolean,
    },
    clickable: {
      type: Boolean,
    },
    noIcon: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';

.banner {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $grey0;
  border-radius: 4px;
  padding: 16px;
  position: relative;
  overflow: hidden;

  &.inverted {
    background-color: $grey5;

    .image-wrapper {
      background-color: $grey0;
    }
  }

  &.primary {
    background-color: $pa-color-main;
    color: $grey0;

    .image-wrapper {
      background-color: $grey0;
    }

    .explanation p {
      color: $grey5;
    }

    .link-main {
      color: $grey0;
    }
  }

  &.condensed {
    padding: 0;
    align-items: flex-start;

    .banner-content {
      flex-direction: column;
      align-items: flex-start;

      a {
        margin: 8px 0 0 0;
        text-align: left;
      }
    }
  }

  .banner-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .explanation {
    margin-right: 24px;
    text-align: left;

    h4 {
      margin: 0;
    }

    p {
      color: $grey60;
      margin: 4px 0 0 0;
      max-width: 600px;
    }
  }

  .left {
    margin-right: 8px;
  }

  .image-wrapper {
    width: 40px;
    height: 40px;
    background-color: $grey5;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon,
    img {
      width: 24px;
      height: 24px;
      color: $pa-color-main;
    }
  }

  a {
    margin-left: auto;
    text-align: right;
  }
}

button.banner:hover {
  transform: scale(1.02);
  transition: transform 0.1s linear;
}
</style>
