import { i18n } from '@/translate/i18n';

const { t } = i18n.global;

export function getLengthValidator(limits) {
  return async function (value) {
    if (!value || value.length < limits.min || value.length > limits.max) {
      throw new Error(t('SA.shared.form.error.length', limits));
    }
  };
}
