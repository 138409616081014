import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

export default {
  getTemplatesForIdea(ideaId, labels) {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/users/${store.state.user.data.id}/ideas/${ideaId}/phenotypes`,
        params: {
          labels: labels && labels.join ? labels.join(',') : null,
        },
      })
      .then((res) => res.data);
  },
  applyTemplateForIdeas({ templateId, ideaIds, parts }) {
    return apiClient.request({
      method: 'POST',
      url: `/api/v1/users/${store.state.user.data.id}/phenotypes/${templateId}/actions/apply/invoke`,
      data: {
        ideaIds,
        parts,
      },
    });
  },
  createTemplate(template) {
    return apiClient.request({
      method: 'POST',
      url: `/api/v1/users/${store.state.user.data.id}/phenotypes`,
      data: template,
    });
  },
  updateTemplate(template) {
    return apiClient.request({
      method: 'PUT',
      url: `/api/v1/users/${store.state.user.data.id}/phenotypes/${template.phenotypeId}`,
      data: template,
    });
  },
  deleteTemplate(templateId) {
    return apiClient.request({
      method: 'DELETE',
      url: `/api/v1/users/${store.state.user.data.id}/phenotypes/${templateId}`,
    });
  },
};
