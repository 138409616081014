export const buildJsSnippet = (tld, shopName, locale) =>
  `<div id="myShop">
    <a href="https://${shopName}.myspreadshop.${tld}">${shopName}</a>
</div>

<script>
    var spread_shop_config = {
        shopName: '${shopName}',
        locale: '${locale}',
        prefix: 'https://${shopName}.myspreadshop.${tld}',
        baseId: 'myShop'
    };
</script>

<script type="text/javascript"
        src="https://${shopName}.myspreadshop.${tld}/shopfiles/shopclient/shopclient.nocache.js">
</script>`;
