<template>
  <div class="navbar-secondary bulk-toolbar">
    <ul class="nav toolbar-bottom">
      <li id="designs-selected-nav-item">
        <strong
          >{{ selectedIdeaCount }}/{{ maxIdeaCount }}
          {{ $t('GENERAL.SELECTED') }}</strong
        >
      </li>
      <li
        id="design-list-tags-nav-item"
        class="bulk-tags"
        :class="{ disabled: selectedIdeaCount <= 0 }"
        v-if="!hideTags"
      >
        <a
          href="#"
          @click.prevent="goToBulkTags"
          id="design-tags-button"
          class="link"
        >
          <Icon icon="tag" /> {{ $t('DESIGNS.TAGS') }}
        </a>
      </li>
      <li
        id="design-list-pos-nav-item"
        class="bulk-pos"
        :class="{ disabled: selectedIdeaCount <= 0 }"
      >
        <a
          href="#"
          @click.prevent="goToBulkPos"
          id="design-delete-button"
          class="link"
        >
          <Icon icon="shop" /> {{ $t('DESIGNS.POINTS_OF_SALE') }}
        </a>
      </li>
      <li
        id="design-list-delete-nav-item"
        class="bulk-delete"
        :class="{ disabled: selectedIdeaCount <= 0 }"
      >
        <a
          href="#"
          id="design-delete-button"
          class="link"
          @click.prevent="$emit('delete', selectedIdeas)"
        >
          <Icon icon="bin" /> {{ $t('GENERAL.DELETE') }}
        </a>
      </li>
      <li class="bulk-select">
        <a
          href="#"
          class="link-main select-link"
          @click.prevent="bulkSelection"
          :disabled="!maxIdeaCount"
        >
          {{
            allSelected ? $t('GENERAL.DESELECT_ALL') : $t('GENERAL.SELECT_ALL')
          }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'IdeaBulkToolbar',
  props: {
    selectedCount: {
      type: Number,
    },
    maxCount: {
      type: Number,
    },
    emitOnly: {
      type: Boolean,
    },
    hideTags: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      selectedIdeas: 'ideas/selected',
      selectable: 'ideas/selectable',
    }),
    ...mapState({
      ideas: (state) => state.ideas.list,
    }),
    selectedIdeaCount() {
      return this.selectedCount || this.selectedIdeas.length;
    },
    maxIdeaCount() {
      return this.maxCount || this.selectable.length;
    },
    allSelected() {
      return this.selectedIdeaCount >= this.maxIdeaCount;
    },
  },
  methods: {
    ...mapActions({
      selectAll: 'ideas/selectAll',
      deselectAll: 'ideas/deselectAll',
    }),
    goToBulkTags() {
      this.$emit('bulkTags');

      if (this.emitOnly) {
        return;
      }
      this.$router.push({ name: 'partnerarea.bulkTags' });
    },
    goToBulkPos() {
      this.$emit('bulkPos');

      if (this.emitOnly) {
        return;
      }
      this.$router.push({ name: 'partnerarea.bulkPos' });
    },
    bulkSelection() {
      this.$emit('bulkSelection');

      if (this.emitOnly) {
        return;
      }

      if (this.allSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.bulk-toolbar {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: auto;
}

.toolbar-bottom {
  justify-content: flex-start !important;
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
  border: none;

  li {
    margin-right: 24px;

    &.bulk-select {
      margin-left: auto;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.bulk-tags,
.bulk-delete,
.bulk-pos {
  a {
    font-weight: normal;
  }
}

.bulk-delete {
  a:hover {
    color: $pa-color-red;
  }

  a:focus {
    color: $pa-color-red-darker;
  }
}
</style>
