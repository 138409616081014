<template>
  <div class="noStuffPlaceholder" v-if="display">
    <Icon class="bulb" icon="lightbulb"></Icon>
    <h1>{{ $t(heading) }}</h1>
    <p>{{ $t(subHeading) }}</p>
    <div>
      <button v-if="ctaIcon" class="cta-btn" @click="goToCTA">
        <Icon class="shop-icon" :icon="ctaIcon"></Icon>
        {{ $t(ctaText) }}
      </button>
      <SmartFaqLink class="cta-link" v-if="faqLink" :link="faqLink" />
    </div>
  </div>
</template>

<script>
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'NoStuffPlaceholder',
  components: { SmartFaqLink },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      default: '',
    },
    faq: {
      type: String,
      default: '',
    },
    faqLink: {
      type: Object,
    },
    ctaIcon: {
      type: String,
      default: '',
    },
    ctaText: {
      type: String,
      default: '',
    },
    ctaTarget: {
      type: String,
      default: '',
    },
  },
  methods: {
    goToCTA() {
      this.$router.push({ name: this.ctaTarget });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.noStuffPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;

  h1 {
    margin: 0;
    font-size: 28px;
  }

  p {
    margin: 32px;
  }
}

.bulb {
  width: 100px;
  height: 100px;
  margin-bottom: 12px;
  color: $pa-color-main;
}

.cta-link {
  justify-content: center;
  margin-top: 16px;
}

.cta-btn {
  background-color: unset;
  color: $pa-color-main;
  font-weight: 700;
  border: 2px solid $pa-color-main;
  padding: 16px;
}
</style>
