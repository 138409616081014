<template>
  <div class="dialog-size-full greyDialog">
    <SocialMediaToolDialogHeader
      :steps="2"
      :currentStep="currentStep"
      :subStep="1"
      @cancel="modalInstance.dismiss"
      @gotoStep="goBack"
      @back="goBack"
    />
    <div class="modal-body">
      <SocialMediaToolCustomizer
        v-if="currentStep === 1"
        @nextStep="goToStep2"
        :savedCanvasData="canvasData"
        :canvasSettings="canvasSettings"
      />
      <SocialMediaToolResultsPage
        v-if="currentStep === 2"
        :canvasData="canvasData"
        ref="resultsPage"
      />
    </div>
  </div>
</template>

<script>
import SocialMediaToolCustomizer from './SocialMediaToolCustomizer.vue';
import SocialMediaToolResultsPage from './SocialMediaToolResultsPage.vue';
import SocialMediaToolDialogHeader from './SocialMediaToolDialogHeader.vue';

import analyticsService from '@/tracking/analytics';

export default {
  name: 'SocialMediaToolPromoDialog',
  props: ['modalInstance', 'data'],
  components: {
    SocialMediaToolDialogHeader,
    SocialMediaToolCustomizer,
    SocialMediaToolResultsPage,
  },
  data() {
    const settings = this.$store.state.shop.shopSavable.clientData.settings; // we do not have access to computed properties in data()
    const logoPossible = settings.logoImage && settings.logoImageEnabled;
    const brandingOptions = [
      {
        type: 'LOGO',
        trans: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_LOGO'),
      },
      {
        type: 'NAME',
        trans: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_NAME'),
      },
      {
        type: 'NONE',
        trans: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_NONE'),
      },
    ];
    return {
      currentStep: 1,
      canvasData: null,
      canvasSettings: {
        customText: this.data.text || '',
        textColor: '#FFFFFF',
        selectedHeadlineFont: settings.fontHeadline,
        logoPossible,
        brandingOptions,
        selectedPromo: null,
        defaultPromo: this.data.defaultPromo,
        selectedBrandingOption: logoPossible
          ? brandingOptions[0]
          : brandingOptions[1],
        selectedStockImageName: null,
      },
    };
  },
  methods: {
    goToStep2(canvasData) {
      this.canvasData = canvasData;
      this.currentStep = 2;

      analyticsService.logEvent('sharing-promo-finished', {
        eventArea: 'sharing-tool',
      });
    },
    goBack() {
      this.canvasData = null;
      this.currentStep = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.greyDialog {
  background-color: $grey5;
}
</style>
