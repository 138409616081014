<template>
  <transition name="reveal" appear>
    <div class="toast-container">
      <div class="toast color-box" :class="colorClass">
        <div v-if="variant === 'loading-bar'" class="loading-bar" />
        <Icon v-if="!hideIcon && icon" class="icon mr" :icon="icon" />
        <div class="toast-text">
          <slot />
        </div>
        <button v-if="dismissible" class="btnDismiss" @click="dismiss">
          <Icon class="icon-dismiss" icon="close" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    variant: {
      type: String,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
    },
    hideIcon: {
      type: Boolean,
    },
  },
  data() {
    return {
      timeoutID: null,
    };
  },
  computed: {
    colorClass() {
      switch (this.variant) {
        case 'info':
          return 'primary';
        case 'black':
          return 'black';
        case 'warning':
          return 'yellow';
        case 'error':
          return 'red';
        case 'success':
          return 'green';
        case 'loading-bar':
          return 'primary';
        default:
          return 'primary';
      }
    },
    icon() {
      switch (this.variant) {
        case 'info':
          return 'info-circle';
        case 'black':
          return 'info-circle';
        case 'warning':
          return 'gear';
        case 'error':
          return 'close';
        case 'success':
          return 'accept';
        case 'loading-bar':
          return 'rocket';
        default:
          return null;
      }
    },
  },
  mounted() {
    if (this.timeout) {
      this.timeoutID = setTimeout(this.dismiss, this.timeout);
    }
  },
  beforeUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeoutID);
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismiss');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-container {
  overflow: hidden;
  width: 100%;
  height: 3rem;
  margin-bottom: 0.5rem;
}

.toast {
  position: relative;
  height: 3rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.btnDismiss {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background: none;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.icon-dismiss {
  width: 1rem;
  height: 1rem;
}

.mr {
  margin-right: 0.5rem;
}

.toast-text {
  z-index: 5;
}

.loading-bar {
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  animation: loading-fake 10s ease-out;
  animation-fill-mode: forwards;
}

@keyframes loading-fake {
  0% {
    width: 0%;
  }
  25% {
    width: 90%;
  }
  100% {
    width: 100%;
  }
}

.reveal-enter-active,
.reveal-leave-active {
  transition: height 0.5s, margin-bottom 0.5s;
}

.reveal-enter,
.reveal-leave-to {
  height: 0px;
  margin-bottom: 0;
}
</style>
