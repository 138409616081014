<template>
  <div class="dialog-size-md">
    <div class="modal-header alert-danger">
      <a href class="modal-close-button" @click.prevent="modalInstance.dismiss">
        <Icon icon="close" />
      </a>
    </div>
    <div class="modal-body">
      <OpenSalesChannel @shop-created="closeDialog" />
    </div>
  </div>
</template>

<script>
import OpenSalesChannel from './OpenSalesChannel.vue';

export default {
  name: 'OpenSalesChannelDialog',
  components: {
    OpenSalesChannel,
  },
  props: ['modalInstance'],
  methods: {
    closeDialog() {
      this.modalInstance.close();
    },
  },
};
</script>
