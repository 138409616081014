<template>
  <a
    href="#"
    tabindex="-1"
    @click.prevent
    class="link text-sm tc-link"
    style="position: absolute; top: 10px; right: 10px; color: #7f7f7f"
  >
    <VDropdown :triggers="['click']" :container="false">
      <Icon class="icon" icon="info-circle" style="width: 20px; height: 20px" />
      <template #popper>
        <div class="google-tc" v-html="$t('PDV.GOOGLE_TC')"></div>
      </template>
    </VDropdown>
  </a>
</template>

<script>
export default {
  name: 'GoogleMapsTC',
};
</script>

<style>
.google-tc {
  min-width: 150px;
}
</style>
