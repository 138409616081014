<template>
  <div class="radiobutton-group">
    <RadioButton
      v-for="item in items"
      :key="itemKey(item)"
      class="radiobutton-container test1"
      :name="id"
      :disabled="disabled || itemDisabled(item)"
      :selected="selectedItem && itemKey(selectedItem) === itemKey(item)"
      :label="itemToString(item)"
      @onSelected="onSelectedItemChanged(item)"
    >
      <slot
        name="item"
        :item="item"
        :checked="selectedItem && itemKey(selectedItem) === itemKey(item)"
      />
    </RadioButton>
  </div>
</template>

<script>
import { genId } from 'src/app/commons/utils';
import RadioButton from './RadioButton.vue';

export default {
  name: 'RadioButtonGroup',
  components: {
    RadioButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      required: false,
    },
    itemKey: {
      type: Function,
      default: (item) => item,
    },
    itemToString: {
      type: Function,
      default: (item) => item,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemDisabled: {
      type: Function,
      default: () => false,
    },
    disabledTooltip: {
      type: String,
    },
  },
  data() {
    return {
      selectedItem: null,
      id: genId('radio'),
    };
  },
  created() {
    if (this.modelValue) {
      this.selectedItem = this.modelValue;
    }
  },
  methods: {
    onSelectedItemChanged(newItem) {
      this.selectedItem = newItem;
      this.$emit('change', this.selectedItem);
      this.$emit('update:modelValue', this.selectedItem);
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler(val) {
        this.selectedItem = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.radiobutton-group {
  display: flex;
}

.radiobutton-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 32px;
  }

  .radiobox {
    display: none;
  }

  .item {
    display: flex;
    align-items: center;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: 1px solid $grey40;
    border-radius: 50%;
    background-color: white;
    margin-right: 8px;
    flex-shrink: 0;

    .icon {
      width: 14px;
      height: 14px;
      display: none;
      color: $pa-color-main;
    }
  }

  .radiobox:checked ~ .item .checkmark,
  &.checked .item .checkmark {
    border-color: $pa-color-main;
    .icon {
      display: inline-block;
    }
  }

  &:hover {
    .checkmark {
      border-color: $pa-color-main-dark;
    }
  }

  &.disabled {
    color: $grey20;
    cursor: not-allowed;
  }

  .radiobox:disabled ~ .item .checkmark {
    border-color: $grey20;

    .icon {
      color: $grey20;
    }
  }
}
</style>
