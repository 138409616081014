<template>
  <div class="credit-details-commission">
    <div class="details-heading">
      <div class="title">
        <h3 v-if="designName">{{ designName }}</h3>
        <SmartFaqLink :link="helpTarget" class="help-link" />
      </div>
      <div class="sub-details">
        <p>{{ productTypeName }}</p>
        <p>{{ posName }}</p>
        <p>
          {{ sprdNumber(item.salesAmount) }}
          {{ $t('STATISTICS.SALE_DETAILS.ITEMS') }}
        </p>
      </div>
    </div>
    <div class="details-body">
      <SaleImageSwitcher
        class="sales-images"
        :images="item.images"
        :width="180"
        :height="180"
        :requestProps="{ width: 200, height: 200 }"
      />
      <div>
        <div class="info-boxes">
          <CommissionInfo
            class="commissioninfo"
            :commissionType="data.commissionType"
            :dateBooked="data.dateBooked"
            :datePaidOut="data.payoutDate"
            :profit="{
              amount: data.payoutAmount,
              currencyId: data.currencyId,
            }"
            :commission="commission"
          />
          <OrderInfo
            :shippingCountry="data.sale.shippingCountry"
            :shippingZip="data.sale.shippingZipCode"
            :saleDate="data.sale.saleDate"
            :orderId="item.order.orderId"
            :pos="item.pointOfSale.pos"
            :status="item.combinedStatus"
            :customerPrice="{
              amount: item.price.price,
              currencyId: item.price.priceCurrencyId,
            }"
            :discounts="item.commission.commissionMultiplierTypes"
          />
        </div>
        <OrderIndicators
          :bonus="{
            active: item.commission.volumeShareEligibility,
            amount: item.commission.volumeShares,
            currency: item.commission.commissionCurrencyId,
          }"
          :customized="item.product.customized"
          :pos="item.pointOfSale.pos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SaleImageSwitcher from 'src/app/partnerarea/statistics/sales/saleImage/SaleImageSwitcher.vue';
import OrderInfo from 'src/app/partnerarea/statistics/sales/OrderInfo.vue';
import OrderIndicators from 'src/app/components/indicator/OrderIndicators.vue';
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';
import CommissionInfo from 'src/app/partnerarea/statistics/credit/details/CommissionInfo.vue';
import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import { getDigitalProductCategory } from '@/api/models/digitalProducts/digitalProductsCategories';

export default {
  name: 'CreditDetailsCommission',
  components: {
    SaleImageSwitcher,
    OrderInfo,
    OrderIndicators,
    CommissionInfo,
    SmartFaqLink,
  },
  props: ['data'],
  data() {
    return {
      commissionTypes: statisticsService.getCommissionTypes(),
      item: this.data.sale.items[0],
    };
  },
  computed: {
    ...mapState({
      productTypes: (s) => s.user.productTypes,
    }),
    posName() {
      return pointOfSaleName({
        pointOfSale: this.item.pointOfSale.pos,
        shopName: this.item.pointOfSale.shopName,
      });
    },
    productTypeName() {
      if (this.item.digital) {
        return this.$t(
          getDigitalProductCategory(this.item.digital.productClass).name
        );
      }

      const productType = this.productTypes[this.item.product.productTypeId];

      if (productType) {
        return productType.name;
      } else {
        return null;
      }
    },
    helpTarget() {
      if (
        this.data.commissionType === this.commissionTypes.AFFILIATE_COMMISSION
      ) {
        return smartFaqLinks.AFFILIATE_COMMISSION_HELP;
      } else if (
        this.data.commissionType === this.commissionTypes.MDS_COMMISSION
      ) {
        return smartFaqLinks.MDS_COMMISSION_HELP;
      } else {
        return smartFaqLinks.MP_COMMISSION_HELP;
      }
    },
    commission() {
      if (
        [
          this.commissionTypes.DESIGN_COMMISSION,
          this.commissionTypes.MDS_COMMISSION,
        ].includes(this.data.commissionType) &&
        this.item.commission.commissionBase
      ) {
        return {
          amount: this.item.commission.commissionBase,
          currencyId: this.item.commission.commissionCurrencyId,
        };
      } else {
        return null;
      }
    },
    designName() {
      if (this.item.digital) {
        return this.item.digital.name;
      }

      if (this.item.designs && this.item.designs.length) {
        return this.item.designs[0].name;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.credit-details-commission {
  display: flex;
  flex-direction: column;
}

.details-heading {
  h3 {
    margin: 0;
  }

  .title {
    display: flex;

    .help-link {
      margin-left: auto;
    }
  }

  .sub-details {
    margin: 4px 0 16px 0;
    display: flex;

    & > p {
      margin: 0 24px 0 0;
      max-width: 200px;

      @extend .text-truncate;
      @extend .text-grey;
      @extend .text-sm;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.details-body {
  display: flex;
}

.sales-images {
  margin-bottom: auto;
}

.info-boxes {
  display: flex;
  flex-direction: column;

  & > div:last-child {
    margin-top: 8px;
  }

  .commissioninfo {
    min-height: 160px;
  }
}
</style>
