import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import router from 'src/app/router/router';

export default {
  getCollections(shopId = router.currentRoute.value.params.shopId) {
    return apiClient
      .request({
        method: 'GET',
        url: `/shop-api/users/${store.state.user.data.id}/shops/${shopId}/collections`,
      })
      .then((res) => res.data.collections);
  },
  saveCollection(collection) {
    if (collection.id) {
      return apiClient
        .request({
          method: 'PUT',
          url: `/shop-api/users/${store.state.user.data.id}/shops/${router.currentRoute.value.params.shopId}/collections/${collection.id}`,
          data: {
            name: collection.name,
            description: collection.description,
            active: collection.active,
          },
        })
        .then((res) => res.data);
    } else {
      return apiClient
        .request({
          method: 'POST',
          url: `/shop-api/users/${store.state.user.data.id}/shops/${router.currentRoute.value.params.shopId}/collections/`,
          data: {
            name: collection.name,
            description: collection.description,
            active: collection.active,
          },
        })
        .then((res) => res.data);
    }
  },
  deleteCollection(collection) {
    return apiClient.request({
      method: 'DELETE',
      url: `/shop-api/users/${store.state.user.data.id}/shops/${router.currentRoute.value.params.shopId}/collections/${collection.id}`,
    });
  },
  saveCollectionsOrder(collections) {
    return apiClient.request({
      method: 'PUT',
      url: `/shop-api/users/${store.state.user.data.id}/shops/${router.currentRoute.value.params.shopId}/collections`,
      data: {
        collectionIds: collections.map((c) => c.id),
      },
    });
  },
  changeIdeasInCollection(collection, changes) {
    return apiClient.request({
      method: 'POST',
      url: `/shop-api/users/${store.state.user.data.id}/shops/${router.currentRoute.value.params.shopId}/collections/${collection.id}/ideas/manage`,
      data: changes,
    });
  },
};
