<template>
  <div class="volume-history modal-container sale-details-dialog">
    <div
      class="modal-body empty-state"
      v-if="commissionHistory && commissionHistory.length === 0"
    >
      <img src="images/chillout.svg" />
      <h3>
        {{ $t('STATISTICS.VOLUME_COMMISSION.EMPTY_STATE_HEADING') }}
      </h3>
      <p class="text-grey">
        {{ $t('STATISTICS.VOLUME_COMMISSION.EMPTY_STATE_DESCRIPTION') }}
      </p>
      <HelpLink :href="helpTarget" />
    </div>
    <div class="modal-body" v-else-if="commissionHistory">
      <div class="header">
        <h3>
          {{ $t('STATISTICS.VOLUME_COMMISSION.SUMMARY_HEADING') }}
        </h3>
      </div>
      <table>
        <thead>
          <tr>
            <th>{{ $t('STATISTICS.VOLUME_COMMISSION.MONTH') }}</th>
            <th>{{ $t('STATISTICS.VOLUME_COMMISSION.TIER') }}</th>
            <th>{{ $t('STATISTICS.VOLUME_COMMISSION.SOLD') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="month in this.commissionHistory" :key="month.periodStart">
            <td>{{ getDate(month) }}</td>
            <td>{{ getTier(month) }}</td>
            <td>{{ sprdNumber(month.orderItemCount) }}</td>
          </tr>
        </tbody>
      </table>
      <p class="text-grey text-sm hint">
        {{ $t('STATISTICS.VOLUME_COMMISSION.HINT') }}
      </p>
    </div>
    <div class="modal-body" v-else>
      <img src="/images/loader.svg" class="sprd-loader loader" />
    </div>
  </div>
</template>

<script>
import commissionerService from '@/api/commissionerService/commissionerService';
import HelpLink from '@/helpLink/HelpLink.vue';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import date from '@/date/date';
import {
  articleKeys,
  getArticleByKey,
  getHref,
} from 'src/app/partnerarea/tipsAndTools/tipsAndToolsConfig';
import localeService from 'src/app/commons/localeService/localeService';

export default {
  name: 'CreditDetailsVolumeSummary',
  components: {
    HelpLink,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      commissionHistory: null,
    };
  },
  computed: {
    helpTarget() {
      const article = getArticleByKey(articleKeys.SELLING_GUIDE);
      const language = localeService.getLanguage();
      const domain = localeService.getSprdUrl();

      return getHref(article, domain, language, SPRD.PLATFORM);
    },
  },
  methods: {
    getDate(month) {
      return `${date(month.periodStart, 'MMMM')} ${date(
        month.periodStart,
        'yyyy'
      )}`;
    },
    getTier(month) {
      return month.bonusLevel > 0
        ? month.bonusLevel
        : this.$t('STATISTICS.VOLUME_COMMISSION.BASE_TIER');
    },
  },
  async created() {
    const { cycles } = await commissionerService.fetchVolumeCommissionDetails();
    this.commissionHistory = cycles.sort((a, b) => {
      return isAfter(parseISO(a.periodStart), parseISO(b.periodStart)) ? -1 : 1;
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.modal-body {
  width: 650px;
}

.volume-history {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      margin: 0;
    }
  }

  .empty-state {
    justify-content: center;
    text-align: center;

    h3 {
      margin: 0 0 16px;
    }

    img {
      display: flex;
      margin: 0 auto 24px auto;
      height: auto;
      width: 256px;
    }

    .text-grey {
      margin-bottom: 16px;
    }

    .help-link {
      justify-content: center;
    }
  }

  table {
    width: 100%;
    margin: 0;
  }

  th,
  td {
    padding: 0.5em;
    text-align: center;
  }

  tr {
    border-bottom: 1px solid $grey5;
  }

  tbody {
    tr:last-child {
      border-style: none;
    }
  }

  th:first-child,
  td:first-child {
    text-align: left;
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    text-align: right;
    padding-right: 0;
  }

  .hint {
    margin: 16px 0 0;
    text-align: center;
  }
}

.loader {
  height: 120px;
  margin: 24px auto;
}
</style>
