<template>
  <div class="error-dialog">
    <div class="modal-body flex-center-vertical">
      <Icon class="main-icon" icon="rocket-crashed" />
      <h1>
        {{ $t('GENERAL.ERROR.HEADING') }}
      </h1>
      <p>
        {{ $t('GENERAL.ERROR.TEXT1') }}
      </p>
      <p>
        {{ $t('GENERAL.ERROR.TEXT2') }}
      </p>
      <button
        class="btn btn-primary btn-lg flex-center-vertical"
        @click="refresh()"
      >
        <Icon icon="refresh" />
        {{ $t('GENERAL.ERROR.REFRESH') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorDialog',
  methods: {
    refresh() {
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.error-dialog {
  padding: 20px 100px;

  .modal-body {
    flex-direction: column;
  }

  .main-icon {
    width: 5em;
    height: 5em;
    margin-bottom: 19px;
  }

  h1,
  p {
    margin-bottom: 24px;
  }

  button > svg.icon {
    margin-right: 4px;
    width: 1.5em;
    height: 1.5em;
  }
}
</style>
