<template>
  <div class="moveToPageAnchor">
    <button
      class="btn btn-light openMoveButton"
      @click="toggleBoxOpen"
      :disabled="!enabled"
    >
      <Icon icon="moveto" class="pageIcon" />
      {{ $t('POS.SHOP_SETTINGS.SORT.PAGE_LABEL') }}
    </button>
    <div class="dialogBox" v-if="enabled && boxOpen">
      <h2>{{ $t('POS.SHOP_SETTINGS.SORT.MOVE_HEADING') }}</h2>
      <div class="subHeading">
        {{ $t('POS.SHOP_SETTINGS.SORT.MOVE_SUBHEADING') }}
      </div>
      <div class="inputz">
        <Dropdown
          :items="dropDownPages"
          :value="pageValue"
          @change="setPageValue"
        />
        <button
          class="btn btn-primary submitBtn"
          @click="$emit('moveToPage', pageValue - 1)"
        >
          {{ $t('POS.SHOP_SETTINGS.SORT.MOVE_PAGE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from 'src/app/commons/Dropdown/Dropdown.vue';

// pageValue starts at 1 (user perspective) but any values we accept from the outside or emit to it are 0-based
export default {
  name: 'MoveToPageButton',
  components: {
    Dropdown,
  },
  props: {
    enabled: { type: Boolean, required: true },
    currentPage: { type: Number, required: true },
    maxPage: { type: Number, required: true },
  },
  data() {
    return {
      boxOpen: false,
      pageValue: this.currentPage === 0 ? 2 : 1,
    };
  },
  methods: {
    toggleBoxOpen() {
      this.boxOpen = !this.boxOpen;
    },
    setPageValue(value) {
      this.pageValue = value;
    },
  },
  watch: {
    currentPage(updatedCurrentPage) {
      this.boxOpen = false;
      this.pageValue = updatedCurrentPage === 0 ? 2 : 1;
    },
  },
  computed: {
    dropDownPages() {
      const result = [];
      for (let i = 1; i <= this.maxPage; i++) {
        if (i !== this.currentPage + 1) {
          result.push(i);
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.moveToPageAnchor {
  position: relative;
}

.openMoveButton {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.pageIcon {
  margin-right: 8px;
}

.dialogBox {
  position: absolute;
  background-color: $white;
  border-radius: 6px;
  filter: drop-shadow(0 0 10px $grey20);
  padding: 1.5rem;
  right: 0;
  margin-top: 1rem;
  min-width: 500px;

  // little triangle boy
  &:after {
    position: absolute;
    right: 2rem;
    top: -10px;
    width: 20px;
    height: 20px;
    background-color: $white;
    content: '\00a0';
    transform: rotate(45deg);
  }
}

.subHeading {
  font-weight: normal;
}

.inputz {
  display: flex;
  margin-top: 1rem;
}

.submitBtn {
  margin-left: 1rem;
}
</style>
