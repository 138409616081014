import apiClient from '@/api/client/apiClient';

export default {
  getCategories(shopId) {
    return apiClient
      .request({
        method: 'GET',
        url: '/api/categoryTrees',
        params: {
          trees: `SPREADSHOP_${SPRD.PLATFORM}`,
          platform: SPRD.PLATFORM,
          shopId,
        },
      })
      .then((res) => res.data?.trees?.[`SPREADSHOP_${SPRD.PLATFORM}`]);
  },
  async getLabels(labelTypes) {
    const res = await apiClient.request({
      method: 'GET',
      url: '/api/v1/partner/assortment/labels',
      params: {
        labelTypes,
      },
    });
    return res.data.labels[labelTypes];
  },
};
