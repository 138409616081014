import notificationService from '@/api/notifications/notificationService';
import analyticsService from '@/tracking/analytics';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { triggerNotificationAction } from '@/api/notifications/notificationTopics';
import router from 'src/app/router/router';

function setProperty(properties, property, state) {
  if (state && !properties.includes(property)) {
    return properties.concat([property]);
  }
  if (!state) {
    return properties.filter((prop) => prop !== property);
  }

  return properties;
}

export default {
  namespaced: true,
  state: {
    list: [],
    pollingInterval: null,
    newNotificationReceived: true,
  },
  getters: {
    list: (state, getters, rootState, rootGetters) =>
      state.list.filter((notification) => {
        const scope = notification.additionalGatewayData.values?._meta?.scope;

        const shopNotificationForMpPartner =
          rootGetters['user/partnerType'] === 'MP' && scope === 'shop';
        const mpNotificationForShopPartner =
          rootGetters['user/partnerType'] === 'SHOP' && scope === 'marketplace';

        return (
          !notification.additionalProperties.includes('HIDE') &&
          !shopNotificationForMpPartner &&
          !mpNotificationForShopPartner
        );
      }),
    excludedTopics: (state, getters, rootState, rootGetters) =>
      rootGetters['settings/getSetting']('EXCLUDED_NOTIFICATION_TOPICS') || [],
    isTopicExcluded: (state, getters) => (topic) =>
      getters.excludedTopics.includes(topic),
    filteredList: (state, getters) =>
      getters.list.filter(
        (notification) => !getters.excludedTopics?.includes(notification.topic)
      ),
    fromToday: (_, getters) =>
      getters.filteredList.filter((notification) => {
        const daysAgo = differenceInCalendarDays(
          Date.now(),
          parseISO(notification.deliveryTime)
        );

        return daysAgo === 0;
      }),
    fromOneWeek: (_, getters) =>
      getters.filteredList.filter((notification) => {
        const daysAgo = differenceInCalendarDays(
          Date.now(),
          parseISO(notification.deliveryTime)
        );

        return daysAgo >= 1 && daysAgo <= 7;
      }),
    fromOneMonth: (_, getters) =>
      getters.filteredList.filter((notification) => {
        const daysAgo = differenceInCalendarDays(
          Date.now(),
          parseISO(notification.deliveryTime)
        );

        return daysAgo > 7 && daysAgo <= 30;
      }),
    otherTimeframe: (_, getters) =>
      getters.filteredList.filter(
        (notification) =>
          differenceInCalendarDays(
            Date.now(),
            parseISO(notification.deliveryTime)
          ) > 30
      ),
    getReadState: () => (notification) =>
      notification.additionalProperties.includes('READ'),
    unread: (state, getters) =>
      getters.filteredList.filter(
        (notification) => !notification.additionalProperties.includes('READ')
      ),
    unreadCount: (state, getters) =>
      getters.unread.length > 99 ? '99+' : getters.unread.length,
  },
  mutations: {
    setNotifications(state, notifications) {
      state.list = notifications;
    },
    setReadState(state, { notification, readState, skipTracking, bulk }) {
      const notificationEntry = state.list.find(
        (entry) => entry.notificationId === notification.notificationId
      );
      if (notificationEntry) {
        notificationEntry.additionalProperties = setProperty(
          notificationEntry.additionalProperties,
          'READ',
          readState
        );

        if (!skipTracking) {
          analyticsService.logEvent(
            readState ? 'notification-markAsRead' : 'notification-markAsUnread',
            {
              eventArea: 'notifications',
              topic: notificationEntry.topic,
              bundle:
                notificationEntry.additionalGatewayData?.templateBundleName,
              bulk,
            },
            { skipAdobe: true }
          );
        }
      }
    },
    setHideState(state, { notification, hideState }) {
      const notificationEntry = state.list.find(
        (entry) => entry.notificationId === notification.notificationId
      );
      if (notificationEntry) {
        notificationEntry.additionalProperties = setProperty(
          notificationEntry.additionalProperties,
          'HIDE',
          hideState
        );
      }
    },
    setPollingInterval(state, pollingInterval) {
      state.pollingInterval = pollingInterval;
    },
    setNewNotificationReceived(state, status) {
      state.newNotificationReceived = status;
    },
  },
  actions: {
    async startPolling({ commit, state, dispatch }) {
      if (state.pollingInterval) {
        return;
      }

      dispatch('fetchNotifications', { initial: true });
      commit(
        'setPollingInterval',
        setInterval(() => {
          dispatch('fetchNotifications');
        }, 10 * 60 * 1000)
      );
    },
    async stopPolling({ commit, state }) {
      clearInterval(state.pollingInterval);

      commit('setPollingInterval', null);
    },
    async fetchNotifications({ commit, state, dispatch }, { initial } = {}) {
      const notifications = await notificationService.fetchNotifications();

      let gotNewNotifications = false;

      if (notifications.length > state.list.length) {
        gotNewNotifications = true;
        dispatch('handleNewNotifications');
      }
      commit('setNotifications', notifications);

      if (!initial && gotNewNotifications && 'Notification' in window) {
        // try to send browser notification
        const permission = Notification.permission;

        if (permission === 'granted') {
          const notification = new Notification(notifications[0].title, {
            body: notifications[0].body,
            icon: '/images/spreadapp.png',
            data: {
              notification: notifications[0],
            },
          });

          notification.onclick = (event) => {
            // todo: mark notification as clicked / read? specific tracking

            window.parent.focus();
            notification.close();

            router.push({
              name: 'partnerarea.notifications',
            });

            if (event?.target?.data?.notification) {
              triggerNotificationAction(event.target.data.notification, {
                browserNotification: true,
              });
            }
          };
        }
      }
    },
    async setReadState(
      { commit, state },
      { notification, readState, skipTracking }
    ) {
      commit('setReadState', { notification, readState, skipTracking });

      await notificationService.updateNotifications(state.list);
    },
    async setAllAsRead({ commit, getters }) {
      getters.unread.forEach((notification) =>
        commit('setReadState', { notification, readState: true, bulk: true })
      );

      await notificationService.updateNotifications(getters.filteredList);
    },
    async hide({ commit, state }, notification) {
      commit('setHideState', { notification, hideState: true });

      await notificationService.updateNotifications(state.list);

      analyticsService.logEvent(
        'notification-remove',
        {
          eventArea: 'notifications',
          topic: notification.topic,
          bundle: notification.additionalGatewayData?.templateBundleName,
        },
        { skipAdobe: true }
      );
    },
    async handleNewNotifications({ commit }) {
      commit('setNewNotificationReceived', true);
      setTimeout(() => {
        commit('setNewNotificationReceived', false);
      }, 5000);
    },
    addExcludedTopic({ dispatch, getters }, topic) {
      dispatch(
        'settings/setSetting',
        {
          key: 'EXCLUDED_NOTIFICATION_TOPICS',
          value: [...new Set([...getters.excludedTopics, topic])],
        },
        { root: true }
      );
      analyticsService.logEvent('notification-topic-exclude', {
        eventArea: 'notifications',
        topic,
      });
    },
    deleteExcludedTopic({ dispatch, getters }, topic) {
      dispatch(
        'settings/setSetting',
        {
          key: 'EXCLUDED_NOTIFICATION_TOPICS',
          value: getters.excludedTopics.filter(
            (excludedTopic) => excludedTopic !== topic
          ),
        },
        { root: true }
      );
      analyticsService.logEvent('notification-topic-include', {
        eventArea: 'notifications',
        topic,
      });
    },
    toggleTopicExclusion({ getters, dispatch }, topic) {
      if (!topic.nonExcludable) {
        getters.isTopicExcluded(topic.key)
          ? dispatch('deleteExcludedTopic', topic.key)
          : dispatch('addExcludedTopic', topic.key);
      }
    },
  },
};
