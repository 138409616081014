<template>
  <div class="good-bye-page">
    <div class="top">
      <a :href="opossumLink" class="sprd-link">
        <Icon icon="sprd-heart" />
      </a>
    </div>
    <div class="content overflow-y">
      <div class="good-bye-card">
        <img class="heart" src="/images/heart_broken.svg" />
        <h1>{{ $t('ACCOUNT_DELETED.HEADING') }}</h1>
        <h3>{{ $t('ACCOUNT_DELETED.SUBHEADING') }}</h3>
        <div class="survey">
          <iframe
            :src="survey"
            width="100%"
            height="100%"
            scrolling="no"
          ></iframe>
          <img class="sprd-loader loader" src="/images/loader.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import localeService from 'src/app/commons/localeService/localeService';

const surveysByLanguages = {
  da: 'MC7GQDX',
  en: 'PF38GW5',
  fr: 'DBVBSQD',
  sv: 'PFZ29J2',
  pl: 'DBL9GV2',
  no: 'DBLJDCF',
  nl: 'DBL8YP6',
  fi: 'PFZZLYP',
  it: 'PF3JF6G',
  es: 'PFZXVHL',
  de: 'DBVZZXX',
};
export default {
  name: 'GoodBye',
  data() {
    return {
      opossumLink: localeService.getSprdUrl(),
    };
  },
  computed: {
    survey() {
      const language = localeService.getLanguage();
      const url = surveysByLanguages[language] || surveysByLanguages.en;
      return `https://www.research.net/r/${url}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.good-bye-page {
  .top {
    width: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;

    .sprd-link .icon {
      width: 40px;
      height: 40px;
      color: $pa-color-main;

      @include small-desktop {
        width: 30px;
        height: 30px;
      }
    }
  }

  .content {
    text-align: center;
    height: calc(100vh - 60px);
    margin-top: 0;
    padding: 20px;

    @include small-desktop {
      height: calc(100vh - 50px);
    }

    .heart {
      width: 100px;
      margin: 30px 0 10px 0;
    }

    h3 {
      margin: 5px 0 0 0;
    }
  }

  .good-bye-card {
    background-color: #fff;
    max-width: 1000px;
    border: 1px solid $sprd-color-light-grey;
    box-shadow: 0 0 5px 0 $sprd-color-light-grey;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0 0 0;

    .survey {
      position: relative;
      height: 650px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .loader {
        width: 100px;
      }
    }

    iframe {
      border: none;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>
