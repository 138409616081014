<template>
  <TrackingMetricsHint
    heading="STATISTICS.TRACKING_METRICS.CUSTOM_TRACKING.HEADING"
    description="STATISTICS.TRACKING_METRICS.CUSTOM_TRACKING.DESCRIPTION"
  >
    <a class="link-main" @click="goToShopArea">
      {{ $t('STATISTICS.TRACKING_METRICS.CUSTOM_TRACKING.CTA_LABEL') }}
    </a>
  </TrackingMetricsHint>
</template>

<script>
import TrackingMetricsHint from './TrackingMetricsHint.vue';

export default {
  name: 'GoogleAnalyticsHint',
  components: { TrackingMetricsHint },
  props: {
    shop: {
      required: true,
      type: String,
    },
  },
  methods: {
    goToShopArea() {
      this.$router.push({
        name: 'shop-area.marketing.google',
        params: {
          shopId: this.shop,
        },
      });
    },
  },
};
</script>
