<template>
  <Tile
    :name="name"
    @onClick="edit"
    icon="download-file"
    clickable
    :image="`${image},width=300,height=300.png`"
    :darkImageBg="composition.properties.darkBackground"
    class="publishing-tile"
  >
    <template v-slot:status v-if="displayPosStatus">
      <CompositionPosStatusBadges
        class="badges"
        :composition="composition"
        :relevantPosTypes="relevantPosTypes"
        :relevantPosStates="relevantPosStates"
      />
    </template>
  </Tile>
</template>

<script>
import compositionHelper from '@/composition/compositionHelper';
import Tile from '@/tileView/Tile.vue';
import CompositionPosStatusBadges from 'src/app/components/publishing/statusBadges/CompositionPosStatusBadges.vue';

export default {
  name: 'PublishingTile',
  components: {
    Tile,
    CompositionPosStatusBadges,
  },
  props: {
    composition: {
      required: true,
      type: Object,
    },
    displayPosStatus: {
      type: Boolean,
    },
    relevantPosTypes: {
      type: Array,
    },
    relevantPosStates: {
      type: Array,
    },
  },
  computed: {
    name() {
      return (
        compositionHelper.getMetaDataPart({
          composition: this.composition,
          part: 'name',
        })?.value || this.composition.id
      );
    },
    image() {
      return compositionHelper.getDesignImage(this.composition);
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.publishing-tile {
  height: 100%;

  :deep(.card-footer) {
    height: auto;
  }

  .badges {
    margin: 8px 0 0 0;
  }
}
</style>
