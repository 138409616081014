<template>
  <div class="indicator">
    <AdvancedPopover
      :heading="hintHeading"
      :description="hintBody"
      :smartFaqLink="moreInfoLink"
      popoverPlacement="top"
      :popoverTrigger="['click', 'touch']"
      :disabled="!hintHeading"
    >
      <div
        type="button"
        class="indicator-button text"
        :class="{ static: !hintHeading }"
      >
        {{ $t(label) }}
      </div>
    </AdvancedPopover>
  </div>
</template>

<script>
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';

export default {
  name: 'Indicator',
  components: { AdvancedPopover },
  props: {
    label: {
      type: String,
      required: true,
    },
    hintHeading: {
      type: String,
    },
    hintBody: {
      type: String,
    },
    moreInfoLink: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.indicator-button {
  cursor: pointer;
  background-color: $grey5;
  border-radius: 24px;
  width: fit-content;
  padding: 8px 12px;

  &.static {
    cursor: initial;
  }
}
</style>
