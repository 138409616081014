<template>
  <div class="spreadapp-store-dialog">
    <div class="qrcode">
      <QRCanvas :options="options" />
    </div>
    <div class="explanation">
      <h1>{{ $t(data.heading) }}</h1>

      <p>
        {{ $t(data.explanation) }}
      </p>

      <a :href="data.link" target="_blank">
        <img
          :src="data.image"
          :href="data.link"
          alt="mobile store"
          target="_blank"
        />
      </a>
    </div>
  </div>
</template>

<script>
async function importQRCanvas() {
  const { QRCanvas } = await import(
    /* webpackChunkName: "qrcanvas" */ 'qrcanvas-vue'
  );
  return QRCanvas;
}

export default {
  name: 'SpreadAppStoreDialog',
  props: {
    modalInstance: Object,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    QRCanvas: () => importQRCanvas(),
  },
  computed: {
    options() {
      return {
        size: 500,
        correctLevel: 'L',
        foreground: '#3075B4',
        data: this.data.link,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.spreadapp-store-dialog {
  display: flex;
}

.explanation {
  text-align: left;
  padding-left: 24px;

  h1 {
    margin: 0 0 16px 0;
  }

  p {
    margin: 0;
    color: $grey60;
  }

  h1,
  p {
    max-width: 350px;
  }

  img {
    height: 40px;
    margin: 16px 0 0 0;
  }
}

.qrcode {
  position: relative;

  canvas {
    width: 200px;
    height: 200px;
  }
}
</style>
