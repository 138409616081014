<template>
  <div class="page-wrapper catalog">
    <nav class="page-header nav navbar-secondary navbar-default">
      <ul>
        <li>
          <h4>{{ $t('DESIGNS.CONFIGURATION.ALL_PRODUCTS') }}</h4>
        </li>
        <li>
          <a href="#" class="link-main" @click.prevent="cancel">{{
            $t('GENERAL.CANCEL')
          }}</a>
        </li>
        <li>
          <button type="button" class="btn btn-primary" @click="$emit('done')">
            {{ $t('GENERAL.DONE') }}
          </button>
        </li>
      </ul>
    </nav>
    <div class="page-content">
      <AssortmentCatalogContent
        :assortment="idea.assortment"
        :activeFilter="activeFilter"
        :flags="idea.flags"
        @setFilter="setFilter"
        :activeColorFilter="activeColorFilter"
        @onColorSelect="setColorFilter"
        @onColorBulkApply="bulkApplyColor"
        @onToggle="onToggle"
        @onSetSelection="onSetSelection"
        @onColorChange="onColorChange"
        enableBulkSelection
      />
    </div>
  </div>
</template>

<script>
import AssortmentCatalogContent from './AssortmentCatalogContent.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'AssortmentCatalog',
  props: ['idea'],
  components: {
    AssortmentCatalogContent,
  },
  data() {
    return {
      activeFilter: this.idea.assortment,
      activeColorFilter: {},
      toggle: false,
    };
  },
  unmounted() {
    this.resetFilter();
  },
  methods: {
    setFilter(filter) {
      this.activeFilter = filter;
      if (filter.$filtered) {
        this.resetFilter();
      }
    },
    onToggle(group) {
      this.$emit('updateGroup', {
        groupId: group.filterId,
        changes: {
          included: !group.included,
        },
      });
    },
    onSetSelection(group, selectionState) {
      if (!group.$filtered) {
        this.$emit('updateGroup', {
          groupId: group.filterId,
          changes: {
            included: selectionState,
          },
        });
      }
    },
    onColorChange(color, group) {
      this.$emit('updateColor', {
        groupId: group.filterId,
        appearanceId: color.appearanceId,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    setColorFilter(metaColor) {
      if (!metaColor || this.activeColorFilter === metaColor) {
        this.resetFilter();
      } else {
        this.activeColorFilter = metaColor;
        this.$emit('filter', {
          filterFn: (entry) =>
            !(
              entry.primarySellable &&
              entry.primarySellable.colors &&
              entry.primarySellable.colors
                .filter((c) => c.metaColors)
                .some((c) =>
                  c.metaColors.find((m) => m.metaColor === metaColor.id)
                )
            ),
        });
      }
    },
    bulkApplyColor(metaColor) {
      assortmentHelper
        .getTotalAvailableSellables(this.activeFilter)
        .filter((g) => !g.$filtered)
        .forEach((group) => {
          const fittingColors = group.primarySellable.colors.filter((color) =>
            color.metaColors.find((m) => m.metaColor === metaColor.id)
          );

          if (fittingColors.length) {
            fittingColors.sort((a, b) => {
              const aMeta = a.metaColors.find(
                (m) => m.metaColor === metaColor.id
              );
              const bMeta = b.metaColors.find(
                (m) => m.metaColor === metaColor.id
              );

              return aMeta.distance - bMeta.distance;
            });

            this.onColorChange(fittingColors[0], group);
          }
        });
    },
    resetFilter() {
      this.activeColorFilter = {};
      this.$emit('resetFilter');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/animations';

.catalog {
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1020;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $grey5;
}

.page-header {
  height: 56px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 24px;

    li {
      h4 {
        margin: 0;
      }

      &:first-child {
        margin-right: auto;
      }

      button {
        margin-left: 16px;
        height: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
.catalog {
  .product {
    .product-name {
      font-weight: normal;
    }

    &.selected {
      .product-name {
        font-weight: bold;
      }
    }
  }
}
</style>
