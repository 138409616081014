<template>
  <div class="dialog-size-full greyDialog page-content">
    <SocialMediaToolDialogHeader
      :steps="3"
      :currentStep="currentStep"
      :subStep="selectedItem.substep"
      @cancel="modalInstance.dismiss"
      @gotoStep="gotoStep"
      @back="goBack"
    />
    <div class="modal-body">
      <SocialMediaToolSelectionWizard
        v-if="currentStep === 1"
        :selectedItem="selectedItem"
        @nextStep="advanceStep1"
      />
      <SocialMediaToolCustomizer
        v-if="currentStep === 2"
        :productImageUrl="selectedItem.selectedAppearance.highResImageUrl"
        @nextStep="goToStep3"
        :canvasSettings="canvasSettings"
      />
      <SocialMediaToolResultsPage
        v-if="currentStep === 3"
        :canvasData="canvasData"
        :ideaId="selectedItem.selectedIdea.id"
        :sellableId="selectedItem.selectedSellable.id"
        :appearanceId="selectedItem.selectedAppearance.id"
        ref="resultsPage"
      />
    </div>
  </div>
</template>

<script>
import SocialMediaToolSelectionWizard from './SocialMediaToolSelectionWizard.vue';
import SocialMediaToolCustomizer from './SocialMediaToolCustomizer.vue';
import SocialMediaToolResultsPage from './SocialMediaToolResultsPage.vue';
import SocialMediaToolDialogHeader from './SocialMediaToolDialogHeader.vue';

import analyticsService from '@/tracking/analytics';

export default {
  name: 'SocialMediaToolSellableDialog',
  props: ['modalInstance', 'data'],
  components: {
    SocialMediaToolSelectionWizard,
    SocialMediaToolCustomizer,
    SocialMediaToolResultsPage,
    SocialMediaToolDialogHeader,
  },
  data() {
    const settings = this.$store.state.shop.shopSavable.clientData.settings; // we do not have access to computed properties in data()
    const logoPossible = settings.logoImage && settings.logoImageEnabled;
    const brandingOptions = [
      {
        type: 'LOGO',
        trans: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_LOGO'),
      },
      {
        type: 'NAME',
        trans: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_NAME'),
      },
      {
        type: 'NONE',
        trans: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_NONE'),
      },
    ];
    return {
      currentStep: 1,
      selectedItem: {
        selectedIdea: null,
        selectedSellable: null,
        selectedAppearance: null,
        substep: 1,
      },
      canvasData: null,
      canvasSettings: {
        customText: '',
        textColor: '#FFFFFF',
        selectedHeadlineFont: settings.fontHeadline,
        logoPossible,
        brandingOptions,
        selectedBrandingOption: logoPossible
          ? brandingOptions[0]
          : brandingOptions[1],
        selectedStockImageName: null,
      },
    };
  },
  methods: {
    advanceStep1(substep, item) {
      switch (substep) {
        case 2:
          this.selectedItem = { substep, selectedIdea: item };
          break;
        case 3:
          this.selectedItem = {
            substep,
            selectedIdea: this.selectedItem.selectedIdea,
            selectedSellable: item,
          };
          break;
        case 4:
          this.selectedItem = {
            substep: 3,
            selectedIdea: this.selectedItem.selectedIdea,
            selectedSellable: this.selectedItem.selectedSellable,
            selectedAppearance: item,
          };
          this.currentStep++;
          break;
      }
    },
    goToStep3(canvasData) {
      this.currentStep++;
      this.canvasData = canvasData;

      analyticsService.logEvent('sharing-product-finished', {
        eventArea: 'sharing-tool',
      });
    },
    goBack() {
      if (this.currentStep === 1) {
        switch (this.selectedItem.substep) {
          case 2:
            this.selectedItem = {
              substep: 1,
              selectedIdea: this.selectedItem.selectedIdea,
            };
            break;
          case 3:
            this.selectedItem = {
              substep: 2,
              selectedIdea: this.selectedItem.selectedIdea,
              selectedSellable: this.selectedItem.selectedSellable,
            };
            break;
          default:
            this.currentStep--;
            break;
        }
      } else if (this.currentStep === 2) {
        this.canvasData = null;
        this.currentStep--;
      } else if (this.currentStep === 3) {
        this.currentStep--;
      }
    },
    gotoStep(step) {
      if (step < this.currentStep) {
        if (step === 1) {
          this.canvasData = null;
          this.selectedItem = {
            substep: 1,
            selectedIdea: null,
          };
        }
        this.currentStep = step;
      }
    },
    downloadAll() {
      this.$refs.resultsPage.downloadAll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.subHeading {
  font-weight: bold;
}

.greyDialog {
  background-color: $grey5;
}
</style>
