export const REJECTION_REASONS = {
  EMBROIDERY: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.EMBROIDERY',
    description: 'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.EMBROIDERY',
  },
  COPYRIGHT: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.COPYRIGHT',
    description: 'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.COPYRIGHT',
  },
  UNKNOWN_RIGHT_OF_USE: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.UNKNOWN_RIGHT_OF_USE',
    description:
      'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.UNKNOWN_RIGHT_OF_USE',
  },
  COMMUNITY_GUIDELINES: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.COMMUNITY_GUIDELINES',
    description:
      'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.COMMUNITY_GUIDELINES',
  },
  MP_GUIDELINES: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.MP_GUIDELINES',
    description: 'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.MP_GUIDELINES',
  },
  QUALITY: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.QUALITY',
    description: 'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.QUALITY',
  },
  OTHER: {
    name: 'DESIGNS.STATUS.REJECTION_REASONS.OTHER',
    description: 'DESIGNS.STATUS.REJECTION_REASONS_DESCRIPTION.OTHER',
  },
};

export function rejectionReasonName(key) {
  return (REJECTION_REASONS[key] || REJECTION_REASONS['OTHER']).name;
}

export function rejectionReasonDescription(key) {
  return (REJECTION_REASONS[key] || REJECTION_REASONS['OTHER']).description;
}
