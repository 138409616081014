import localeService from 'src/app/commons/localeService/localeService';
import apiClient from '@/api/client/apiClient';
import smartFaqLinks from './smartFaqLinks';

const getHelpLinks = (
  articleId,
  { isCategory, spreadshop, isSection } = {}
) => {
  const locale = localeService.getLocale();
  let language = localeService.getLanguage();

  if (['en_US', 'en_GB', 'fr_CA'].includes(locale)) {
    language = locale.replace('_', '-').toLowerCase();
  } else if (locale === 'en_CA') {
    language = 'en-us';
  }

  const domain = spreadshop ? 'spreadshop.support' : 'spreadshirt.com';
  let type = 'articles';
  if (isCategory) {
    type = 'categories';
  }
  if (isSection) {
    type = 'sections';
  }
  return {
    href: `https://help.${domain}/hc/${language}/${type}/${articleId}`,
    apiUrl: `https://help.${domain}/api/v2/help_center/${language}/${type}/${articleId}`,
  };
};

export default {
  namespaced: true,
  state: {
    currentLinks: [],
    linkData: {},
    smartFaqLinks,
    highlightLinkKey: null,
  },
  getters: {
    uniqueCurrentLinks: (state) => [...new Set([...state.currentLinks])],
    currentRelevantLinks: (state, getters) =>
      getters.uniqueCurrentLinks
        .map(getters.relevantLink)
        .filter((link) => link),
    relevantLinksByKey: (state, getters) => (linkKey) =>
      getters.relevantLink(state.linkData[linkKey]),
    relevantLink: (state, getters, rootState, rootGetters) => (link) => {
      if (!link?.targets.SHOP && !link?.targets.MP) {
        return null;
      }

      const partnerType = rootGetters['user/partnerType'];
      let relevantPart;
      if (partnerType === 'SHOP') {
        relevantPart = link.targets.SHOP || link.targets.MP;
      } else {
        relevantPart = link.targets.MP || link.targets.SHOP;
      }

      return {
        key: link.key,
        ...relevantPart,
      };
    },
  },
  mutations: {
    addLink(state, link) {
      state.currentLinks.push(link);
    },
    removeLink(state, linkKey) {
      // only remove link once even if it could be included multiple times
      const index = state.currentLinks.findIndex(
        (link) => link.key === linkKey
      );
      if (index >= 0) {
        state.currentLinks.splice(index, 1);
      }
    },
    setLinkData(state, linkData) {
      state.linkData[linkData.key] = linkData;
    },
    setHighlightLinkKey(state, value) {
      state.highlightLinkKey = value;
    },
  },
  actions: {
    async addLink({ commit, dispatch, state, getters }, linkKey) {
      if (!state.linkData[linkKey]) {
        return;
      }

      await dispatch('fetchLinkMetaData', linkKey);

      commit('addLink', state.linkData[linkKey]);

      return getters.relevantLink(state.linkData[linkKey]);
    },
    async init({ dispatch }) {
      Object.values(smartFaqLinks).forEach((link) =>
        dispatch('addLinkConfig', link)
      );
    },
    async addLinkConfig({ commit, state }, link) {
      if (state.linkData[link.key]) {
        return;
      }

      const linkData = {
        key: link.key,
        targets: {},
      };

      Object.entries(link.targets).forEach(([target, targetConfig]) => {
        linkData.targets[target] = {
          ...targetConfig,
          ...getHelpLinks(targetConfig.articleId, {
            spreadshop: target === 'SHOP',
            isCategory: targetConfig.isCategory,
            isSection: targetConfig.isSection,
          }),
        };
      });

      commit('setLinkData', linkData);
    },
    async fetchLinkMetaData({ commit, state }, linkKey) {
      if (
        state.linkData[linkKey].targets.MP?.title ||
        state.linkData[linkKey].targets.SHOP?.title
      ) {
        return;
      }

      const linkData = state.linkData[linkKey];

      await Promise.all(
        Object.values(linkData.targets).map(async (targetConfig) => {
          const meta = await apiClient.request({
            method: 'GET',
            url: targetConfig.apiUrl,
            withCredentials: false,
          });
          targetConfig.title =
            meta.data.article?.title || meta.data.section?.name;
        })
      );

      commit('setLinkData', linkData);
    },
  },
};
