<template>
  <div>
    <div class="box">
      <div class="box-header">
        <h2>{{ $t('ACCOUNT.PERSONAL.HEADING') }}</h2>
      </div>
      <div class="box-content">
        <PersonalDataForm :updatePdvState="updatePdvState" />
        <AccountPageActionsAVS />
      </div>
    </div>
    <div class="box">
      <div class="box-header">
        <h2>{{ $t('ACCOUNT.EMAIL.HEADING') }}</h2>
      </div>
      <div class="box-content">
        <EmailForm />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalDataForm from './PersonalDataForm.vue';
import accountService from '@/api/accountService/accountService';
import AccountPageActionsAVS from 'src/app/partnerarea/account/AccountPageActionsAVS.vue';
import EmailForm from './EmailForm.vue';

export default {
  name: 'Personal',
  components: {
    PersonalDataForm,
    AccountPageActionsAVS,
    EmailForm,
  },
  data() {
    return {
      updatePdvState: accountService.updatePdvState,
    };
  },
};
</script>

<style lang="scss" scoped>
.box:first-child {
  margin-bottom: 24px;
}
</style>
