<template>
  <div class="loader">
    <Icon :icon="icon" />
    <h1>{{ $t(headline) }}</h1>
    <p v-if="description">{{ $t(description) }}</p>
  </div>
</template>

<script>
export default {
  name: 'LoaderOverlay',
  props: {
    headline: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'savedisk',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.loader {
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  color: $white;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0.8;
  z-index: 10;
  animation: delay 0.4s;
}

h1 {
  margin: 0.25em 0 0.5em;
}

.icon {
  width: 4em;
  height: 4em;
}

p {
  max-width: 40em;
  line-height: 1.5;
}

@keyframes delay {
  0%,
  99% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}
</style>
