export default {
  inserted: function (el, bindings) {
    const maxLength = parseInt(bindings.value, 10);
    const input = el.querySelector('input');
    if (maxLength && input) {
      input.addEventListener('keypress', function (event) {
        if (event.target.value.length >= maxLength) {
          event.preventDefault();
        }
      });
    }
  },
};
