<template>
  <div>
    <div class="default-language">
      <p>
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT_LANGUAGE'
          )
        }}
      </p>
      <strong>{{ defaultLanguage }}</strong>
      <button
        class="btn text-btn link-blue"
        @click="openDefaultTranslationsDialog"
      >
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.DEFAULT_TRANSLATIONS.CHANGE_BUTTON'
          )
        }}
      </button>
    </div>
    <AdvancedPopover
      class="toggle-popover"
      :disabled="intPubDecision !== INTPUB_DECISION_STATES.REJECTED"
      heading="DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.REJECTED_HEADING"
      description="DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.REJECTED"
      :smartFaqLink="intPubHelpLink"
      v-if="translationsEditable"
    >
      <button
        v-if="enhancedTranslationControl"
        class="btn btn-light icon-btn"
        @click="openTranslationsDialog"
        :disabled="intPubDecision === INTPUB_DECISION_STATES.REJECTED"
      >
        <Icon icon="globe" />
        {{ $t('DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.BUTTON') }}
      </button>
      <div v-else class="autotranslate-toggle">
        <ToggleSwitch
          :model-value="requestedAutotranslations"
          @update:model-value="setAutotranslations"
          :disabled="intPubDecision === INTPUB_DECISION_STATES.REJECTED"
        />
        <p>
          {{
            $t(
              'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.SIMPLE_TOGGLE_LABEL'
            )
          }}
        </p>
      </div>
    </AdvancedPopover>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import INTPUB_DECISION_STATES from '@/states/intpubDecisionStates';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import dialogService from '@/dialogs/wrapper/dialogService';
import IdeaTranslationsDialog from 'src/app/partnerarea/idea/configuration/metaData/IdeaTranslationsDialog.vue';
import DefaultTranslationsDialog from 'src/app/partnerarea/idea/configuration/metaData/DefaultTranslationsDialog.vue';
import analyticsService from '@/tracking/analytics';

export default {
  name: 'IdeaTranslationControl',
  components: {
    ToggleSwitch,
    AdvancedPopover,
  },
  props: {
    idea: {
      require: true,
      type: Object,
    },
  },
  data() {
    return {
      INTPUB_DECISION_STATES,
      intPubHelpLink: smartFaqLinks.INTPUB,
    };
  },
  computed: {
    ...mapGetters({
      intPubDecision: 'idea/intPubDecision',
      requestedAutotranslations: 'idea/requestedAutotranslations',
      translationsEditable: 'dms/translationsEditable',
      getProperty: 'dms/getProperty',
      defaultLocale: 'idea/language',
      languageByIso: 'platform/languageByIso',
    }),
    enhancedTranslationControl() {
      return this.getProperty(DMS_PROPERTIES.INT_PUB_ADVANCED).value;
    },
    defaultLanguage() {
      return this.languageByIso(this.defaultLocale).name;
    },
  },
  methods: {
    ...mapMutations({
      setRequestedAutotranslations: 'idea/setRequestedAutotranslations',
      removeAllTranslationsExceptLocale:
        'idea/removeAllTranslationsExceptLocale',
    }),
    openTranslationsDialog() {
      dialogService.openDialog(IdeaTranslationsDialog);
    },
    openDefaultTranslationsDialog() {
      dialogService.openDialog(DefaultTranslationsDialog);
    },
    setAutotranslations(value) {
      this.setRequestedAutotranslations(value);

      analyticsService.logEvent('idea_autotranslation_toggle', {
        eventArea: 'idea-translations',
        value,
      });

      if (!value) {
        this.removeAllTranslationsExceptLocale({ locale: this.defaultLocale });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.toggle-popover {
  display: inline-block;
  margin-bottom: 18px;

  button {
    height: 40px;
  }

  .autotranslate-toggle {
    display: flex;
    align-items: center;

    p {
      margin: 0 0 0 12px;
    }
  }
}

.default-language {
  display: flex;
  margin-bottom: 22px;
  align-items: center;

  p {
    margin: 0;
  }

  strong {
    margin: 0 8px 0 12px;
  }
}
</style>
