<template>
  <span class="field-info">
    <small class="error-info">{{ errorText }}</small>
  </span>
</template>

<script>
export default {
  name: 'AccountError',
  props: {
    reason: {
      type: String,
      required: true,
    },
  },
  computed: {
    errorText() {
      const errorKey = `ACCOUNT.ERRORS.${this.reason}`;
      let text = this.$t(errorKey);
      // if there is no translation, use the generic error message
      if (errorKey === text) {
        text = this.$t('ACCOUNT.ERRORS.GENERAL_ERROR');
      }
      return text;
    },
  },
};
</script>
