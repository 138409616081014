import {
  getFontLineHeightFactor,
  getFontPaddingBottomFactor,
} from './socialMediaToolFontData';

const globalFontCache = {};
const globalImageCache = {};

const layout = {
  '500x500': {
    mainTextSize: 84,
    subTextSize: 40,
    shopNameTextSize: 30,
    mainTextY: 35,
    mainTextAloneY: 150,
    subTextY: 300,
    mainTextLines: 2,
    subTextLines: 2,
    stripeTextLines: 1,
  }, // customizer
  '1080x1920': {
    mainTextSize: 168,
    subTextSize: 80,
    shopNameTextSize: 60,
    mainTextY: 150,
    mainTextAloneY: 350,
    subTextY: 900,
    mainTextLines: 2,
    subTextLines: 3,
    stripeTextLines: 3,
  },
  '1080x1080': {
    mainTextSize: 168,
    subTextSize: 80,
    shopNameTextSize: 60,
    mainTextY: 60,
    mainTextAloneY: 350,
    subTextY: 600,
    mainTextLines: 2,
    subTextLines: 2,
    stripeTextLines: 1,
  },
  '1200x630': {
    mainTextSize: 120,
    subTextSize: 60,
    shopNameTextSize: 60,
    mainTextY: 20,
    mainTextAloneY: 250,
    subTextY: 300,
    mainTextLines: 1,
    subTextLines: 2,
    stripeTextLines: 1,
  },
  '600x600': {
    mainTextSize: 84,
    subTextSize: 40,
    shopNameTextSize: 30,
    mainTextY: 40,
    mainTextAloneY: 200,
    subTextY: 300,
    mainTextLines: 2,
    subTextLines: 2,
    stripeTextLines: 1,
  },
  '600x900': {
    mainTextSize: 84,
    subTextSize: 40,
    shopNameTextSize: 30,
    mainTextY: 100,
    mainTextAloneY: 300,
    subTextY: 450,
    mainTextLines: 2,
    subTextLines: 3,
    stripeTextLines: 3,
  },
  '1024x512': {
    mainTextSize: 100,
    subTextSize: 49,
    shopNameTextSize: 60,
    mainTextY: 20,
    mainTextAloneY: 200,
    subTextY: 200,
    mainTextLines: 1,
    subTextLines: 2,
    stripeTextLines: 1,
  },
};

// calculate a canvas covering image that keeps aspect ratio like CSS background: cover
// copy-pasta from https://stackoverflow.com/questions/33368723/how-to-scale-image-in-canvas
function drawCoveringImage(canvas, img, x, y, width, height, offsetX, offsetY) {
  // default offset is center
  offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
  offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

  const imageWidth = img.width;
  const imageHeight = img.height;
  const ratio = Math.min(width / imageWidth, height / imageHeight);
  let newWidth = imageWidth * ratio; // new prop. width
  let newHeight = imageHeight * ratio; // new prop. height
  let aspectRatio = 1;

  // decide which gap to fill
  if (newWidth < width) {
    aspectRatio = width / newWidth;
  }
  if (Math.abs(aspectRatio - 1) < 1e-14 && newHeight < height) {
    aspectRatio = height / newHeight;
  } // updated
  newWidth *= aspectRatio;
  newHeight *= aspectRatio;

  // calculate source rectangle
  let calcWidth = imageWidth / (newWidth / width);
  let calcHeight = imageHeight / (newHeight / height);

  let calcX = (imageWidth - calcWidth) * offsetX;
  let calcY = (imageHeight - calcHeight) * offsetY;

  // make sure source rectangle is valid
  if (calcX < 0) {
    calcX = 0;
  }
  if (calcY < 0) {
    calcY = 0;
  }
  if (calcWidth > imageWidth) {
    calcWidth = imageWidth;
  }
  if (calcHeight > imageHeight) {
    calcHeight = imageHeight;
  }

  // fill image in destination rectangle
  canvas
    .getContext('2d')
    .drawImage(img, calcX, calcY, calcWidth, calcHeight, x, y, width, height);
}

function drawLogoImage(canvas, img, margin) {
  const targetWith = Math.min(canvas.width / 3, img.width);
  const targetHeight = (targetWith / img.width) * img.height; // scale height down proportionally
  const xOffset = Math.max(0, canvas.width - targetWith - margin);
  const yOffset = canvas.height - margin - targetHeight;
  canvas
    .getContext('2d')
    .drawImage(img, xOffset, yOffset, targetWith, targetHeight);
}

// assembles the individual words into the minimal number of lines a text would need in a given font size and having the full canvas width available.
// returns a list of strings (one per line) or null if it does not fit at all.
function assembleIntoLines(canvas, words, fontSize, font) {
  const textContext = canvas.getContext('2d');
  const maxWidth = canvas.width;
  textContext.font = `${fontSize}px ${font}`;
  textContext.textAlign = 'center';

  let lastLine = words[0];
  const result = [];

  for (let i = 1; i < words.length; i++) {
    if (textContext.measureText(lastLine).width > maxWidth) {
      return null;
    }
    const newLine = lastLine + ' ' + words[i];
    if (textContext.measureText(newLine).width > maxWidth) {
      result.push(lastLine);
      lastLine = words[i];
    } else {
      lastLine = newLine;
    }
  }

  if (textContext.measureText(lastLine).width > maxWidth) {
    return null;
  }
  result.push(lastLine);
  return result;
}

// calculate a font size so that a given text does not have more than n lines.
// returns the calculated font size as well as the lines in an array.
function fitText(canvas, text, baseFontSize, font, maxLines) {
  let fontSize = baseFontSize;
  let attempts = 0;
  let lines = 0;

  while (attempts < 20) {
    attempts++;
    lines = assembleIntoLines(canvas, text.split(' '), fontSize, font);
    if (lines === null || lines.length > maxLines) {
      fontSize = fontSize * 0.9;
    } else {
      return { fontSize, lines };
    }
  }
  return { fontSize, lines };
}

function fitWhiteBox(canvas, customText, font, d) {
  const { fontSize, lines } = fitText(
    canvas,
    customText,
    d.mainTextSize,
    font,
    d.stripeTextLines
  );
  const regularHeight =
    fontSize * (lines.length - 1) * getFontLineHeightFactor(font);
  const padding = fontSize * getFontPaddingBottomFactor(font);
  return { fontSize, lines, height: regularHeight + padding };
}

function drawText(
  canvas,
  lines,
  fontSize,
  color,
  x,
  y,
  textAlign,
  font,
  textBaseline
) {
  const textContext = canvas.getContext('2d');
  textContext.fillStyle = color;
  textContext.font = `${fontSize}px ${font}`;
  textContext.textAlign = textAlign;
  textContext.textBaseline = textBaseline;
  let yOffset = y;
  lines.forEach((line) => {
    textContext.fillText(line, x, yOffset);
    yOffset += fontSize * getFontLineHeightFactor(font);
  });
}

function drawWhiteBox(canvas, height) {
  const boxContext = canvas.getContext('2d');
  boxContext.fillStyle = 'rgba(255, 255, 255, 0.8)';
  boxContext.fillRect(0, 0, canvas.width, height);
}

export function loadImage(src) {
  if (globalImageCache[src]) {
    return Promise.resolve(globalImageCache[src]);
  }
  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = src;
    image.onload = () => {
      globalImageCache[src] = image;
      resolve(image);
    };
  });
}

export async function loadFont(name, src) {
  if (globalFontCache[name]) {
    return globalFontCache[name].family;
  }
  const fontFace = new FontFace(name, `url(${src})`);
  await fontFace.load();
  globalFontCache[name] = fontFace;
  window.document.fonts.add(fontFace);
  return fontFace.family;
}

export function downloadCanvas(canvas, fileName) {
  const link = document.createElement('a');
  link.download = `${fileName}.png`;
  link.href = canvas.toDataURL('image/png;base64');
  link.click();
}

export function drawImage({
  canvas,
  backgroundImage,
  productImage,
  promoText,
  customText,
  textColor,
  logoImage,
  shopName,
  fontHeadline,
  fontText,
  isModel,
}) {
  if (!canvas) {
    return; // avoid some mount / unmount race conditions
  }
  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
  const d = layout[`${canvas.width}x${canvas.height}`];
  const whiteBoxLayout =
    productImage && customText
      ? fitWhiteBox(canvas, customText, fontHeadline, d)
      : { height: 0 };

  if (backgroundImage) {
    drawCoveringImage(
      canvas,
      backgroundImage,
      0,
      0,
      canvas.width,
      canvas.height
    );
  }

  if (promoText) {
    const { fontSize, lines } = fitText(
      canvas,
      promoText,
      d.mainTextSize,
      fontHeadline,
      d.mainTextLines
    );
    const yOffset = customText ? d.mainTextY : d.mainTextAloneY;
    drawText(
      canvas,
      lines,
      fontSize,
      textColor,
      canvas.width / 2,
      yOffset,
      'center',
      fontHeadline,
      'top'
    );
  }

  if (productImage) {
    let targetWidth;
    let yOffset;
    if (isModel) {
      targetWidth = Math.min(canvas.width, canvas.height);
      yOffset = canvas.height - targetWidth; // model images should be bottom-aligned
    } else {
      // ensure that the white box does not overlap with the product image
      targetWidth = Math.min(
        canvas.width,
        canvas.height - whiteBoxLayout.height
      );
      yOffset = (canvas.height - targetWidth + whiteBoxLayout.height) / 2; // product images should be centered
    }
    canvas
      .getContext('2d')
      .drawImage(
        productImage,
        (canvas.width - targetWidth) / 2,
        yOffset,
        targetWidth,
        targetWidth
      );
  }
  if (customText) {
    if (productImage) {
      drawWhiteBox(canvas, whiteBoxLayout.height);
      drawText(
        canvas,
        whiteBoxLayout.lines,
        whiteBoxLayout.fontSize,
        '#000000',
        canvas.width / 2,
        whiteBoxLayout.fontSize * 0.3,
        'center',
        fontHeadline,
        'top'
      );
    } else {
      const { fontSize, lines } = fitText(
        canvas,
        customText,
        d.subTextSize,
        fontText,
        d.subTextLines
      );
      drawText(
        canvas,
        lines,
        fontSize,
        textColor,
        canvas.width / 2,
        d.subTextY,
        'center',
        fontText,
        'top'
      );
    }
  }

  const logoMargin = 0.2 * d.shopNameTextSize;
  if (shopName) {
    const { fontSize, lines } = fitText(
      canvas,
      shopName,
      d.shopNameTextSize,
      fontText,
      1
    );
    drawText(
      canvas,
      lines,
      fontSize,
      textColor,
      canvas.width - logoMargin,
      canvas.height - fontSize - logoMargin,
      'right',
      fontText,
      'top'
    );
  } else if (logoImage) {
    drawLogoImage(canvas, logoImage, logoMargin);
  }
}
