import { shopAPI } from 'src/app/commons/api/shop/shopAPI';
import { isToday, differenceInCalendarDays } from 'date-fns';

function saveToCache(data, userId) {
  localStorage.setItem(
    `tracking-metrics`,
    JSON.stringify({
      data,
      timestamp: Date.now(),
      userId: userId,
    })
  );
}

function getTrafficFromCache(userId) {
  try {
    const cache = JSON.parse(localStorage.getItem('tracking-metrics'));
    const isStale = !isToday(new Date(cache?.timestamp));
    const fromDifferentUser = cache?.userId !== userId;

    if (isStale || fromDifferentUser) {
      localStorage.removeItem('tracking-metrics');

      return {};
    }

    return cache?.data || {};
  } catch (error) {
    return {};
  }
}

export default {
  namespaced: true,
  state: {
    data: {},
    filters: {
      pos: null,
      time: null,
    },
    hasError: false,
  },
  getters: {
    rawMetrics: (state) => state.data[state.filters?.pos?.value],
    shopMetrics: (state, getters) =>
      getters.rawMetrics &&
      getters.rawMetrics.filter(
        (e) =>
          differenceInCalendarDays(Date.now(), new Date(e.date)) <=
          state.filters.time.duration
      ),
    totalVisits: (_, getters) =>
      getters.shopMetrics &&
      getters.shopMetrics.reduce((x, y) => x + y.visits, 0),
  },
  actions: {
    async fetchTrackingMetrics({ state, commit, rootState }) {
      commit('setMetrics', {});

      const shopId = state.filters.pos.value;
      const userId = rootState.user.data.id;

      let data = getTrafficFromCache(userId);

      if (!Object.keys(data).includes(shopId)) {
        try {
          const { traffic } = await shopAPI.getTrackingMetrics(shopId);

          data[shopId] = traffic;
          saveToCache(data, userId);
        } catch (error) {
          commit('setError', true);

          return null;
        }
      }

      commit('setMetrics', data);
      commit('setError', false);
    },
    clearCache({ rootState }, { purge = false } = {}) {
      const userId = rootState.user.data.id;

      if (purge) {
        localStorage.removeItem('tracking-metrics');
      } else {
        getTrafficFromCache(userId);
      }
    },
  },
  mutations: {
    setMetrics(state, data) {
      state.data = data;
    },
    setFilters(state, filters) {
      state.filters.pos = filters.pos;
      state.filters.time = filters.time;
    },
    setError(state, data) {
      state.hasError = data;
    },
  },
};
