import { deepCopy } from 'src/app/commons/utils';

const localeMapping = {
  //EU
  '.co.uk': 'en_GB',
  '.de': 'de_DE',
  '.fr': 'fr_FR',
  '.be': 'fr_BE',
  '.dk': 'da_DK',
  '.es': 'es_ES',
  '.ie': 'en_IE',
  '.it': 'it_IT',
  '.no': 'no_NO',
  '.at': 'de_AT',
  '.pl': 'pl_PL',
  '.ch': 'de_CH',
  '.fi': 'fi_FI',
  '.se': 'sv_SE',
  '.nl': 'nl_NL',
  '.net': 'en_GB',

  //NA
  '.com': 'en_US',
  '.ca': 'en_CA',
  '.com.au': 'en_AU',

  //VM
  '.co.vuk': 'en_GB',
  '.vde': 'de_DE',
  '.vfr': 'fr_FR',
  '.vbe': 'fr_BE',
  '.vdk': 'da_DK',
  '.ves': 'es_ES',
  '.vie': 'en_IE',
  '.vit': 'it_IT',
  '.vno': 'no_NO',
  '.vat': 'de_AT',
  '.vpl': 'pl_PL',
  '.vch': 'de_CH',
  '.vfi': 'fi_FI',
  '.vse': 'sv_SE',
  '.vnl': 'nl_NL',
  '.vcom': 'en_US',
  '.vca': 'en_CA',
  '.com.vau': 'en_AU',
  '.vnet': 'en_GB',
};

const languageToLocaleMapping = {
  EU: {
    en: 'en_GB',
    de: 'de_DE',
    fr: 'fr_FR',
    da: 'da_DK',
    es: 'es_ES',
    it: 'it_IT',
    no: 'no_NO',
    pl: 'pl_PL',
    fi: 'fi_FI',
    sv: 'sv_SE',
    nl: 'nl_NL',
  },
  NA: {
    en: 'en_US',
    fr: 'fr_CA',
  },
};

const domainMapping = {
  //EU
  'co.uk': 'co.vuk',
  de: 'vde',
  fr: 'vfr',
  be: 'vbe',
  dk: 'vdk',
  es: 'ves',
  ie: 'vie',
  it: 'vit',
  no: 'vno',
  at: 'vat',
  pl: 'vpl',
  ch: 'vch',
  fi: 'vfi',
  se: 'vse',
  nl: 'vnl',
  net: 'vnet',

  //NA
  com: 'vcom',
  ca: 'vca',
  'com.au': 'com.vau',
};

const nonDefaultLocales = ['nl_BE', 'it_CH', 'fr_CH', 'fr_CA'];

//special cases like switzerland with languages it and fr are handled with a lang-parameter in the url
//so partner.spreadshirt.ch?lang=it will result in a locale it_CH
const specialCases = {
  '.be': {
    nl: 'nl_BE',
  },
  '.ch': {
    it: 'it_CH',
    fr: 'fr_CH',
  },
  '.ca': {
    fr: 'fr_CA',
  },

  // VM
  '.vbe': {
    nl: 'nl_BE',
  },
  '.vch': {
    it: 'it_CH',
    fr: 'fr_CH',
  },
  '.vca': {
    fr: 'fr_CA',
  },
};

const sprdLocaleMapping = {
  en_US: 'us_US',
  sv_SE: 'se_SE',
  da_DK: 'dk_DK',

  en_CA: 'us_CA',
  en_AU: 'us_AU',
};

const sprdLanguageMapping = {
  EU: {
    da: 'dk',
    sv: 'se',
  },
  NA: {
    en: 'us',
  },
};

//taken from https://spaces.spreadomat.net/display/BU/D2C+Shop+IDs
const shopIdForLocale = {
  de_DE: 783914,
  de_AT: 591997,
  en_GB: 629655,
  en_IE: 404968,
  fr_FR: 595780,
  nl_NL: 595765,
  nl_BE: 595786,
  fr_BE: 595784,
  no_NO: 595768,
  fi_FI: 595964,
  sv_SE: 595776,
  da_DK: 595778,
  es_ES: 595774,
  it_IT: 595772,
  pl_PL: 595770,

  en_US: 349065,
  en_CA: 349069,
  fr_CA: 371368,
  en_AU: 588650,
};

const localeInformationNonOPS = {
  EU: [
    {
      locale: 'en_GB',
      platform: 'EU',
      name: 'United Kingdom',
      domain: '.co.vuk',
      countryName: 'United Kingdom',
      languageName: 'English',
    },
    {
      locale: 'de_DE',
      platform: 'EU',
      name: 'Deutschland',
      domain: '.vde',
      countryName: 'Deutschland',
      languageName: 'Deutsch',
    },
    {
      locale: 'fr_FR',
      platform: 'EU',
      name: 'France',
      domain: '.vfr',
      countryName: 'France',
      languageName: 'Français',
    },
    {
      locale: 'nl_BE',
      platform: 'EU',
      name: 'Belgie (Nederlands)',
      domain: '.vbe',
      lang: 'nl',
      countryName: 'Belgie',
      languageName: 'Nederlands',
    },
    {
      locale: 'fr_BE',
      platform: 'EU',
      name: 'Belgie (Français)',
      domain: '.vbe',
      countryName: 'Belgie',
      languageName: 'Français',
    },
    {
      locale: 'da_DK',
      platform: 'EU',
      name: 'Danmark',
      domain: '.vdk',
      countryName: 'Danmark',
      languageName: 'Dansk',
    },
    {
      locale: 'es_ES',
      platform: 'EU',
      name: 'España',
      domain: '.ves',
      countryName: 'España',
      languageName: 'Español',
    },
    {
      locale: 'en_IE',
      platform: 'EU',
      name: 'Ireland',
      domain: '.vie',
      countryName: 'Ireland',
      languageName: 'English',
    },
    {
      locale: 'it_IT',
      platform: 'EU',
      name: 'Italia',
      domain: '.vit',
      countryName: 'Italia',
      languageName: 'Italiano',
    },
    {
      locale: 'nl_NL',
      platform: 'EU',
      name: 'Nederland',
      domain: '.vnl',
      countryName: 'Nederland',
      languageName: 'Nederlands',
    },
    {
      locale: 'no_NO',
      platform: 'EU',
      name: 'Norge',
      domain: '.vno',
      countryName: 'Norge',
      languageName: 'Norsk',
    },
    {
      locale: 'pl_PL',
      platform: 'EU',
      name: 'Polska',
      domain: '.vpl',
      countryName: 'Polska',
      languageName: 'Polski',
    },
    {
      locale: 'de_CH',
      platform: 'EU',
      name: 'Schweiz (Deutsch)',
      domain: '.vch',
      countryName: 'Schweiz',
      languageName: 'Deutsch',
    },
    {
      locale: 'fr_CH',
      platform: 'EU',
      name: 'Suisse (Français)',
      domain: '.vch',
      lang: 'fr',
      countryName: 'Suisse',
      languageName: 'Français',
    },
    {
      locale: 'it_CH',
      platform: 'EU',
      name: 'Svizzera (Italiano)',
      domain: '.vch',
      lang: 'it',
      countryName: 'Svizzera',
      languageName: 'Italiano',
    },
    {
      locale: 'fi_FI',
      platform: 'EU',
      name: 'Suomi',
      domain: '.vfi',
      countryName: 'Suomi',
      languageName: 'Suomi',
    },
    {
      locale: 'sv_SE',
      platform: 'EU',
      name: 'Sverige',
      domain: '.vse',
      countryName: 'Sverige',
      languageName: 'Svenska',
    },
    {
      locale: 'de_AT',
      platform: 'EU',
      name: 'Österreich',
      domain: '.vat',
      countryName: 'Österreich',
      languageName: 'Deutsch',
    },
  ],
  NA: [
    {
      locale: 'en_US',
      platform: 'NA',
      name: 'United States',
      domain: '.vcom',
      countryName: 'United States',
      languageName: 'English',
    },
    {
      locale: 'fr_CA',
      platform: 'NA',
      name: 'Canada (Français)',
      domain: '.vca',
      lang: 'fr',
      countryName: 'Canada',
      languageName: 'Français',
    },
    {
      locale: 'en_CA',
      platform: 'NA',
      name: 'Canada (English)',
      domain: '.vca',
      countryName: 'Canada',
      languageName: 'English',
    },
    {
      locale: 'en_AU',
      platform: 'NA',
      name: 'Australia',
      domain: '.com.vau',
      countryName: 'Australia',
      languageName: 'English',
    },
  ],
};

const localeInformation = {
  OPS: {
    EU: [
      {
        locale: 'en_GB',
        platform: 'EU',
        name: 'United Kingdom',
        domain: '.co.uk',
        countryName: 'United Kingdom',
        languageName: 'English',
      },
      {
        locale: 'de_DE',
        platform: 'EU',
        name: 'Deutschland',
        domain: '.de',
        countryName: 'Deutschland',
        languageName: 'Deutsch',
      },
      {
        locale: 'fr_FR',
        platform: 'EU',
        name: 'France',
        domain: '.fr',
        countryName: 'France',
        languageName: 'Français',
      },
      {
        locale: 'fr_BE',
        platform: 'EU',
        name: 'Belgie (Français)',
        domain: '.be',
        countryName: 'Belgie',
        languageName: 'Français',
      },
      {
        locale: 'da_DK',
        platform: 'EU',
        name: 'Danmark',
        domain: '.dk',
        countryName: 'Danmark',
        languageName: 'Dansk',
      },
      {
        locale: 'es_ES',
        platform: 'EU',
        name: 'España',
        domain: '.es',
        countryName: 'España',
        languageName: 'Español',
      },
      {
        locale: 'en_IE',
        platform: 'EU',
        name: 'Ireland',
        domain: '.ie',
        countryName: 'Ireland',
        languageName: 'English',
      },
      {
        locale: 'it_IT',
        platform: 'EU',
        name: 'Italia',
        domain: '.it',
        countryName: 'Italia',
        languageName: 'Italiano',
      },
      {
        locale: 'nl_NL',
        platform: 'EU',
        name: 'Nederland',
        domain: '.nl',
        countryName: 'Nederland',
        languageName: 'Nederlands',
      },
      {
        locale: 'nl_BE',
        platform: 'EU',
        name: 'Belgie (Nederlands)',
        domain: '.be',
        lang: 'nl',
        countryName: 'Belgie',
        languageName: 'Nederlands',
      },
      {
        locale: 'no_NO',
        platform: 'EU',
        name: 'Norge',
        domain: '.no',
        countryName: 'Norge',
        languageName: 'Norsk',
      },
      {
        locale: 'pl_PL',
        platform: 'EU',
        name: 'Polska',
        domain: '.pl',
        countryName: 'Polska',
        languageName: 'Polski',
      },
      {
        locale: 'de_CH',
        platform: 'EU',
        name: 'Schweiz (Deutsch)',
        domain: '.ch',
        countryName: 'Schweiz',
        languageName: 'Deutsch',
      },
      {
        locale: 'fr_CH',
        platform: 'EU',
        name: 'Suisse (Français)',
        domain: '.ch',
        lang: 'fr',
        countryName: 'Suisse',
        languageName: 'Français',
      },
      {
        locale: 'it_CH',
        platform: 'EU',
        name: 'Svizzera (Italiano)',
        domain: '.ch',
        lang: 'it',
        countryName: 'Svizzera',
        languageName: 'Italiano',
      },
      {
        locale: 'fi_FI',
        platform: 'EU',
        name: 'Suomi',
        domain: '.fi',
        countryName: 'Suomi',
        languageName: 'Suomi',
      },
      {
        locale: 'sv_SE',
        platform: 'EU',
        name: 'Sverige',
        domain: '.se',
        countryName: 'Sverige',
        languageName: 'Svenska',
      },
      {
        locale: 'de_AT',
        platform: 'EU',
        name: 'Österreich',
        domain: '.at',
        countryName: 'Österreich',
        languageName: 'Deutsch',
      },
    ],
    NA: [
      {
        locale: 'en_US',
        platform: 'NA',
        name: 'United States',
        domain: '.com',
        countryName: 'United States',
        languageName: 'English',
      },
      {
        locale: 'fr_CA',
        platform: 'NA',
        name: 'Canada (Français)',
        domain: '.ca',
        lang: 'fr',
        countryName: 'Canada',
        languageName: 'Français',
      },
      {
        locale: 'en_CA',
        platform: 'NA',
        name: 'Canada (English)',
        domain: '.ca',
        countryName: 'Canada',
        languageName: 'English',
      },
      {
        locale: 'en_AU',
        platform: 'NA',
        name: 'Australia',
        domain: '.com.au',
        countryName: 'Australia',
        languageName: 'English',
      },
    ],
  },
  QA: localeInformationNonOPS,
};

const getParameterByName = function (name, url) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search || url);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const hostnameContainsDomain = function (domain) {
  const host = window.location.hostname;
  return host.indexOf(domain, host.length - domain.length) !== -1;
};

const getLocale = function () {
  for (const domain in localeMapping) {
    if (hostnameContainsDomain(domain)) {
      // handle special cases
      const langParam =
        getParameterByName('lang') ||
        getParameterByName('lang', window.ORIGINAL_ENTRY_URL);
      if (
        specialCases[domain] &&
        langParam &&
        specialCases[domain][langParam]
      ) {
        return specialCases[domain][langParam];
      }

      return localeMapping[domain];
    }
  }

  if (SPRD.LOCALE_DEFAULT) {
    return SPRD.LOCALE_DEFAULT;
  }
};

const getIsoLocale = function () {
  return getLocale().replace('_', '-');
};

const convertToSprdLocale = function (locale) {
  return sprdLocaleMapping[locale] || locale;
};

const convertToSprdLanguage = function (language) {
  return sprdLanguageMapping[SPRD.PLATFORM][language] || language;
};

const convertFromSprdLocale = function (sprdLocale) {
  return (
    Object.keys(sprdLocaleMapping).find(
      (locale) => sprdLocaleMapping[locale] === sprdLocale
    ) || sprdLocale
  );
};

const convertFromSprdLanguage = function (sprdLanguage) {
  return (
    Object.keys(sprdLanguageMapping[SPRD.PLATFORM]).find(
      (language) =>
        sprdLanguageMapping[SPRD.PLATFORM][language] === sprdLanguage
    ) || sprdLanguage
  );
};

const getShopIdForLocale = function (locale) {
  return shopIdForLocale[locale];
};

const getLanguage = function () {
  const locale = getLocale();
  return locale ? locale.split('_')[0] : undefined;
};

const getLanguageFromLocale = function (locale) {
  return locale ? locale.split('_')[0] : undefined;
};

const getSprdLanguage = function () {
  const locale = convertToSprdLocale(getLocale());
  return locale ? locale.split('_')[0] : undefined;
};

const getCountry = function () {
  const locale = getLocale();
  return locale ? locale.split('_')[1] : undefined;
};

const getCountryFromLocale = function (locale) {
  return locale ? locale.split('_')[1] : undefined;
};

const isNonDefaultLocale = function () {
  const locale = getLocale();
  return nonDefaultLocales.indexOf(locale) >= 0;
};

const getTld = () => {
  const hostnameParts = window.location.hostname.split('.');
  if (hostnameParts.length === 1) {
    return hostnameParts[0]; // localhost case
  } else if (hostnameParts.length === 3 || hostnameParts.length === 4) {
    return hostnameParts.slice(2).join('.'); // allows for xxx.yyy.de as well as xxx.yyy.co.uk
  } else {
    throw Error(
      `Failed to extract tld from hostname ${window.location.hostname}`
    );
  }
};

const getSprdUrl = (options = {}) => {
  // get domain without subdomain and port
  let domain = `${window.location.protocol}//${
    options.subdomain || 'www'
  }.${window.location.hostname
    .replace('localpartner.', '')
    .replace('partner.', '')
    .replace('www.', '')
    .replace('rentrap.', '')}`;

  if (!options.omitLanguage && isNonDefaultLocale()) {
    domain += `/${getLanguage()}`;
  }

  return domain;
};

const convertLanguageToLocale = (language) => {
  return languageToLocaleMapping[SPRD.PLATFORM][language];
};

export default {
  getLocale,
  getIsoLocale,
  convertToSprdLocale,
  convertToSprdLanguage,
  getShopIdForLocale,
  getLanguage,
  getLanguageFromLocale,
  getSprdLanguage,
  getCountry,
  getCountryFromLocale,
  isNonDefaultLocale,
  getLocaleInformation: function () {
    return deepCopy(localeInformation[SPRD.ENVIRONMENT][SPRD.PLATFORM]);
  },
  convertFromSprdLocale,
  convertFromSprdLanguage,
  getSprdUrl,
  getVDomain: (domain) => domainMapping[domain] || domain,
  getTld,
  convertLanguageToLocale,
};
