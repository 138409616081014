<template>
  <div class="activation-bar">
    <button class="btn btn-ghost position-relative" @click="activate">
      <div>
        <Icon icon="rocket"></Icon>
        {{ $t('SA.inactiveBanner.button') }}
      </div>
    </button>

    <div class="activation-bar-text">
      <h2>{{ $t('SA.inactiveBanner.headline') }}</h2>
      <div v-if="!settings.shopLaunched">
        {{ $t('SA.inactiveBanner.description') }}
      </div>
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { ActivationMixin } from './ActivationMixin';

export default {
  name: 'ShopActivationBar',
  mixins: [SettingsData, ActivationMixin],
  methods: {
    activate() {
      this.toggleShopStatus();
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-bar {
  z-index: 1;
  align-items: center;
  background-color: #ff9343;
  display: flex;
  justify-content: center;
  height: 78px;
  padding: 1em;

  .btn {
    height: 50px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-color: #fff;
    color: #fff;

    &:hover,
    &:focus {
      border-color: darken(#fff, 5%);
      div {
        color: darken(#fff, 5%);
      }
    }

    & > div > .icon {
      width: 25px;
      height: 25px;
    }
  }
}

.activation-bar-text {
  text-align: center;
  color: #fff;
  flex-grow: 1;

  h2 {
    margin: 0;
  }
}
</style>
